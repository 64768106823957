import { ReactElement, useCallback, useEffect, useState } from 'react';

import { useStoreActions, useStoreState } from '~/store/hooks';
import api from '~/services/api';

import useIsMounted from '~/ui/pages/Coordinate/hooks/useIsMounted';
import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';
import { extractErrorMessage } from '~/utils/error/error';
import { isFile } from '~/utils/file';

import Form from './Form';
import formatResourceDefaultValues from '../../helpers/formatResourceDefaultValues';
import getFileTypeByExt from '../../helpers/getFileTypeByExt';

import { IFormValues } from './types';

import styles from './ResourcesModal.module.scss';

interface IProps {
  resourceId?: number;
  onClose: () => void;
}

const ResourcesModal = ({ resourceId, onClose }: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const resource = useStoreState(store => store.resource.current);

  const { onCreateResource, onUpdateResource, onGetResource } = useStoreActions(
    actions => actions.resource,
  );

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onSubmit = async (values: IFormValues, evt: any) => {
    const url = values.externalUrl;
    setProcessing(true);
    const { name } = evt.nativeEvent.submitter;

    if (isFile(values.externalUrl)) {
      const formData = new FormData();
      formData.append('file', values.externalUrl);
      try {
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        values.externalUrl = fileName;
      } catch (e) {
        showError(extractErrorMessage(e));
      }
    }

    if (!values.isVisible) {
      values.isVisible = false;
    }

    try {
      if (name) {
        await onUpdateResource({
          requestInfo: { id: resourceId },
          requestPayload: values,
        });
      } else {
        values.type = getFileTypeByExt((url as unknown as File).name);
        await onCreateResource(values);
      }

      showNotify({ message: `Resource successfully ${name ? 'updated' : 'added'}` });
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  const isMounted = useIsMounted();

  const onMount = useCallback(async () => {
    if (!resourceId || !isMounted()) {
      return;
    }
    try {
      setLoading(true);
      await onGetResource(resourceId);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [resourceId, onGetResource, showError, isMounted]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Modal className={styles.modal} open onClose={onClose}>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.modalWrapper}>
          <h3 className={styles.modalTitle}>{`${
            resource?.name ? 'Edit' : 'Add'
          } Resource File`}</h3>
          <Form
            defaultValues={formatResourceDefaultValues(resource)}
            loading={processing}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        </div>
      )}
    </Modal>
  );
};

export default ResourcesModal;

import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from 'react-hook-form';

import { IClientDetailed } from '~/services/api/client/types';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import { IClientStatus } from '~/services/api/types';
import Button from '~/ui/components/common/Button';
import DatePickerNew from '~/ui/components/inputs/DatePickerNew';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import { getLocalDateNoFormat, setNoon } from '~/utils/date/date';
import normalizeClientStatusDates from '~/ui/pages/MedicationLogistics/helpers/normalizeClientStatusDates';

import StatusHistoryItem from './StatusHistoryItem';
import { statusOptions } from '../../constants/statusOptions';
import ClientStatus from '../../models/clientStatus';
import styles from './Status.module.scss';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
}

const Status = ({ client, clinicId, actTeamId }: IProps): ReactElement => {
  const [showHistory, setShowHistory] = useState(false);

  const {
    status: { current, previous: previousStatuses },
  } = useStoreState(state => state.clientDetails) || {};

  const previous = normalizeClientStatusDates(previousStatuses, current);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onChangeClientStatus, onChangeClientStatusDate, onGetClientStatus } = useStoreActions(
    actions => actions.clientDetails,
  );

  const initialDate =
    current?.value === ClientStatus.ACTIVE ? current?.dateOfRelease : current?.dateOfEntry;

  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      status: current?.value || null,
      date: initialDate ? new Date(setNoon(initialDate)) : null,
    },
  });

  const { status } = watch();

  const isStatusActive = status === ClientStatus.ACTIVE;
  const isSameStatus = status === current?.value;

  // send change client status request
  const onSubmit = async (newDate: Date) => {
    newDate.setHours(12);
    if (newDate.toISOString() === initialDate && status === current?.value) {
      return;
    }

    const formattedDate = newDate.toISOString().split('T')[0];
    try {
      const requestInfo = { clinicId, teamId: actTeamId, clientId: String(client.id) };
      const payload = {
        requestInfo,
        status: {
          value: status,
          dateOfEntry: !isStatusActive ? formattedDate : undefined,
          dateOfRelease: isStatusActive ? formattedDate : undefined,
        },
      };
      if (status === current?.value) {
        await onChangeClientStatusDate({ requestInfo, dateOfEntry: formattedDate });
      } else {
        await onChangeClientStatus(payload);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onCancel = () => {
    setValue('date', initialDate ? new Date(setNoon(initialDate)) : null);
    setValue('status', current?.value || null);
  };

  const onMount = useCallback(async () => {
    if (Number(clinicId) && actTeamId && client.id) {
      try {
        await onGetClientStatus({ clinicId, teamId: actTeamId, clientId: String(client.id) });
      } catch (e) {
        showError(extractErrorMessage(e));
      }
    }
  }, [clinicId, actTeamId, client.id, onGetClientStatus, showError]);

  // get client current status and history on mount
  useEffect(() => {
    onMount();
  }, [onMount]);

  // open date picker on status change
  useEffect(() => {
    if (status !== current?.value) {
      const picker = document.getElementsByClassName(
        'controlledDatePicker',
      )?.[0] as HTMLButtonElement;
      if (picker) {
        setTimeout(() => {
          picker.click();
        }, 300);
      }
    }
  }, [status, current?.value]);

  const getIcon = (expanded: boolean) =>
    !expanded ? <ExpandMoreIcon color="primary" /> : <ExpandLessIcon color="primary" />;

  const renderItem = (item: IClientStatus, index: number) => (
    <StatusHistoryItem item={item} key={item?.value + index} isFirst={!index} />
  );

  const calendarPlaceholder = `Date of ${status === ClientStatus.ACTIVE ? 'Release' : 'Entry'}`;
  const calendarTitle = `Please enter the ${calendarPlaceholder}`;
  const minDate = isStatusActive
    ? getLocalDateNoFormat(current?.dateOfEntry || new Date()).toISOString()
    : getLocalDateNoFormat(
        current?.dateOfEntry || current?.dateOfRelease || new Date(),
      ).toISOString();

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={classNames(styles.row, styles.topBlock)}>
          <h4 className={styles.title}>Status</h4>
        </div>
        <Grid container spacing={2} className={styles.form}>
          <Grid item sm={6}>
            <Select
              name="status"
              options={statusOptions}
              control={control}
              errors={errors}
              label="Status"
              hideSelectedOptions={false}
            />
          </Grid>

          <Grid
            item
            sm={6}
            className={isStatusActive ? styles.noDateSelectionContainer : undefined}
          >
            <DatePickerNew
              control={control}
              errors={errors}
              name="date"
              label={calendarPlaceholder}
              className="controlledDatePicker"
              maxDate={new Date().toISOString()}
              minDate={
                isSameStatus || (!current?.dateOfEntry && !current?.dateOfRelease)
                  ? undefined
                  : minDate
              }
              title={calendarTitle}
              inputDisabled
              inputClassName={isStatusActive ? styles.noDateSelection : undefined}
              withConfirmation
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          </Grid>
        </Grid>
        <div className={styles.historyList}>
          {!!previous.length && (
            <Button
              variant="text"
              endIcon={getIcon(showHistory)}
              onClick={() => setShowHistory(prev => !prev)}
              color="primary"
              className={styles.showMoreButton}
            >
              {`${showHistory ? 'Hide' : 'Show'} Prior Statuses`}
            </Button>
          )}
          {showHistory && !!previous.length && previous.map(renderItem)}
        </div>
      </div>
    </div>
  );
};

export default Status;

import { action } from 'easy-peasy';

import { IActionSetTeamMemberDashboardReport } from '../types';

const setTeamMemberDashboardReport: IActionSetTeamMemberDashboardReport = action(
  (state, payload) => {
    state.teamMemberDashboard = payload;
  },
);

export default setTeamMemberDashboardReport;

import { getItem, setItem } from '.';
import { ISendbirdData, IUserData } from '../api/auth/types';
import { IPasswordExpirationData } from '../api/types';

interface ISetTokens {
  accessToken: string;
  refreshToken: string;
}

interface ISetTokens {
  accessToken: string;
  refreshToken: string;
}

export const getAccessToken = (): string | null => localStorage.getItem('accessToken');
export const getRefreshToken = (): string | null => localStorage.getItem('refreshToken');

export const setTokens = ({ accessToken, refreshToken }: ISetTokens): void => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

export const deleteTokens = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const setPasswordExpiration = (data: IPasswordExpirationData): void => {
  setItem('passwordExpiration', data);
};
export const getPasswordExpiration = (): IPasswordExpirationData | null =>
  getItem('passwordExpiration');
export const deletePasswordExpiration = (): void => {
  localStorage.removeItem('passwordExpiration');
};

export const setSendbirdData = (data: ISendbirdData): void => setItem('sendbirdData', data);
export const getSendbirdData = (): ISendbirdData => getItem('sendbirdData');
export const deleteSendbirdData = (): void => {
  localStorage.removeItem('sendbirdData');
};

export const setUserData = (data: IUserData): void => setItem('userData', data);
export const getUserData = (): IUserData => getItem('userData');
export const deleteUserData = (): void => {
  localStorage.removeItem('userData');
};

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ISystemAlertActions, IThunkGetSystemAlerts } from '../types';

const onGetSystemAlerts: IThunkGetSystemAlerts = thunk(
  async ({ setSystemAlerts }: Actions<ISystemAlertActions>, payload) => {
    const { items, total } = await api.systemAlert.getSystemAlerts(payload).then(r => r.data);
    const pagination = { ...payload, total };

    setSystemAlerts({ items, pagination });
  },
);

export default onGetSystemAlerts;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ISuperAdminActions, IThunkGetSuperAdmin } from '../types';

const onGetSuperAdmin: IThunkGetSuperAdmin = thunk(
  async ({ setSuperAdmin }: Actions<ISuperAdminActions>, superAdminId) => {
    const { data } = await api.superAdmin.getSuperAdmin(superAdminId);

    setSuperAdmin(data);
  },
);

export default onGetSuperAdmin;

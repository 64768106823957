import { ReactElement, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isPast } from 'date-fns';

import ListItem from '~/ui/components/common/ListItem';
import Status from '~/ui/components/common/Status';
import Button from '~/ui/components/common/Button';
import { TriggerType } from '~/services/api/clientDetails/types';
import { IHealthDetailGroup } from '../types';
import { setNoon } from '~/utils/date/date';

import circleIcon from '~/ui/assets/images/circle.svg';
import styles from './DetailsBlock.module.scss';

interface IProps {
  title: string;
  buttonText?: string;
  showArchivedButton?: boolean;
  showEdit?: boolean;
  showArchive?: boolean;
  showRestore?: boolean;
  healthDetailGroup: IHealthDetailGroup;
  labelGrey?: boolean;
  noActions?: boolean;
  setDetailsInfo?: (data: any) => void;
  onAdd?: () => void;
  onEdit?: (num: number, triggerName: string) => void;
}

const DetailsBlock = ({
  title,
  buttonText,
  showArchivedButton = true,
  showEdit = true,
  showArchive = true,
  showRestore = true,
  healthDetailGroup,
  labelGrey,
  noActions,
  setDetailsInfo,
  onAdd,
  onEdit,
}: IProps): ReactElement => {
  const [showArchivedList, setShowArchivedList] = useState(false);

  const toggle = () => setShowArchivedList(val => !val);

  const icon = !showArchivedList ? (
    <ExpandMoreIcon color="primary" />
  ) : (
    <ExpandLessIcon color="primary" />
  );

  const startIcon = (
    <div>
      <img src={circleIcon} alt="circle" />
    </div>
  );

  const { active, archived } = healthDetailGroup;

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <h4 className={styles.title}>{title}</h4>
        {buttonText && (
          <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={onAdd}>
            {buttonText}
          </Button>
        )}
      </div>
      {!!active.length &&
        active.map((item, idx) => (
          <ListItem
            noActions={noActions}
            className={active.length === 1 || active.length - 1 === idx ? styles.noBorder : ''}
            key={item.id}
            label={item.name}
            dose={item.dose}
            startDate={item.startDate}
            endDate={item.endDate}
            injectable={item.injectable}
            outsideFacility={item.outsideFacility}
            content={item.note}
            onEdit={() => onEdit(item.id, item.name)}
            showEdit={showEdit}
            showArchived={showArchive}
            startIcon={item.type === TriggerType.Recurring ? startIcon : undefined}
            chip={
              item.endDate && isPast(new Date(setNoon(item.endDate))) ? (
                <Status status="expired" />
              ) : undefined
            }
            onArchive={() =>
              setDetailsInfo({
                method: 'archive',
                id: item.id,
                name: item.name,
                endDate: item.endDate,
              })
            }
          />
        ))}
      {showArchivedButton && !!archived.length && (
        <div className={styles.archivedButtonWrapper}>
          <button type="button" onClick={toggle} className={styles.archivedButton}>
            Archived {icon}
          </button>
        </div>
      )}
      {!!archived.length &&
        showArchivedList &&
        archived.map((item, idx) => (
          <ListItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.id}${idx}`}
            label={item.name}
            dose={item.dose}
            injectable={item.injectable}
            content={item.note}
            startDate={item.startDate}
            endDate={item.endDate}
            labelGrey={labelGrey}
            showRestore={showRestore}
            noActions={noActions}
            startIcon={item.type === TriggerType.Recurring ? startIcon : undefined}
            onRestore={() => setDetailsInfo({ method: 'restore', id: item.id, name: item.name })}
          />
        ))}
    </div>
  );
};

export default DetailsBlock;

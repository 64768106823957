import { action } from 'easy-peasy';

import { IActionSetToxicologyOverviewReport } from '../types';

const setToxicologyOverviewReport: IActionSetToxicologyOverviewReport = action((state, payload) => {
  const { list, pagination } = payload;

  if (list) {
    state.toxicology.list = list;
  }
  if (pagination) {
    state.toxicology.pagination = pagination;
  }
});

export default setToxicologyOverviewReport;

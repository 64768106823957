enum ResourceType {
  Pdf = 'Pdf',
  Excel = 'Excel',
  Text = 'Text',
  Document = 'Document',
  Image = 'Image',
  Presentation = 'Presentation',
}

export default ResourceType;

import onGetClinicLocationGroups from './onGetClinicLocationGroups';
import onGetClinicLocationGroup from './onGetClinicLocationGroup';
import onArchiveLocationGroup from './onArchiveLocationGroup';
import onCreateLocationGroup from './onCreateLocationGroup';
import onRestoreLocationGroup from './onRestoreLocationGroup';
import onUpdateLocationGroup from './onUpdateLocationGroup';

export default {
  onGetClinicLocationGroups,
  onGetClinicLocationGroup,
  onArchiveLocationGroup,
  onRestoreLocationGroup,
  onCreateLocationGroup,
  onUpdateLocationGroup,
};

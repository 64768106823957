import { getVisitsFilter } from '~/services/localStorage/filters';
import { IVisitsStore } from './types';

const initTotals = {
  billable: {
    client: 0,
    hospital: 0,
    collateral: 0,
  },
  nonBillable: {
    client: 0,
    hospital: 0,
    collateral: 0,
  },
};

const initStore: IVisitsStore = {
  current: null,
  list: [],
  documents: { list: [], filters: { pageNumber: 1, name: '' }, total: 0 },
  pastVisits: { visits: [], totals: initTotals },
  monitoredBy: [],
  pagination: {
    total: 0,
    pageNumber: 1,
    pageSize: 25,
  },
  totals: initTotals,
  filters: getVisitsFilter() || {
    sortBy: 0,
    filterBy: 0,
    startDate: null,
    endDate: null,
  },
};

export default initStore;

import { useCallback, useEffect, useState } from 'react';

import { IDocument } from '~/services/api/visits/types';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { IOption } from '~/types';
import DocumentsOrderBy from '~/ui/pages/Clients/models/documentsOrderBy';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import { extractErrorMessage } from '~/utils/error/error';

const PAGE_SIZE = 5;

interface IUseDocuments {
  loading: boolean;
  items: IDocument[];
  searchQuery: string;
  sorting: DocumentsOrderBy;
  sortDirection: SortDirection;
  documentTypeId: number;
  setSearchQuery: (value: string) => void;
  setDocumentTypeFilter: (value: number) => void;
  setDocumentOrderBy: (value: IOption) => void;
  setDocumentSortDirection: (value: IOption) => void;
  loadMore?: () => void;
  refresh: () => void;
}

const useDocuments = (): IUseDocuments => {
  const [loading, setLoading] = useState(false);

  const { list: items, filters, total } = useStoreState(store => store.visits.documents);
  const { current } = useStoreState(state => state.user);
  const client = useStoreState(state => state.client.current);

  const { onGetDocuments, setClientDocumentsFilter } = useStoreActions(action => action.visits);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const { pageNumber, documentTypeId, name, sorting, sortDirection } = filters;
  const itemsLength = items?.length;

  const needLoadMore = itemsLength < total;

  const teamId = client?.actTeam?.id || current?.teamId;

  const fetchDocuments = useCallback(async () => {
    if (!loading && current?.clinic?.id && teamId && client?.id) {
      try {
        setLoading(true);
        await onGetDocuments({
          requestInfo: {
            clinicId: String(current.clinic.id),
            teamId: String(teamId),
            clientId: String(client.id),
          },
          params: {
            name,
            pageSize: PAGE_SIZE,
            pageNumber,
            documentTypeId,
            sorting,
            sortDirection,
          },
        });
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    current.clinic.id,
    teamId,
    client.id,
    onGetDocuments,
    name,
    pageNumber,
    documentTypeId,
    sorting,
    sortDirection,
    showError,
  ]);

  const setSearchQuery = useCallback(
    (value: string) => {
      setClientDocumentsFilter({ name: value, pageNumber: 1 });
    },
    [setClientDocumentsFilter],
  );

  const setDocumentTypeFilter = useCallback(
    (value: number) => {
      setClientDocumentsFilter({ documentTypeId: value, pageNumber: 1 });
    },
    [setClientDocumentsFilter],
  );
  const setDocumentOrderBy = useCallback(
    (option: IOption) => {
      setClientDocumentsFilter({ sorting: option.value as DocumentsOrderBy, pageNumber: 1 });
    },
    [setClientDocumentsFilter],
  );
  const setDocumentSortDirection = useCallback(
    (option: IOption) => {
      setClientDocumentsFilter({ sortDirection: option.value as SortDirection, pageNumber: 1 });
    },
    [setClientDocumentsFilter],
  );

  const loadMore = useCallback(() => {
    if (needLoadMore) {
      setClientDocumentsFilter({ pageNumber: pageNumber + 1 });
    }
  }, [needLoadMore, pageNumber, setClientDocumentsFilter]);

  const refresh = useCallback(() => {
    setClientDocumentsFilter({ pageNumber: 1 });
  }, [setClientDocumentsFilter]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return {
    loading,
    items,
    searchQuery: filters.name || '',
    documentTypeId: filters.documentTypeId,
    sorting,
    sortDirection,
    setSearchQuery,
    setDocumentTypeFilter,
    setDocumentOrderBy,
    setDocumentSortDirection,
    loadMore: needLoadMore ? loadMore : undefined,
    refresh,
  };
};

export default useDocuments;

import { ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Button from '~/ui/components/common/Button';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';

import {
  CLINIC_EDIT_PROGRAM_ASSISTANT,
  CLINIC_MANAGEMENT,
  VIEW_CLINIC,
} from '~/ui/constants/paths';

import editWhite from '~/ui/assets/images/editWhite.svg';
import styles from '../ViewProgramAssistant.module.scss';

interface IProps {
  clinicName: string;
  clinicId: string;
  programAssistantName: string;
  programAssistantId: string | number;
}

const Header = ({
  clinicName,
  clinicId,
  programAssistantName,
  programAssistantId,
}: IProps): ReactElement => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const breadcrumbsItems: Array<{ title: string; to: string | any }> = [
    {
      title: 'Clinic management',
      to: CLINIC_MANAGEMENT,
    },
    {
      title: `${clinicName}`,
      to: VIEW_CLINIC.replace(':id', clinicId),
    },
    {
      title: `Program Assistant`,
      to: {
        pathname: VIEW_CLINIC.replace(':id', clinicId),
        state: { selectedTab: 3 },
      },
    },
    {
      title: 'Program Assistant Profile',
      to: pathname,
    },
  ];

  return (
    <Grid container sm={12} className={styles.header}>
      <Grid item sm={9}>
        <Grid item sm={12}>
          <h1>{programAssistantName}</h1>
        </Grid>
        <Grid item sm={12}>
          <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
        </Grid>
      </Grid>

      <Grid item sm={3} className={styles.redirectButton}>
        <Button
          startIcon={<img src={editWhite} alt="edit" />}
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(
              CLINIC_EDIT_PROGRAM_ASSISTANT.replace(':id', clinicId).replace(
                ':programAssistantId',
                String(programAssistantId),
              ),
            )
          }
        >
          Edit Program Assistant Profile
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamActions, IThunkGetClinicActTeam } from '../types';

const onGetClinicActTeam: IThunkGetClinicActTeam = thunk(
  async ({ setActTeam }: Actions<IActTeamActions>, payload) => {
    const { clinicId, actTeamId } = payload;
    const { data } = await api.actTeam.getClinicActTeam(clinicId, actTeamId);

    setActTeam(data);
  },
);

export default onGetClinicActTeam;

import { createTheme } from '@material-ui/core/styles';

const muiTheme = createTheme({
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: 'Nunito,sans-serif',
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#D83713',
    },
    secondary: {
      main: '#757575',
    },
  },
  overrides: {
    MuiTypography: {
      body2: {
        fontWeight: 300,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#FFF',
        color: '#000',
        borderRadius: 5,
        fontSize: '0.875rem',
        border: '1px solid rgba(0, 0, 0, 0.13)',
        boxShadow: '0px 10px 15px rgba(91, 78, 78, 0.15)',
        padding: 10,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#FFF',
        },
        backgroundColor: '#FFF',
        fontWeight: 400,
        fontSize: '0.875rem',
      },
    },
    MuiRadio: {
      root: {
        '&$checked': {
          color: '#D83713',
        },
      },
    },
    MuiPaper: {
      rounded: {
        marginTop: 5,
        borderRadius: 5,
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 300,
        '&$selected': { color: '#D83713' },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 300,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 300,
        borderRadius: 30,
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Nunito,sans-serif',
        fontSize: 16,
      },
    },
    MuiTablePagination: {
      root: {
        color: '#D83713',
        fontWeight: 300,
      },
      caption: {
        fontWeight: 300,
      },
      input: {
        fontWeight: 300,
      },
      menuItem: {
        fontWeight: 300,
        color: '#D83713',
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 300,
        backgroundColor: '#fffbfa',
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 300,
      },
    },
    MuiTab: {
      root: {
        fontWeight: 300,
      },
    },
    MuiOutlinedInput: {
      root: {
        fontWeight: 300,
      },
      input: {
        '&::placeholder': {
          color: '#c54326',
        },
        '&:-webkit-autofill,input:-webkit-autofill:focus': {
          transition: 'background-color 600000s 0s, color 600000s 0s',
        },
      },
    },
    MuiTableContainer: {
      root: {
        border: '1px solid #E0E0E0',
        boxShadow: 'none',
        borderRadius: 18,
        backgroundColor: '#fff',
      },
    },
    MuiTableRow: {
      head: {
        color: 'red',
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 300,
      },
      head: {
        fontWeight: 300,
        color: '#D83713',
      },
      footer: {
        left: 0,
        bottom: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
        borderTop: '1px solid #E0E0E0',
      },
    },
  },
});

export default muiTheme;

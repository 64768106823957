import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IDeleteRequestModel } from './types';

const deleteRequest: IDeleteRequestModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default deleteRequest;

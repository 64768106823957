import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IShelterActions, IThunkGetClinicShelter } from '../types';

const onGetClinicShelter: IThunkGetClinicShelter = thunk(
  async ({ setShelter }: Actions<IShelterActions>, payload) => {
    const { clinicId, id } = payload;
    const { data } = await api.shelters.getClinicShelter(clinicId, id);

    setShelter(data);
  },
);

export default onGetClinicShelter;

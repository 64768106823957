import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { ReactElement, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import UnreadCounter from '~/ui/components/common/UnreadCounter';
import getChannelDataItem from '~/ui/pages/Coordinate/helpers/getChannelDataItem';
import useChannels from '~/ui/pages/Coordinate/hooks/useChannels';

import hamburgerIcon from '~/ui/assets/images/hamburger.svg';
import sidebarMenuItems from '~/ui/constants/sidebarMenuItems';
import styles from './Menu.module.scss';

interface IProps {
  url: string;
  title: string;
  icon: any;
  pathname: string;
  isMenuOpened: boolean;
  unreadMessagesCount?: number;
}

const Item = ({ url, title, icon: Icon, pathname, isMenuOpened, unreadMessagesCount }: IProps) => {
  const [hover, setHover] = useState(false);
  const isActive = pathname.startsWith(url);

  const innerContent = () => (
    <>
      {isMenuOpened && <div className={styles.spacer} />}
      <Icon className={styles.icon} isActive={isActive || hover} />
      {isMenuOpened && <span className={styles.title}>{title}</span>}
    </>
  );

  return (
    <li
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.menuItem}
    >
      {isActive ? (
        <div className={classNames(styles.link, { [styles.collapsedLink]: !isMenuOpened })}>
          {innerContent()}
        </div>
      ) : (
        <NavLink
          to={url}
          className={classNames(styles.link, { [styles.collapsedLink]: !isMenuOpened })}
          title={title}
        >
          {innerContent()}
        </NavLink>
      )}

      {!!unreadMessagesCount && (
        <div
          className={classNames(styles.unreadCounter, {
            [styles.unreadCounterCollapsed]: !isMenuOpened,
          })}
        >
          <UnreadCounter count={unreadMessagesCount} noBorder className={styles.unreadMessages} />
        </div>
      )}
    </li>
  );
};

const Menu = (): ReactElement => {
  const isMenuOpened = useStoreState(state => state.app.sidebarMenuOpened);
  const sendBirdUserId = useStoreState(state => state.user.current.sendBirdUserId);

  useChannels();

  const channels = useStoreState(state => state.coordinate.channels);

  const unreadMessagesCount = useMemo(
    () =>
      channels.reduce((total, obj) => {
        const archivedBy = getChannelDataItem(obj?.data, 'archivedBy') || [];
        const isArchived = sendBirdUserId && archivedBy.includes(sendBirdUserId);
        if (!isArchived) {
          return total + obj.unreadMessageCount;
        }
        return total;
      }, 0),
    [channels, sendBirdUserId],
  );

  const { showSidebar, hideSidebar } = useStoreActions(actions => actions.app);
  const { pathname } = useLocation();

  const toggleMenu = () => {
    if (isMenuOpened) {
      hideSidebar();
    } else {
      showSidebar();
    }
  };

  return (
    <>
      <Box className={classNames(styles.menuButton, { [styles.menuButtonClosed]: !isMenuOpened })}>
        <IconButton onClick={toggleMenu}>
          <img src={hamburgerIcon} alt="menu" />
        </IconButton>
      </Box>
      <ul className={classNames(styles.menu, { [styles.closed]: !isMenuOpened })}>
        {sidebarMenuItems().map(({ url, title, icon: Icon }) => (
          <Item
            key={url}
            url={url}
            title={title}
            icon={Icon}
            pathname={pathname}
            isMenuOpened={isMenuOpened}
            unreadMessagesCount={title === 'Coordinate' ? unreadMessagesCount : undefined}
          />
        ))}
      </ul>
    </>
  );
};
export default Menu;

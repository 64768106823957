import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';
import { IVisitsModel } from './types';

const visits: IVisitsModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default visits;

export const isFile = (value: File | string): boolean => Boolean((value as File)?.name);

export const validateFileType = (file: File, acceptTypes: string): boolean => {
  if (!acceptTypes) return true;

  const type = file?.type;
  if (!type) return false;

  return acceptTypes.split(',').includes(type);
};

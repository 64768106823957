import { useEffect } from 'react';

const useGlobalError = (): void => {
  const onError = (event: ErrorEvent) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('event: ', event);
    }
  };

  useEffect(() => {
    window.addEventListener('error', onError);

    return () => {
      window.removeEventListener('error', onError);
    };
  }, []);
};

export default useGlobalError;

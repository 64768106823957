import { action } from 'easy-peasy';
import { IActionSetProgramAssistants } from '../types';

const setProgramAssistants: IActionSetProgramAssistants = action((state, payload) => {
  const { items, pagination } = payload;

  state.list = items;

  if (pagination) state.pagination = { ...pagination };
});

export default setProgramAssistants;

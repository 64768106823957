import Grid from '@material-ui/core/Grid';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { useStoreActions } from '~/store/hooks';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import InputMask from '~/ui/components/inputs/InputMask';
import { extractErrorMessage } from '~/utils/error/error';
import { phoneValidationSchema } from './validates';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import api from '~/services/api';

import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/ui/components/inputs/Select/types';
import { IClientPhoneRequest } from '~/services/api/clientDetails/types';
import { IDetails } from '../types';
import { IRequestInfo } from './types';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './Styles.module.scss';
import Checkbox from '~/ui/components/inputs/Checkbox';

interface IProps {
  showSecondSubmit: boolean;
  defaultValues: IClientPhoneRequest;
  details: IDetails | null;
  requestInfo: IRequestInfo;
  setModalTitle: (str: string) => void;
  setDetails: (arg: IDetails) => void;
}

const AddPhone = ({
  showSecondSubmit,
  defaultValues,
  details,
  requestInfo,
  setModalTitle,
  setDetails,
}: IProps): ReactElement => {
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneTypes, setPhoneTypes] = useState<IOption[]>([]);

  const {
    control,
    formState: { errors },
    reset,
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: { ...(defaultValues || { setAsPrimary: false, archivePrimary: true }) },
    resolver: phoneValidationSchema,
  });

  const { setAsPrimary } = watch();

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onUpdatePhone, onCreatePhone, setCurrent } = useStoreActions(
    actions => actions.clientDetails,
  );

  const onSubmit = async (vals: IClientPhoneRequest, evt: React.BaseSyntheticEvent) => {
    const { name } = (evt as React.BaseSyntheticEvent<HTMLFormElement>).nativeEvent.submitter;
    try {
      setProcessing(true);
      if (details && details.id) {
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload: {
            ...vals,
            archivePrimary: vals.setAsPrimary ? !vals.archivePrimary : false,
          },
        };
        await onUpdatePhone(payload);

        setDetails(null);
        setCurrent(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload: {
            ...vals,
            archivePrimary: vals.setAsPrimary ? !vals.archivePrimary : false,
          },
        };

        await onCreatePhone(payload);

        reset({ number: null, typeId: null });
      }
      setModalTitle(name);
      const type = details?.id ? 'update' : 'added';
      showNotify({ message: `Phone successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const dictionaryPhoneTypes = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.PhoneType)
        .then(r => formatDictionaryOptions(r.data));

      setPhoneTypes(dictionaryPhoneTypes);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    setValue('archivePrimary', setAsPrimary);
  }, [setAsPrimary, setValue]);

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Select
            name="typeId"
            hideSelectedOptions={false}
            options={phoneTypes}
            control={control}
            errors={errors}
            maxMenuHeight={200}
            label="Select Type"
          />
        </Grid>
        <Grid item sm={12}>
          <InputMask control={control} errors={errors} name="number" label="Phone" />
        </Grid>
        {!defaultValues?.setAsPrimary && (
          <Grid item sm={12}>
            <Checkbox
              size="small"
              name="setAsPrimary"
              control={control}
              errors={errors}
              label="Primary Phone"
            />
          </Grid>
        )}
        {setAsPrimary && !defaultValues?.setAsPrimary && (
          <Grid item sm={12}>
            <Checkbox
              size="small"
              name="archivePrimary"
              control={control}
              errors={errors}
              label="Retain current Primary Phone number as Alternate"
            />
          </Grid>
        )}
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          className={styles.margin}
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          isLoading={processing}
          loaderColor={variables.colorGrey}
          disabled={processing}
          className={!showSecondSubmit ? styles.margin : ''}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddPhone;

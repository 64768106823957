const openChipTag = '<chip>';
const closeChipTag = '</chip>';

const extractChipText = (text: string): { text: string; chipText?: string } => {
  if (!text.includes(openChipTag) || !text.includes(closeChipTag)) {
    return { text };
  }

  const chipText = text.split(openChipTag)[1].split(closeChipTag)[0];
  const textWithChipTag = `${openChipTag}${chipText}${closeChipTag}`;

  return {
    text: text.replace(textWithChipTag, ''),
    chipText: text.split(openChipTag)[1].split(closeChipTag)[0],
  };
};

export default extractChipText;

import setRelease from './setRelease';
import setReleases from './setReleases';
import setReleasesPagination from './setReleasesPagination';
import setReleaseUpdates from './setReleaseUpdates';

export default {
  setRelease,
  setReleases,
  setReleasesPagination,
  setReleaseUpdates,
};

import { action } from 'easy-peasy';
import { IActionSetLocalAdmins } from '../types';

const setLocalAdmins: IActionSetLocalAdmins = action((state, payload) => {
  const { items, pagination } = payload;

  state.list = items;

  if (pagination) {
    state.pagination.total = pagination.total;
    state.pagination.pageSize = pagination.pageSize;
    state.pagination.pageNumber = pagination.pageNumber;
  }
});

export default setLocalAdmins;

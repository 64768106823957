import ResourceType from '../models/ResourceType';
import AcceptableResourceMimeTypes from '../models/AcceptableResourceMimeTypes';

const getMimeType = (type: ResourceType): AcceptableResourceMimeTypes => {
  switch (type) {
    case ResourceType.Pdf: {
      return AcceptableResourceMimeTypes.Pdf;
    }
    case ResourceType.Excel: {
      return AcceptableResourceMimeTypes.Xlsx;
    }
    case ResourceType.Document: {
      return AcceptableResourceMimeTypes.Docx;
    }
    case ResourceType.Text: {
      return AcceptableResourceMimeTypes.Txt;
    }
    case ResourceType.Image: {
      return AcceptableResourceMimeTypes.Jpeg;
    }
    case ResourceType.Presentation: {
      return AcceptableResourceMimeTypes.Ppt;
    }
    default: {
      return AcceptableResourceMimeTypes.Txt;
    }
  }
};

export default getMimeType;

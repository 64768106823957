import { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Status.module.scss';

interface IProps {
  isArchived?: boolean;
  status?: string;
}

const Status = ({ isArchived, status }: IProps): ReactElement => {
  let text;
  let textClass;

  if (status) {
    text = status;
    textClass = styles[status.toLowerCase()];
  } else {
    text = isArchived ? 'Archived' : 'Active';
    textClass = isArchived ? styles.archived : styles.active;
  }

  return <span className={classNames(textClass, styles.text)}>{text}</span>;
};

export default Status;

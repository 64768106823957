import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Status from '~/ui/components/common/Status';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';

import { IActTeam } from '~/services/api/actTeam/types';
import { IPagination, IParams, Sorting } from '~/services/api/types';
import { red } from '~/ui/constants/colors';
import { CLINIC_EDIT_ACT_TEAM, CLINIC_VIEW_ACT_TEAM } from '~/ui/constants/paths';
import { sortingOptions, statusesOptions } from '~/ui/constants/sortingOptions';

import archiveIcon from '~/ui/assets/images/archiveIcon.svg';
import editGrey from '~/ui/assets/images/editGrey.svg';
import styles from './Table.module.scss';

interface IProps {
  actTeams: IActTeam[];
  pagination: IPagination;
  onGetActTeams: (pagination: { clinicId: string; params: IParams }) => void;
  clinicId: string;
  setisArchiveActTeam: (actTeamId: boolean) => void;
  setActTeam: (IActTeam: IActTeam) => void;
}

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

const Table = ({
  pagination,
  onGetActTeams,
  clinicId,
  actTeams,
  setisArchiveActTeam,
  setActTeam,
}: IProps): ReactElement => {
  const formMethods = useForm({ defaultValues });
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const watchValues = watch();

  const onSubmit = (vals: IParams) => {
    const { pageSize, pageNumber } = pagination;

    onGetActTeams({ clinicId, params: { pageSize, pageNumber, ...vals } });
  };

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search Team"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={statusesOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <span className={styles.actionsCell}>Actions</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actTeams.map(actTeam => {
            const toView = CLINIC_VIEW_ACT_TEAM.replace(':clinicId', clinicId).replace(
              ':actTeamId',
              String(actTeam.id),
            );
            const toEdit = CLINIC_EDIT_ACT_TEAM.replace(':clinicId', clinicId).replace(
              ':actTeamId',
              String(actTeam.id),
            );
            return (
              <TableRow key={actTeam.id}>
                <TableCell className={actTeam.isArchived ? styles.disabled : ''}>
                  <Link to={toView} className={styles.link}>
                    {actTeam.name}
                  </Link>
                </TableCell>
                <TableCell>
                  <Status isArchived={actTeam.isArchived} />
                </TableCell>
                {actTeam.isArchived ? (
                  <TableCell>
                    <button
                      type="button"
                      className={styles.restoreButton}
                      onClick={() => {
                        setActTeam(actTeam);
                        setisArchiveActTeam(false);
                      }}
                    >
                      Restore
                    </button>
                  </TableCell>
                ) : (
                  <TableCell>
                    <Link to={toEdit}>
                      <IconButton>
                        <img src={editGrey} alt="edit" />
                      </IconButton>
                    </Link>
                    <IconButton
                      onClick={() => {
                        setisArchiveActTeam(true);
                        setActTeam(actTeam);
                      }}
                    >
                      <img src={archiveIcon} alt="archive" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => {
          onGetActTeams({
            clinicId,
            params: { pageNumber: pageNumber + 1, pageSize: pagination.pageSize, ...watchValues },
          });
        }}
        onRowsPerPageChange={e => {
          onGetActTeams({
            clinicId,
            params: { pageNumber: 1, pageSize: Number(e.target.value), ...watchValues },
          });
        }}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;

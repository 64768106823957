import { AxiosPromise, AxiosRequestConfig } from 'axios';

import replaceApiVersion from '~/utils/text/replaceApiVersion';
import axiosInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_API_URL;

export const publicAxios = (data: AxiosRequestConfig, apiVersion?: string): AxiosPromise =>
  axiosInstance(replaceApiVersion(baseUrl, apiVersion))(data);
export const privateAxios = (data: AxiosRequestConfig, apiVersion?: string): AxiosPromise =>
  axiosInstance(replaceApiVersion(baseUrl, apiVersion), true)(data);

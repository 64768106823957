import { useMemo } from 'react';

import { useStoreState } from '~/store/hooks';

import { IUserRole } from '~/types';
import { IDictionary } from '~/services/api/dictionaries/types';
import { ITeam, IRole } from '../types';

interface IUseRole {
  isSuperAdmin: boolean;
  isProductOwner: boolean;
  isLocalAdmin: boolean;
  isActTeamMember: boolean;
  isActTeamLeader: boolean;
  isProgramAssistant: boolean;
  isActTeam: boolean;
  team: ITeam;
  role: IRole;
  clinic: IDictionary;
}

const useRole = (): IUseRole => {
  const { roles, teamId, roleId, clinic } = useStoreState(state => state.user.current) || {};

  const currentRole: IRole | undefined = useMemo(
    () => roles?.find(role => role.id === roleId && role.clinic?.id === clinic?.id),
    [roles, roleId, clinic?.id],
  );

  const currentTeam = useMemo(
    () => currentRole?.teams?.find(team => team.id === teamId),
    [currentRole, teamId],
  );

  const isSuperAdmin = currentRole?.name === IUserRole.SuperAdmin;
  const isProductOwner = currentRole?.name === IUserRole.ProductOwner;
  const isLocalAdmin = currentRole?.name === IUserRole.LocalAdmin;
  const isActTeamMember = currentRole?.name === IUserRole.ActTeamMember;
  const isActTeamLeader = currentRole?.name === IUserRole.ActTeamLeader;
  const isProgramAssistant = currentRole?.name === IUserRole.ProgramAssistant;
  const isActTeam = isActTeamMember || isActTeamLeader;

  return {
    isSuperAdmin,
    isProductOwner,
    isLocalAdmin,
    isActTeamMember,
    isActTeamLeader,
    isProgramAssistant,
    isActTeam,
    team: currentTeam,
    role: currentRole,
    clinic,
  };
};

export default useRole;

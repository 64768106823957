import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';

import styles from './ConfirmModal.module.scss';

interface IProps {
  description?: string;
  isLoading?: boolean;
  disabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  customContent?: ReactNode;
  className?: string;
  closeOutsideClick?: boolean;
  onClose?: () => void;
  onConfirm: () => void;
}

const ConfirmModal = ({
  description,
  isLoading = false,
  closeOutsideClick = true,
  disabled,
  confirmText = 'Discard',
  cancelText = 'Cancel',
  customContent,
  className,
  onClose,
  onConfirm,
}: IProps): ReactElement => {
  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <Modal open className={styles.modal} onClose={closeOutsideClick ? handleClose : undefined}>
      <div className={classNames(styles.inner, className)}>
        <div className={styles.description}>{description}</div>
        {customContent}
        <div className={styles.buttonsWrapper}>
          <Button
            color="primary"
            variant="outlined"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleClose}
          >
            {cancelText}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={styles.btnCancel}
            disabled={isLoading || disabled}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

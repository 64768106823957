import { getResourcesFilter } from '~/services/localStorage/filters';
import { IResourceStore } from './types';

const initStore: IResourceStore = {
  current: null,
  blobUrl: null,
  list: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25,
    total: 0,
  },
  filters: getResourcesFilter() || {
    name: '',
    type: undefined,
  },
};

export default initStore;

import { red, orange, green } from './colors';

export enum RiskAssessment {
  NoRisk = 'NoRisk',
  AtRisk = 'AtRisk',
  HighRisk = 'HighRisk',
}

export const riskAssessmentLabel: { [key: string]: string } = {
  NoRisk: 'No Risk',
  AtRisk: 'At Risk',
  HighRisk: 'High Risk',
};

export const riskAssessmentColor: { [key: string]: string } = {
  NoRisk: green,
  AtRisk: orange,
  HighRisk: red,
};

export default [
  {
    value: RiskAssessment.NoRisk,
    label: riskAssessmentLabel[RiskAssessment.NoRisk],
  },
  {
    value: RiskAssessment.AtRisk,
    label: riskAssessmentLabel[RiskAssessment.AtRisk],
  },
  {
    value: RiskAssessment.HighRisk,
    label: riskAssessmentLabel[RiskAssessment.HighRisk],
  },
];

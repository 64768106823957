enum ClientLastContactSortableColumns {
  ClientName = 'ClientName',
  LastVisitDate = 'VisitDate',
}

export const clientLastContactSortableColumns = [
  ClientLastContactSortableColumns.ClientName,
  ClientLastContactSortableColumns.LastVisitDate,
];

export default ClientLastContactSortableColumns;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IOrganizationActions, IThunkGetOrganizations } from '../types';

const onGetOrganizations: IThunkGetOrganizations = thunk(
  async ({ setOrganizations }: Actions<IOrganizationActions>) => {
    const items = await api.organization.getOrganizations().then(r => r.data);

    setOrganizations({ items });
  },
);

export default onGetOrganizations;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IThunkGetVisits, IVisitsActions } from '../types';

const onGetVisits: IThunkGetVisits = thunk(
  async ({ setPastVisits }: Actions<IVisitsActions>, { requestInfo, params }) => {
    const { clinicId, teamId } = requestInfo;

    const pastVisits = await api.visits
      .getPastVisits({ clinicId, teamId }, params)
      .then(r => r.data);

    setPastVisits(pastVisits);
  },
);

export default onGetVisits;

import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import concat from 'lodash/concat';

import Form from '../Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { isFile } from '~/utils/file';
import { extractErrorMessage } from '~/utils/error/error';

import { CLINIC_MANAGEMENT, VIEW_CLINIC } from '~/ui/constants/paths';
import { IProgramAssistantForm } from '~/services/api/programAssistant/types';

type IParams = {
  id: string;
};

const breadcrumbsItem = [
  {
    title: 'Clinic Management',
    to: CLINIC_MANAGEMENT,
  },
];

const AddProgramAssistant = (): ReactElement => {
  const { id } = useParams<IParams>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const currentClinic = useStoreState(state => state.clinic.current);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      if (!currentClinic) {
        await onGetClinic(id);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [currentClinic, id, onGetClinic, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (!currentClinic || loading) return <Loader />;

  const breadcrumbsItems = concat(breadcrumbsItem, [
    {
      title: currentClinic.name,
      to: VIEW_CLINIC.replace(':id', id),
    },
    {
      title: 'Add Program Assistant',
      to: pathname,
    },
  ]);

  const emailDomainOptions = currentClinic.emailDomains.map(emailDomain => ({
    value: emailDomain,
    label: emailDomain,
  }));

  const onSubmit = async ({ email, emailDomain, ...values }: IProgramAssistantForm) => {
    try {
      setIsCreating(true);

      const payload = { ...values, email: `${email}${emailDomain}` };

      if (isFile(payload.photo)) {
        const formData = new FormData();
        formData.append('file', payload.photo);

        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);

        payload.photo = fileName;
      }
      await api.programAssistant.createProgramAssistant(id, payload);
      showNotify({ message: 'Program Assistant added successfully' });
      navigate(VIEW_CLINIC.replace(':id', id), { state: { selectedTab: 3 } });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsCreating(false);
    }
  };

  const leavePageDescription = "Are you sure you want to cancel 'Add Program Assistant'?";
  const cancelLinkTo = {
    pathname: VIEW_CLINIC.replace(':id', id),
    state: { selectedTab: 3 },
  };

  return (
    <div>
      <h2>Add Program Assistant </h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
      <Form
        isProcessing={isCreating}
        isPending
        leavePageDescription={leavePageDescription}
        cancelLinkTo={cancelLinkTo}
        onSubmit={onSubmit}
        emailDomainOptions={emailDomainOptions}
      />
    </div>
  );
};

export default AddProgramAssistant;

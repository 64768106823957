import debounce from 'lodash/debounce';
import { ReactElement, useMemo } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { ValueType } from 'react-select';

import { useStoreActions, useStoreState } from '~/store/hooks';
import formatClientOptions from '~/utils/formatClientOptions';

import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import OptionWithLastRef from '~/ui/components/inputs/SelectWithoutAnimation/components/OptionWithLastRef';

import { IClientOption } from '~/store/client/types';
import { IOption } from '~/types';

interface IClientSelectProps {
  loading: boolean;
  control: Control<any>;
  errors: FieldErrors;
  label?: string;
  additionalOption?: IOption;
  loadMore?: () => void;
  onSelect?: (value: IClientOption) => void;
}

const ClientSelect = ({
  loading,
  control,
  errors,
  label = 'Select Client',
  additionalOption,
  loadMore,
  onSelect,
}: IClientSelectProps): ReactElement => {
  const { list: availableClients, current } = useStoreState(store => store.client.clientOptions);

  const { setClientOptionsFilter, setCurrentClientOption } = useStoreActions(
    action => action.client,
  );

  const formattedClientOptions = useMemo(
    () => formatClientOptions([...availableClients, ...current]),
    [current, availableClients],
  );

  const clientsOptions = useMemo(
    () =>
      additionalOption ? [additionalOption, ...formattedClientOptions] : formattedClientOptions,
    [formattedClientOptions, additionalOption],
  );

  const handleSelectOption = (option: ValueType<IOption | IOption[], boolean>) => {
    const newClient = availableClients.find(client => client.id === (option as IOption).value);
    if (newClient) {
      setCurrentClientOption([newClient]);
      onSelect?.(newClient);
    }
  };

  const setValueDebounced = debounce(name => setClientOptionsFilter({ name, pageNumber: 1 }), 500);

  return (
    <Select
      options={clientsOptions}
      name="client"
      control={control}
      label={label}
      errors={errors}
      hideSelectedOptions
      onInputChange={setValueDebounced}
      customComponents={{
        Option: props => (
          <OptionWithLastRef
            {...props}
            loadMore={loadMore && !loading ? debounce(loadMore, 1000) : undefined}
          />
        ),
      }}
      onSelect={handleSelectOption}
      isLoading={loading}
    />
  );
};

export default ClientSelect;

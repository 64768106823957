import { action } from 'easy-peasy';
import { setVisitStatusReportFilter } from '~/services/localStorage/filters';

import { IActionSetVisitsByReportFilters } from '../types';

const setVisitStatusReportFilters: IActionSetVisitsByReportFilters = action((state, payload) => {
  // save filters to storage
  setVisitStatusReportFilter(payload);

  state.visitStatus.filters = payload;
});

export default setVisitStatusReportFilters;

enum ErrorCodes {
  ACCOUNT_DELETED = 'account_deleted_or_not_exists',
  PASSWORD_EXPIRED = 'password_expired',
  USER_UNAUTHORIZED = 'user_unauthorized',
  NO_ROLE = 'user_has_no_role',
  REFRESH_TOKEN_USED = 'refresh_token_used',
  REFRESH_TOKEN_INVALID = 'refresh_token_invalid',
}

export default ErrorCodes;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IResourceActions, IResourceStore, IThunkUpdateResource } from '../types';

const onUpdateResource: IThunkUpdateResource = thunk(
  async ({ setResources }: Actions<IResourceActions>, payload, { getState }) => {
    const localState = getState() as IResourceStore;

    const { requestInfo, requestPayload } = payload;

    const resource = await api.resource
      .updateResource(requestInfo.id, requestPayload)
      .then(r => r.data);

    const resources = localState.list.map(item => (item.id !== resource.id ? item : resource));

    setResources({
      items: resources,
      pagination: localState.pagination,
    });
  },
);

export default onUpdateResource;

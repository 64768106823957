import {
  IAllocationClient,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';

const extractArchivedClients = (
  clientAllocations: IClientAllocationDetailed[],
): IAllocationClient[] =>
  clientAllocations?.reduce((acc: IAllocationClient[], obj) => {
    const clientIds = obj.user.clients.filter(client => client.isArchived);

    return [...acc, ...clientIds];
  }, []) || [];

export default extractArchivedClients;

import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IClientAllocationModel } from './types';

const clientAllocation: IClientAllocationModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default clientAllocation;

import { privateAxios } from '../requestsCollection';
import { IParams, IResponse } from '../types';
import {
  IResource,
  ICurrentResource,
  IResourceCreateRequest,
  IResourceUpdateRequest,
  IResourceResponse,
} from './types';

export const getResources = ({
  fullList,
  params,
}: {
  fullList: boolean;
  params: IParams;
}): IResponse<IResourceResponse> =>
  privateAxios({
    method: 'get',
    url: `resources/${fullList ? 'list' : 'available'}`,
    params,
  });

export const getResource = (resourceId: number): IResponse<ICurrentResource> =>
  privateAxios({
    method: 'get',
    url: `resources/${resourceId}`,
  });

export const addResource = (data: IResourceCreateRequest): IResponse<IResource> =>
  privateAxios({
    method: 'post',
    url: 'resources',
    data,
  });

export const archiveResource = (resourceId: number): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `resources/${resourceId}`,
  });

export const restoreResource = (resourceId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `resources/${resourceId}/recover`,
  });

export const updateResource = (
  resourceId: number,
  data: IResourceUpdateRequest,
): IResponse<IResource> =>
  privateAxios({
    method: 'put',
    url: `resources/${resourceId}`,
    data,
  });

export const downloadResource = (externalUrl: string): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `/file/${externalUrl.split('/').slice(-1)}`,
    responseType: 'blob',
  });

export default {};

import { action } from 'easy-peasy';
import initStore from '../initStore';

import { IActionResetResourceStore } from '../types';

const resetResourceStore: IActionResetResourceStore = action(state => {
  state.filters = initStore.filters;
  state.list = initStore.list;
  state.pagination = initStore.pagination;
});

export default resetResourceStore;

import setSystemAlerts from './setSystemAlerts';
import setSystemAlertsPagination from './setSystemAlertsPagination';
import setSystemAlertUpdates from './setSystemAlertUpdates';
import setSystemAlertUpdatesPagination from './setSystemAlertUpdatesPagination';
import resetSystemAlertsStore from './resetSystemAlertsStore';

export default {
  setSystemAlerts,
  setSystemAlertsPagination,
  setSystemAlertUpdates,
  setSystemAlertUpdatesPagination,
  resetSystemAlertsStore,
};

import { action } from 'easy-peasy';
import { IActionShowNotify } from '~/store/snackbar/types';

const showNotify: IActionShowNotify = action((state, { message, options }) => {
  state.message = message;
  state.isError = false;
  state.options = options;
});

export default showNotify;

import { action } from 'easy-peasy';
import { setUserData } from '~/services/localStorage/auth';
import { IActionSetRole } from '../types';

const setRole: IActionSetRole = action((state, payload) => {
  setUserData(payload);
  state.current = { ...state.current, ...payload };
});

export default setRole;

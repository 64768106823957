import { action } from 'easy-peasy';

import { IActionSetVisitsByClientReport } from '../types';

const setVisitsByClientReport: IActionSetVisitsByClientReport = action((state, payload) => {
  const {
    visitsByClient: { items, total, totals, pagination },
  } = payload;

  state.visitsByClient.list =
    pagination.pageNumber === 1 ? items : [...state.visitsByClient.list, ...items];
  state.visitsByClient.pagination = { ...pagination, total };
  state.visitsByClient.totals = totals;
});

export default setVisitsByClientReport;

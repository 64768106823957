import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ISystemAlertActions, ISystemAlertStore, IThunkReadAllSystemAlert } from '../types';

const onReadAllSystemAlert: IThunkReadAllSystemAlert = thunk(
  async (
    { setSystemAlertUpdatesPagination }: Actions<ISystemAlertActions>,
    payload,
    { getState },
  ) => {
    const localState = getState() as ISystemAlertStore;
    await api.systemAlert.readAllSystemAlert(payload);

    setSystemAlertUpdatesPagination({
      ...localState.systemAlertUpdates.pagination,
      total: 0,
    });
  },
);

export default onReadAllSystemAlert;

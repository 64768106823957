import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';

import { phone, validateLengthField, zipCode } from '~/ui/configs/form/validation';
import { fieldMax, requiredField, validEmail } from '~/ui/constants/errorMessages';

const validateSchema = object().shape({
  firstName: string().required(requiredField),
  lastName: string().required(requiredField),
  email: string().test('length', fieldMax, validateLengthField).email(validEmail).nullable(),
  phone,
  relationshipId: number().required(requiredField),
  address: object().when('hasAddress', {
    is: true,
    then: object().shape({
      line1: string().required(requiredField).test('length', fieldMax, validateLengthField),
      line2: string().test('length', fieldMax, validateLengthField).nullable(),
      city: string().required(requiredField).test('length', fieldMax, validateLengthField),
      stateId: number().required(requiredField).typeError(requiredField),
      zipCode: zipCode.typeError(requiredField),
    }),
    otherwise: object().nullable(),
  }),
});

export default yupResolver(validateSchema);

import { action } from 'easy-peasy';
import { IActionReInviteProgramAssistant } from '../types';

const reInviteProgramAssistant: IActionReInviteProgramAssistant = action(
  (state, programAssistantId) => {
    const updatedList = state.list.map(item =>
      item.id !== programAssistantId ? item : { ...item, status: 'Pending' },
    );

    state.list = updatedList;
  },
);

export default reInviteProgramAssistant;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetTrigger } from '../types';

const onGetTrigger: IThunkGetTrigger = thunk(
  async ({ setCurrent }: Actions<IClientDetailsActions>, payload) => {
    const current = await api.clientDetails.getClientTrigger(payload).then(r => r.data);

    setCurrent(current);
  },
);

export default onGetTrigger;

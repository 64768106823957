import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetAvailableTeamMembers, IActTeamMemberActions } from '../types';

const onGetAvailableTeamMembers: IThunkGetAvailableTeamMembers = thunk(
  async ({ setAvailableActTeamMembers }: Actions<IActTeamMemberActions>, payload) => {
    const { clinicId, teamIds } = payload;

    if (teamIds?.length) {
      const { data } = await api.actTeamMember.getTeamMemberList(clinicId, teamIds);
      setAvailableActTeamMembers(data);
    } else {
      setAvailableActTeamMembers([]);
    }
  },
);

export default onGetAvailableTeamMembers;

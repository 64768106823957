import { IClientMedicationResponse, IClientMedication } from '~/services/api/clientDetails/types';

const formatMedication = (medication: IClientMedicationResponse): IClientMedication => ({
  id: medication.id,
  note: medication.note,
  medication: { id: medication.medication.id, name: medication.medication.name },
  isArchived: false,
  dose: medication.dose,
  injectable: medication.injectable,
  startDate: medication.startDate,
  endDate: medication.endDate,
  outsideFacility: !!medication.outsideFacility,
});

export default formatMedication;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetAddress } from '../types';

const onGetAddress: IThunkGetAddress = thunk(
  async ({ setCurrent }: Actions<IClientDetailsActions>, payload) => {
    const current = await api.clientDetails.getClientAddress(payload).then(r => r.data);

    setCurrent(current);
  },
);

export default onGetAddress;

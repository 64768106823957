import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteTreatmentPlan, IClientDetailsStore, IClientDetailsActions } from '../types';

const onDeleteTreatmentPlan: IThunkDeleteTreatmentPlan = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.deleteTreatmentPlan(payload);

    const treatmentPlans = localState.treatmentPlans.filter(item => item.id !== Number(payload.id));

    setClientDetails({ treatmentPlans });
  },
);

export default onDeleteTreatmentPlan;

import { action } from 'easy-peasy';

import { IActionSetVisitsFilters } from '../types';
import { setVisitsFilter } from '~/services/localStorage/filters';

const setVisitsFilters: IActionSetVisitsFilters = action((state, payload) => {
  setVisitsFilter(payload);

  state.filters = payload;
});

export default setVisitsFilters;

import onArchiveResource from './onArchiveResource';
import onGetResource from './onGetResource';
import onGetResources from './onGetResources';
import onRestoreResource from './onRestoreResource';
import onCreateResource from './onCreateResource';
import onUpdateResource from './onUpdateResource';
import onDownloadResource from './onDownloadResource';

export default {
  onArchiveResource,
  onGetResource,
  onGetResources,
  onRestoreResource,
  onCreateResource,
  onUpdateResource,
  onDownloadResource,
};

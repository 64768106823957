import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { ISuperAdminModel } from './types';

const superAdmin: ISuperAdminModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default superAdmin;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateOrganization, IOrganizationStore, IOrganizationActions } from '../types';

const onCreateOrganization: IThunkCreateOrganization = thunk(
  async ({ setOrganizations }: Actions<IOrganizationActions>, payload, { getState }) => {
    const localState = getState() as IOrganizationStore;

    const organization = await api.organization.addOrganization(payload).then(r => r.data);

    setOrganizations({ items: [organization, ...localState.list] });
  },
);

export default onCreateOrganization;

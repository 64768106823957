import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkReleaseArchive, IReleaseStore, IReleaseActions } from '../types';

const IReleaseModel: IThunkReleaseArchive = thunk(
  async ({ setReleases }: Actions<IReleaseActions>, releaseId, { getState }) => {
    const localState = getState() as IReleaseStore;

    await api.release.archiveRelease(releaseId);

    const updatedList = localState.list.map(release =>
      release.id !== releaseId ? release : { ...release, isArchived: true },
    );

    setReleases({ items: updatedList });
  },
);

export default IReleaseModel;

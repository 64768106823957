export enum AllocationMode {
  BY_CLIENT = 'byClient',
  BY_LOCATION_GROUP = 'byLocationGroup',
}

export const allocationModeOptions = [
  { label: 'By Client', value: AllocationMode.BY_CLIENT },
  {
    label: 'By Location Group',
    value: AllocationMode.BY_LOCATION_GROUP,
  },
];

import { action } from 'easy-peasy';
import { IActionSetOrganizations } from '../types';

const setOrganizations: IActionSetOrganizations = action((state, payload) => {
  const { items } = payload;

  state.list = items;
});

export default setOrganizations;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IClientDetailsStore, IThunkUpdatePhone } from '../types';

const onUpdatePhone: IThunkUpdatePhone = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const { setAsPrimary, archivePrimary } = requestPayload;
    const { id } = requestInfo;

    const phone = await api.clientDetails
      .updatePhone(requestInfo, requestPayload)
      .then(r => r.data);

    const phones = localState.phones.map(item =>
      item.id !== Number(id)
        ? {
            ...item,
            ...(archivePrimary ? { isArchived: item.isPrimary ? true : item.isArchived } : {}),
            ...(setAsPrimary ? { isPrimary: false } : {}),
          }
        : { ...phone, isPrimary: setAsPrimary },
    );

    setClientDetails({ phones });
  },
);

export default onUpdatePhone;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestorePhone, IClientDetailsStore, IClientDetailsActions } from '../types';

const onRestorePhone: IThunkRestorePhone = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.restorePhone(payload);

    const phones = localState.phones.map(item =>
      item.id !== Number(payload.id) ? item : { ...item, isArchived: false },
    );

    setClientDetails({ phones });
  },
);

export default onRestorePhone;

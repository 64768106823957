const CELL_HEIGHT = 37;

const calculateOptionsHeight = (
  optionsLength: number,
  maxMenuHeight: number,
  isCreatable = false,
): number => {
  if (isCreatable) return CELL_HEIGHT;

  const menuHeight = (optionsLength || 1) * CELL_HEIGHT;

  return menuHeight > maxMenuHeight ? maxMenuHeight : menuHeight;
};

export default calculateOptionsHeight;

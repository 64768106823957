import setResource from './setResource';
import setBlobUrl from './setBlobUrl';
import setResources from './setResources';
import setResourcesFilters from './setResourcesFilters';
import resetResourceStore from './resetResourceStore';

export default {
  setResource,
  setBlobUrl,
  setResources,
  setResourcesFilters,
  resetResourceStore,
};

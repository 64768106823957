import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import {
  IThunkActivateOwnTreatmentPlan,
  IClientDetailsStore,
  IClientDetailsActions,
} from '../types';

const onActivateOwnTreatmentPlan: IThunkActivateOwnTreatmentPlan = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.activateOwnTreatmentPlan(payload);

    const treatmentPlans = localState.treatmentPlans.map(item =>
      item.id !== Number(payload.id)
        ? {
            ...item,
            ...(item.active ? { active: false, isArchived: true } : {}), // make current active - inactive
            ...(!item.active && !item.isArchived ? { isArchived: true } : {}), // make current draft (if exists) - deleted
          }
        : { ...item, active: true },
    );

    setClientDetails({ treatmentPlans });
  },
);

export default onActivateOwnTreatmentPlan;

import { action } from 'easy-peasy';

import { IActionSetVisitsByTeamReport } from '../types';

const setVisitsByTeamReport: IActionSetVisitsByTeamReport = action((state, payload) => {
  const {
    visitsByTeam: { items, total, totals, pagination },
  } = payload;

  state.visitsByTeam.list =
    pagination.pageNumber === 1 ? items : [...state.visitsByTeam.list, ...items];
  state.visitsByTeam.pagination = { ...pagination, total };
  state.visitsByTeam.totals = totals;
});

export default setVisitsByTeamReport;

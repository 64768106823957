import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import isEqual from 'lodash/isEqual';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Status from '~/ui/components/common/Status';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import formatActTeamsOptions from '~/utils/formatActTeamsOptions';
import extractFullName from '~/utils/text/extractFullName';
import useMemoCompare from '~/utils/useMemoCompare';

import { sortingOptions, statusesOptions } from '~/ui/constants/sortingOptions';
import { EDIT_CLIENT, VIEW_CLIENT } from '~/ui/constants/paths';
import { red } from '~/ui/constants/colors';

import { IClient } from '~/services/api/client/types';
import { IClientsParams, Sorting } from '~/services/api/types';
import { IClientsFilters, IGetTeamClientsRequestPayload } from '~/store/client/types';

import archiveIcon from '~/ui/assets/images/archiveIcon.svg';
import editIcon from '~/ui/assets/images/editGrey.svg';

import styles from './Table.module.scss';

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

let initialized = false;
let prevRequestPayload: IGetTeamClientsRequestPayload;

interface IProps {
  isLocalAdmin: boolean;
  noActions?: boolean;
  loading?: boolean;
  list: IClient[];
  clinicId: number;
  teamId?: number;
  onGetClients: (payload: any) => Promise<void>;
  setClient: (payload: IClient) => void;
}

const Table = ({
  list,
  isLocalAdmin,
  noActions,
  loading: dataLoading,
  clinicId,
  teamId,
  onGetClients,
  setClient,
}: IProps): ReactElement => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const filters = useStoreState(state => state.client.filters);

  const memoizedFilters = useMemoCompare(filters, (prev, next) => prev && isEqual(prev, next));

  const formMethods = useForm({
    defaultValues: {
      ...(memoizedFilters || defaultValues),
      teams: memoizedFilters.teamIds || [],
    },
  });

  const pagination = useStoreState(state => state.client.pagination);

  const { list: availableActTeams } = useStoreState(state => state.actTeam);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = formMethods;

  const watchValues = watch();

  const onSubmit = async ({ teams, teamIds, ...params }: IClientsParams) => {
    if (clinicId) {
      try {
        setLoading(true);

        const requestPayload = {
          clinicId: String(clinicId),
          teamId: teamId ? String(teamId) : undefined,
          teamIds: teams,
          params: { ...params, pageSize: pagination.pageSize, pageNumber: pagination.pageNumber },
        };

        if (isEqual(prevRequestPayload, requestPayload)) {
          setLoading(false);
          return;
        }

        await onGetClients(requestPayload);
        prevRequestPayload = requestPayload;
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePagination = async (pageSize: number, pageNumber: number) => {
    if (clinicId) {
      try {
        setLoading(true);
        const { teams, teamIds, ...restValues } = watchValues as IClientsFilters & {
          teams: number[];
        };
        await onGetClients({
          clinicId: String(clinicId),
          teamId: teamId ? String(teamId) : undefined,
          teamIds: teams,
          params: { ...restValues, pageSize, pageNumber },
        });
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  // format team object for select component
  const filteredTeamsOptions = useMemo(
    () => formatActTeamsOptions(availableActTeams),
    [availableActTeams],
  );

  // select all items in select component
  const setAll = useCallback(
    (all?: boolean) => {
      if (all)
        setValue(
          'teams',
          filteredTeamsOptions.map(m => Number(m.value)),
        );
      else {
        setValue('teams', []);
      }
    },
    [filteredTeamsOptions, setValue],
  );

  const renderItem = (item: IClient) => {
    const isTransferredToCLient =
      !!item.transferredToClient && !item.transferredToClient.isArchived;
    return (
      <TableRow key={item.id}>
        <TableCell>
          <Link
            className={styles.link}
            to={VIEW_CLIENT.replace(':clientId', String(item.id)).replace(
              ':actTeamId',
              String(item.actTeam.id),
            )}
            state={{ actTeamId: String(item.actTeam.id) }}
          >
            {item.firstName}
          </Link>
        </TableCell>
        <TableCell>{item.lastName}</TableCell>
        <TableCell>{item.email}</TableCell>
        <TableCell>{item.actTeam.name}</TableCell>
        <TableCell>
          <Status isArchived={item.isArchived} />
        </TableCell>
        {!noActions &&
          (item.isArchived ? (
            <TableCell>
              <Tooltip
                title={
                  item.transferredToClient
                    ? `${extractFullName(item.transferredToClient)} was transferred to ${
                        item.transferredToClient.team?.name
                      } team and can not be restored.`
                    : ''
                }
                disableHoverListener={!isTransferredToCLient}
              >
                <Button
                  onClick={isTransferredToCLient ? undefined : () => setClient(item)}
                  className={classNames({ [styles.disabledButton]: isTransferredToCLient })}
                >
                  Restore
                </Button>
              </Tooltip>
            </TableCell>
          ) : (
            <TableCell>
              <IconButton
                onClick={() =>
                  navigate(
                    EDIT_CLIENT.replace(':clientId', String(item.id)).replace(
                      ':actTeamId',
                      String(item.actTeam.id),
                    ),
                    {
                      state: {
                        actTeamId: String(item.actTeam.id),
                      },
                    },
                  )
                }
              >
                <img src={editIcon} alt="editIcon" />
              </IconButton>
              <IconButton onClick={() => setClient(item)}>
                <img src={archiveIcon} alt="archiveIcon" />
              </IconButton>
            </TableCell>
          ))}
      </TableRow>
    );
  };

  // select all teams on mount
  useEffect(() => {
    if (filteredTeamsOptions && !memoizedFilters.teamIds?.length && !initialized) {
      setAll(true);
      initialized = true;
    }

    if (memoizedFilters.teamIds?.length && !initialized) {
      setValue('teams', memoizedFilters.teamIds);
      initialized = true;
    }
  }, [filteredTeamsOptions, memoizedFilters.teamIds, setAll, setValue]);

  useEffect(
    () => () => {
      initialized = false;
    },
    [],
  );

  return (
    <TableContainer className={styles.tableContainer}>
      {loading || dataLoading ? <Loader className={styles.loader} /> : null}
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form>
            <Grid container spacing={1}>
              {isLocalAdmin && (
                <Grid item sm={3} md={3} className={styles.multiSelect}>
                  <Select
                    isMulti
                    oneLineMulti
                    closeMenuOnSelect={false}
                    showCustomComponents
                    showSelectAll
                    hideSelectedOptions={false}
                    name="teams"
                    options={filteredTeamsOptions}
                    control={control}
                    errors={errors}
                    label="Select Team"
                    setAll={setAll}
                  />
                </Grid>
              )}
              <Grid item sm={4}>
                <Input
                  register={register}
                  placeholder="Search clients"
                  name="name"
                  errors={errors}
                  startAdornment={<SearchIcon color="primary" />}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={statusesOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Assigned Team</TableCell>
            <TableCell>Status</TableCell>
            {isLocalAdmin && (
              <TableCell>
                <span className={styles.actionCell}>Actions</span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{list.map(renderItem)}</TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => handlePagination(pagination.pageSize, pageNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';

import Profile from '../../reusable/Profile';
import TeamMembers from '../../LocalAdmin/TeamMembers';
import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import ClientAllocation from '../../LocalAdmin/ClientAllocation';
import { extractErrorMessage } from '~/utils/error/error';

import { ADD_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import styles from './MyActTeam.module.scss';

const MyActTeam = (): ReactElement => {
  const { state: locationState }: { state: { selectedTab: number } } = useLocation();
  const navigate = useNavigate();

  const { isLocalAdmin, team } = useRole();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(locationState?.selectedTab || 0);

  // a plug for TeamMembers component
  const [, setTeamMember] = useState(null);

  const { clinic: userClinic } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const actTeamId = team?.id ? String(team?.id) : null;

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { onGetClinicActTeam, resetPagination } = useStoreActions(actions => actions.actTeam);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = useCallback(async () => {
    try {
      resetPagination();
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };

      const promises = [onGetClinicActTeam(payload)];

      if (isLocalAdmin) {
        promises.push(onGetMyClinic());
      }

      await Promise.all(promises);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [
    actTeamId,
    isLocalAdmin,
    onGetClinicActTeam,
    onGetMyClinic,
    resetPagination,
    showError,
    userClinic.id,
  ]);

  useEffect(() => {
    if (actTeamId) {
      onMount();
    }
  }, [actTeamId, onMount]);

  if (loading || !actTeam || !actTeamId) return <Loader />;

  return (
    <div>
      <div className={styles.header}>
        <h2>{actTeam.name}</h2>
        {tab === 0 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate(ADD_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId))}
          >
            Add Client Allocation
          </Button>
        )}
      </div>
      <div className={styles.tabsWrapper}>
        <Tabs
          value={tab}
          textColor="primary"
          indicatorColor="primary"
          onChange={(_e, val) => setTab(val)}
        >
          <Tab label="CLIENT ALLOCATIONS" value={0} />
          <Tab label="TEAM PROFILE" value={1} />
          <Tab label="TEAM MEMBERS" value={2} />
        </Tabs>
      </div>
      {tab === 0 && <ClientAllocation clinicId={String(userClinic.id)} actTeamId={actTeamId} />}
      {tab === 1 && <Profile actTeam={actTeam} />}
      {tab === 2 && (
        <TeamMembers
          noActions
          clinicId={String(userClinic.id)}
          actTeamId={actTeamId}
          setTeamMember={setTeamMember}
        />
      )}
    </div>
  );
};

export default MyActTeam;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientAllocationActions, IThunkGetAllocationClientsByGroup } from '../types';

const onGetAllocationClientsByGroup: IThunkGetAllocationClientsByGroup = thunk(
  async ({ setAllocationClientsByGroup }: Actions<IClientAllocationActions>, payload) => {
    const allocationClientsByGroup = await api.clientAllocation
      .getAllocationClientsByGroup(payload)
      .then(r => r.data);

    setAllocationClientsByGroup(allocationClientsByGroup);
  },
);

export default onGetAllocationClientsByGroup;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IProgramAssistantActions, IThunkOnActivateProgramAssistant } from '../types';

const onGetCurrentProgramAssistant: IThunkOnActivateProgramAssistant = thunk(
  async ({ setCurrentProgramAssistant }: Actions<IProgramAssistantActions>, payload) => {
    const { clinicId, programAssistantId } = payload;
    const { data } = await api.programAssistant.getProgramAssistant(
      clinicId,
      String(programAssistantId),
    );

    setCurrentProgramAssistant(data);
  },
);

export default onGetCurrentProgramAssistant;

import onArchiveRelease from './onArchiveRelease';
import onGetRelease from './onGetRelease';
import onGetReleases from './onGetReleases';
import onRestoreRelease from './onRestoreRelease';
import onAddRelease from './onAddRelease';
import onUpdateRelease from './onUpdateRelease';
import onGetReleaseUpdates from './onGetReleaseUpdates';
import onReadReleaseUpdates from './onReadReleaseUpdates';
import onReadAllReleaseUpdates from './onReadAllReleaseUpdates';

export default {
  onArchiveRelease,
  onGetRelease,
  onGetReleases,
  onRestoreRelease,
  onAddRelease,
  onUpdateRelease,
  onGetReleaseUpdates,
  onReadReleaseUpdates,
  onReadAllReleaseUpdates,
};

import { IResource } from '~/services/api/resource/types';
import ResourceType from '~/ui/pages/Resources/models/ResourceType';
import replaceApiVersion from '~/utils/text/replaceApiVersion';

const formatResource = (
  item: { id: number; type: string; date: string },
  clinicId: number,
  teamId: number,
  clientId: number,
): IResource => ({
  id: item.id,
  name: item.type,
  externalUrl: `${replaceApiVersion(
    process.env.REACT_APP_API_URL,
    'v2/',
  )}clinic/${clinicId}/act-team/${teamId}/client/${clientId}/document/${item.id}`,
  type: ResourceType.Pdf,
  isVisible: true,
  isArchived: false,
  modifiedOn: item.date,
});

export default formatResource;

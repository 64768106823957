import { action } from 'easy-peasy';

import { IActionResetCurrentVisitsReport } from '../types';

const resetCurrentVisits: IActionResetCurrentVisitsReport = action(state => {
  state.visitsByClient.current = null;
  state.visitsByTeam.current = null;
});

export default resetCurrentVisits;

import { action } from 'easy-peasy';

import { IActionSetClientAllocationVisitsReport } from '../types';

const setClientAllocationVisitsReport: IActionSetClientAllocationVisitsReport = action(
  (state, payload) => {
    state.clientAllocationVisits = payload;
  },
);

export default setClientAllocationVisitsReport;

import { action } from 'easy-peasy';
import { FileMessage, UserMessage, getInstance } from 'sendbird';

import { IActionAddTempFile } from '../types';

const addTempFile: IActionAddTempFile = action((state, payload) => {
  const { channelUrl, type, url, reqId } = payload;

  const isRequestExist = state.messages[channelUrl].items.find(
    m => (m as FileMessage).reqId === reqId,
  );

  if (isRequestExist) {
    return;
  }

  const sb = getInstance();

  const currentUser: SendBird.User = sb?.currentUser;

  const message = {
    url,
    createdAt: new Date().getTime(),
    type: 'file',
    sender: {
      userId: currentUser.userId,
      nickname: currentUser.nickname,
    },
    name: reqId,
    reqId,
    messageId: 0,
    channelUrl,
    customType: type,
    isFileMessage: () => true,
  };

  const extendedMessage = message as FileMessage;
  if (state.messages[channelUrl].items.length > 0) {
    Object.assign(extendedMessage, {
      hasSameSenderAbove:
        (message as UserMessage | FileMessage).sender &&
        (state.messages[channelUrl].items[0] as UserMessage | FileMessage).sender &&
        (message as UserMessage | FileMessage).sender.userId ===
          (state.messages[channelUrl].items[0] as UserMessage | FileMessage).sender.userId,
    });
  }

  state.messages = {
    ...state.messages,
    [channelUrl]: {
      ...state.messages[channelUrl],
      messagesMap: {
        ...state.messages[channelUrl].messagesMap,
        [message.messageId]: true,
      },
      items: [
        ...state.messages[channelUrl].items,
        Object.assign(extendedMessage, { tempMessage: true }),
      ],
    },
  };
});

export default addTempFile;

import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { ReactElement } from 'react';

import useRole from '~/store/user/hooks/useRole';
import extractDateFromString from '~/utils/date/extractDateFromString';
import getFileTypeIcon from '../../helpers/getFileTypeIcon';

import { IResource } from '~/services/api/resource/types';

import styles from './Table.module.scss';
import ResourceType from '../../models/ResourceType';

interface IProps {
  item: IResource;
  onUpdateResource: (item: IResource) => Promise<void>;
  onArchiveResource: (id: number) => Promise<void>;
  setResource: (item: IResource) => void;
  downloadFile: (item: IResource) => void;
  openFile: (item: IResource) => void;
}

const ResourceItem = ({
  item,
  onUpdateResource,
  onArchiveResource,
  setResource,
  downloadFile,
  openFile,
}: IProps): ReactElement => {
  const { isSuperAdmin } = useRole();

  const canOpen = ![ResourceType.Excel, ResourceType.Document, ResourceType.Presentation].includes(
    item.type,
  );

  return (
    <>
      <TableRow key={item.id}>
        <TableCell colSpan={4}>
          <div className={styles.firstCell}>
            {getFileTypeIcon(item.type)} <span>{item.name}</span>
          </div>
        </TableCell>
        <TableCell>{extractDateFromString(item.modifiedOn)}</TableCell>
        {isSuperAdmin && (
          <TableCell>
            <Switch
              checked={item.isVisible}
              name="isVisible"
              color="primary"
              onChange={() => {
                onUpdateResource({ ...item, isVisible: !item.isVisible });
              }}
            />
          </TableCell>
        )}
        <TableCell colSpan={3} className={styles.actionsCell} align="right">
          {canOpen && (
            <IconButton
              onClick={() => {
                openFile(item);
              }}
            >
              <div className={classNames(styles.iconBlack, styles.viewOpenIcon)} />
            </IconButton>
          )}

          <IconButton
            onClick={() => {
              downloadFile(item);
            }}
          >
            <div className={classNames(styles.iconBlack, styles.downloadIcon)} />
          </IconButton>
          {isSuperAdmin && (
            <>
              <IconButton
                onClick={() => {
                  setResource(item);
                }}
              >
                <div className={classNames(styles.iconBlack, styles.editIcon)} />
              </IconButton>
              <IconButton
                onClick={() => {
                  onArchiveResource(item.id);
                }}
              >
                <div className={classNames(styles.iconBlack, styles.trashIcon)} />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ResourceItem;

import { ReactElement } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';

interface IProps {
  children: ReactElement;
  open: boolean;
  preventOutsideClick?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  containerClassName?: string;
  className?: string;
  onClose?: () => void;
}

const Modal = ({
  children,
  open,
  preventOutsideClick,
  maxWidth = 'sm',
  containerClassName = '',
  className = '',
  onClose,
}: IProps): ReactElement => (
  <Dialog
    open={open}
    maxWidth={maxWidth}
    className={containerClassName}
    PaperProps={{ className }}
    onClose={(_, reason): DialogProps['onClose'] => {
      if (reason && preventOutsideClick && reason === 'backdropClick') return;
      onClose?.();
    }}
  >
    <MuiDialogContent>{children}</MuiDialogContent>
  </Dialog>
);

export default Modal;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateLocationGroup, ILocationGroupsStore, ILocationGroupActions } from '../types';

const onCreateLocationGroup: IThunkCreateLocationGroup = thunk(
  async ({ setLocationGroups }: Actions<ILocationGroupActions>, payload, { getState }) => {
    const localState = getState() as ILocationGroupsStore;

    const { clinicId, data } = payload;

    const locationGroup = await api.locationGroups
      .createClinicLocationGroup(clinicId, data)
      .then(r => r.data);

    setLocationGroups({ items: [locationGroup, ...localState.list] });
  },
);

export default onCreateLocationGroup;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IVisitsRequestInfo } from '~/services/api/visits/types';
import { IThunkArchiveVisit, IVisitsActions } from '../types';

const onArchiveVisit: IThunkArchiveVisit = thunk(
  async (_: Actions<IVisitsActions>, payload: IVisitsRequestInfo) => {
    const { clinicId, visitId, teamId } = payload;

    await api.visits.archiveVisit({ clinicId, visitId, teamId });
  },
);

export default onArchiveVisit;

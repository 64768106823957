import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IStoreModel } from '~/store/types';
import { IClientAllocationActions, IThunkGetOwnClientAllocation } from '../types';

const onGetOwnClientAllocation: IThunkGetOwnClientAllocation = thunk(
  async (
    { setClientAllocation }: Actions<IClientAllocationActions>,
    payload,
    { getStoreState },
  ) => {
    const store = getStoreState() as IStoreModel;

    const profile = store.user.current;

    const clientAllocation = await api.clientAllocation
      .getOwnClientAllocation(payload)
      .then(r => r.data);

    if (!clientAllocation) {
      return;
    }

    const normalizedAllocation = {
      id: clientAllocation.id,
      date: clientAllocation.date,
      isArchived: false,
      isOwn: true,
      user: {
        id: profile.id,
        name: `${profile.firstName} ${profile.lastName}`,
        subRoles: profile.subRoles,
        photo: profile.photo,
        clients: clientAllocation.clients,
      },
      locationGroups: clientAllocation.locationGroups,
    };

    setClientAllocation([normalizedAllocation]);
  },
);

export default onGetOwnClientAllocation;

import { Actions, thunk } from 'easy-peasy';
import { IThunkGetSecondaryDiagnosis, IClientDetailsStore, IClientDetailsActions } from '../types';

const onGetSecondaryDiagnosis: IThunkGetSecondaryDiagnosis = thunk(
  ({ setCurrent }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const current = localState.diagnoses.find(item => item.id === payload);

    setCurrent(current);
  },
);

export default onGetSecondaryDiagnosis;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { setClientLastContactFilter } from '~/services/localStorage/filters';
import { IReportsActions, IReportsStore, IThunkGetClientLastContactOverviewReport } from '../types';

const onGetClientLastContactOverviewReport: IThunkGetClientLastContactOverviewReport = thunk(
  async (
    { setClientLastContactOverviewReport }: Actions<IReportsActions>,
    { requestInfo, params },
    { getState },
  ) => {
    const localState = getState() as IReportsStore;

    const { list: items } = localState.clientLastContact;

    const clientLastContact = await api.reports
      .getClientLastContactOverviewReport(requestInfo, params)
      .then(r => r.data);

    setClientLastContactFilter(params);

    setClientLastContactOverviewReport({
      list:
        params.pageNumber === 1 ? clientLastContact.items : [...items, ...clientLastContact.items],
      pagination: {
        total: clientLastContact.total,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      },
    });
  },
);

export default onGetClientLastContactOverviewReport;

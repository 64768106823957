import { action } from 'easy-peasy';

import { IActionUpdateMessage } from '../types';

const updateMessage: IActionUpdateMessage = action((state, message) => {
  const { channelUrl } = message || {};

  const channel = state.messages[channelUrl];

  const newMessages =
    channel &&
    channel.items.map(m => {
      if (m.messageId === message.messageId) {
        return message;
      }
      return m;
    });

  state.messages = {
    ...state.messages,
    [channelUrl]: {
      ...state.messages[channelUrl],
      messagesMap: state.messages[channelUrl]?.messagesMap,
      items: [...newMessages],
    },
  };
});

export default updateMessage;

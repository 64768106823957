import Grid from '@material-ui/core/Grid';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import unionBy from 'lodash/unionBy';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ListItem from '~/ui/components/common/ListItem';
import ProfilePhotoUpload from '~/ui/components/common/ProfilePhotoUpload';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import ChangePasswordForm from '../ChangePassword/components/ChangePasswordForm';

import compareRoles from '~/utils/compareRoles';
import useMemoCompare from '~/utils/useMemoCompare';
import { extractErrorMessage } from '~/utils/error/error';
import useRole from '../../../store/user/hooks/useRole';
import { getChangeRoleSuccessMessage, getSubRoleName } from './helper';
import { isFile } from '~/utils/file';
import removeActPrefix from '~/utils/text/removeActPrefix';

import { IUserRole } from '~/types';
import { IChangeRoleFormValues, IChangePasswordFormValues } from './types';
import { EDIT_PROFILE, FRESH_DESK_SUPPORT } from '~/ui/constants/paths';

import editWhite from '~/ui/assets/images/editWhite.svg';
import styles from './Profile.module.scss';

let changeRolePayload: IChangeRoleFormValues | null = null;

const Profile = (): ReactElement => {
  const navigate = useNavigate();

  const current = useStoreState(state => state.user.current);
  const [showConfirm, setShowConfirm] = useState<'profile' | 'logout'>(null);
  const [value, setValue] = useState(current.photo || null);
  const [error, setError] = useState(null);
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [changingRole, setChangingRole] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);

  const { isSuperAdmin, isProgramAssistant, role, team, clinic } = useRole();

  const {
    control,
    watch,
    setValue: setFormValue,
    formState: { errors },
  } = useForm<IChangeRoleFormValues>();

  const { roleId, teamId, clinicId } = watch();

  useEffect(() => {
    if (clinicId) {
      setFormValue('roleId', null);
    }
  }, [clinicId, setFormValue]);

  const clinicOptions = useMemo(
    () =>
      unionBy(
        current?.roles.map(option => ({ label: option.clinic?.name, value: option.clinic?.id })),
        'value',
      ),
    [current?.roles],
  );

  const roleOptions = useMemo(
    () =>
      unionBy(
        current?.roles.reduce((acc, obj) => {
          if (obj.clinic?.id === clinicId) {
            acc.push({ value: obj.id, label: removeActPrefix(obj?.name) });
          }

          return acc;
        }, []),
        'value',
      ),
    [clinicId, current?.roles],
  );

  const showClinicSelect = clinicOptions.length > 1;
  const showRoleSelect = !!clinicId || !showClinicSelect;

  const selectedRole = useMemo(
    () =>
      current?.roles.find(
        option => option.id === roleId && (clinicId ? clinicId === option.clinic?.id : true),
      ),
    [current?.roles, roleId, clinicId],
  );

  const memoizedSelectedRole = useMemoCompare(
    selectedRole,
    (prev, next) => prev && isEqual(prev, next),
  );

  const selectedTeam = useMemo(
    () => memoizedSelectedRole?.teams.find(option => option.id === teamId),
    [memoizedSelectedRole?.teams, teamId],
  );

  const teamOptions = useMemo(
    () => memoizedSelectedRole?.teams.map(option => ({ value: option.id, label: option?.name })),
    [memoizedSelectedRole],
  );

  const multipleRoles = current?.roles.length > 1;
  const multipleTeams = !multipleRoles && current?.roles[0]?.teams?.length > 1;

  const showTeamSelect =
    compareRoles(memoizedSelectedRole?.name, IUserRole.ActTeamMember) ||
    compareRoles(memoizedSelectedRole?.name, IUserRole.ActTeamLeader);

  useEffect(() => {
    if (!showTeamSelect) {
      setFormValue('teamId', null);
    }
  }, [showTeamSelect, setFormValue]);

  const isProgramAssistantNewRole = compareRoles(
    memoizedSelectedRole?.name,
    IUserRole.ProgramAssistant,
  );

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onLogout, onUploadAvatar, onRemoveAvatar, onChangeRole } = useStoreActions(
    actions => actions.user,
  );
  const onChangePassword = useStoreActions(actions => actions.user.onChangePassword);
  const { setAvailableClients, setClientOptions, setDefaultClient, setCurrentClientOption } =
    useStoreActions(actions => actions.client);
  const { setChannels, resetMessages } = useStoreActions(actions => actions.coordinate);
  const onGetCurrentProfile = useStoreActions(actions => actions.user.onGetCurrentProfile);
  const { setClinic, onGetMyClinic } = useStoreActions(actions => actions.clinic);

  const isLogout = showConfirm === 'logout';
  const description = isLogout
    ? 'Are you sure you want to log out?'
    : 'Are you sure you want to remove profile photo?';
  const confirmText = isLogout ? 'Log Out' : 'Remove';

  const onUpload = async (file: any) => {
    try {
      setLoadingPhoto(true);
      if (!isFile(file)) return;

      await onUploadAvatar(file);
      setValue(file);
      showNotify({ message: 'Photo successfully updated' });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const onRemove = async () => {
    try {
      setLoadingPhoto(true);
      await onRemoveAvatar();
      setValue('');
      showNotify({ message: 'Photo successfully removed!' });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const onSubmitChangePassword = async (values: IChangePasswordFormValues, cb: () => void) => {
    try {
      setChangingPassword(true);

      const payload = { email: current?.email?.trim(), ...values };
      await onChangePassword(payload);
      cb?.();

      showNotify({ message: 'Password successfully changed!' });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setChangingPassword(false);
    }
  };

  const onConfirm = isLogout
    ? () => {
        onLogout();
        resetMessages();
        setChannels([]);
      }
    : () => {
        onRemove();
        setShowConfirm(null);
      };

  const openSupportLink = () => {
    window.open(FRESH_DESK_SUPPORT, '_blank');
  };

  const canChangeTeam = useMemo(
    () => !!teamId && memoizedSelectedRole?.teams.some(item => item.id === teamId),
    [teamId, memoizedSelectedRole?.teams],
  );

  const canChangeClinic = useMemo(
    () => !!clinicId && memoizedSelectedRole?.clinic?.id === clinicId,
    [clinicId, memoizedSelectedRole?.clinic?.id],
  );

  const handleChangeRole = useCallback(async () => {
    const isSameRole = roleId === role?.id;
    const isSameTeam = teamId === team?.id;
    const isSameClinic = clinicId === clinic?.id;

    try {
      if (
        changingRole ||
        (showTeamSelect && !teamId) ||
        !roleId ||
        (!teamId && isSameRole && isSameClinic) ||
        (isSameTeam && isSameRole && isSameClinic) ||
        (showTeamSelect && !canChangeTeam) ||
        !canChangeClinic
      ) {
        return;
      }

      const defaultTeamId = showTeamSelect ? teamId : null;

      const payload = {
        roleId,
        teamId: isProgramAssistantNewRole ? memoizedSelectedRole?.teams[0].id : defaultTeamId,
        clinicId,
      };

      if (isEqual(changeRolePayload, payload)) {
        return;
      }

      changeRolePayload = payload;

      setChangingRole(true);

      await onChangeRole(payload);

      const message = getChangeRoleSuccessMessage({
        teamFrom: team?.name,
        teamTo: selectedTeam?.name,
        roleFrom: removeActPrefix(role?.name),
        roleTo: removeActPrefix(memoizedSelectedRole?.name),
        clinicFrom: clinic?.name,
        clinicTo: memoizedSelectedRole?.clinic?.name,
      });

      setAvailableClients([]);
      setClientOptions({ items: [], total: 0 });
      setDefaultClient(null);
      setCurrentClientOption([]);
      if (clinic?.name !== memoizedSelectedRole?.clinic?.name) {
        setClinic(null);
        onGetMyClinic();
      }
      showNotify({ message });
    } catch (e) {
      showError(extractErrorMessage(e));
      setFormValue('teamId', null);
      setFormValue('roleId', null);
      setFormValue('clinicId', null);
    } finally {
      setChangingRole(false);
    }
  }, [
    roleId,
    role?.id,
    role?.name,
    teamId,
    team?.id,
    team?.name,
    clinicId,
    clinic?.id,
    clinic?.name,
    changingRole,
    showTeamSelect,
    canChangeTeam,
    canChangeClinic,
    isProgramAssistantNewRole,
    memoizedSelectedRole?.teams,
    memoizedSelectedRole?.name,
    memoizedSelectedRole?.clinic?.name,
    onChangeRole,
    onGetMyClinic,
    selectedTeam?.name,
    setAvailableClients,
    setClientOptions,
    setDefaultClient,
    setCurrentClientOption,
    setClinic,
    showNotify,
    showError,
    setFormValue,
  ]);

  const onMount = useCallback(async () => {
    try {
      await onGetCurrentProfile();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [onGetCurrentProfile, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (roleId && roleId !== role?.id) {
      setFormValue('teamId', null);
    }
  }, [roleId, role?.id, setFormValue]);

  useEffect(() => {
    if (!teamId && team?.id) {
      setFormValue('teamId', team?.id);
    }
  }, [teamId, team?.id, setFormValue]);

  useEffect(() => {
    if (!roleId && role?.id) {
      setFormValue('roleId', role?.id);
    }
  }, [roleId, role?.id, setFormValue]);

  useEffect(() => {
    if (!roleId && !teamId && (role || team)) {
      setFormValue('roleId', role?.id);
      if (!isProgramAssistant) {
        setFormValue('teamId', team?.id);
      }
    }
  }, [isProgramAssistant, role, team, roleId, teamId, setFormValue]);

  useEffect(() => {
    if (!clinicId && clinic) {
      setFormValue('clinicId', clinic?.id);
    }
  }, [clinic, role, clinicId, setFormValue]);

  useEffect(() => {
    if (clinicOptions?.length === 1) {
      setFormValue('clinicId', clinicOptions[0].value);
    }
  }, [clinicOptions, setFormValue]);

  useEffect(() => {
    if (roleOptions?.length === 1) {
      setFormValue('roleId', roleOptions[0].value);
    }
  }, [roleOptions, setFormValue]);

  useEffect(() => {
    if (teamOptions?.length === 1) {
      setFormValue('teamId', teamOptions[0].value);
    }
  }, [teamOptions, setFormValue]);

  useEffect(() => {
    debounce(() => {
      handleChangeRole();
    }, 500)();
  }, [handleChangeRole]);

  return (
    <div>
      <div className={styles.header}>
        <h2>Your Profile</h2>
        {isSuperAdmin && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<img src={editWhite} alt="edit" />}
            onClick={() => navigate(EDIT_PROFILE)}
          >
            Edit
          </Button>
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item sm={5}>
          <div className={styles.itemsWrapper}>
            <div className={styles.item}>
              <h3 className={styles.header}>Profile Details</h3>
            </div>
            <ListItem label="First Name" content={current?.firstName} />
            <ListItem label="Last Name" content={current?.lastName} />
            {!isSuperAdmin && (
              <ListItem label="Sub-Role" content={getSubRoleName(current?.subRoles)} />
            )}
            <ListItem label="Phone" content={current?.phone} />
            <ListItem label="Email" content={current?.email} />
          </div>
        </Grid>
        <Grid item sm={7}>
          {!isSuperAdmin && (
            <div className={styles.uploaderWrapper}>
              <ProfilePhotoUpload
                disabled={loadingPhoto}
                value={value}
                onUpload={onUpload}
                onError={setError}
                onRemove={() => setShowConfirm('profile')}
              />
              {error && <div>{error}</div>}
            </div>
          )}
        </Grid>
        {(multipleRoles || multipleTeams) && (
          <Grid item sm={5}>
            <div className={classNames(styles.itemsWrapper, styles.roleSelect)}>
              <h3 className={styles.header}>Role</h3>
              {showClinicSelect && (
                <div className={styles.row}>
                  <Select
                    name="clinicId"
                    options={clinicOptions}
                    control={control}
                    errors={errors}
                    label="Select Clinic"
                    hideSelectedOptions={false}
                  />
                </div>
              )}
              {showRoleSelect && (
                <div className={styles.row}>
                  <Select
                    name="roleId"
                    options={roleOptions}
                    control={control}
                    errors={errors}
                    label="Select Role"
                    hideSelectedOptions={false}
                  />
                </div>
              )}
              {!!roleId && showTeamSelect && (
                <div className={styles.row}>
                  <Select
                    name="teamId"
                    options={teamOptions}
                    control={control}
                    errors={errors}
                    label="Select Team"
                    hideSelectedOptions={false}
                  />
                </div>
              )}
            </div>
          </Grid>
        )}
        <ChangePasswordForm
          loading={changingPassword}
          buttonFullWidth={false}
          error=""
          title="Change Password"
          isPasswordExpired={false}
          buttonText="Change password"
          onSubmit={onSubmitChangePassword}
          formClassName={styles.changePasswordForm}
          className={classNames(styles.itemsWrapper, styles.changePassword)}
        />
      </Grid>

      <div className={styles.buttonContainer}>
        <Button
          type="button"
          color="primary"
          variant="contained"
          startIcon={<ExitToAppIcon />}
          onClick={() => setShowConfirm('logout')}
        >
          Log Out
        </Button>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          startIcon={<InfoIcon />}
          onClick={openSupportLink}
        >
          Submit a Ticket
        </Button>
        {process.env.REACT_APP_VERSION && (
          <>
            <br />
            <br />
            <span>App Version: {process.env.REACT_APP_VERSION}</span>
          </>
        )}
      </div>
      {showConfirm && (
        <ConfirmModal
          confirmText={confirmText}
          description={description}
          onConfirm={() => {
            onConfirm();
          }}
          onClose={() => setShowConfirm(null)}
        />
      )}
    </div>
  );
};

export default Profile;

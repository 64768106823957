import { IOption } from '~/types';
import { IActTeamMemberAvailableList } from '~/services/api/actTeamMember/types';

export default (
  teamMembers: IActTeamMemberAvailableList[],
  excludeDeleted = false,
  notDisable = false,
): IOption[] => {
  const { deleted, active } = teamMembers.reduce(
    (acc, member) => {
      if (member.isDeleted || !member.isActive) {
        acc.deleted.push(member);
      } else {
        acc.active.push(member);
      }
      return acc;
    },
    { deleted: [], active: [] },
  );

  const sortByName = (a: IActTeamMemberAvailableList, b: IActTeamMemberAvailableList): number =>
    a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase() ? -1 : 1;

  const activeSorted = active.sort(sortByName);
  const deletedSorted = deleted.sort(sortByName);

  return [...activeSorted, ...(excludeDeleted ? [] : deletedSorted)].map(item => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
    isDisabled: notDisable ? false : item.isDeleted || !item.isActive,
  }));
};

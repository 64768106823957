import Grid from '@material-ui/core/Grid';
import { ReactElement } from 'react';
import { FieldErrors } from 'react-hook-form';

import BloodDrawn from './BloodDrawn';
import Toxicology from './Toxicology';

import { IBloodDrawn, IToxicology } from '../AddVisit/types';

interface ITestingProps {
  toxicology: IToxicology | null;
  bloodDrawn: IBloodDrawn | null;
  control: any;
  errors: FieldErrors;
}

const Testing = ({ toxicology, bloodDrawn, control, errors }: ITestingProps): ReactElement => (
  <Grid item container sm={12}>
    <Grid item sm={12}>
      <Toxicology toxicology={toxicology} control={control} errors={errors} />
    </Grid>
    <Grid item sm={12}>
      <BloodDrawn bloodDrawn={bloodDrawn} control={control} errors={errors} />
    </Grid>
  </Grid>
);

export default Testing;

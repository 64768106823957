import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamMemberActions, IThunkGetActTeamMember } from '../types';

const onGetActTeamMember: IThunkGetActTeamMember = thunk(
  async ({ setActTeamMember }: Actions<IActTeamMemberActions>, payload) => {
    const { clinicId, actTeamId, teamMemberId } = payload;

    const { data } = await api.actTeamMember.getActTeamMember(clinicId, actTeamId, teamMemberId);

    setActTeamMember(data);
  },
);

export default onGetActTeamMember;

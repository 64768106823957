import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IOrganizationModel } from './types';

const clinic: IOrganizationModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default clinic;

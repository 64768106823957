import classNames from 'classnames';
import { ReactElement } from 'react';

import { useStoreState } from '~/store/hooks';
import Menu from './Menu';

import styles from './Sidebar.module.scss';

const Sidebar = (): ReactElement => {
  const isMenuOpened = useStoreState(state => state.app.sidebarMenuOpened);

  return (
    <div className={classNames(styles.sidebar, { [styles.closed]: !isMenuOpened })}>
      <div className={styles.inner}>
        <Menu />
      </div>
    </div>
  );
};

export default Sidebar;

import { ReactElement } from 'react';
import ReactRouterPrompt from 'react-router-prompt';

import ConfirmModal from '~/ui/components/common/ConfirmModal';

interface IProps {
  when: boolean;
  description: string;
}

const NavigationConfirmModal = ({
  when = true,
  description = 'Are you sure you want to leave this page?',
}: IProps): ReactElement => (
  <ReactRouterPrompt when={when}>
    {({ onConfirm, onCancel }) => (
      <ConfirmModal description={description} onClose={onCancel} onConfirm={onConfirm} />
    )}
  </ReactRouterPrompt>
);

export default NavigationConfirmModal;

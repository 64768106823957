import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Loader from '~/ui/components/common/Loader';
import Shelters from './Shelters';
import ClinicProfile from './ClinicProfile';
import ActTeam from './ActTeam';
import LocalAdminTable from './LocalAdmin/Table';
import ProgramAssistant from './ProgramAssistant';
import Header from './Header';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import AssignUserModal from '../../ActTeam/reusable/AssignUserModal';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import { IUserRole } from '~/types';
import LocationGroups from './LocationGroups';

type IParams = {
  id: string;
};

type ILocationState = { state: { selectedTab: number } };

const ViewClinic = (): ReactElement => {
  const { id } = useParams<IParams>();
  const location: ILocationState = useLocation();

  const [tab, setTab] = useState(location.state?.selectedTab || 0);
  const [loading, setLoading] = useState(false);
  const [localAdmin, setLocalAdmin] = useState(null);
  const [assignModalVisible, setAssignModalVisible] = useState(false);

  const current = useStoreState(state => state.clinic.current);
  const localAdminPagination = useStoreState(state => state.localAdmin.pagination);
  const programAssistantPagination = useStoreState(state => state.programAssistant.pagination);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetProgramAssistants = useStoreActions(
    actions => actions.programAssistant.onGetProgramAssistants,
  );
  const { onGetLocalAdmins, onUnAssignLocalAdmin } = useStoreActions(actions => actions.localAdmin);
  const { onDeactivateLocalAdmin, onActivateLocalAdmin, onUnlockLocalAdmin } = useStoreActions(
    actions => actions.localAdmin,
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    try {
      await onGetClinic(id);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [id, onGetClinic, showError]);

  const refetchUsers = useCallback(async () => {
    if (tab === 2) {
      await onGetLocalAdmins({
        clinicId: id,
        params: {
          pageNumber: localAdminPagination.pageNumber,
          pageSize: localAdminPagination.pageSize,
        },
      });
    } else {
      await onGetProgramAssistants({
        clinicId: id,
        params: {
          pageNumber: programAssistantPagination.pageNumber,
          pageSize: programAssistantPagination.pageSize,
        },
      });
    }
  }, [
    tab,
    onGetLocalAdmins,
    id,
    localAdminPagination.pageNumber,
    localAdminPagination.pageSize,
    onGetProgramAssistants,
    programAssistantPagination.pageNumber,
    programAssistantPagination.pageSize,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !current) return <Loader />;

  const getConfirmProps = (status: string) => {
    if (status === 'Active')
      return {
        confirmText: 'Deactivate',
        description: 'Are you sure you want to deactivate this user?',
        method: () => onDeactivateLocalAdmin({ clinicId: id, localAdminId: localAdmin?.id }),
      };

    if (status === 'Deactivated')
      return {
        confirmText: 'Activate',
        description: 'Are you sure you want to activate this user?',
        method: () => onActivateLocalAdmin({ clinicId: id, localAdminId: localAdmin?.id }),
      };

    if (status === 'Locked')
      return {
        confirmText: 'Unlock',
        description: 'Are you sure you want to unlock this user?',
        method: () => onUnlockLocalAdmin({ clinicId: id, localAdminId: localAdmin?.id }),
      };

    if (status === 'Assigned')
      return {
        confirmText: 'Unassign',
        description: 'Are you sure you want to unassign this user?',
        method: async () => {
          try {
            await onUnAssignLocalAdmin({ clinicId: id, localAdminId: localAdmin?.id });
            refetchUsers();
            showNotify({ message: `User successfully unassigned` });
          } catch (e) {
            showError(extractErrorMessage(e));
          }
        },
      };

    return {
      confirmText: 'Resend',
      description: 'Are you sure you want to resend invitation?',
      method: () => api.localAdmin.resendInvitation(id, localAdmin?.id),
    };
  };

  const { confirmText, description, method } = getConfirmProps(localAdmin?.status);

  const onConfirm = async () => {
    try {
      await method();
      setLocalAdmin(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLocalAdmin(false);
    }
  };

  return (
    <div>
      <Header
        name={current.name}
        tab={tab}
        setTab={setTab}
        id={id}
        currentTab={tab}
        openAssignModal={() => setAssignModalVisible(true)}
      />
      {tab === 0 && <ClinicProfile clinic={current} />}
      {tab === 1 && <ActTeam />}
      {tab === 2 && <LocalAdminTable setLocalAdmin={setLocalAdmin} />}
      {tab === 3 && <ProgramAssistant />}
      {tab === 4 && <Shelters />}
      {tab === 5 && <LocationGroups />}
      {localAdmin && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onClose={() => setLocalAdmin(null)}
          onConfirm={onConfirm}
        />
      )}
      {assignModalVisible && (
        <AssignUserModal
          modalTitle={
            tab === 2
              ? 'Search User to assign Local Admin Role'
              : 'Search Program Assistant to assign to this Team '
          }
          role={(tab === 2 ? IUserRole.LocalAdmin : IUserRole.ProgramAssistant).replace(/\s/g, '')}
          onClose={() => setAssignModalVisible(null)}
          refetchUsers={refetchUsers}
        />
      )}
    </div>
  );
};

export default ViewClinic;

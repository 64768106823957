import unionBy from 'lodash/unionBy';
import { IClientOption } from '~/store/client/types';
import { IOption } from '~/types';
import sortByName from '~/ui/pages/Reports/helpers/sortByName';

export default (clients: IClientOption[]): IOption[] =>
  unionBy(
    clients
      .sort((a, b) => sortByName(a, b, true, false))
      .map(item => ({ value: item.id, label: item.name })),
    'value',
  );

import { action } from 'easy-peasy';

import { IActionUpdateChannel } from '../types';

const updateChannel: IActionUpdateChannel = action((state, payload) => {
  let exist = false;
  const newChannels = state.channels.map(channel => {
    if (channel.url === payload.url) {
      exist = true;
      return payload;
    }
    return channel;
  });

  const channels = (exist ? newChannels : [payload, ...newChannels]).sort((a, b) => {
    const at = a.lastMessage ? a.lastMessage.createdAt : a.createdAt;
    const bt = b.lastMessage ? b.lastMessage.createdAt : b.createdAt;
    return bt - at;
  });

  state.channels = channels;
});

export default updateChannel;

import React, { useEffect, ReactElement, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import Loader from '~/ui/components/common/Loader';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import Input from '~/ui/components/inputs/Input';
import Button from '~/ui/components/common/Button';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { allergyValidationSchema } from './validates';

import { IAllergyInitialValues, IRequestInfo } from './types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/types';
import { IDetails } from '../types';
import styles from './Styles.module.scss';

interface IProps {
  showSecondSubmit: boolean;
  setModalTitle: (str: string) => void;
  setDetails: (arg: IDetails) => void;
  defaultValues: IAllergyInitialValues;
  details: IDetails | null;
  requestInfo: IRequestInfo;
}

const AddAllergy = ({
  showSecondSubmit,
  setModalTitle,
  setDetails,
  defaultValues,
  details,
  requestInfo,
}: IProps): ReactElement => {
  const [allergies, setAllergies] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(false);

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: allergyValidationSchema });

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onUpdateAllergy, onCreateAllergy, setCurrent } = useStoreActions(
    actions => actions.clientDetails,
  );

  const onMount = useCallback(async () => {
    try {
      const allergyDictionaries = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.Allergy)
        .then(r => formatDictionaryOptions(r.data));

      setAllergies(allergyDictionaries);

      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = async (vals: IAllergyInitialValues, evt: React.BaseSyntheticEvent) => {
    const { name } = (evt as React.BaseSyntheticEvent<HTMLFormElement>).nativeEvent.submitter;
    try {
      if (details && details.id) {
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload: vals,
        };
        onUpdateAllergy(payload);
        setDetails(null);
        setCurrent(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload: vals,
        };

        await onCreateAllergy(payload);

        reset({ id: null, note: '' });
      }
      setModalTitle(name);
      const type = details?.id ? 'update' : 'added';
      showNotify({ message: `Allergy successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <SelectComponent
            isRelativeWindow
            name="id"
            options={allergies}
            control={control}
            errors={errors}
            maxMenuHeight={150}
            label="Select Allergy"
            isDisabled={!showSecondSubmit}
            hideSelectedOptions={false}
          />
        </Grid>
        <Grid item sm={12}>
          <Input name="note" register={register} label="Allergy Note" multiline errors={errors} />
        </Grid>
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        {showSecondSubmit && (
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            name="Add Allergy"
            className={styles.margin}
          >
            Save and Add another
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={!showSecondSubmit ? styles.margin : ''}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddAllergy;

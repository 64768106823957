import { privateAxios } from '../requestsCollection';
import { IParams, IResponse } from '../types';
import { IDeleteRequest } from './types';

export const getDeleteRequests = (
  clinicId: string,
  params: IParams,
): IResponse<{ items: IDeleteRequest[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/account-management/delete-requests`,
    params,
  });

export const rejectDeleteRequest = ({
  clinicId,
  teamId,
  userId,
}: {
  clinicId: string;
  teamId: string;
  userId: string;
}): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/team/${teamId}/account-management/delete-requests/${userId}/reject`,
  });

export const approveDeleteRequest = ({
  clinicId,
  teamId,
  userId,
}: {
  clinicId: string;
  teamId: string;
  userId: string;
}): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/team/${teamId}/account-management/delete-requests/${userId}/approve`,
  });

export default {};

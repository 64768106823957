import { isSunday, startOfMonth, previousSunday } from 'date-fns';

// this func take into account start of week according to app business logic (add days from previous month to show whole week)
// use only for week mode
const getFromDate = (date: Date, dateFormat?: boolean): string | Date => {
  const startOfMonthDate = startOfMonth(date);
  const sunday = isSunday(startOfMonthDate);
  const prevSunday = sunday ? startOfMonthDate : previousSunday(startOfMonthDate);
  if (dateFormat) {
    return prevSunday;
  }
  return new Date(prevSunday.setHours(0)).toISOString();
};

export default getFromDate;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IDeleteRequestActions, IDeleteRequestsStore, IThunkRejectDeleteRequest } from '../types';

const onRejectDeleteRequest: IThunkRejectDeleteRequest = thunk(
  async ({ setDeleteRequests }: Actions<IDeleteRequestActions>, payload, { getState }) => {
    const localState = getState() as IDeleteRequestsStore;
    const { clinicId, teamId, userId } = payload;

    await api.deleteRequests.rejectDeleteRequest({
      clinicId,
      teamId,
      userId,
    });

    const updatedList = localState.list.filter(item => String(item.id) !== userId);

    setDeleteRequests({ items: updatedList });
  },
);

export default onRejectDeleteRequest;

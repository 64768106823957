import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';

import { validateLengthField } from '~/ui/configs/form/validation';
import { requiredField, fieldMax, fieldOnlyNumbers } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  clinicId: number().required(requiredField),
  name: string().required(requiredField).test('length', fieldMax, validateLengthField),
  typeId: number().required(requiredField),
  capacity: string()
    .matches(/^[0-9]+$|^$/, {
      message: fieldOnlyNumbers,
      excludeEmptyString: true,
    })
    .nullable(),
});

export default yupResolver(validationSchema);

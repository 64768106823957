import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetDiagnoses } from '../types';

const onGetDiagnoses: IThunkGetDiagnoses = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const diagnoses = await api.clientDetails
      .getClientDiagnosisList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ diagnoses });
  },
);

export default onGetDiagnoses;

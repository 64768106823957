import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkUnAssignTeamMember } from '../types';

const onUnAssignTeamMember: IThunkUnAssignTeamMember = thunk(async (_, payload) => {
  const { clinicId, actTeamId, teamMemberId } = payload;

  await api.actTeamMember.unAssignTeamMember(clinicId, teamMemberId, actTeamId);
});

export default onUnAssignTeamMember;

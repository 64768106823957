import { IResponse, IShelterParams } from '~/services/api/types';
import { privateAxios } from '../requestsCollection';
import { IShelter, IShelterRequest } from './types';

export const getClinicShelters = (
  clinicId: string,
  params: IShelterParams,
): IResponse<{ items: IShelter[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/shelters`,
    params,
  });

export const getClinicShelter = (clinicId: string, id: string): IResponse<IShelter> =>
  privateAxios({ method: 'get', url: `clinic/${clinicId}/shelters/${id}` });

export const archiveShelter = (clinicId: string, id: string): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `/clinic/${clinicId}/shelters/${id}`,
  });

export const restoreShelter = (clinicId: string, id: string): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `/clinic/${clinicId}/shelters/${id}/recover`,
  });

export const updateClinicShelter = (
  clinicId: string,
  id: string,
  data: IShelterRequest,
): IResponse<IShelter> =>
  privateAxios({
    method: 'put',
    url: `/clinic/${clinicId}/shelters/${id}`,
    data,
  });

export const createClinicShelter = (clinicId: string, data: IShelterRequest): IResponse<IShelter> =>
  privateAxios({
    method: 'post',
    url: `/clinic/${clinicId}/shelters`,
    data,
  });

export default {};

import { action } from 'easy-peasy';
import { IActionSetAvailableDictionaryFilters } from '../types';

const setAvailableDictionaryFilters: IActionSetAvailableDictionaryFilters = action(
  (state, { type, ...filters }) => {
    state.availableDictionaries = {
      ...state.availableDictionaries,
      [type]: { ...state.availableDictionaries[type], ...filters },
    };
  },
);

export default setAvailableDictionaryFilters;

import { IResource } from '~/services/api/resource/types';
import { IFormValues } from '../components/ResourcesModal/types';

export default (resource: IResource): IFormValues | undefined =>
  resource
    ? {
        name: resource.name,
        type: resource.type,
        isVisible: resource.isVisible,
        externalUrl: resource.externalUrl,
      }
    : undefined;

import { privateAxios } from '../requestsCollection';
import { IParams, IResponse } from '../types';
import { ISuperAdminList, IRequestPayload, ISuperAdminCurrent } from './types';

export const getSuperAdmins = (
  params: IParams,
): IResponse<{ items: ISuperAdminList[]; total: number }> =>
  privateAxios({ method: 'get', url: 'super-admin/list', params });

export const deactivateSuperAdmin = (id: number): IResponse<void> =>
  privateAxios({ method: 'post', url: `super-admin/${id}/deactivate` });

export const activateSuperAdmin = (id: number): IResponse<void> =>
  privateAxios({ method: 'post', url: `super-admin/${id}/activate` });

export const resendInvitation = (id: number): IResponse<void> =>
  privateAxios({ method: 'post', url: `super-admin/${id}/resend-invitation` });

export const unlockSuperAdmin = (id: number): IResponse<void> =>
  privateAxios({ method: 'post', url: `super-admin/${id}/unlock-account` });

export const getSuperAdmin = (id: string): IResponse<ISuperAdminCurrent> =>
  privateAxios({ method: 'get', url: `super-admin/${id}` });

export const addSuperAdmin = (data: IRequestPayload): IResponse<void> =>
  privateAxios({ method: 'post', url: `super-admin`, data });

export const editSuperAdmin = (id: string, data: IRequestPayload): IResponse<void> =>
  privateAxios({ method: 'put', url: `super-admin/${id}`, data });

export default {};

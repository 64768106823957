import { ReactElement } from 'react';
import { IDictionary } from '~/services/api/dictionaries/types';
import { IProgramAssistantForm } from '~/services/api/programAssistant/types';

import ListItem from '~/ui/components/common/ListItem';
import { isoToLocal } from '~/utils/date/date';

import styles from './WorkDetails.module.scss';

interface IProps {
  teamMember: IProgramAssistantForm;
  clinicName: string;
}

const WorkDetails = ({ teamMember, clinicName }: IProps): ReactElement => (
  <div className={styles.content}>
    <div className={styles.column}>
      <div className={styles.listItemBlock}>
        <div className={styles.item}>
          <h4>Work Details</h4>
        </div>
        <ListItem label="Associated Clinic" content={clinicName} moreLabelWidth />
        <ListItem
          label="Employment Status"
          content={teamMember.workDetails.employmentStatus.name}
          moreLabelWidth
        />
        <ListItem
          label="Credentials"
          content={teamMember.workDetails.credentials.name}
          moreLabelWidth
        />
        <ListItem
          label="Education"
          content={teamMember.workDetails.education.name}
          moreLabelWidth
        />
        <ListItem
          label="Years of Experience"
          content={String(teamMember.workDetails.experience)}
          moreLabelWidth
        />
        {teamMember.roles && (
          <ListItem
            label="Roles"
            content={teamMember.roles.map((r: IDictionary) => r.name).join(', ')}
            moreLabelWidth
          />
        )}
        <ListItem
          label="Assigned Teams"
          content={teamMember.teams.map(t => t.name).join(', ')}
          moreLabelWidth
        />
        <ListItem
          label="Hire Date"
          content={isoToLocal(teamMember.workDetails.hireDate)}
          moreLabelWidth
        />
      </div>
    </div>
  </div>
);

export default WorkDetails;

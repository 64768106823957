import setClientAllocations from './setClientAllocations';
import setClientAllocation from './setClientAllocation';
import resetPagination from './resetPagination';
import setAllocationClientsByGroup from './setAllocationClientsByGroup';

export default {
  setClientAllocations,
  setClientAllocation,
  resetPagination,
  setAllocationClientsByGroup,
};

import { secondsToHours } from 'date-fns';

const splitSeconds = (seconds: number, withColon = false): string => {
  const hours = secondsToHours(seconds);
  const minutes = seconds && seconds < 60 ? 1 : Math.ceil(seconds / 60) - hours * 60;

  const defaultDuration = !hours && !minutes ? '1 min' : '';

  let res = '';
  if (!withColon) {
    res = `${hours ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${
      minutes ? `${minutes} min` : defaultDuration
    } `;
  } else {
    res = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }

  return res;
};

export default splitSeconds;

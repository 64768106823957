import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IDictionariesModel } from './types';

const dictionaries: IDictionariesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default dictionaries;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreShelter, ISheltersStore, IShelterActions } from '../types';

const onRestoreShelter: IThunkRestoreShelter = thunk(
  async ({ setShelters }: Actions<IShelterActions>, payload, { getState }) => {
    const localState = getState() as ISheltersStore;

    const { clinicId, id } = payload;

    await api.shelters.restoreShelter(clinicId, id);

    const updatedList = localState.list.map(item =>
      item.id !== id ? item : { ...item, isArchived: false },
    );

    setShelters({ items: updatedList });
  },
);

export default onRestoreShelter;

import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import {
  IReleaseParams,
  IRelease,
  ICurrentRelease,
  IReleaseRequest,
  IReleaseUpdates,
} from './types';

export const getReleases = (
  params?: IReleaseParams,
): IResponse<{ total: number; items: IRelease[] }> =>
  privateAxios({
    method: 'get',
    url: 'releases/list',
    params,
  });

export const getRelease = (releaseId: string): IResponse<ICurrentRelease> =>
  privateAxios({
    method: 'get',
    url: `releases/${releaseId}`,
  });

export const getReleaseUpdates = (): IResponse<IReleaseUpdates> =>
  privateAxios({
    method: 'get',
    url: `releases/updates`,
  });

export const readReleaseUpdates = (id: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `releases/${id}/acknowledge`,
  });

export const readAllReleaseUpdates = (ids: number[]): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `releases/acknowledge-list`,
    data: { ids },
  });

export const addRelease = (data: IReleaseRequest): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: 'releases',
    data,
  });

export const archiveRelease = (releaseId: number): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `releases/${releaseId}`,
  });

export const restoreRelease = (releaseId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `releases/${releaseId}/recover`,
  });

export const updateRelease = (releaseId: number, data: IReleaseRequest): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `releases/${releaseId}`,
    data,
  });

export default {};

import { Actions, thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import api from '~/services/api';
import getMimeType from '~/ui/pages/Resources/helpers/getMimeType';
import replaceType from '~/ui/pages/Resources/helpers/replaceType';

import { IResourceActions, IThunkDownloadResource } from '../types';
import getFileExtension from '~/ui/pages/Resources/helpers/getFileExtension';

const onDownloadResource: IThunkDownloadResource = thunk(
  async (
    { setBlobUrl }: Actions<IResourceActions>,
    { resource, open = false, returnUrl = false },
  ) => {
    await api.resource
      .downloadResource(resource.externalUrl)
      .then(({ data }: AxiosResponse<BlobPart, any>) => {
        const type = replaceType(getMimeType(resource.type), !open);
        const blob = new Blob([data], {
          type,
        });
        const link = window.URL.createObjectURL(blob);

        if (returnUrl) {
          setBlobUrl(link);
        } else if (open) {
          window.open(link, 'window2', '');
        } else {
          saveAs(
            link,
            resource.name.includes('.')
              ? `${resource.name}${getFileExtension(resource.type)}`
              : resource.name,
          );
        }
      });
  },
);

export default onDownloadResource;

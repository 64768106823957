import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreOrganization, IOrganizationStore, IOrganizationActions } from '../types';

const onRestoreOrganization: IThunkRestoreOrganization = thunk(
  async ({ setOrganizations }: Actions<IOrganizationActions>, organizationId, { getState }) => {
    const localState = getState() as IOrganizationStore;
    await api.organization.restoreOrganization(organizationId);

    const updatedList = localState.list.map(organization =>
      organization.id !== organizationId ? organization : { ...organization, isArchived: false },
    );

    setOrganizations({ items: updatedList });
  },
);

export default onRestoreOrganization;

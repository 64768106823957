import { action } from 'easy-peasy';
import { IActionDeactivateProgramAssistant } from '../types';

const deactivateProgramAssistant: IActionDeactivateProgramAssistant = action(
  (state, localAdminId) => {
    const updatedList = state.list.map(item =>
      item.id !== localAdminId ? item : { ...item, status: 'Deactivated' },
    );

    state.list = updatedList;
  },
);

export default deactivateProgramAssistant;

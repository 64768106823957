import { ReactElement, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import Input from '~/ui/components/inputs/Input';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IRequestInfo, IPrimaryDiagnosisInitialValues } from './types';
import { IOption } from '~/types';
import styles from './Styles.module.scss';

interface IProps {
  setModalTitle: (v: any) => void;
  setDetails: (v: any) => void;
  requestInfo: IRequestInfo;
  defaultValues: IPrimaryDiagnosisInitialValues;
}

const EditPrimaryDiagnosis = ({
  setModalTitle,
  setDetails,
  requestInfo,
  defaultValues,
}: IProps): ReactElement => {
  const [primaryDiagnoses, setPrimaryDiagnoses] = useState<IOption[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const setCurrent = useStoreActions(actions => actions.clientDetails.setCurrent);
  const onGetClient = useStoreActions(actions => actions.client.onGetClient);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const primaryDiagnosesDictionaries = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.Diagnosis)
        .then(r => formatDictionaryOptions(r.data));

      setPrimaryDiagnoses(primaryDiagnosesDictionaries);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = async (vals: { id: number; note: string }) => {
    try {
      await api.clientDetails.updateClientPrimaryDiagnosis(requestInfo, vals);
      await onGetClient(requestInfo);

      setModalTitle(null);
      setDetails(null);
      showNotify({ message: 'Primary diagnosis successfully updated' });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Select
            isRelativeWindow
            name="id"
            options={primaryDiagnoses}
            control={control}
            errors={errors}
            label="Select Primary Dx"
            hideSelectedOptions={false}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            name="note"
            register={register}
            label="Primary Dx Note"
            multiline
            errors={errors}
          />
        </Grid>
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="submit" className={styles.margin}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default EditPrimaryDiagnosis;

/* eslint-disable jsx-a11y/tabindex-no-positive */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import InputMask from '~/ui/components/inputs/InputMask';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';

import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { useStoreActions } from '~/store/hooks';
import { IOption } from '~/types';
import Checkbox from '~/ui/components/inputs/Checkbox';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { IRequestInfo } from '../../HealthDetails/Forms/types';
import styles from '../SupportContacts.module.scss';
import { IInitialValues } from './types';
import validate from './validate';

interface IOptions {
  states: IOption[];
  relationships: IOption[];
}

interface IProps {
  defaultValues: IInitialValues;
  onClose: () => void;
  formType: 'create' | 'edit';
  requestInfo: IRequestInfo;
  supportContactId: number;
}

const Form = ({
  onClose,
  formType,
  defaultValues,
  requestInfo,
  supportContactId,
}: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOptions>(null);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onCreateSupportContact, onUpdateSupportContact } = useStoreActions(
    actions => actions.clientDetails,
  );
  const {
    control,
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm<IInitialValues & { hasAddress: boolean }>({ defaultValues, resolver: validate });

  const { hasAddress } = watch();

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const [states, relationships] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.State)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Relationship)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setOptions({ states, relationships });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [showError]);

  const onSubmit = async (vals: IInitialValues) => {
    try {
      if (formType === 'create') {
        const payload = {
          requestInfo,
          requestPayload: { ...vals, address: hasAddress ? vals.address : null },
        };
        await onCreateSupportContact(payload);
      } else {
        const payload = {
          requestInfo: { id: String(supportContactId), ...requestInfo },
          requestPayload: { ...vals, address: hasAddress ? vals.address : null },
        };
        await onUpdateSupportContact(payload);
      }

      showNotify({ message: `Collateral contact successfully ${formType}d` });
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (!hasAddress) {
      unregister(['address']);
    }
  }, [hasAddress, unregister]);

  const formTitle = formType === 'create' ? 'Add Collateral Contact' : 'Edit Collateral Contact';

  if (loading || !options) return <Loader />;

  return (
    <Box p={3}>
      <h3 className={styles.center}>{formTitle}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Input
              register={register}
              name="firstName"
              errors={errors}
              label="First Name"
              autoFocus
              tabIndex={1}
            />
          </Grid>
          <Grid item sm={6}>
            <Input
              register={register}
              name="lastName"
              errors={errors}
              label="Last Name"
              tabIndex={2}
            />
          </Grid>
          <Grid item sm={6}>
            <InputMask
              alwaysShowMask={false}
              name="phone"
              control={control}
              errors={errors}
              label="Phone"
              tabIndex={3}
            />
          </Grid>
          <Grid item sm={6}>
            <Input register={register} name="email" errors={errors} label="Email" tabIndex={4} />
          </Grid>
          <Grid item sm={6}>
            <Select
              isRelativeWindow
              maxMenuHeight={170}
              options={options.relationships}
              name="relationshipId"
              control={control}
              errors={errors}
              label="Relationship"
              tabIndex={5}
              openMenuOnFocus
              hideSelectedOptions={false}
            />
          </Grid>
          <Grid item sm={6}>
            <Checkbox control={control} errors={errors} name="hasAddress" label="Address" />
          </Grid>
          {hasAddress && (
            <>
              <Grid item sm={6}>
                <Input
                  register={register}
                  name="address.city"
                  errors={errors}
                  label="City"
                  tabIndex={6}
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  register={register}
                  name="address.line1"
                  errors={errors}
                  label="Address 1"
                  tabIndex={7}
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  register={register}
                  name="address.line2"
                  errors={errors}
                  label="Address 2"
                  tabIndex={8}
                />
              </Grid>
              <Grid item sm={3}>
                <Select
                  isRelativeWindow
                  maxMenuHeight={170}
                  options={options.states}
                  name="address.stateId"
                  control={control}
                  errors={errors}
                  label="State"
                  tabIndex={9}
                  openMenuOnFocus
                  hideSelectedOptions={false}
                />
              </Grid>
              <Grid item sm={3}>
                <InputMask
                  alwaysShowMask={false}
                  mask="99999"
                  name="address.zipCode"
                  control={control}
                  errors={errors}
                  label="Zip Code"
                  tabIndex={10}
                />
              </Grid>
            </>
          )}
        </Grid>
        <div className={styles.buttonsWrapper}>
          <Button
            variant="outlined"
            color="primary"
            className={styles.margin}
            onClick={onClose}
            tabIndex={11}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" tabIndex={12}>
            Save
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default Form;

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { isToday } from 'date-fns';
import { Fragment, ReactElement, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import delay from 'lodash/delay';
import uniq from 'lodash/uniq';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import PrivateImage from '~/ui/components/common/PrivateImage';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import useRole from '~/store/user/hooks/useRole';
import MultiValue from '../ClientAllocationForm/components/MultiValue';
import Option from '../ClientAllocationForm/components/Option';
import MenuList from '~/ui/components/inputs/SelectWithoutAnimation/components/MenuList';

import extractLocationGroupClientsIds from '../ClientAllocationForm/helpers/extractLocationGroupClientsIds';

import {
  formatLocationGroupOptions,
  formValuesMapper,
  groupClientAllocationData,
  ITeamMemberMapped,
  prepareDefaultValues,
} from '~/utils/clientAllocation';
import { getLocalDateNoFormat, normalizeDateString } from '~/utils/date/date';
import { extractErrorMessage } from '~/utils/error/error';
import filterNumberArray from '../ClientAllocationForm/helpers/filterNumberArray';
import extractArchivedClients from '../ClientAllocationForm/helpers/extractArchivedClients';
import extractFullName from '~/utils/text/extractFullName';
import excludeArchivedClients from '../ClientAllocationForm/helpers/excludeArchivedClients';
import { IUpdateClientAllocation } from '~/services/api/clientAllocation';

import {
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';
import smallAvatarIcon from '~/ui/assets/images/smallAvatar.svg';
import { IOption } from '~/ui/components/inputs/Select/types';
import { MY_ACT_TEAM, VIEW_ACT_TEAM, VIEW_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import { AllocationMode } from '../ClientAllocationForm/constants/allocationMode';
import hasLocationGroup from '../ClientAllocationForm/helpers/hasLocationGroup';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './EditClientAllocation.module.scss';

interface IProps {
  teamMembers: ITeamMemberMapped[];
  clients: IOption[];
  clientAllocation: IClientAllocationDetailed[];
  actTeamId: string;
  assignedClients?: number[];
  clinicId: string;
  locationGroups: IAllocationClientsByGroup[];
}

const Form = ({
  clientAllocation,
  teamMembers,
  clients,
  actTeamId,
  assignedClients = [],
  clinicId,
  locationGroups,
}: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  // * details used to transfer data from form to modal
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();

  const isLocationGroupAllocation = hasLocationGroup(clientAllocation);

  const defValues = {
    date: clientAllocation[0].date,
    allocationMode: isLocationGroupAllocation
      ? AllocationMode.BY_LOCATION_GROUP
      : AllocationMode.BY_CLIENT,
    ...prepareDefaultValues(clientAllocation),
  };

  const {
    control,
    formState: { errors, isDirty },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: defValues,
  });

  const userIdsWithClients = useMemo(
    () => clientAllocation.filter(item => !item.isArchived).map(item => item.user.id),
    [clientAllocation],
  );

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { isActTeam, isActTeamMember } = useRole();

  const { date: formDate, allocationMode, ...formValues } = watch();

  const newAssignedClients = formValues as { [key: string]: number[] };

  const unique = new Set([
    ...formValuesMapper(formValues as { [key: string]: number[] }),
    ...assignedClients,
  ]); // un-mark clients who has already been assigned (for team member role)

  const deletedMembers = useMemo(
    () =>
      teamMembers.filter(
        teamMember => teamMember.isDeleted && userIdsWithClients.includes(teamMember.id),
      ),
    [teamMembers, userIdsWithClients],
  );

  const activeMembers = useMemo(
    () => teamMembers.filter(teamMember => !teamMember.isDeleted),
    [teamMembers],
  );

  const additionalStyleHandler = () => ({
    option: (provided: any, { data }: any) => {
      const notSelected = !unique?.has(data?.value);

      return {
        ...provided,
        backgroundColor: notSelected ? '#FFF1EE' : 'inherit',
        fontWeight: '300',
        color: variables.colorBlack,
      };
    },
  });

  const handleRemoveClient = (id: number, field: string) => {
    const newClients = filterNumberArray(newAssignedClients[field], id);
    setValue(field as any, newClients);
  };

  const handleRemoveGroup = (id: number, field: string) => {
    const groupField = `groups${field}`;
    const clientField = `clients${field}`;
    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    const newGroups = filterNumberArray(newAssignedClients[groupField], id);
    const newClients =
      newAssignedClients[clientField]?.filter(item => !(groupClientsIds || []).includes(item)) ||
      [];

    setValue(clientField as any, newClients);
    setValue(groupField as any, newGroups);
  };

  const handleSelectGroup = (id: number, field: string) => {
    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    const newValue = Array.from(
      new Set([...groupClientsIds, ...(newAssignedClients[field] || [])]),
    );

    setValue(field as any, newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit = async ({ name, allocationMode, ...vals }: any) => {
    try {
      const archivedClients = extractArchivedClients(clientAllocation);

      if (archivedClients.length) {
        const archivedClientsNames = uniq(
          archivedClients.map(client => extractFullName(client)),
        ).join(', ');
        const isMultipleArchived = archivedClients.length > 1;
        showError(
          `The archived client${isMultipleArchived ? 's' : ''} ${archivedClientsNames} ha${
            isMultipleArchived ? 've' : 's'
          } been removed from the created allocation`,
        );
      }

      delay(
        async () => {
          setIsAdding(true);
          const {
            allocationsForAdd,
            allocationsForDelete,
            allocationsForEdit: allocationsForEditWithArchivedClients,
          } = groupClientAllocationData(vals);

          const allocationsForEdit = excludeArchivedClients<IUpdateClientAllocation>(
            allocationsForEditWithArchivedClients,
            archivedClients,
          );

          const editPayload = {
            allocations: allocationsForEdit,
          };

          const addPayload = {
            from: normalizeDateString(clientAllocation[0].date),
            to: normalizeDateString(clientAllocation[0].date, true),
            includeWeekends: true,
            allocations: allocationsForAdd,
          };

          if (
            !allocationsForAdd.length &&
            !allocationsForDelete.length &&
            !allocationsForEdit.length
          ) {
            showError('Please provide at least one client');
            return;
          }

          if (allocationsForAdd.length) {
            await api.clientAllocation.addClientAllocation(
              { clinicId, teamId: actTeamId },
              addPayload,
            );
          }
          if (allocationsForEdit.length) {
            if (isActTeamMember) {
              await api.clientAllocation.updateOwnClientAllocation(
                { clinicId, teamId: actTeamId, clientAllocationId: allocationsForEdit[0].id },
                {
                  ...addPayload,
                  clientIds: allocationsForEdit[0].clientIds,
                  locationGroupIds: allocationsForEdit[0].locationGroupIds,
                },
              );
            } else {
              await api.clientAllocation.updateClientAllocation(
                { clinicId, teamId: actTeamId },
                editPayload,
              );
            }
          }
          if (allocationsForDelete.length) {
            if (isActTeamMember) {
              await api.clientAllocation.deleteOwnClientAllocation({
                clinicId,
                teamId: actTeamId,
                clientAllocationId: String(allocationsForDelete[0]),
              });
            } else {
              await Promise.all(
                allocationsForDelete.map(async id => {
                  api.clientAllocation.deleteClientAllocation({
                    clinicId,
                    teamId: actTeamId,
                    clientAllocationId: String(id),
                  });
                }),
              );
            }
          }

          showNotify({ message: 'Client allocation successfully updated' });
          if (isActTeam) {
            navigate(MY_ACT_TEAM, { state: { selectedTab: 0 } });
          } else {
            navigate(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), {
              state: { selectedTab: 0 },
            });
          }
        },
        archivedClients.length ? 3000 : 0,
      );
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const openConfirmationModal = ({ date, ...rest }: any) => {
    if (getLocalDateNoFormat(date) < new Date() && !isToday(getLocalDateNoFormat(date))) {
      setDetails(rest);
      return;
    }
    onSubmit(rest);
  };

  const renderMembers = (members: ITeamMemberMapped[]) => (
    <>
      {members.map(teamMember => {
        const allocationId = clientAllocation?.find(
          allocation => allocation.user.id === teamMember.id && !allocation.isArchived,
        )?.id;

        const selectName = `_${teamMember.id}${allocationId ? `_${allocationId}` : ''}`;
        return (
          <Fragment key={teamMember.id}>
            <Grid
              item
              sm={3}
              className={classNames({ [styles.deletedUser]: teamMember.isDeleted })}
            >
              <div className={styles.row}>
                <PrivateImage
                  className={styles.image}
                  src={teamMember.photo || smallAvatarIcon}
                  height={30}
                  alt="avatar"
                />
                <span className={styles.text}>{teamMember.fullName}</span>
              </div>
            </Grid>
            <Grid item sm={9}>
              {allocationMode === AllocationMode.BY_CLIENT ? (
                <SelectComponent
                  closeMenuOnSelect={false}
                  showCustomComponents
                  hideSelectedOptions={false}
                  additionalStyleHandler={() => additionalStyleHandler()}
                  isMulti
                  label="Assign Clients"
                  options={clients}
                  disableMenu={teamMember.isDeleted}
                  name={`clients${selectName}`}
                  control={control}
                  errors={errors}
                />
              ) : (
                <SelectComponent
                  closeMenuOnSelect={false}
                  showCustomComponents
                  hideSelectedOptions={false}
                  additionalStyleHandler={() => additionalStyleHandler()}
                  isMulti
                  label="Location Group"
                  options={formatLocationGroupOptions(locationGroups)}
                  disableMenu={teamMember.isDeleted}
                  name={`groups${selectName}`}
                  control={control}
                  errors={errors}
                  customComponents={{
                    MenuList: props => <MenuList {...props} label="Location Groups" />,
                    MultiValue: props => (
                      <MultiValue
                        {...props}
                        clientIds={newAssignedClients[`clients${selectName}`]}
                        locationGroups={locationGroups}
                        onDelete={id => handleRemoveClient(id, `clients${selectName}`)}
                        onDeleteGroup={id => handleRemoveGroup(id, selectName)}
                      />
                    ),
                    Option: props => (
                      <Option
                        {...props}
                        selectGroup={id => handleSelectGroup(id, `clients${selectName}`)}
                      />
                    ),
                  }}
                />
              )}
            </Grid>
          </Fragment>
        );
      })}
    </>
  );

  const leavePageDescription =
    'Are you sure you want to cancel Edit Client Allocation”? All the changes will be discarded.';

  const editPastAllocationDescription =
    'Caution. You are modifying a past date. Are you sure you want to proceed?';

  return (
    <form onSubmit={handleSubmit(openConfirmationModal)}>
      <Paper>
        <Box p={2}>
          {!isActTeamMember && (
            <div className={styles.header}>
              <h5 className={styles.subTitle}>Client Allocation Details</h5>
            </div>
          )}
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <h5 className={styles.subTitle}>Team Members</h5>
            </Grid>
            <Grid item sm={9}>
              <h5 className={styles.subTitle}>
                {allocationMode === AllocationMode.BY_CLIENT
                  ? 'Assigned Clients'
                  : 'Location Groups'}
              </h5>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={styles.formWrapper}>
            {renderMembers(activeMembers)}
            {!!deletedMembers.length && (
              <>
                <Grid item sm={12}>
                  <h5 className={styles.subTitle}>Deleted/Deactivated Team Members</h5>
                </Grid>
                {renderMembers(deletedMembers)}
              </>
            )}
          </Grid>
        </Box>
      </Paper>
      <div className={styles.buttons}>
        <div className={styles.buttonWrapper}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (isActTeam) {
                navigate(
                  VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                    ':clientAllocationDate',
                    clientAllocation[0].date.split('T')[0],
                  ),
                );
              } else
                navigate(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), {
                  state: { selectedTab: 0 },
                });
            }}
          >
            Cancel
          </Button>
        </div>
        <Button color="primary" variant="contained" type="submit">
          Save Changes
        </Button>
      </div>
      <NavigationConfirmModal when={isDirty && !isAdding} description={leavePageDescription} />
      {/* this modal triggers only when allocation date is in the past */}
      {details && (
        <ConfirmModal
          onConfirm={() => {
            onSubmit(details);
          }}
          onClose={() => setDetails(null)}
          description={editPastAllocationDescription}
          confirmText="Proceed"
        />
      )}
    </form>
  );
};

export default Form;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkResourceArchive, IResourceStore, IResourceActions } from '../types';

const onArchiveResource: IThunkResourceArchive = thunk(
  async ({ setResources }: Actions<IResourceActions>, resourceId, { getState }) => {
    const localState = getState() as IResourceStore;

    await api.resource.archiveResource(resourceId);

    const updatedList = localState.list.filter(resource => resource.id !== resourceId);

    setResources({
      items: updatedList,
      pagination: { ...localState.pagination, total: localState.pagination.total - 1 },
    });
  },
);

export default onArchiveResource;

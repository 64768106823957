import setLocalAdmins from './setLocalAdmins';
import deactivateLocalAdmin from './deactivateLocalAdmin';
import activateLocalAdmin from './activateLocalAdmin';
import setLocalAdmin from './setLocalAdmin';

export default {
  setLocalAdmins,
  activateLocalAdmin,
  deactivateLocalAdmin,
  setLocalAdmin,
};

import classNames from 'classnames';
import { ReactElement } from 'react';

import { IClientStatus } from '~/services/api/types';
import { isoToFormat, setNoon } from '~/utils/date/date';
import splitByUpperCase from '~/utils/text/splitByUpperCase';

import ClientStatus from '../../models/clientStatus';

import styles from './Status.module.scss';

interface IClientStatusProps {
  item: IClientStatus;
  isFirst?: boolean;
  className?: string;
  withPrefixes?: boolean;
}

const StatusHistoryItem = ({
  item,
  className,
  isFirst,
  withPrefixes,
}: IClientStatusProps): ReactElement => {
  if (!item) {
    return null;
  }
  const isStatusActive = item.value === ClientStatus.ACTIVE;

  return (
    <>
      {!!isFirst && (
        <div className={classNames(styles.statusHistoryItem, className)}>
          <span className={classNames(styles.text, styles.underline)}>Status</span>
          <span className={classNames(styles.text, styles.underline)}>Date of Entry</span>
          <span className={classNames(styles.text, styles.underline)}>Date of Release</span>
        </div>
      )}
      <div className={classNames(styles.statusHistoryItem, className)}>
        <span className={styles.text}>
          {withPrefixes && <span>Status: </span>}
          <span className={styles.secondaryText}>{splitByUpperCase(item.value)}</span>
        </span>
        {!!item?.dateOfEntry && !isStatusActive && (
          <span className={styles.text}>
            {withPrefixes && <span>Date of Entry: </span>}
            <span className={styles.secondaryText}>{isoToFormat(setNoon(item.dateOfEntry))}</span>
          </span>
        )}
        {!!item?.dateOfRelease && !isStatusActive && (
          <span className={styles.text}>
            {withPrefixes && <span>Date of Release: </span>}
            <span className={styles.secondaryText}>{isoToFormat(setNoon(item.dateOfRelease))}</span>
          </span>
        )}
      </div>
    </>
  );
};

export default StatusHistoryItem;

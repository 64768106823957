import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { setAllocationsFilter } from '~/services/localStorage/filters';
import { IClientAllocationActions, IThunkGetClientAllocations } from '../types';

const onGetClientAllocations: IThunkGetClientAllocations = thunk(
  async ({ setClientAllocations }: Actions<IClientAllocationActions>, payload) => {
    const { clinicId, teamId, params } = payload;

    const { items, total } = await api.clientAllocation
      .getClientAllocationList({ clinicId, teamId }, params)
      .then(r => r.data);

    const pagination = { ...params, total };
    const filters = {
      sorting: Number(params.sorting),
      dateRange:
        params.from && params.to ? [params.from?.split('T')[0], params.to?.split('T')[0]] : [],
    };

    // save filters to storage
    setAllocationsFilter(filters);

    setClientAllocations({ items, pagination, filters });
  },
);

export default onGetClientAllocations;

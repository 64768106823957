import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateLocationGroup, ILocationGroupsStore, ILocationGroupActions } from '../types';

const onUpdateLocationGroup: IThunkUpdateLocationGroup = thunk(
  async ({ setLocationGroups }: Actions<ILocationGroupActions>, payload, { getState }) => {
    const localState = getState() as ILocationGroupsStore;

    const { clinicId, id, data } = payload;

    const locationGroup = await api.locationGroups
      .updateClinicLocationGroup(clinicId, id, data)
      .then(r => r.data);

    const updatedList = localState.list.map(item => (item.id !== id ? item : locationGroup));

    setLocationGroups({ items: updatedList });
  },
);

export default onUpdateLocationGroup;

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import Button from '~/ui/components/common/Button';
import Textarea from '~/ui/components/inputs/Textarea';

import validate from './validate';

import styles from './Form.module.scss';

interface IInitialValues {
  memo?: string | null;
}

interface IProps {
  defaultValues?: IInitialValues;
  isProcessing: boolean;
  onSubmit: (val: IInitialValues) => void;
  onCancel: () => void;
}

const Form = ({ defaultValues, isProcessing, onCancel, onSubmit }: IProps): ReactElement => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm({ defaultValues, mode: 'onBlur', resolver: validate });

  const { memo } = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Textarea register={register} errors={errors} name="memo" label="Text" />
          </Grid>
        </Grid>
        <div className={styles.buttonsWrapper}>
          <div className={styles.button}>
            <Button type="button" color="primary" variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </div>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isProcessing || !memo}
            isLoading={isProcessing}
          >
            Save
          </Button>
        </div>
      </Box>
    </form>
  );
};

export default Form;

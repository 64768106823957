import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { ISheltersModel } from './types';

const shelters: ISheltersModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default shelters;

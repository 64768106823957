import { action } from 'easy-peasy';

import { IActionSetClientLengthOfStayOverviewReport } from '../types';

const setClientLengthOfStayOverviewReport: IActionSetClientLengthOfStayOverviewReport = action(
  (state, payload) => {
    const {
      clientLengthOfStay: { teams, totals },
    } = payload;

    state.clientLengthOfStay.list = teams;
    state.clientLengthOfStay.totals = totals;
  },
);

export default setClientLengthOfStayOverviewReport;

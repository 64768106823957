import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkUnAssignProgramAssistant } from '../types';

const onUnAssignProgramAssistant: IThunkUnAssignProgramAssistant = thunk(async (_, payload) => {
  const { clinicId, programAssistantId, teamId } = payload;

  await api.programAssistant.unAssignProgramAssistant(Number(clinicId), programAssistantId, teamId);
});

export default onUnAssignProgramAssistant;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetAddresses } from '../types';

const onGetAddresses: IThunkGetAddresses = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const addresses = await api.clientDetails
      .getClientAddressList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ addresses });
  },
);

export default onGetAddresses;

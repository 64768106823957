import initStore from './initStore';
import actions from './actions';

import { IAppModel } from './types';

const app: IAppModel = {
  ...initStore,
  ...actions,
};

export default app;

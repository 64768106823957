import { action } from 'easy-peasy';

import { IActionLeaveChannel } from '../types';

const leaveChannel: IActionLeaveChannel = action((state, payload) => {
  const newChannels = state.channels.filter(channel => channel.url !== payload);

  state.channels = newChannels;
});

export default leaveChannel;

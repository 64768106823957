import { action } from 'easy-peasy';

import { IActionSetClientDocuments } from '../types';

const setClientDocuments: IActionSetClientDocuments = action((state, { total, list }) => {
  if (state.documents.filters.pageNumber !== 1 && !state.documents.filters.documentTypeId) {
    state.documents.list = [...state.documents.list, ...list];
  } else {
    state.documents.list = list;
  }
  state.documents.total = total;
});

export default setClientDocuments;

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { IOption } from '~/ui/components/inputs/Select/types';

import styles from './ObjectivesCheckboxGroup.module.scss';

interface IProps {
  title: string;
  options: IOption[];
  selected: IOption[];
  setSelected: Dispatch<SetStateAction<IOption[]>>;
}

const ObjectivesCheckboxGroup = ({
  title,
  options,
  selected,
  setSelected,
}: IProps): ReactElement => {
  // toggle option
  const handleSelect = (option: IOption, checked: boolean) => {
    setSelected(prev => {
      if (checked) {
        return [...prev, option];
      }
      return prev.filter(item => String(item.value) !== option.value);
    });
  };

  return (
    <Box className={styles.checkboxGroup}>
      <Typography className={styles.groupTitle}>{title}</Typography>
      {options.map(option => {
        const isSelected = !!selected.find(item => String(item.value) === option.value);
        return (
          <FormControlLabel
            key={`${option.label}-${option.value}`}
            control={
              <Checkbox
                checked={isSelected}
                color="primary"
                onChange={(_e, checked) => handleSelect(option, checked)}
              />
            }
            label={option.label}
          />
        );
      })}
    </Box>
  );
};

export default ObjectivesCheckboxGroup;

import { ReactElement } from 'react';
import Box from '@material-ui/core/Box';

import { isoToLocal } from '~/utils/date/date';
import { generalDateTime } from '~/ui/constants/dateFormat';

import styles from './MedicationChangeInfo.module.scss';

interface IProps {
  delivered: boolean;
  changedBy: string;
  when: string;
}

const MedicationChangeInfo = ({ delivered, changedBy, when }: IProps): ReactElement => {
  const deliveryStatus = delivered ? '"Delivered"' : '"Not Delivered"';
  return (
    <Box className={styles.infoBox}>
      Status was changed to {deliveryStatus} by {changedBy} on {isoToLocal(when, generalDateTime)}
    </Box>
  );
};

export default MedicationChangeInfo;

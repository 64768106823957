import { IClientDetailsStore } from './types';

const initStore: IClientDetailsStore = {
  current: null,
  supportContacts: [],
  deEscalationTechniques: [],
  diagnoses: [],
  medications: [],
  triggers: [],
  allergies: [],
  addresses: [],
  phones: [],
  treatmentPlans: [],
  memo: null,
  status: null,
};

export default initStore;

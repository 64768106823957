import { action } from 'easy-peasy';
import { ISosRequestsPaginationAction } from '../types';

const setSosRequestsPagination: ISosRequestsPaginationAction = action((state, pagination) => {
  state.pagination.pageNumber = pagination.pageNumber;
  state.pagination.pageSize = pagination.pageSize;
  state.pagination.total =
    typeof pagination.total === 'number' ? pagination.total : state.pagination.total;
});

export default setSosRequestsPagination;

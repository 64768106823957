import Grid from '@material-ui/core/Grid';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import DatePicker from '~/ui/components/inputs/DatePicker';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useFeature from '~/store/clinic/hooks/useFeature';
import useRole from '~/store/user/hooks/useRole';
import { extractErrorMessage } from '~/utils/error/error';
import { setNoon } from '~/utils/date/date';

import { IRiskAssessment, ISuicideRisk } from '~/services/api/client/types';
import { IClinic } from '~/services/api/clinic/types';
import riskAssessmentOptions from '~/ui/constants/riskAssessment';
import suicideRiskOptions from '~/ui/constants/suicideRisks';
import { dateRequestFormat, generalDate } from '~/ui/constants/dateFormat';

import styles from './RisksBlock.module.scss';

interface IInitialValues {
  suicideRisk: ISuicideRisk;
  riskAssessment: IRiskAssessment;
  dateCompleted: string | null;
}

const RisksBlock = (): ReactElement => {
  const client = useStoreState(state => state.client.current);
  const clinic = useStoreState(state => state.clinic.current || ({} as IClinic));
  const { treatmentPlans } = useStoreState(state => state.clientDetails);
  const { id: userId } = useStoreState(state => state.user.current);

  const { onGetTreatmentPlans } = useStoreActions(actions => actions.clientDetails);
  const { setClient } = useStoreActions(actions => actions.client);

  const activeTreatmentPlan = treatmentPlans.find(plan => plan.active && !plan.isArchived);
  const draftTreatmentPlan = treatmentPlans.find(plan => !plan.active && !plan.isArchived);

  // case manager in active plan
  const isCaseManager = useMemo(
    () => activeTreatmentPlan?.caseManager?.id === userId || false,
    [userId, activeTreatmentPlan],
  );

  // give ability to edit risk assessment if user is a case manager in draft plan but only when no active treatment plan with other case managers (to prevent edit collision with two case managers)
  // case manager in  draft plan
  const isDraftCaseManager = useMemo(
    () => draftTreatmentPlan?.caseManager?.id === userId || false,
    [userId, draftTreatmentPlan],
  );

  const { isLocalAdmin, isProgramAssistant, isActTeamLeader } = useRole();

  const canEditRisks =
    isLocalAdmin ||
    isActTeamLeader ||
    isProgramAssistant ||
    isCaseManager ||
    (!activeTreatmentPlan && isDraftCaseManager);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { isTreatmentPlansAvailable } = useFeature();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<IInitialValues>({
    defaultValues: {
      riskAssessment: client.riskAssessment,
      suicideRisk: client.suicideRisk,
      dateCompleted: setNoon(client.suicideRiskDateCompleted) || null,
    },
  });

  const { riskAssessment, suicideRisk, dateCompleted } = watch();

  const handleChangeRisk = useCallback(
    async (isSuicide?: boolean) => {
      const newDateCompleted = format(new Date(dateCompleted), dateRequestFormat);
      try {
        await api.client[isSuicide ? 'editSuicideRisk' : 'editRiskAssessment'](
          {
            clinicId: String(clinic.id),
            teamId: String(client.actTeam.id),
            clientId: String(client.id),
          },
          {
            risk: isSuicide ? suicideRisk : riskAssessment,
            ...(isSuicide ? { dateCompleted: newDateCompleted } : {}),
          },
        );
        setClient({
          ...client,
          suicideRisk,
          riskAssessment,
          suicideRiskDateCompleted: newDateCompleted,
        });
        showNotify({ message: 'Risk successfully updated' });
      } catch (e) {
        showError(extractErrorMessage(e));
      }
    },
    [
      client,
      dateCompleted,
      clinic.id,
      riskAssessment,
      showError,
      showNotify,
      suicideRisk,
      setClient,
    ],
  );

  useEffect(() => {
    const suicideUpdate =
      suicideRisk !== client.suicideRisk ||
      (dateCompleted &&
        format(new Date(dateCompleted), dateRequestFormat) !== client.suicideRiskDateCompleted);
    if (riskAssessment !== client.riskAssessment || suicideUpdate) {
      handleChangeRisk(suicideUpdate);
    }
  }, [
    riskAssessment,
    dateCompleted,
    suicideRisk,
    client.riskAssessment,
    client.suicideRisk,
    client.suicideRiskDateCompleted,
    handleChangeRisk,
  ]);

  const onMount = useCallback(async () => {
    try {
      if (!isTreatmentPlansAvailable) return;
      const payload = {
        clinicId: String(clinic.id),
        teamId: String(client.actTeam.id),
        clientId: String(client.id),
      };

      await onGetTreatmentPlans(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [
    client.actTeam.id,
    client.id,
    clinic.id,
    isTreatmentPlansAvailable,
    onGetTreatmentPlans,
    showError,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <h4 className={styles.title}>Risks</h4>
      </div>
      <Grid container spacing={2} sm={12} className={styles.detailsBlock}>
        <Grid item sm={12}>
          <Select
            options={riskAssessmentOptions}
            name="riskAssessment"
            control={control}
            errors={errors}
            label="Risk Assessment"
            hideSelectedOptions={false}
            openMenuOnFocus
            isDisabled={!canEditRisks || client.isArchived}
          />
        </Grid>
        <Grid item sm={12} className={styles.row}>
          <Grid container sm={12} spacing={1}>
            <Grid item sm={6}>
              <Select
                options={suicideRiskOptions}
                name="suicideRisk"
                control={control}
                errors={errors}
                label="Suicide Risk"
                hideSelectedOptions={false}
                openMenuOnFocus
                isDisabled={client.isArchived}
              />
            </Grid>
            <Grid item sm={6}>
              <DatePicker
                control={control}
                errors={errors}
                label="Date Completed"
                name="dateCompleted"
                dateFormat={generalDate}
                views={['date']}
                maxDate={new Date().toISOString()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RisksBlock;

import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';

import { validateLengthField, phone, zipCode } from '~/ui/configs/form/validation';
import { requiredField, fieldMax, validEmail } from '~/ui/constants/errorMessages';

const commonClientValidationSchema = {
  firstName: string().required(requiredField).test('length', fieldMax, validateLengthField),
  lastName: string().required(requiredField).test('length', fieldMax, validateLengthField),
  email: string().test('length', fieldMax, validateLengthField).email(validEmail).nullable(),
  dateOfBirth: string().required(requiredField),
  medicaId: string().when('medicaIdRequired', {
    is: true,
    then: string().required(requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
  medicaIdRecertificationDate: string().when('medicaIdRequired', {
    is: true,
    then: string().required(requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
  emrMrn: string().nullable(),
  teamId: number().required(requiredField),
  suicideRisk: string().required(requiredField),
  genderId: number().required(requiredField),
  raceId: number().nullable(),
  admissionDetails: object().shape({
    referralSourceId: number().required(requiredField),
    admissionDate: string().required(requiredField),
  }),
};

const addValidationSchema = object().shape({
  ...commonClientValidationSchema,
  phone,
  address: object().when('isShelter', {
    is: true,
    then: object().nullable(),
    otherwise: object().shape({
      line1: string().required(requiredField).test('length', fieldMax, validateLengthField),
      line2: string().test('length', fieldMax, validateLengthField).nullable(),
      city: string().required(requiredField).test('length', fieldMax, validateLengthField),
      stateId: number().required(requiredField),
      zipCode,
      poBox: string().nullable(),
      shelterName: string().nullable(),
    }),
  }),
  shelterId: number().when('isShelter', {
    is: true,
    then: number().required(requiredField),
    otherwise: number().nullable(),
  }),
});

export const editValidationSchema = yupResolver(object().shape(commonClientValidationSchema));

export default yupResolver(addValidationSchema);

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { getAccessToken, getUserData } from '~/services/localStorage/auth';
import bugsnagClient from '~/utils/error/bugsnag';
import { IThunkCheckAuthorization, IUserActions } from '../types';

const onCheckAuthorization: IThunkCheckAuthorization = thunk(
  async ({ setUser, setAuthorized, setRole }: Actions<IUserActions>) => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      setAuthorized({ authorized: false });
      return;
    }
    const userData = getUserData();

    try {
      const user = await api.auth.getCurrentUser().then(response => response.data);

      bugsnagClient.setUser(String(user.id), user.email, `${user.firstName} ${user.lastName}`);
      bugsnagClient.setContext(JSON.stringify(userData));

      setUser(user);
      setRole(userData);
      setAuthorized({ authorized: true });
    } catch (e) {
      setAuthorized({ authorized: false });
    }
  },
);

export default onCheckAuthorization;

import { action } from 'easy-peasy';

import { IActionSetClientStatusReport } from '../types';

const SetClientStatusReport: IActionSetClientStatusReport = action((state, payload) => {
  const { items, total, pageNumber } = payload;

  state.clientStatus.report = {
    items: pageNumber === 1 ? items : [...state.clientStatus.report.items, ...items],
    total,
    pageNumber,
  };
});

export default SetClientStatusReport;

import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOnUpdateRelease } from '../types';

const onUpdateRelease: IThunkOnUpdateRelease = thunk(async (_, payload) => {
  const { releaseId, ...rest } = payload;

  await api.release.updateRelease(releaseId, rest);
});

export default onUpdateRelease;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IDeleteRequestActions, IThunkGetDeleteRequests } from '../types';

const onGetDeleteRequests: IThunkGetDeleteRequests = thunk(
  async ({ setDeleteRequests }: Actions<IDeleteRequestActions>, payload) => {
    const { clinicId, params } = payload;

    const {
      data: { items, total },
    } = await api.deleteRequests.getDeleteRequests(String(clinicId), params);

    const pagination = { ...params, total };

    setDeleteRequests({ items, pagination });
  },
);

export default onGetDeleteRequests;

import { action } from 'easy-peasy';
import { IActionSetAllClientOption } from '../types';

const setAllClientOption: IActionSetAllClientOption = action((state, payload) => {
  state.clientOptions.isAllSelected = payload;

  if (payload) {
    state.clientOptions.current = state.clientOptions.list;
  }
});

export default setAllClientOption;

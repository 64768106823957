import { useEffect, useRef } from 'react';

const useMemoCompare = <T>(next: T, compare: (p: T, n: T) => boolean): T => {
  const previousRef = useRef<T>();
  const previous = previousRef.current;
  const isEqual = compare(previous, next);
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  return isEqual ? previous : next;
};

export default useMemoCompare;

import { useState, useEffect, useMemo, ReactElement, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import concat from 'lodash/concat';

import Loader from '~/ui/components/common/Loader';
import Form from '~/ui/pages/ActTeam/reusable/Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import { CLINIC_MANAGEMENT, VIEW_CLINIC, CLINIC_VIEW_ACT_TEAM } from '~/ui/constants/paths';

type IParams = {
  clinicId: string;
  actTeamId: string;
};

const breadcrumbItem = [
  {
    to: CLINIC_MANAGEMENT,
    title: 'Clinic Management',
  },
];

type ILocationState = { pathname: string; state: { from: boolean } };

const EditActTeam = (): ReactElement => {
  const { clinicId, actTeamId } = useParams<IParams>();
  const { pathname, state: locationState }: ILocationState = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const actTeam = useStoreState(state => state.actTeam.current);
  const clinic = useStoreState(state => state.clinic.current);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);

  const cancelLinkTo = useMemo(() => {
    if (locationState?.from) return locationState.from;

    return {
      pathname: VIEW_CLINIC.replace(':id', clinicId),
      state: { selectedTab: 1 },
    };
  }, [clinicId, locationState?.from]);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all([onGetClinicActTeam({ clinicId, actTeamId }), onGetClinic(clinicId)]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [actTeamId, clinicId, onGetClinic, onGetClinicActTeam, showError]);

  const onSubmit = async (values: any) => {
    try {
      setIsUpdating(true);
      const { typeId, name, capacity, isShelterTeam } = values;
      const payload = { id: Number(actTeamId), typeId, name, capacity, isShelterTeam };
      await api.actTeam.editClinicActTeam(clinicId, actTeamId, payload);
      navigate(
        CLINIC_VIEW_ACT_TEAM.replace(':clinicId', clinicId).replace(':actTeamId', actTeamId),
      );
      showNotify({ message: 'Team successfully updated' });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !actTeam || !clinic) return <Loader />;

  const breadcrumbItems = concat(breadcrumbItem, [
    {
      title: clinic?.name,
      to: VIEW_CLINIC.replace(':id', clinicId),
    },
    {
      title: actTeam?.name,
      to: CLINIC_VIEW_ACT_TEAM.replace(':clinicId', clinicId).replace(':actTeamId', actTeamId),
    },
    {
      title: 'Edit Team',
      to: pathname,
    },
  ]);

  const defaultValues = {
    name: actTeam?.name,
    typeId: actTeam?.type.id,
    clinicId,
    capacity: actTeam?.capacity,
    isShelterTeam: actTeam?.isShelterTeam,
  };

  const leavePageDescription = 'Are you sure you want to discard changes?';

  return (
    <div>
      <h2>Edit {actTeam?.name}</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        isEdit
        defaultValues={defaultValues}
        clinicSelectOptions={[{ value: clinicId, label: clinic?.name }]}
        isProcessing={isUpdating}
        cancelLinkTo={cancelLinkTo}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default EditActTeam;

import Box from '@material-ui/core/Box';
import { saveAs } from 'file-saver';
import curry from 'lodash/curry';
import {
  ReactElement,
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { useReactToPrint } from 'react-to-print';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';
import Forms from '../Clients/reusable/HealthDetails/Forms';
import useRole from '../../../store/user/hooks/useRole';
import ClientInfo from '../../components/common/ClientInfo';
import SimpleSelect from './components/SimpleSelect';
import NoDataFound from '~/ui/components/common/NoDataFound';

import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import { extractErrorMessage } from '~/utils/error/error';
import formatActTeamsOptions from '~/utils/formatActTeamsOptions';
import useQueryParam from './hooks/useQueryParam';
import useClientOptions from '~/store/client/hooks/useClientOptions';

import { IDetails, ITypeDetails } from '../Clients/reusable/HealthDetails/types';
import { ReactComponent as ReportDownloadIcon } from '~/ui/assets/images/reportDownload.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './MedicationLogistics.module.scss';

const MedicationDeliveryCalendar = lazy(() => import('./components/MedicationDeliveryCalendar'));
const MedicationDeliveryPreview = lazy(() => import('./components/MedicationDeliveryPreview'));

const MedicationLogistics = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [openPreview, setOpenPreview] = useState(null);
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [details, setDetails] = useState<IDetails | null>(null);

  const query = useQuery();
  const actTeamId = query.get('actTeamId');
  const clientId = query.get('clientId');
  const includeDeletedQuery = query.get('includeDeleted');

  const weekModeQuery = query.get('weekMode');
  const [weekMode, setWeekMode] = useState(weekModeQuery === 'true' || false);

  const user = useStoreState(state => state.user.current);
  const { availableActTeams } = useStoreState(state => state.actTeam);
  const { current, defaultClient } = useStoreState(state => state.client);

  const {
    items: availableClients,
    loading: clientsLoading,
    loadMore,
    setClientOptionsTeamIds,
  } = useClientOptions(true, includeDeletedQuery === 'true');

  const currentMedicationDelivery = useStoreState(state => state.medicationLogistics.current);
  const deliveriesList = useStoreState(state => state.medicationLogistics.list);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeamsAvailable = useStoreActions(actions => actions.actTeam.onGetActTeamsAvailable);
  const onGetMedicationDelivery = useStoreActions(
    actions => actions.medicationLogistics.onGetMedicationDelivery,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const actTeamOptions = formatActTeamsOptions(availableActTeams);

  const { isLocalAdmin, isProgramAssistant, team: userTeam } = useRole();

  const showTeamSelect = isLocalAdmin || isProgramAssistant;

  const initialTeamId = showTeamSelect
    ? defaultClient?.actTeam.id || Number(actTeamId)
    : userTeam?.id;

  const [team, setTeam] = useState<number | null>(initialTeamId || null);

  const teamId = showTeamSelect ? String(team) : String(userTeam?.id);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      if (showTeamSelect) {
        await Promise.all([
          onGetMyClinic(),
          onGetActTeamsAvailable({ clinicId: String(user.clinic.id) }),
        ]);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [onGetActTeamsAvailable, onGetMyClinic, showError, showTeamSelect, user.clinic.id]);

  const requestInfo = {
    clientId: String(current?.id),
    clinicId: String(user?.clinic.id),
    teamId,
  };

  const showSecondSubmit = !Object.keys(details || {}).length;

  const onAdd = curry((title: string, medicationId: number, date: string, injectable: boolean) => {
    setDetails({ medicationId, date, injectable });
    setModalTitle(title);
  });

  const onEdit = curry(
    (
      type: ITypeDetails,
      healthDetailsType: string,
      removeHealthDetailsName: boolean,
      id: number,
      healthDetailsName: string,
      isArchived: boolean,
    ) => {
      if (isArchived) {
        setOpenPreview(true);
        setDetails({ type, id });
      } else {
        let title = `Edit “${healthDetailsName}” ${healthDetailsType}`;

        if (removeHealthDetailsName) {
          title = title.replace(/“[\w\s]*”/gi, '');
        }

        setModalTitle(title);
        setDetails({ type, id });
      }
      setLoadingDetails(true);
    },
  );

  const componentRef = useRef(null);

  const selectedInterval = query.get('selectedMonth')?.split('-');
  const documentTitle = `${current?.firstName}_${current?.lastName}_Delivery_data_${selectedInterval?.[0]}${selectedInterval?.[1]}${selectedInterval?.[2]}`;

  const printCalendar = useReactToPrint({
    content: () => componentRef.current,
    documentTitle,
  });

  const loadDetails = useCallback(async () => {
    try {
      if (details?.id) {
        const payload = {
          clinicId: String(user.clinic.id),
          clientId: String(current?.id),
          teamId: String(teamId),
          medicationDeliveryId: String(details?.id),
        };

        await onGetMedicationDelivery(payload);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingDetails(false);
    }
  }, [current?.id, details?.id, onGetMedicationDelivery, showError, teamId, user?.clinic.id]);

  const generatePDF = async (): Promise<void> => {
    setReportLoading(true);
    try {
      await api.medicationLogistics
        .getMedicationDeliveryReport(
          { clinicId: String(user.clinic.id), teamId },
          {
            clientId: String(current?.id),
            year: selectedInterval[0],
            month: selectedInterval[1],
            timeZoneOffset: new Date().getTimezoneOffset(),
          },
        )
        .then((val: AxiosResponse<BlobPart, any>) => {
          const blob = new Blob([val.data]);
          const link = window.URL.createObjectURL(blob);
          saveAs(link, `${documentTitle}.pdf`);
          setReportLoading(false);
        });
    } catch (e) {
      showError(extractErrorMessage(e));
      setReportLoading(false);
    }
  };

  const renderHeader = () => (
    <div className={styles.header}>
      <h2>Medication Logistics</h2>
    </div>
  );

  const exportDisabled = useMemo(
    () => deliveriesList?.length === 0 || weekMode,
    [deliveriesList?.length, weekMode],
  );

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    loadDetails();
  }, [details, loadDetails]);

  useEffect(() => {
    if (team) {
      setClientOptionsTeamIds([team]);
    }
  }, [team, setClientOptionsTeamIds]);

  useQueryParam('actTeamId', showTeamSelect && team ? String(team) : undefined);

  if (loading || !user) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      <div className={styles.container}>
        {showTeamSelect && renderHeader()}
        <Box className={styles.titleSection}>
          {showTeamSelect ? (
            <SimpleSelect
              label="Assigned Team"
              value={team}
              setValue={setTeam}
              options={actTeamOptions}
            />
          ) : (
            renderHeader()
          )}

          {team && !!availableClients.length && (
            <Box className={styles.exportButtons}>
              <Button
                variant="outlined"
                color="primary"
                disabled={exportDisabled}
                onClick={() => printCalendar()}
                className={styles.printCalendarButton}
              >
                Export Calendar
              </Button>
              <Button
                isLoading={reportLoading}
                loaderColor="white"
                variant="contained"
                color="primary"
                startIcon={
                  <ReportDownloadIcon
                    fill={exportDisabled ? variables.colorWhite : variables.colorWhite}
                  />
                }
                disabled={exportDisabled}
                onClick={() => generatePDF()}
              >
                Export PDF
              </Button>
            </Box>
          )}
        </Box>

        {!!team &&
          (availableClients.length ? (
            <ClientInfo
              clientId={
                availableClients.find(c => c.id === (defaultClient?.id || Number(clientId)))?.id
              }
              teamId={team}
              loading={clientsLoading}
              loadMore={loadMore}
            />
          ) : (
            <Box className={styles.centered}>
              <NoDataFound title="No clients assigned to the team" />
            </Box>
          ))}
        {!!team && !!availableClients.length && !!current && (
          <MedicationDeliveryCalendar
            teamId={parseInt(teamId, 10)}
            weekMode={weekMode}
            setWeekMode={setWeekMode}
            onAdd={onAdd('Add Medication Delivery')}
            onEdit={onEdit('medicationDelivery', 'Medication Delivery', true)}
            componentRef={componentRef}
          />
        )}
        {(openPreview || (details && details.id)) && !modalTitle && (
          <Modal
            className={styles.modal}
            open
            onClose={() => {
              setOpenPreview(null);
              setDetails(null);
            }}
          >
            <MedicationDeliveryPreview
              medicationDelivery={currentMedicationDelivery}
              onClose={() => {
                setOpenPreview(null);
                setDetails(null);
              }}
            />
          </Modal>
        )}
        {(modalTitle || (details && details.id)) && !openPreview && (
          <Modal
            className={styles.modal}
            open
            onClose={() => {
              setModalTitle(null);
              setDetails(null);
            }}
          >
            <div className={styles.modalWrapper}>
              <h3 className={styles.modalTitle}>{modalTitle}</h3>
              {loadingDetails ? (
                <Loader />
              ) : (
                <Forms
                  formType={
                    currentMedicationDelivery?.medication?.injectable || details?.injectable
                      ? 'injectableMedicationDelivery'
                      : 'medicationDelivery'
                  }
                  setModalTitle={setModalTitle}
                  setDetails={setDetails}
                  requestInfo={requestInfo}
                  details={details}
                  showSecondSubmit={showSecondSubmit}
                  current={
                    currentMedicationDelivery || {
                      medicationIds: [details.medicationId],
                      date: details.date,
                      teamMember: !showTeamSelect
                        ? { id: user.id, name: user.fullName }
                        : undefined,
                    }
                  }
                  primaryDiagnosis={null}
                />
              )}
            </div>
          </Modal>
        )}
      </div>
    </Suspense>
  );
};

export default MedicationLogistics;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import ClientStatusSortableColumns from '~/ui/pages/Reports/constants/clientStatusSortableColumns';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import { IReportsActions, IReportsStore, IThunkGetClientStatusReport } from '../types';

const onGetClientStatusReport: IThunkGetClientStatusReport = thunk(
  async (
    { setClientStatusReport, setClientStatusFilters }: Actions<IReportsActions>,
    payload,
    { getState },
  ) => {
    const { filters } = (getState() as IReportsStore).clientStatus;
    const data = await api.reports
      .getClientStatusReport(payload.requestInfo, payload.params)
      .then(r => r.data);

    setClientStatusFilters({
      ...filters,
      sorting: payload.params.sorting as ClientStatusSortableColumns,
      sortDirection: payload.params.sortDirection as SortDirection,
    });

    setClientStatusReport({ ...data, pageNumber: payload.params.pageNumber });
  },
);

export default onGetClientStatusReport;

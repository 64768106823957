import onArchiveOrganization from './onArchiveOrganization';
import onGetOrganization from './onGetOrganization';
import onGetOrganizations from './onGetOrganizations';
import onRestoreOrganization from './onRestoreOrganization';
import onCreateOrganization from './onCreateOrganization';
import onUpdateOrganization from './onUpdateOrganization';

export default {
  onArchiveOrganization,
  onGetOrganization,
  onGetOrganizations,
  onRestoreOrganization,
  onCreateOrganization,
  onUpdateOrganization,
};

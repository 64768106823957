import { ReactElement, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import curry from 'lodash/curry';

import Modal from '~/ui/components/common/Modal';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Forms from '../HealthDetails/Forms';
import DetailsBlock from './DetailsBlock';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import { IClientPhone, IRequestParams } from '~/services/api/clientDetails/types';
import { IClientDetailed } from '~/services/api/client/types';
import { IDetailsInfo, IDetails, ITypeDetails } from './types';
import styles from './ContactDetails.module.scss';
import useRole from '~/store/user/hooks/useRole';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
}

const ContactDetails = ({ client, clinicId, actTeamId }: IProps): ReactElement => {
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [formType, setFormType] = useState<ITypeDetails | null>(null);
  const [details, setDetails] = useState<IDetails | null>(null);
  const [detailsInfo, setDetailsInfo] = useState<IDetailsInfo | null>(null);

  const { current, phones } = useStoreState(state => state.clientDetails);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const { onArchivePhone, onRestorePhone, setCurrent } = useStoreActions(
    actions => actions.clientDetails,
  );

  const { isLocalAdmin, isProgramAssistant } = useRole();

  const methods = {
    phone: {
      restore: onRestorePhone,
      archive: onArchivePhone,
    },
  };

  const phone: IClientPhone | undefined = useMemo(
    () => phones.find(item => item.id === details?.id),
    [phones, details?.id],
  );

  useEffect(() => {
    setCurrent(phone || null);
  }, [phone, setCurrent]);

  const onAdd = (title: string, type: ITypeDetails) => {
    setModalTitle(title);
    setFormType(type);
  };

  const onEdit = curry(
    (
      type: ITypeDetails,
      healthDetailsType: string,
      removeHealthDetailsName: boolean,
      id: number,
      healthDetailsName: string,
    ) => {
      let title = `Edit ${healthDetailsName} ${healthDetailsType}`;

      if (removeHealthDetailsName) {
        title = title.replace(/“[\w\s]*”/gi, '');
      }

      setModalTitle(title);
      setDetails({ type, id });
      setFormType(type);
    },
  );

  const requestInfo = { clientId: String(client.id), clinicId, teamId: actTeamId };

  const confirmText = detailsInfo?.method === 'archive' ? 'Archive' : 'Restore';
  const description = `Are you sure you want to ${confirmText.toLowerCase()} “${
    detailsInfo?.name
  }” ${detailsInfo?.title || detailsInfo?.typeDetails}?`;

  const method = get(methods, [detailsInfo?.typeDetails, detailsInfo?.method], () => {});

  const onConfirmModal = async (payload: Required<IRequestParams>) => {
    try {
      const type = detailsInfo?.method === 'archive' ? 'archived' : 'restored';

      await method(payload);

      showNotify({
        message: `${capitalize(
          detailsInfo?.title || detailsInfo?.typeDetails,
        )} successfully ${type}`,
      });

      setDetailsInfo(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const showSecondSubmit = !Object.keys(details || {}).length;

  const isClientActive = !client.isArchived;

  return (
    <div className={styles.wrapper}>
      <DetailsBlock
        onAdd={() => onAdd('Add Phone Number', 'phone')}
        onEdit={onEdit('phone', 'Phone Number', false)}
        title="Contact Information"
        buttonText={isClientActive && (isLocalAdmin || isProgramAssistant) && 'Add Phone Number'}
        showEdit={isClientActive}
        items={phones}
        email={client.email}
        setDetailsInfo={rest => setDetailsInfo({ typeDetails: 'phone', ...rest })}
        showArchivedButton
        noActions={!isLocalAdmin && !isProgramAssistant}
      />
      <div className={styles.width}>
        {(modalTitle || (details && details.id)) && (
          <Modal
            className={styles.modal}
            open
            onClose={() => {
              setModalTitle(null);
              setDetails(null);
              setCurrent(null);
            }}
          >
            <div className={styles.modalWrapper}>
              <h3 className={styles.modalTitle}>{modalTitle}</h3>
              <Forms
                formType={formType}
                setModalTitle={setModalTitle}
                setDetails={setDetails}
                requestInfo={requestInfo}
                details={details}
                showSecondSubmit={showSecondSubmit}
                current={current}
                primaryDiagnosis={null}
              />
            </div>
          </Modal>
        )}
        {detailsInfo && (
          <ConfirmModal
            onConfirm={() => {
              onConfirmModal({
                clientId: String(client.id),
                clinicId,
                teamId: actTeamId,
                id: String(detailsInfo?.id),
              });
            }}
            onClose={() => setDetailsInfo(null)}
            description={description}
            confirmText={confirmText}
          />
        )}
      </div>
    </div>
  );
};

export default ContactDetails;

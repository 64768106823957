import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import formatDeEscalation from '~/utils/formatDeEscalation';
import { IThunkCreateDeEscalation, IClientDetailsStore, IClientDetailsActions } from '../types';

const onCreateDeEscalation: IThunkCreateDeEscalation = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const clientDeEscalation = await api.clientDetails
      .createDeEscalationTechnique(requestInfo, requestPayload)
      .then(r => formatDeEscalation(r.data));

    const deEscalationTechniques = [clientDeEscalation, ...localState.deEscalationTechniques];

    setClientDetails({ deEscalationTechniques });
  },
);

export default onCreateDeEscalation;

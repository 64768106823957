import Grid from '@material-ui/core/Grid';
import { ReactElement, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';

import { extractErrorMessage } from '~/utils/error/error';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { locationGroupValidationSchema } from '~/ui/pages/Clients/reusable/HealthDetails/Forms/validates';

import { ILocationGroupRequest } from '~/services/api/locationGroups/types';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './LocationGroupModal.module.scss';

interface IProps {
  defaultValues?: ILocationGroupRequest;
  id?: number;
  onClose: () => void;
}

type IParams = {
  id?: string;
};

const Form = ({ defaultValues, id, onClose }: IProps): ReactElement => {
  const [processing, setProcessing] = useState(false);

  const { id: currentClinicId } = useParams<IParams>();

  const {
    formState: { errors },
    reset,
    handleSubmit,
    register,
  } = useForm({
    defaultValues,
    resolver: locationGroupValidationSchema,
  });

  const user = useStoreState(state => state.user.current);

  const { onCreateLocationGroup, onUpdateLocationGroup } = useStoreActions(
    actions => actions.locationGroups,
  );
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const clinicId = currentClinicId || String(user?.clinic?.id);

  const onSubmit = async (data: ILocationGroupRequest, evt: any) => {
    const { name } = evt?.nativeEvent?.submitter || {};
    const payload = {
      clinicId,
      data,
    };

    try {
      setProcessing(true);

      if (id) {
        await onUpdateLocationGroup({ ...payload, id });
      } else {
        await onCreateLocationGroup(payload);
      }

      const type = defaultValues ? 'updated' : 'added';

      reset({ name: null });

      if (!name) {
        onClose();
      }

      showNotify({ message: `Location Group successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className={styles.modalWrapper}>
      <h3 className={styles.modalTitle}>Add Location Group</h3>

      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Input register={register} name="name" errors={errors} label="Name" />
          </Grid>
        </Grid>
        <div className={styles.buttonsWrapper}>
          <Button
            color="primary"
            variant="outlined"
            className={styles.margin}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          {!defaultValues && (
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              className={styles.margin}
              disabled={processing}
              name="Add Location Group"
            >
              Save And Add Another One
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            type="submit"
            name={null}
            isLoading={processing}
            loaderColor={variables.colorGrey}
            disabled={processing}
          >
            {!defaultValues ? 'Add Location Group' : 'Update'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Form;

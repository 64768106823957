import { Actions, thunk } from 'easy-peasy';
import { IClientDetailsActions, IClientDetailsStore, IThunkGetSupportContact } from '../types';

const onGetSupportContact: IThunkGetSupportContact = thunk(
  ({ setCurrent }: Actions<IClientDetailsActions>, id, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const current = localState.supportContacts.find(item => item.id === id);

    setCurrent(current);
  },
);

export default onGetSupportContact;

import { action } from 'easy-peasy';

import { IActionRemoveMember } from '../types';

const removeMember: IActionRemoveMember = action((state, { channelUrl, userIds }) => {
  const newChannels = state.channels.map(channel => {
    if (channel.url === channelUrl) {
      const newMembers = channel.members.filter(member => !userIds.includes(member.userId));
      return { ...channel, members: newMembers };
    }
    return channel;
  });

  state.channels = newChannels;
});

export default removeMember;

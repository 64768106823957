import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import ChangePasswordForm from './components/ChangePasswordForm';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import api from '~/services/api';
import { SIGN_IN } from '~/ui/constants/paths';

import { IFormValues } from './components/ChangePasswordForm/types';
import companyLogo from '~/ui/assets/images/logo.svg';
import styles from './ChangePassword.module.scss';

type ILocationState = { state: { email: string } };

const ChangePassword = (): ReactElement => {
  const navigate = useNavigate();
  const { state }: ILocationState = useLocation();
  const [error, setError] = useState<string>(null);

  const { showNotify } = useStoreActions(actions => actions.snackbar);

  const onSubmitChangePassword = async (values: IFormValues) => {
    try {
      const payload = { email: state?.email?.trim(), ...values };
      await api.auth.changePassword(payload);
      navigate(SIGN_IN);
      showNotify({ message: 'Password successfully changed' });
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    if (!state?.email?.length) {
      navigate(SIGN_IN);
    }
  }, [navigate, state?.email]);

  return (
    <div className={styles.container}>
      <img src={companyLogo} alt="Logo" />
      <ChangePasswordForm
        error={error?.toString()}
        onSubmit={onSubmitChangePassword}
        title="Change Password"
        buttonText="Set New Password"
      />
    </div>
  );
};

export default ChangePassword;

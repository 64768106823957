import { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { components, GroupTypeBase, IndicatorProps } from 'react-select';

import { IOption } from '~/types';

const ClearIndicator = (
  props: IndicatorProps<IOption, boolean, GroupTypeBase<IOption>>,
): ReactElement => (
  <components.ClearIndicator {...props}>
    <IconButton onClick={() => props.clearValue()} size="small" style={{ padding: 2 }}>
      <CloseIcon color="secondary" fontSize="small" />
    </IconButton>
  </components.ClearIndicator>
);

export default ClearIndicator;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetLocationGroupOverviewReport } from '../types';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import SortableColumns from '~/ui/pages/Reports/constants/locationGroupSortableColumns';

const onGetLocationGroupOverviewReport: IThunkGetLocationGroupOverviewReport = thunk(
  async (
    { setLocationGroupOverviewReport }: Actions<IReportsActions>,
    { requestInfo, params },
    { getState },
  ) => {
    const localState = getState() as IReportsStore;

    const { list: items, filters } = localState.locationGroup;

    const locationGroup = await api.reports
      .getLocationGroupOverviewReport(requestInfo, params)
      .then(r => r.data);

    setLocationGroupOverviewReport({
      locationGroup: {
        items:
          params.pageNumber === 1 ||
          params.sorting !== filters.sorting ||
          params.sortDirection !== filters.sortDirection
            ? locationGroup.items
            : [...items, ...locationGroup.items],
        total: locationGroup.total,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        sorting: params.sorting as SortableColumns,
        sortDirection: params.sortDirection as SortDirection,
      },
    });
  },
);

export default onGetLocationGroupOverviewReport;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IProgramAssistantActions, IThunkOnGetProgramAssistants } from '../types';

const onGetProgramAssistants: IThunkOnGetProgramAssistants = thunk(
  async ({ setProgramAssistants }: Actions<IProgramAssistantActions>, payload) => {
    const { clinicId, params } = payload;

    const {
      data: { total, items },
    } = await api.programAssistant.getProgramAssistants(clinicId, params);

    const pagination = { ...params, total };

    setProgramAssistants({ items, pagination });
  },
);

export default onGetProgramAssistants;

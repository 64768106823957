import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';
import { IReportsModel } from './types';

const reports: IReportsModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default reports;

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import delay from 'lodash/delay';
import uniq from 'lodash/uniq';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import PrivateImage from '~/ui/components/common/PrivateImage';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DateRangePicker from '~/ui/components/inputs/DateRangePicker';
import RadioGroup from '~/ui/components/inputs/RadioGroup/RadioGroup';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import MenuList from '~/ui/components/inputs/SelectWithoutAnimation/components/MenuList';
import useRole from '~/store/user/hooks/useRole';
import useMemoCompare from '~/utils/useMemoCompare';
import MultiValue from './components/MultiValue';
import Option from './components/Option';
import validate, { IFormValues } from './validate';

import { IAddClientAllocation, IDatesValidation } from '~/services/api/clientAllocation';
import {
  formatLocationGroupOptions,
  formValuesMapper,
  groupClientAllocationData,
  ITeamMemberMapped,
} from '~/utils/clientAllocation';
import { normalizeDateString } from '~/utils/date/date';
import { extractErrorMessage } from '~/utils/error/error';
import ErrorTooltip from '../../reusable/ErrorTooltip';
import extractLocationGroupClientsIds from './helpers/extractLocationGroupClientsIds';
import filterNumberArray from './helpers/filterNumberArray';
import extractFullName from '~/utils/text/extractFullName';
import excludeArchivedClients from './helpers/excludeArchivedClients';
import extractArchivedClients from './helpers/extractArchivedClients';

import {
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
} from '~/services/api/clientAllocation/types';
import { IOption } from '~/ui/components/inputs/Select/types';
import { MY_ACT_TEAM, VIEW_ACT_TEAM, VIEW_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import { AllocationMode, allocationModeOptions } from './constants/allocationMode';

import { ReactComponent as DangerIcon } from '~/ui/assets/images/danger.svg';
import smallAvatarIcon from '~/ui/assets/images/smallAvatar.svg';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './ClientAllocationForm.module.scss';

interface IProps {
  teamMembers: ITeamMemberMapped[];
  clients: IOption[];
  locationGroups: IAllocationClientsByGroup[];
  actTeamId: string;
  defaultValues?: any;
  clientAllocationDate?: string;
  clientAllocations?: IClientAllocationDetailed[];
  clinicId: string;
}

const defValues = {
  dateRange: '',
  includeWeekends: false,
};

const Form = ({
  actTeamId,
  teamMembers,
  clients,
  locationGroups,
  clinicId,
  clientAllocationDate,
  clientAllocations,
  defaultValues = defValues,
}: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  const [datesValidation, setDatesValidation] = useState<IDatesValidation>({
    isValid: true,
    users: [],
  });

  const navigate = useNavigate();

  const isDuplicatingLocationGroups = Object.keys(defaultValues).some(key =>
    key.includes('groups'),
  );

  const initialAllocationMode = isDuplicatingLocationGroups
    ? AllocationMode.BY_LOCATION_GROUP
    : AllocationMode.BY_CLIENT;

  const {
    control,
    watch,
    clearErrors,
    setValue,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
  } = useForm<IFormValues>({
    defaultValues: {
      ...defaultValues,
      allocationMode: initialAllocationMode,
    },
    resolver: validate,
  });
  const { includeWeekends, dateRange: range, allocationMode, ...formValues } = watch();

  // reset form on mode change
  useEffect(() => {
    if (!isDuplicatingLocationGroups) {
      reset({ ...defaultValues, allocationMode, includeWeekends, dateRange: range });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationMode, reset]);

  const [from, to] = range || [];

  const assignedClients = formValues as { [key: string]: number[] };

  const selectedMembersIds = useMemo(
    () =>
      Object.keys(assignedClients)
        .filter(key => assignedClients[key]?.length)
        .map(key => Number(key.split('_')[1])),
    [assignedClients],
  );

  const memoizedSelectedMembersIds = useMemoCompare(
    selectedMembersIds,
    (prev, next) => prev && isEqual(prev, next),
  );

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { isActTeam, isActTeamMember } = useRole();

  const deletedMembers = useMemo(
    () => teamMembers.filter(teamMember => teamMember.isDeleted),
    [teamMembers],
  );

  const activeMembers = useMemo(
    () => teamMembers.filter(teamMember => !teamMember.isDeleted),
    [teamMembers],
  );

  const handleRemoveClient = (id: number, field: string) => {
    const newClients = filterNumberArray(assignedClients[field], id);
    setValue(field as any, newClients);
  };

  const handleRemoveGroup = (id: number, field: string) => {
    const groupField = `groups${field}`;
    const clientField = `clients${field}`;
    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    const newGroups = filterNumberArray(assignedClients[groupField], id);
    const newClients =
      assignedClients[clientField]?.filter(item => !(groupClientsIds || []).includes(item)) || [];

    setValue(clientField as any, newClients);
    setValue(groupField as any, newGroups);
  };

  const handleSelectGroup = (id: number, field: string) => {
    const groupField = `groups${field}`;
    const clientField = `clients${field}`;

    const isSelectedGroup = assignedClients[groupField]?.includes(id);

    const groupClientsIds = extractLocationGroupClientsIds(locationGroups, id);

    if (isSelectedGroup) {
      const newClients =
        assignedClients[clientField]?.filter(item => !(groupClientsIds || []).includes(item)) || [];

      setValue(clientField as any, newClients);
    } else {
      const newValue = Array.from(
        new Set([...groupClientsIds, ...(assignedClients[clientField] || [])]),
      );

      setValue(clientField as any, newValue);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit = async ({ dateRange, includeWeekends, allocationMode, ...vals }: IFormValues) => {
    try {
      const { allocationsForAdd } = groupClientAllocationData(
        vals as { [key: string]: number[] },
        true,
      );

      const archivedClients = extractArchivedClients(clientAllocations);

      if (archivedClients.length) {
        const archivedClientsNames = uniq(
          archivedClients.map(client => extractFullName(client)),
        ).join(', ');
        const isMultipleArchived = archivedClients.length > 1;
        showError(
          `The archived client${isMultipleArchived ? 's' : ''} ${archivedClientsNames} ha${
            isMultipleArchived ? 've' : 's'
          } been removed from the created allocation`,
        );
      }

      const allocations = excludeArchivedClients<IAddClientAllocation>(
        allocationsForAdd,
        archivedClients,
      );

      const payload = {
        from: from ? normalizeDateString(dateRange[0]) : undefined,
        to: to ? normalizeDateString(dateRange[1], true) : undefined,
        includeWeekends,
        allocations,
      };

      if (!allocations.length) {
        delay(
          () => showError('Please provide at least one client'),
          archivedClients.length ? 3000 : 0,
        );

        return;
      }

      delay(
        async () => {
          setIsAdding(true);
          if (isActTeamMember) {
            await api.clientAllocation.addOwnClientAllocation(
              { clinicId, teamId: actTeamId },
              {
                clientIds: allocations[0].clientIds,
                locationGroupIds: allocations[0].locationGroupIds,
                from: payload.from,
                to: payload.to,
                includeWeekends,
              },
            );
          } else {
            await api.clientAllocation.addClientAllocation(
              { clinicId, teamId: actTeamId },
              payload,
            );
          }

          showNotify({ message: 'Client allocation successfully added' });
          if (isActTeam) {
            navigate(MY_ACT_TEAM, { state: { selectedTab: 0 } });
          } else {
            navigate(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), {
              state: { selectedTab: 0 },
            });
          }
        },
        archivedClients.length ? 3000 : 0,
      );
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  const unique = new Set(formValuesMapper(formValues as { [key: string]: number[] }));

  const additionalStyleHandler = () => ({
    option: (provided: any, { data }: any) => {
      const notSelected = !unique?.has(data?.value);

      return {
        ...provided,
        backgroundColor: notSelected ? '#FFF1EE' : 'inherit',
        fontWeight: '300',
        color: variables.colorBlack,
      };
    },
  });

  const getAllocationIdByUserId = (id: number) =>
    clientAllocations?.find(allocation => allocation.user.id === id && !allocation.isArchived)?.id;

  // validate allocation dates for availability
  const validateClientAllocationRange = useCallback(async () => {
    try {
      if (!from || !to) {
        return;
      }
      const payload = {
        from: normalizeDateString(from),
        to: normalizeDateString(to, true),
        includeWeekends,
        userIds: memoizedSelectedMembersIds,
      };

      const method = isActTeamMember
        ? api.clientAllocation.validateOwnDateRange
        : api.clientAllocation.validateDateRange;

      const validationResult = await method({ clinicId, teamId: actTeamId }, payload).then(
        r => r.data,
      );

      setDatesValidation(validationResult);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [
    actTeamId,
    clinicId,
    from,
    includeWeekends,
    isActTeamMember,
    memoizedSelectedMembersIds,
    showError,
    to,
  ]);

  const renderMembers = (members: ITeamMemberMapped[]) => (
    <>
      {' '}
      {members.map(teamMember => {
        const allocationId = getAllocationIdByUserId(teamMember.id);

        if (teamMember.isDeleted && !clientAllocationDate) {
          return null;
        }

        const selectName = `_${teamMember.id}${allocationId ? `_${allocationId}` : ''}`;

        const errorDates = datesValidation?.users
          .find(user => user.userId === teamMember.id)
          ?.unavailableDates.map(item => item.split('T')[0]);
        return (
          <Fragment key={teamMember.id}>
            <Grid item sm={3}>
              <div className={styles.row}>
                <PrivateImage
                  className={styles.image}
                  src={teamMember.photo || smallAvatarIcon}
                  height={30}
                  alt="avatar"
                />
                <span className={styles.text}>{teamMember.fullName}</span>
                {!datesValidation.isValid && !!errorDates?.length && (
                  <ErrorTooltip items={errorDates} />
                )}
              </div>
            </Grid>
            <Grid item sm={9}>
              {allocationMode === AllocationMode.BY_CLIENT ? (
                <SelectComponent
                  closeMenuOnSelect={false}
                  showCustomComponents
                  hideSelectedOptions={false}
                  additionalStyleHandler={() => additionalStyleHandler()}
                  isMulti
                  label="Assign Clients"
                  options={clients}
                  disableMenu={teamMember.isDeleted}
                  name={`clients${selectName}`}
                  control={control}
                  errors={errors}
                />
              ) : (
                <SelectComponent
                  closeMenuOnSelect={false}
                  showCustomComponents
                  hideSelectedOptions={false}
                  additionalStyleHandler={() => additionalStyleHandler()}
                  isMulti
                  label="Location Group"
                  options={formatLocationGroupOptions(locationGroups)}
                  disableMenu={teamMember.isDeleted}
                  name={`groups${selectName}`}
                  control={control}
                  errors={errors}
                  customComponents={{
                    MenuList: props => <MenuList {...props} label="Location Groups" />,
                    MultiValue: props => (
                      <MultiValue
                        {...props}
                        clientIds={assignedClients[`clients${selectName}`]}
                        locationGroups={locationGroups}
                        onDelete={id => handleRemoveClient(id, `clients${selectName}`)}
                        onDeleteGroup={id => handleRemoveGroup(id, selectName)}
                      />
                    ),
                    Option: props => (
                      <Option {...props} selectGroup={id => handleSelectGroup(id, selectName)} />
                    ),
                  }}
                />
              )}
            </Grid>
          </Fragment>
        );
      })}
    </>
  );

  useEffect(() => {
    if (to && from && memoizedSelectedMembersIds?.length) {
      clearErrors();
      if (memoizedSelectedMembersIds?.length) {
        validateClientAllocationRange();
      }
    }
  }, [to, from, memoizedSelectedMembersIds.length, validateClientAllocationRange, clearErrors]);

  const leavePageDescription =
    'Are you sure you want to cancel “Add Client Allocation”? All the changes will be discarded.';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper>
        <Box p={3}>
          <div className={styles.header}>
            <h5 className={styles.subTitle}>Client Allocation Details</h5>
            {!clientAllocationDate && (
              <RadioGroup
                control={control}
                name="allocationMode"
                errors={errors}
                options={allocationModeOptions}
                label="Allocate By"
                className={styles.radioGroupContainer}
                size="small"
              />
            )}
            {!isActTeamMember && (
              <Checkbox
                name="includeWeekends"
                size="small"
                control={control}
                label="Include weekends"
                errors={errors}
              />
            )}
          </div>

          <Grid container spacing={2}>
            <Grid item sm={12} />
            <Grid item sm={4}>
              <DateRangePicker
                label="Select Date Range"
                control={control}
                name="dateRange"
                errors={errors}
              />
            </Grid>

            <Grid item sm={6} container justifyContent="flex-end">
              {isActTeamMember && (
                <Checkbox
                  name="includeWeekends"
                  size="small"
                  control={control}
                  label="Include weekends"
                  errors={errors}
                />
              )}
            </Grid>
            {!datesValidation.isValid && (
              <Grid item sm={12} container direction="row" alignItems="center">
                <DangerIcon color={variables.colorRed} />
                <h5 className={styles.validationErrors}>
                  {datesValidation.users.length} Team members have already allocations within this
                  period!
                </h5>
              </Grid>
            )}
            <Grid item sm={3}>
              <h5 className={styles.subTitle}>Team Members</h5>
            </Grid>
            <Grid item sm={9}>
              <h5 className={styles.subTitle}>
                {allocationMode === AllocationMode.BY_CLIENT
                  ? 'Assigned Clients'
                  : 'Location Groups'}
              </h5>
            </Grid>
            <Grid container spacing={3} className={styles.formWrapper}>
              {renderMembers(activeMembers)}
              {!!deletedMembers.length && clientAllocationDate && (
                <Grid item sm={12}>
                  <h5 className={styles.subTitle}>Deleted/Deactivated Team Members</h5>
                </Grid>
              )}
              {renderMembers(deletedMembers)}
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div className={styles.buttons}>
        <div className={styles.buttonWrapper}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (clientAllocationDate) {
                navigate(
                  VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                    ':clientAllocationDate',
                    String(clientAllocationDate.split('T')[0]),
                  ),
                );
              } else if (isActTeam) {
                navigate(MY_ACT_TEAM, { state: { selectedTab: 0 } });
              } else
                navigate(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), {
                  state: { selectedTab: 0 },
                });
            }}
          >
            Cancel
          </Button>
        </div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={!!Object.keys(errors).length || !datesValidation.isValid || !from || !to}
        >
          Add Client Allocation
        </Button>
      </div>
      <NavigationConfirmModal when={isDirty && !isAdding} description={leavePageDescription} />
    </form>
  );
};

export default Form;

import setSosRequests from './setSosRequests';
import setSosRequest from './setSosRequest';
import resetCurrentSosRequest from './resetCurrentSosRequest';
import setSosRequestsPagination from './setSosRequestsPagination';

export default {
  setSosRequests,
  setSosRequest,
  resetCurrentSosRequest,
  setSosRequestsPagination,
};

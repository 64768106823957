import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetDemographicsAvailableColumns } from '../types';

const onGetDemographicsAvailableColumns: IThunkGetDemographicsAvailableColumns = thunk(
  async ({ setDemographicsAvailableColumns }: Actions<IReportsActions>) => {
    const columns = await api.reports.getDemographicsAvailableColumns().then(r => r.data);

    setDemographicsAvailableColumns(columns);
  },
);

export default onGetDemographicsAvailableColumns;

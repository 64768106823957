import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IProgramAssistantActions, IThunkOnReInviteProgramAssistant } from '../types';

const onReInviteProgramAssistant: IThunkOnReInviteProgramAssistant = thunk(
  async ({ reInviteProgramAssistant }: Actions<IProgramAssistantActions>, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.reInviteProgramAssistant(clinicId, String(programAssistantId));

    reInviteProgramAssistant(programAssistantId);
  },
);

export default onReInviteProgramAssistant;

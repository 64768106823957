import { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import Input from '~/ui/components/inputs/Input';
import Button from '~/ui/components/common/Button';
import InfoMessage from '~/ui/components/common/InfoMessage';
import validate from './validate';

import { IFormValues } from './types';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from '../../ChangePassword.module.scss';

interface IProps {
  error: string;
  title: string;
  isPasswordExpired?: boolean;
  loading?: boolean;
  buttonText: string;
  buttonFullWidth?: boolean;
  formClassName?: string;
  className?: string;
  onSubmit: (val: IFormValues, cb?: () => void) => void;
}

const ChangePasswordForm = ({
  error,
  title,
  loading,
  buttonText,
  buttonFullWidth = true,
  isPasswordExpired = true,
  formClassName,
  className,
  onSubmit,
}: IProps): ReactElement => {
  const {
    handleSubmit,
    register,
    resetField,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({ mode: 'onBlur', resolver: validate });

  const resetForm = () => {
    resetField('newPassword');
    resetField('oldPassword');
    resetField('passwordConfirmation');
  };

  const { oldPassword, newPassword, passwordConfirmation } = watch();
  return (
    <form onSubmit={handleSubmit(e => onSubmit(e, resetForm))} className={formClassName}>
      <Paper className={classNames(styles.changePassword, className)}>
        <h2 className={styles.title}>{title}</h2>
        {isPasswordExpired && (
          <InfoMessage message="Your current password has expired, please create a new one." />
        )}
        <div className={styles.inputWrapper}>
          <Input
            type="password"
            register={register}
            errors={errors}
            name="oldPassword"
            label="Current Password"
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            type="password"
            register={register}
            errors={errors}
            name="newPassword"
            label="New Password"
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            type="password"
            register={register}
            errors={errors}
            name="passwordConfirmation"
            label="Confirm Password"
          />
        </div>
        <span className={styles.error}>{error?.toString()}</span>
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={buttonFullWidth}
            isLoading={loading}
            loaderColor={variables.colorWhite}
            disabled={!oldPassword || !newPassword || !passwordConfirmation}
          >
            {buttonText}
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default ChangePasswordForm;

import { format } from 'date-fns';
import {
  IClientLengthOfStayTotals,
  IDashboardReportParams,
  IRecertificationReportParams,
  IReportClientLengthOfStay,
  IReportClientVisit,
  IReportVisit,
  ITeamMemberDashboardReportParams,
  IVisitsReportTotals,
} from '~/store/reports/types';
import formatToxicologyParams from '~/ui/pages/Reports/helpers/formatToxicologyParams';
import { IDictionary } from '../dictionaries/types';
import { privateAxios } from '../requestsCollection';
import {
  IClientAllocationVisitsReportParams,
  IClientLastContactOverviewParams,
  IClientLengthOfStayDetailsParams,
  IClientLengthOfStayOverviewParams,
  IClientStatusReportParams,
  IDemographicsReportParams,
  ILocationGroupOverviewParams,
  IMedicationsReportCommonParams,
  IMedicationsReportExportParams,
  IMedicationsReportParams,
  IMedicationsReportTeamParams,
  IResponse,
  IToxicologyOverviewParams,
  ITreatmentPlansReportParams,
  IVisitStatusReportOverviewParams,
  IVisitsReportParams,
} from '../types';
import {
  IClientAllocationVisitsReport,
  IClientLastContactTeamReport,
  IClientLengthOfStayTeamReport,
  IClientStatusReportItem,
  IClientTreatmentPlansReportItem,
  IClientTreatmentPlansReportRequestInfo,
  ICommonRequestInfo,
  IDashboardReport,
  IDemographicsClient,
  IDemographicsReportRequestInfo,
  IDemographicsTeam,
  IEffectiveDate,
  IEffectiveDatesRequestInfo,
  IFavoriteReportRequest,
  IFavoriteReportsResponse,
  IFormattedToxicologyParams,
  ILocationGroupTeamReport,
  IMedicationsReportClient,
  IMedicationsReportResponse,
  IMedicationsReportResponseItemClients,
  IRecertificationReport,
  IRecertificationReportsRequestInfo,
  IReportTableColumn,
  ITeamMemberDashboardReport,
  IToxicologyTeamReport,
  ITreatmentPlansReportItem,
  IVisitStatusReportItem,
  IVisitsByClientReport,
  IVisitsByTeamReport,
} from './types';

export const getRecertificationReports = (
  { clinicId, date }: IRecertificationReportsRequestInfo,
  params?: IRecertificationReportParams,
): IResponse<{
  items: IRecertificationReport[];
  total: number;
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/medicaid-recertification?${
      date
        ? `Date.Year=${format(new Date(date), 'yyyy')}&Date.Month=${format(new Date(date), 'MM')}`
        : ''
    }`,
    params,
  });

export const getDashboardReport = (params?: IDashboardReportParams): IResponse<IDashboardReport> =>
  privateAxios({
    method: 'get',
    url: `dashboard`,
    params,
  });

export const getTeamMemberDashboardReport = ({
  clinicId,
  ...params
}: ITeamMemberDashboardReportParams): IResponse<ITeamMemberDashboardReport> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/team-member-dashboard`,
    params,
  });

export const getVisitsByTeamReport = (
  { clinicId }: ICommonRequestInfo,
  params?: IVisitsReportParams,
): IResponse<{
  totals: IVisitsReportTotals;
  items: { total: number; items: IVisitsByTeamReport[] };
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/overview/by-team-members`,
    params,
  });

export const getVisitsListByTeamReport = (
  { clinicId }: ICommonRequestInfo,
  params?: IVisitsReportParams,
): IResponse<{
  total: number;
  items: IReportVisit[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/overview/by-team-member/list`,
    params,
  });

export const getVisitsByClientReport = (
  { clinicId }: ICommonRequestInfo,
  params?: IVisitsReportParams,
): IResponse<{
  totals: IVisitsReportTotals;
  items: { total: number; items: IVisitsByClientReport[] };
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/overview/by-clients`,
    params,
  });

export const getVisitsListByClientReport = (
  { clinicId }: ICommonRequestInfo,
  params?: IVisitsReportParams,
): IResponse<{ total: number; items: IReportClientVisit[] }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/overview/by-client/list`,
    params,
  });

export const generateRecertificationReport = (
  { clinicId, date }: IRecertificationReportsRequestInfo,
  params?: IRecertificationReportParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/medicaid-recertification/export?${
      date
        ? `Date.Year=${format(new Date(date), 'yyyy')}&Date.Month=${format(new Date(date), 'MM')}`
        : ''
    }`,
    responseType: 'blob',
    params,
  });

export const generateVisitsByTeamReport = (
  { clinicId, short }: ICommonRequestInfo & { short?: boolean },
  params?: IVisitsReportParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/overview/by-team-members${short ? '-short' : ''}/export`,
    responseType: 'blob',
    params,
  });

export const generateVisitsByClientReport = (
  { clinicId }: ICommonRequestInfo,
  params?: IVisitsReportParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/overview/by-clients/export`,
    responseType: 'blob',
    params,
  });

export const getClientAllocationVisitsReport = (
  { clinicId }: ICommonRequestInfo,
  params?: IClientAllocationVisitsReportParams,
): IResponse<IClientAllocationVisitsReport> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/allocation-visits/overview`,
    params,
  });

export const getFavoriteReports = (): IResponse<IFavoriteReportsResponse> =>
  privateAxios({
    method: 'get',
    url: 'Account/favorite-reports',
  });

export const addFavoriteReport = ({ type }: IFavoriteReportRequest): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `Account/favorite-reports/${type}/add`,
  });

export const removeFavoriteReport = ({ type }: IFavoriteReportRequest): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `Account/favorite-reports/${type}/remove`,
  });

export const generateClientAllocationVisitsReport = (
  { clinicId }: ICommonRequestInfo,
  params?: IClientAllocationVisitsReportParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/allocation-visits/export`,
    responseType: 'blob',
    params,
  });

export const generateClientLengthOfStayReport = (
  { clinicId }: ICommonRequestInfo,
  params: IClientLengthOfStayOverviewParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/length-of-stay/export`,
    responseType: 'blob',
    params,
  });

export const generateVisitStatusReport = (
  { clinicId }: ICommonRequestInfo,
  params: IVisitStatusReportOverviewParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/status/export`,
    responseType: 'blob',
    params,
  });

export const generateTreatmentPlansReport = (
  { clinicId }: ICommonRequestInfo,
  params?: ITreatmentPlansReportParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/treatment-plans/report/export`,
    responseType: 'blob',
    params: {
      ...params,
      'NextReviewDate.From': params.from,
      'NextReviewDate.To': params.to,
    },
  });

export const generateLocationGroupReport = (
  { clinicId }: ICommonRequestInfo,
  params: ILocationGroupOverviewParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/location-group/export`,
    responseType: 'blob',
    params,
  });

export const generateToxicologyReport = (
  { clinicId }: ICommonRequestInfo,
  params: IFormattedToxicologyParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/toxicology/export`,
    responseType: 'blob',
    params,
  });

export const generateClientLastContactReport = (
  { clinicId }: { clinicId: string },
  params: IClientLastContactOverviewParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/last-contact/export`,
    responseType: 'blob',
    params,
  });

export const generateClientTreatmentPlansReport = ({
  clinicId,
  teamId,
  clientId,
  treatmentPlanIds,
}: IClientTreatmentPlansReportRequestInfo): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/treatment-plans/report/export`,
    params: {
      treatmentPlanIds,
    },
    responseType: 'blob',
  });

export const generateDemographicsReport = (
  { clinicId }: IDemographicsReportRequestInfo,
  params: IDemographicsReportParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/demographics/export`,
    responseType: 'blob',
    params,
  });

export const generateMedicationsReport = ({
  clinicId,
  ...params
}: IMedicationsReportExportParams): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/medications/export`,
    responseType: 'blob',
    params,
  });

export const getDemographicsAvailableColumns = (): IResponse<IReportTableColumn[]> =>
  privateAxios({
    method: 'get',
    url: 'client/report/demographics/available-columns',
  });

export const getDemographicsReport = (
  { clinicId }: IDemographicsReportRequestInfo,
  params: IDemographicsReportParams,
): IResponse<{
  columns: IReportTableColumn[];
  teams: IDemographicsTeam[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/demographics`,
    params,
  });

export const getDemographicsReportByTeam = (
  { clinicId, teamId }: IDemographicsReportRequestInfo,
  params: IDemographicsReportParams,
): IResponse<IDemographicsClient[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/report/demographics-data`,
    params,
  });

export const getMedicationsReport = ({
  clinicId,
  ...params
}: IMedicationsReportCommonParams): IResponse<IMedicationsReportResponse> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/medications/overview`,
    params: {
      ...params,
    },
  });

export const getMedicationsReportTeam = ({
  clinicId,
  ...params
}: IMedicationsReportTeamParams): IResponse<IMedicationsReportResponseItemClients> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/medications/overview/clients`,
    params,
  });

export const getMedicationsReportClient = ({
  clinicId,
  sorting,
  sortDirection,
  ...params
}: IMedicationsReportParams): IResponse<IMedicationsReportClient> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/medications`,
    params: {
      ...params,
      'Sorting.Property': sorting,
      'Sorting.Direction': sortDirection,
      includeDeleted: true,
    },
  });

export const getClientsVisitsByClientReport = (
  { clinicId }: ICommonRequestInfo,
  params: IVisitsReportParams,
): IResponse<IDictionary[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/overview/client-list`,
    params,
  });

export const getTreatmentPlansReport = (
  { clinicId }: ICommonRequestInfo,
  { from, to, ...params }: ITreatmentPlansReportParams,
): IResponse<{
  total: number;
  items: ITreatmentPlansReportItem[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/treatment-plans/report`,
    params: {
      ...params,
      'NextReviewDate.From': from,
      'NextReviewDate.To': to,
    },
  });

export const getClientStatusReport = (
  { clinicId }: ICommonRequestInfo,
  params: IClientStatusReportParams,
): IResponse<{
  total: number;
  items: IClientStatusReportItem[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/status`,
    params,
  });

export const generateClientStatusReport = (
  { clinicId }: ICommonRequestInfo,
  params: IClientStatusReportParams,
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/status/export`,
    responseType: 'blob',
    params,
  });

export const getClientTreatmentPlansReport = ({
  clinicId,
  teamId,
  clientId,
  treatmentPlanIds,
}: IClientTreatmentPlansReportRequestInfo): IResponse<{
  treatmentPlans: IClientTreatmentPlansReportItem[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/treatment-plans/report`,
    params: {
      treatmentPlanIds,
    },
  });

export const getEffectiveDates = ({
  clinicId,
  teamId,
  clientId,
}: IEffectiveDatesRequestInfo): IResponse<IEffectiveDate[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/treatment-plans/report/effective-dates`,
  });

export const getClientLengthOfStayOverviewReport = (
  { clinicId }: ICommonRequestInfo,
  params: IClientLengthOfStayOverviewParams,
): IResponse<{
  totals: IClientLengthOfStayTotals;
  teams: IClientLengthOfStayTeamReport[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/length-of-stay/overview`,
    params,
  });

export const getClientLengthOfStayDetailsReport = (
  { clinicId }: ICommonRequestInfo,
  { teamId, ...params }: IClientLengthOfStayDetailsParams,
): IResponse<{ total: number; items: IReportClientLengthOfStay[] }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/report/length-of-stay/details`,
    params,
  });

export const getVisitStatusOverviewReport = (
  { clinicId }: ICommonRequestInfo,
  params: IVisitStatusReportOverviewParams,
): IResponse<{
  total: number;
  items: IVisitStatusReportItem[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visit-report/status`,
    params,
  });

export const getLocationGroupOverviewReport = (
  { clinicId }: ICommonRequestInfo,
  params: ILocationGroupOverviewParams,
): IResponse<{
  items: ILocationGroupTeamReport[];
  total: number;
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/location-group`,
    params,
  });

export const getToxicologyOverviewReport = (
  { clinicId }: { clinicId: string },
  params: IToxicologyOverviewParams,
): IResponse<{
  items: IToxicologyTeamReport[];
  total: number;
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/toxicology`,
    params: formatToxicologyParams(params),
  });

export const getClientLastContactOverviewReport = (
  { clinicId }: { clinicId: string },
  params: IClientLastContactOverviewParams,
): IResponse<{
  items: IClientLastContactTeamReport[];
  total: number;
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/report/last-contact`,
    params,
  });

export default {};

import { ReactElement, SyntheticEvent } from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { useStoreState, useStoreActions } from '~/store/hooks';
import Button from '../../common/Button';

import styles from './Snackbar.module.scss';

const SnackbarComponent = (): ReactElement | null => {
  const { message, isError, options } = useStoreState(state => state.snackbar);
  const hide = useStoreActions(actions => actions.snackbar.hide);

  const handleClose = (_event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    options?.closeCb();
    hide();
  };

  const { buttonLabel, onButtonClick } = options || {};

  if (!message) return null;

  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const severity = isError ? 'error' : 'success';

  return (
    <Snackbar
      anchorOrigin={options?.anchorOrigin || anchorOrigin}
      open
      autoHideDuration={options?.autoHide === false ? undefined : 4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} className={styles.snackbar}>
        {message}
        {!!onButtonClick && buttonLabel && (
          <Button color="primary" variant="outlined" onClick={onButtonClick}>
            {buttonLabel}
          </Button>
        )}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;

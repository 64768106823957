import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkAddVisit, IVisitsActions } from '../types';

const onAddVisit: IThunkAddVisit = thunk(async ({ setVisit }: Actions<IVisitsActions>, payload) => {
  const {
    requestInfo: { clinicId, teamId },
    requestPayload,
  } = payload;
  const data = await api.visits.addVisit({ clinicId, teamId }, requestPayload).then(r => r.data);

  setVisit(data);
});

export default onAddVisit;

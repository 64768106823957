import onGetSuperAdmins from './onGetSuperAdmins';
import onDeactivateSuperAdmin from './onDeactivateSuperAdmin';
import onActivateSuperAdmin from './onActivateSuperAdmin';
import onUnlockSuperAdmin from './onUnlockSuperAdmin';
import onGetSuperAdmin from './onGetSuperAdmin';

export default {
  onGetSuperAdmins,
  onDeactivateSuperAdmin,
  onActivateSuperAdmin,
  onUnlockSuperAdmin,
  onGetSuperAdmin,
};

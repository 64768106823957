import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientActions, IThunkGetAvailableClients } from '../types';

const onGetAvailableClients: IThunkGetAvailableClients = thunk(
  async ({ setAvailableClients }: Actions<IClientActions>, payload) => {
    const { clinicId, teamId } = payload;

    const { data } = await api.clientAllocation.getRelatedClients(String(clinicId), teamId);

    setAvailableClients(data);
  },
);

export default onGetAvailableClients;

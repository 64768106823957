import unionBy from 'lodash/unionBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

import api from '~/services/api';
import { IAssignableActTeamMember } from '~/services/api/actTeamMember/types';
import { useStoreActions } from '~/store/hooks';
import compareRoles from '~/utils/compareRoles';
import { extractErrorMessage } from '~/utils/error/error';
import removeSpaces from '~/utils/text/removeSpaces';

import { IUserRole } from '~/types';

interface IUseMembers {
  loading: boolean;
  hasMore: boolean;
  data: IAssignableActTeamMember[];
  loadMore: () => void;
  refresh: () => void;
}

interface IProps {
  searchQuery: string;
  teamId: number;
  assignTeamId: number;
  clinicId: number;
  role: string;
}

const DEFAULT_PAGE_SIZES = 10;
const SEARCH_PAGE_SIZES = 30;

let search = '';

const useMembers = ({ searchQuery, clinicId, teamId, assignTeamId, role }: IProps): IUseMembers => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IAssignableActTeamMember[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState<number | undefined>();

  const isLocalAdmin = role === removeSpaces(IUserRole.LocalAdmin);
  const isTeam =
    compareRoles(role, IUserRole.ActTeamMember) || compareRoles(role, IUserRole.ActTeamLeader);

  const isProgramAssistant = role === removeSpaces(IUserRole.ProgramAssistant);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const withSearch = !!searchQuery?.length;

  const hasMore = useMemo(
    () => total === undefined || data.length < total || !data.length || searchQuery !== search,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length, searchQuery, total, search],
  );

  // load mode data on scroll to bottom
  const loadMore = useCallback(async () => {
    try {
      setLoading(true);
      // fetch team members with pagination
      const assignToTeamId = assignTeamId ? String(assignTeamId) : null;
      const fromTeamId = teamId ? String(teamId) : null;

      // for the program assistant role 'team select' is used for selecting 'team to assign'
      const toTeamId = isProgramAssistant ? fromTeamId : assignToTeamId;

      const { items, total: totalItems } = await api.actTeamMember
        .getAssignableUsers(
          String(clinicId),
          toTeamId,
          isProgramAssistant ? undefined : fromTeamId,
          role,
          {
            pageNumber,
            name: searchQuery,
            ...(withSearch
              ? { pageNumber: 1, pageSize: SEARCH_PAGE_SIZES }
              : { pageSize: DEFAULT_PAGE_SIZES }),
          },
        )
        .then(r => r.data);

      let newData: IAssignableActTeamMember[] = [];

      // set new data
      setData(prev => {
        newData = unionBy(withSearch ? items : [...prev, ...items], 'id');
        return newData;
      });

      setTotal(totalItems);
      search = searchQuery;
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    teamId,
    assignTeamId,
    clinicId,
    searchQuery,
    withSearch,
    pageNumber,
    isProgramAssistant,
    showError,
    role,
  ]);

  const handleLoadMore = () => {
    if (hasMore) {
      setPageNumber(prev => prev + 1);
    }
  };

  // refresh data and pagination
  const refresh = useCallback(() => {
    setData([]);
    setPageNumber(1);
    setTotal(undefined);
  }, []);

  // start fetching on mount
  useEffect(() => {
    if (
      (clinicId && ((assignTeamId && teamId) || isLocalAdmin || isTeam)) ||
      (teamId && isProgramAssistant)
    ) {
      loadMore();
    }
  }, [
    teamId,
    assignTeamId,
    isLocalAdmin,
    isTeam,
    isProgramAssistant,
    clinicId,
    searchQuery,
    loadMore,
  ]);

  useEffect(() => {
    refresh();
  }, [
    teamId,
    assignTeamId,
    isLocalAdmin,
    isTeam,
    isProgramAssistant,
    clinicId,
    searchQuery,
    refresh,
  ]);

  return {
    loading,
    hasMore,
    data,
    refresh,
    loadMore: handleLoadMore,
  };
};

export default useMembers;

import onGetMedicationDeliveries from './onGetMedicationDeliveries';
import onGetMedicationDelivery from './onGetMedicationDelivery';
import onUpdateMedicationDelivery from './onUpdateMedicationDelivery';
import onAddMedicationDelivery from './onAddMedicationDelivery';

export default {
  onGetMedicationDeliveries,
  onGetMedicationDelivery,
  onUpdateMedicationDelivery,
  onAddMedicationDelivery,
};

import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import { validateLengthField } from '~/ui/configs/form/validation';
import { fieldMax, requiredField } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  name: string().required(requiredField).test('length', fieldMax, validateLengthField),
});

export default yupResolver(validationSchema);

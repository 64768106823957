export enum TestingStatus {
  ALL = 'All',
  DONE = 'Done',
  REFUSED = 'Refused',
  REQUESTED = 'Requested',
}

export enum ToxicologyResult {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
}

import ResourceType from '../models/ResourceType';

const getFileTypeByExt = (value: string): ResourceType => {
  switch (true) {
    case value.includes('docx'):
    case value.includes('doc'): {
      return ResourceType.Document;
    }
    case value.includes('xlsx'):
    case value.includes('xls'):
    case value.includes('csv'): {
      return ResourceType.Excel;
    }
    case value.includes('pdf'): {
      return ResourceType.Pdf;
    }
    case value.includes('jpeg'):
    case value.includes('png'):
    case value.includes('jpg'): {
      return ResourceType.Image;
    }
    case value.includes('txt'): {
      return ResourceType.Text;
    }
    case value.includes('ppt'):
    case value.includes('pptx'): {
      return ResourceType.Presentation;
    }
    default: {
      return ResourceType.Pdf;
    }
  }
};

export default getFileTypeByExt;

const extractInitials = (name?: string, cut = 3): string | undefined => {
  if (name) {
    return name
      .split(' ')
      .map((el: string) => el.substring(0, 1))
      .join('')
      .toUpperCase()
      .substring(0, cut);
  }
  return undefined;
};

export default extractInitials;

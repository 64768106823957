import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { MouseEvent, ReactElement, useState } from 'react';

import { ReactComponent as DangerIcon } from '~/ui/assets/images/danger.svg';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './ErrorTooltip.module.scss';

interface IProps {
  items: string[];
}

const ErrorTooltip = ({ items }: IProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick} className={styles.icon}>
        <DangerIcon color={variables.colorRed} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: styles.tooltip }}
      >
        <Box className={styles.column}>
          {items?.map(item => (
            <span key={item}>{item}</span>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default ErrorTooltip;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetClientLengthOfStayOverviewReport } from '../types';

const onGetClientLengthOfStayOverviewReport: IThunkGetClientLengthOfStayOverviewReport = thunk(
  async (
    { setClientLengthOfStayOverviewReport }: Actions<IReportsActions>,
    { requestInfo, params },
  ) => {
    const clientLengthOfStayReport = await api.reports
      .getClientLengthOfStayOverviewReport(requestInfo, params)
      .then(r => r.data);

    setClientLengthOfStayOverviewReport({
      clientLengthOfStay: {
        teams: clientLengthOfStayReport.teams,
        totals: clientLengthOfStayReport.totals,
      },
    });
  },
);

export default onGetClientLengthOfStayOverviewReport;

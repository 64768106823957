import { action } from 'easy-peasy';
import { IActionSetCurrentAvailableDictionaryOption } from '../types';

const setCurrentAvailableDictionaryOption: IActionSetCurrentAvailableDictionaryOption = action(
  (state, { type, ...rest }) => {
    state.availableDictionaries = {
      ...state.availableDictionaries,
      [type]: { ...state.availableDictionaries[type], ...rest },
    };
  },
);

export default setCurrentAvailableDictionaryOption;

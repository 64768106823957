import { object, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { requiredRole } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  teamId: number().nullable(),
  clinicId: number().nullable(),
  roleId: number().min(1, requiredRole).required(requiredRole).typeError(requiredRole),
});

export default yupResolver(validationSchema);

import { useState, useEffect, MutableRefObject } from 'react';

const OPTIONS = {
  rootMargin: '300px', // extend viewport visibility
  threshold: 0,
};

const useIsVisible = (elementRef: MutableRefObject<HTMLDivElement>): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef?.current) {
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[], obs: IntersectionObserver) => {
          entries.forEach(entry => {
            if (entry.isIntersecting && elementRef) {
              setIsVisible(true);
              if (elementRef?.current) {
                obs.unobserve(elementRef?.current);
              }
            }
          });
        },
        OPTIONS,
      );

      observer.observe(elementRef?.current);
    }
  }, [elementRef]);

  return isVisible;
};

export default useIsVisible;

import { format } from 'date-fns';
import { IToxicologyResults } from '../components/AddVisit/types';
import { dateRequestFormat } from '~/ui/constants/dateFormat';
import { setNoon } from '~/utils/date/date';

const formatToxicologyPayload = ({
  toxicology: tx,
  bloodDrawn: bd,
}: IToxicologyResults | null): IToxicologyResults | null =>
  tx.status || bd.status
    ? {
        toxicology: tx?.status
          ? {
              ...(tx || {}),
              date: tx.date ? format(new Date(setNoon(tx.date)), dateRequestFormat) : null,
            }
          : null,
        bloodDrawn: bd?.status
          ? {
              ...(bd || {}),
              date: bd.drawDate ? format(new Date(setNoon(bd.drawDate)), dateRequestFormat) : null,
            }
          : null,
      }
    : null;

export default formatToxicologyPayload;

import { action } from 'easy-peasy';
import { IActionActivateLocalAdmin } from '../types';

const activateLocalAdmin: IActionActivateLocalAdmin = action((state, localAdminId) => {
  const updatedList = state.list.map(item =>
    item.id !== localAdminId ? item : { ...item, status: 'Active' },
  );

  state.list = updatedList;
});

export default activateLocalAdmin;

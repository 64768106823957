import { ISubRole } from '~/services/api/auth/types';

export const getSubRoleName = (subRoles: ISubRole[]): string => {
  if (!Array.isArray(subRoles)) return '';
  return subRoles.map(item => item.name).join(', ');
};

export const getChangeRoleSuccessMessage = ({
  teamFrom,
  teamTo,
  roleFrom,
  roleTo,
  clinicFrom,
  clinicTo,
}: {
  [key: string]: string;
}): string => {
  const isTeamChanged = !!teamFrom && teamTo && teamFrom !== teamTo;
  const isRoleChanged = roleFrom !== roleTo;
  const isClinicChanged = clinicFrom !== clinicTo;

  if (isRoleChanged && isTeamChanged && isClinicChanged) {
    return `Clinic ${clinicFrom} has been changed to ${clinicTo}, role ${roleFrom} has been changed to ${roleTo} and team ${teamFrom} has been changed to ${teamTo}`;
  }

  if (isRoleChanged && isTeamChanged) {
    return `Role ${roleFrom} has been changed to ${roleTo} and team ${teamFrom} has been changed to ${teamTo}`;
  }

  if (isClinicChanged && isRoleChanged) {
    return `Clinic ${clinicFrom} has been changed to ${clinicTo} and role ${roleFrom} has been changed to ${roleTo}`;
  }

  if (isClinicChanged && isTeamChanged) {
    return `Clinic ${clinicFrom} has been changed to ${clinicTo} and team ${teamFrom} has been changed to ${teamTo}`;
  }

  if (isClinicChanged) {
    return `Clinic ${clinicFrom} has been successfully changed to ${clinicTo}`;
  }

  if (isRoleChanged) {
    return `Role ${roleFrom} has been successfully changed to ${roleTo}`;
  }
  if (isTeamChanged) {
    return `Team ${teamFrom} has been successfully changed to ${teamTo}`;
  }

  return '';
};

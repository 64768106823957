import { action } from 'easy-peasy';
import { IActionSetAuthorized } from '../types';

const setAuthorized: IActionSetAuthorized = action((state, payload) => {
  const { authorized } = payload;

  state.authorized = authorized;
  state.authChecked = true;
});

export default setAuthorized;

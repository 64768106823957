import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreatePhone, IClientDetailsStore, IClientDetailsActions } from '../types';

const onCreatePhone: IThunkCreatePhone = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const { setAsPrimary, archivePrimary } = requestPayload;

    const clientPhone = await api.clientDetails
      .createPhone(requestInfo, requestPayload)
      .then(r => r.data);

    const updatedPhones = localState.phones.map(item => ({
      ...item,
      ...(archivePrimary ? { isArchived: item.isPrimary ? true : item.isArchived } : {}),
      ...(setAsPrimary ? { isPrimary: item.id === Number(clientPhone.id) } : {}),
    }));

    const phones = [...updatedPhones, clientPhone];

    setClientDetails({ phones });
  },
);

export default onCreatePhone;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IDictionariesActions, IThunkGetDictionariesByType } from '../types';

const onGetDictionariesByType: IThunkGetDictionariesByType = thunk(
  async ({ setAvailableDictionaries }: Actions<IDictionariesActions>, payload) => {
    const items = await api.dictionaries.getAvailableTypeList(payload).then(r => r.data);

    setAvailableDictionaries({ type: payload, items });
  },
);

export default onGetDictionariesByType;

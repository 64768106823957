import onGetClientAllocations from './onGetClientAllocations';
import onGetClientAllocation from './onGetClientAllocation';
import onGetOwnClientAllocation from './onGetOwnClientAllocation';
import onGetAllocationClientsByGroup from './onGetAllocationClientsByGroup';

export default {
  onGetClientAllocations,
  onGetClientAllocation,
  onGetOwnClientAllocation,
  onGetAllocationClientsByGroup,
};

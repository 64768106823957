import { IRefreshTokenParams } from '~/services/api/auth/types';
import getNewTokens from './getNewTokens';
import { setPasswordExpiration } from '~/services/localStorage/auth';

const refreshTokens = async (
  data: IRefreshTokenParams,
  cb: (tokens: { accessToken: string; refreshToken: string }) => void,
): Promise<void> => {
  const { accessToken, refreshToken, passwordExpiresSoon, daysTillExpiration } = await getNewTokens(
    data,
  );

  setPasswordExpiration({ passwordExpiresSoon, daysTillExpiration });

  if (accessToken && refreshToken) {
    cb({ accessToken, refreshToken });
  }
};

export default refreshTokens;

import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import buildDate from '~/utils/date/buildDate';
import { extractErrorMessage } from '~/utils/error/error';
import formatActTeamMemberOptions from '~/utils/formatActTeamMemberOptions';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { medicationDeliveryValidationSchema } from './validates';

import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import TimePicker from '~/ui/components/inputs/TimePicker';
import MedicationChangeInfo from '~/ui/pages/MedicationLogistics/components/MedicationChangeInfo';
import MedicationDeliveryInfo from '~/ui/pages/MedicationLogistics/components/MedicationDeliveryInfo';
import MultiValueNoChipText from '~/ui/components/inputs/SelectWithoutAnimation/components/MultiValueNoChipText';

import getFromDate from '~/ui/pages/MedicationLogistics/helpers/getFromDate';
import getToDate from '~/ui/pages/MedicationLogistics/helpers/getToDate';

import { IMedication } from '~/services/api/client/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IMedicationDeliveryInitialValues, IRequestInfo } from './types';
import { IOption } from '~/types';
import { IDetails } from '../types';
import styles from './Styles.module.scss';

interface IProps {
  defaultValues: IMedicationDeliveryInitialValues;
  requestInfo: IRequestInfo;
  details: IDetails | null;
  setModalTitle: (v: string | null) => void;
  setDetails: (v: IDetails | null) => void;
}

const AddMedicationDelivery = ({
  defaultValues,
  details,
  requestInfo,
  setDetails,
  setModalTitle,
}: IProps): ReactElement => {
  const [members, setMembers] = useState<IOption[]>([]);
  const [reasons, setReasons] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(true);

  const {
    reset,
    register,
    handleSubmit,
    unregister,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
    resolver: medicationDeliveryValidationSchema,
  });

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const {
    onGetMedicationDeliveries,
    onUpdateMedicationDelivery,
    onAddMedicationDelivery,
    setMedicationDelivery,
  } = useStoreActions(actions => actions.medicationLogistics);

  const { current } = useStoreState(state => state.client);
  const currentMedicationDelivery = useStoreState(state => state.medicationLogistics.current);

  useEffect(
    () => () => {
      setDetails(null);
      setModalTitle(null);
      setMedicationDelivery(null);
    },
    [setDetails, setMedicationDelivery, setModalTitle],
  );

  const medications = useMemo(
    () =>
      current.medications.reduce((acc: IOption[], obj: IMedication) => {
        if (!obj.isArchived && !obj.injectable) {
          let label = obj.medication.name;
          if (obj.dose) {
            label = `${label} (${obj.dose})`;
          }
          if (obj.outsideFacility) {
            label = `${label} <chip>O.F.</chip>`;
          }

          acc.push({
            label,
            value: obj.id,
          });
        }
        return acc;
      }, []),
    [current.medications],
  );

  const notDelivered = watch('notDelivered');

  const onMount = useCallback(async () => {
    try {
      const [teamMembers, nonDeliveryReasons] = await Promise.all([
        api.actTeamMember
          .getActTeamMemberList(requestInfo.clinicId, requestInfo.teamId)
          .then(r => formatActTeamMemberOptions(r.data, !defaultValues?.id)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.MedicationNonDeliveryReason)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setMembers(teamMembers);
      setReasons(nonDeliveryReasons);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [defaultValues?.id, requestInfo.clinicId, requestInfo.teamId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const refetchDeliveries = (date: Date) => {
    onGetMedicationDeliveries({
      clinicId: String(requestInfo.clinicId),
      clientId: String(current.id),
      teamId: String(requestInfo.teamId),
      from: getFromDate(date) as string,
      to: getToDate(date) as string,
    });
  };

  const onSubmit = async (values: IMedicationDeliveryInitialValues, evt: any) => {
    const {
      time,
      date: medicationDeliveryDate,
      archiveMedication,
      hasAlerts,
      lastInjection,
      nextDueDate,
      notificationOn,
      notificationTime,
      teamMembersToNotify,
      statusChangeLog,
      ...rest
    } = values;

    const date = buildDate(time, medicationDeliveryDate);

    const requestPayload = {
      ...rest,
      date,
      clientId: current.id,
    };

    const { name } = evt.nativeEvent.submitter;
    try {
      if (details && details.id) {
        const payload = {
          requestInfo,
          requestPayload: { ...requestPayload, medicationDeliveryId: String(details.id) },
        };
        await onUpdateMedicationDelivery(payload);
        setDetails(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload,
        };
        await onAddMedicationDelivery(payload);
        reset({ notDelivered: false, id: null, note: '', medicationIds: [], teamMemberId: null });
      }
      refetchDeliveries(new Date(date));
      setModalTitle(name);
      setMedicationDelivery(null);

      const type = details?.id ? 'updated' : 'added';

      showNotify({ message: `Medication delivery successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    if (!notDelivered) {
      unregister(['nonDeliveryReasonId']);
    }
  }, [notDelivered, unregister]);

  const filteredMembers = useMemo(
    () =>
      members.filter(
        member => !member.isDisabled || defaultValues?.teamMemberId === (member.value as number),
      ),
    [members, defaultValues],
  );

  if (loading) return <Loader />;

  const fullName = `${current.firstName} ${current.lastName}`;

  const showSecondSubmit = !details?.id;

  const setAll = (all?: boolean) => {
    register('medicationIds');
    if (all)
      setValue(
        'medicationIds',
        medications.map(m => Number(m.value)),
      );
    else {
      setValue('medicationIds', []);
    }
  };

  const additionalStyleHandler = () => ({
    menuList: (provided: any) => ({ ...provided, height: '100%' }),
    menu: (provided: any) => ({ ...provided, minHeight: '50px', maxHeight: '200px' }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MedicationDeliveryInfo
            name={fullName}
            medication={currentMedicationDelivery}
            fullInfo={!!details?.id}
          />
        </Grid>
        {!currentMedicationDelivery && (
          <Grid item sm={12}>
            <SelectComponent
              isMulti
              closeMenuOnSelect={false}
              showCustomComponents
              showSelectAll
              hideSelectedOptions={false}
              name="medicationIds"
              options={medications}
              additionalStyleHandler={() => additionalStyleHandler()}
              control={control}
              errors={errors}
              label="Select Medication"
              isDisabled={!!defaultValues?.id}
              setAll={setAll}
              customComponents={{
                MultiValue: MultiValueNoChipText,
              }}
            />
          </Grid>
        )}

        <Grid item sm={6}>
          <Checkbox
            size="small"
            name="notDelivered"
            control={control}
            errors={errors}
            label="Medication not delivered"
          />
        </Grid>
        <Grid item sm={6}>
          <Checkbox
            size="small"
            name="outsideFacility"
            control={control}
            errors={errors}
            label="Outside Facility"
          />
        </Grid>
        {defaultValues.statusChangeLog && (
          <Grid item sm={12}>
            <MedicationChangeInfo
              delivered={defaultValues.statusChangeLog.delivered}
              when={defaultValues.statusChangeLog.date}
              changedBy={defaultValues.statusChangeLog.user.name}
            />
          </Grid>
        )}
        {notDelivered && (
          <Grid item sm={12}>
            <SelectComponent
              options={reasons}
              name="nonDeliveryReasonId"
              control={control}
              errors={errors}
              label="Select Reason"
              hideSelectedOptions={false}
            />
          </Grid>
        )}
        <Grid item sm={12}>
          <SelectComponent
            options={filteredMembers}
            name="teamMemberId"
            control={control}
            errors={errors}
            label="Select Responsible Team Member"
            hideSelectedOptions={false}
          />
        </Grid>
        <Grid item sm={12}>
          <Input name="note" register={register} label="Note" multiline errors={errors} />
        </Grid>
        <Grid item sm={6}>
          <DatePicker
            name="date"
            openTo="date"
            control={control}
            errors={errors}
            label="Delivery Date"
            maxDate={new Date().toString()}
          />
        </Grid>

        <Grid item sm={6}>
          <TimePicker name="time" control={control} label="Delivery Time" errors={errors} />
        </Grid>
      </Grid>
      <div className={classNames(styles.buttonsWrapper, styles.marginVertical)}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setMedicationDelivery(null);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={styles.margin}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddMedicationDelivery;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IProgramAssistantActions, IThunkOnDeactivateProgramAssistant } from '../types';

const onDeactivateProgramAssistant: IThunkOnDeactivateProgramAssistant = thunk(
  async ({ deactivateProgramAssistant }: Actions<IProgramAssistantActions>, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.deactivateProgramAssistant(clinicId, String(programAssistantId));

    deactivateProgramAssistant(programAssistantId);
  },
);

export default onDeactivateProgramAssistant;

import { destructHours, setNoon } from './date/date';
import { IClientDetailed, IDiagnosis } from '~/services/api/client/types';
import { IInitialValues as IClientInitialValues } from '~/ui/pages/Clients/LocalAdmin/Form/initialValues';
import {
  IClientTriggerResponse,
  IClientMedicationResponse,
  IClientDeEscalationResponse,
  IClientSecondaryDiagnosisResponse,
  IClientSupportContanctResponse,
  IClientAllergyResponse,
  IClientMedicationDeliveryResponse,
  IClientAddressRequest,
  IClientPhone,
  IClientPhoneRequest,
  IClientTreatmentPlanResponse,
  IClientTreatmentPlanRequest,
} from '~/services/api/clientDetails/types';
import {
  ITriggerInitialValues,
  IMedicationInitialValues,
  IMedicationDeliveryInitialValues,
  IDeEscalationInitialValues,
  ISecondaryDiagnosisInitialValues,
  IPrimaryDiagnosisInitialValues,
  IAllergyInitialValues,
} from '~/ui/pages/Clients/reusable/HealthDetails/Forms/types';
import { IInitialValues as SupportContactInitialValues } from '~/ui/pages/Clients/reusable/SupportContacts/Form/types';
import { IAddressExtended } from '~/services/api/types';
import { IShelter, IShelterRequest } from '~/services/api/shelters/types';
import replaceAt from './text/replaceAt';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatDefaultValues = <T>(currentUser: any): T => {
  const { address } = currentUser;
  const { workDetails } = currentUser;
  const formattedValues = {
    ...currentUser,
    ...(currentUser.status === 'Pending'
      ? {
          email: currentUser.email.split('@')[0],
          emailDomain: `@${currentUser.email.split('@')[1]}`,
        }
      : {}),
    subRoleIds: currentUser.subRoles.map((subRole: any) => subRole.id),
    address: {
      ...address,
      stateId: address.state.id,
    },
    workDetails: {
      ...workDetails,
      credentialsId: workDetails.credentials.id,
      educationId: workDetails.education.id,
      employmentStatusId: workDetails.employmentStatus.id,
      hireDate: setNoon(workDetails.hireDate),
    },
  };
  if (currentUser.teams)
    return { ...formattedValues, teams: currentUser.teams.map((team: any) => team.id) };
  return formattedValues;
};

export const formatClientDefaultValues = (
  currentClient: IClientDetailed,
): IClientInitialValues => ({
  ...currentClient,
  phone:
    currentClient.phones.find(phone => phone.isPrimary)?.number || currentClient.phones[0].number,
  genderId: currentClient.gender.id,
  raceId: currentClient.race?.id,
  teamId: currentClient.actTeam.id,
  admissionDetails: {
    referralSourceId: currentClient.admissionDetails.referralSource.id,
    admissionDate: setNoon(currentClient.admissionDetails.admissionDate),
  },
  dateOfBirth: setNoon(currentClient.dateOfBirth),
  address: {
    ...currentClient.address,
    stateId: currentClient.address.state.id,
  },
  medicaIdRecertificationDate: currentClient.medicaIdRecertificationDate
    ? setNoon(currentClient.medicaIdRecertificationDate)
    : null,
});

export const formatTriggerDefaultValues = (
  currentTrigger: IClientTriggerResponse,
): ITriggerInitialValues => ({
  frequency: currentTrigger.frequency,
  hasAlerts: currentTrigger.hasAlerts,
  note: currentTrigger.note,
  notificationOn: currentTrigger.notificationOn,
  triggerDate: currentTrigger.triggerDate,
  triggerId: currentTrigger.trigger.id,
  teamMembersToNotify: currentTrigger.teamMembersToNotify.map(item => item.id),
  type: currentTrigger.type,
  time: currentTrigger.triggerDate ? new Date(currentTrigger.triggerDate) : null,
});

export const formatAddressDefaultValues = (
  currentAddress: IAddressExtended,
): IClientAddressRequest => ({
  address: {
    stateId: currentAddress.state.id,
    city: currentAddress.city,
    line1: currentAddress.line1,
    line2: currentAddress.line2,
    zipCode: currentAddress.zipCode,
    poBox: currentAddress.poBox,
    shelterName: currentAddress.shelterName,
  },
  typeId: currentAddress.type?.id,
  setAsPrimary: currentAddress.isPrimary,
  archivePrimary: true,
  shelterId: currentAddress.shelter?.id,
  isShelter: currentAddress.isShelterAddress,
});

export const formatPhoneDefaultValues = (currentPhone: IClientPhone): IClientPhoneRequest => ({
  number: currentPhone.number,
  typeId: currentPhone.type.id,
  setAsPrimary: currentPhone.isPrimary,
  archivePrimary: true,
});

export const formatMedicationDefaultValues = (
  currentMedication: IClientMedicationResponse,
): IMedicationInitialValues => ({
  id: currentMedication.medication.id,
  note: currentMedication.note,
  dose: currentMedication.dose,
  injectable: currentMedication.injectable,
  outsideFacility: currentMedication.outsideFacility,
  startDate: currentMedication.startDate
    ? replaceAt(currentMedication.startDate, 11, '12')
    : new Date().toISOString(),
  endDate: currentMedication.endDate ? replaceAt(currentMedication.endDate, 11, '12') : undefined,
});

export const formatMedicationDeliveryDefaultValues = (
  currentMedicationDelivery: IClientMedicationDeliveryResponse,
): IMedicationDeliveryInitialValues => ({
  id: currentMedicationDelivery.id,
  note: currentMedicationDelivery.note,
  notDelivered: currentMedicationDelivery.notDelivered,
  nonDeliveryReasonId: currentMedicationDelivery.nonDeliveryReason?.id,
  date: currentMedicationDelivery.date,
  nextDueDate: currentMedicationDelivery.nextDueDate,
  teamMemberId: currentMedicationDelivery.teamMember?.id,
  lastInjection: currentMedicationDelivery?.lastInjection,
  outsideFacility: currentMedicationDelivery?.outsideFacility,
  archiveMedication: currentMedicationDelivery?.isArchived,
  hasAlerts: currentMedicationDelivery?.hasAlerts,
  medicationIds: currentMedicationDelivery?.medicationIds,
  clientId: currentMedicationDelivery.client?.id,
  statusChangeLog: currentMedicationDelivery?.statusChangeLog,
  notificationOn: currentMedicationDelivery?.notificationOn,
  notificationTime: currentMedicationDelivery?.notificationTime
    ? destructHours(currentMedicationDelivery?.notificationTime)
    : null,
  teamMembersToNotify: currentMedicationDelivery?.teamMembersToNotify?.map(tm => tm.id) || [],
  time:
    currentMedicationDelivery.date && currentMedicationDelivery.id
      ? new Date(currentMedicationDelivery.date)
      : null,
});

export const formatDeEsclationDefaultValues = (
  currentDeEscalation: IClientDeEscalationResponse,
): IDeEscalationInitialValues => ({
  id: currentDeEscalation.deEscalationTechnique.id,
  note: currentDeEscalation.note,
});

export const formatAllergyDefaultValues = (
  currentAllergy: IClientAllergyResponse,
): IAllergyInitialValues => ({
  id: currentAllergy.allergy.id,
  note: currentAllergy.note,
});

export const formatSecondaryDiagnosisDefaultValues = (
  secondaryDiagnosis: IClientSecondaryDiagnosisResponse,
): ISecondaryDiagnosisInitialValues => ({
  id: secondaryDiagnosis.diagnosis.id,
  note: secondaryDiagnosis.note,
});

export const formatPrimaryDiagnosisDefaultValues = (
  primaryDiagnosis: IDiagnosis,
): IPrimaryDiagnosisInitialValues => ({
  id: primaryDiagnosis.diagnosis.id,
  note: primaryDiagnosis.note,
});

export const formatSupportContactDefaultValues = (
  supportContact: IClientSupportContanctResponse,
): SupportContactInitialValues & { hasAddress: boolean } => {
  const { address } = supportContact;

  return {
    firstName: supportContact.firstName,
    lastName: supportContact.lastName,
    phone: supportContact.phone,
    email: supportContact.email,
    relationshipId: supportContact.relationship.id,
    address: address
      ? {
          stateId: (address.state || {}).id,
          city: address.city,
          zipCode: address.zipCode,
          line1: address.line1,
          line2: address.line2,
        }
      : null,
    hasAddress: !!address,
  };
};

export const formatTreatmentPlanDefaultValues = (
  currentTreatmentPlan: IClientTreatmentPlanResponse,
): IClientTreatmentPlanRequest => ({
  caseManagerId: currentTreatmentPlan.caseManager?.id,
  effectiveDate: setNoon(currentTreatmentPlan.effectiveDate),
  nextReviewDate: setNoon(currentTreatmentPlan.nextReviewDate),
  goals: currentTreatmentPlan.goals.filter(goal => !goal.isArchived),
  setActive: !currentTreatmentPlan.active,
  typeId: currentTreatmentPlan.type?.id || null,
});

export const formatShelterDefaultValues = (shelter: IShelter): IShelterRequest | undefined =>
  shelter
    ? {
        name: shelter.name,
        phone: shelter.phone,
        address: {
          stateId: shelter.address.state.id,
          city: shelter.address.city,
          zipCode: shelter.address.zipCode,
          line1: shelter.address.line1,
          line2: shelter.address.line2,
        },
      }
    : undefined;

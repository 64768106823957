export enum SortByOption {
  'All',
  'Visited Person',
  'Type of Visit',
  'Date of Visit',
  'Visit Status',
}

export enum TypeOfVisit {
  'In Person Off Site',
  'In Person On Site',
  'Voice Call',
  'Video Call',
  'Hospital',
}

export const visitTypes = {
  InPerson: 'In Person Off Site',
  InPersonOnSite: 'In Person On Site',
  VoiceCall: 'Voice Call',
  VideoCall: 'Video Call',
  Hospital: 'Hospital',
};

export enum VisitedPerson {
  'Client',
  'Collateral Contact',
  'No Show',
  'Hospital Staff',
}

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import {
  IMedicationLogisticsActions,
  IMedicationLogisticsStore,
  IThunkAddMedicationDelivery,
} from '../types';

const onAddMedicationDelivery: IThunkAddMedicationDelivery = thunk(
  async (
    { setMedicationDeliveries }: Actions<IMedicationLogisticsActions>,
    payload,
    { getState },
  ) => {
    const localState = getState() as IMedicationLogisticsStore;
    const { requestInfo, requestPayload } = payload;

    const { data } = await api.medicationLogistics.addMedicationDelivery(
      requestInfo,
      requestPayload,
    );
    const deliveries = [...data, ...localState.list];

    setMedicationDeliveries({ deliveries, monitoredBy: localState.monitoredBy });
  },
);

export default onAddMedicationDelivery;

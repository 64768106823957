import { action } from 'easy-peasy';

import { IActionCacheDocumentMessage } from '../types';

const cacheDocumentMessage: IActionCacheDocumentMessage = action((state, payload) => {
  const { message, cachedFilePath } = payload || {};
  const { channelUrl } = message || {};

  state.messages = {
    ...state.messages,
    [channelUrl]: {
      ...state.messages[channelUrl],
      cachedFiles: {
        ...(state.messages[channelUrl]?.cachedFiles || {}),
        [message.messageId]: cachedFilePath,
      },
    },
  };
});

export default cacheDocumentMessage;

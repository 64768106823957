export enum VisitStatus {
  INITIATED = 'Initiated',
  SENT_TO_EHR = 'SentToEhr',
  FINALIZED = 'Finalized',
}

export enum VisitStatusFilterBy {
  'Initiated',
  'Sent to EHR',
  'Finalized',
}

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Input from '~/ui/components/inputs/Input';
import InputMask from '~/ui/components/inputs/InputMask';
import { extractErrorMessage } from '~/utils/error/error';
import validate from './validate';

import { IUpdateUser } from '~/services/api/auth/types';
import { PROFILE } from '~/ui/constants/paths';
import styles from './EditProfile.module.scss';

const EditProfile = (): ReactElement => {
  const navigate = useNavigate();

  const { firstName, lastName, email, phone } = useStoreState(state => state.user.current);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const setUser = useStoreActions(actions => actions.user.setUser);
  const defaultValues = { firstName, lastName, email, phone };

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<any>({ defaultValues, resolver: validate });

  const onSubmit = async (vals: IUpdateUser) => {
    try {
      await api.auth.updateCurrentUser(vals);
      const { data } = await api.auth.getCurrentUser();
      setUser(data);
      showNotify({ message: 'Profile successfully updated' });
      navigate(PROFILE);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <div>
      <h2>Edit Your Profile </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box sx={{ p: 2 }}>
            <p className={styles.subTitle}>Profile Details</p>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Input label="First Name" register={register} name="firstName" errors={errors} />
              </Grid>
              <Grid item sm={6}>
                <InputMask control={control} name="phone" label="Phone" errors={errors} />
              </Grid>
              <Grid item sm={6}>
                <Input label="Last Name" register={register} name="lastName" errors={errors} />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttonWrapper}>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => navigate(PROFILE)}
            >
              Cancel
            </Button>
          </div>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;

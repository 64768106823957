import { ReactElement } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { components, GroupTypeBase, IndicatorProps } from 'react-select';
import IconButton from '@material-ui/core/IconButton';

import { IOption } from '~/types';

const DropdownIndicator = (
  props: IndicatorProps<IOption, boolean, GroupTypeBase<IOption>>,
): ReactElement => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      <IconButton size="small" style={{ padding: 0 }}>
        {selectProps.menuIsOpen ? (
          <ExpandLessIcon color="secondary" />
        ) : (
          <ExpandMoreIcon color="secondary" />
        )}
      </IconButton>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;

import { IFormattedToxicologyParams } from '~/services/api/reports/types';
import { IToxicologyOverviewParams } from '~/services/api/types';
import { removeTime } from '~/utils/date/date';
import { TestingStatus } from '../../Visits/constants/toxicologyStatus';

const formatToxicologyParams = ({
  toxicologyStatus,
  bloodDrawnStatus,
  visitDateFrom,
  visitDateTo,
  ...params
}: IToxicologyOverviewParams): IFormattedToxicologyParams => ({
  ...params,
  toxicology: toxicologyStatus === TestingStatus.ALL ? undefined : toxicologyStatus,
  bloodDraw: bloodDrawnStatus === TestingStatus.ALL ? undefined : bloodDrawnStatus,
  'visitDate.from': removeTime(visitDateFrom),
  'visitDate.to': removeTime(visitDateTo),
});

export default formatToxicologyParams;

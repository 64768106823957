import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IMedicationLogisticsModel } from './types';

const medicationLogistics: IMedicationLogisticsModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default medicationLogistics;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetCurrentProfile, IUserActions } from '../types';

const onGetCurrentProfile: IThunkGetCurrentProfile = thunk(
  async ({ setUser }: Actions<IUserActions>) => {
    const user = await api.auth.getCurrentUser().then(response => response.data);
    setUser(user);
  },
);

export default onGetCurrentProfile;

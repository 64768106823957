import ClientStatus from '../models/clientStatus';

export const requiredStatusOptions = [
  { label: 'Hospitalized', value: ClientStatus.HOSPITALIZED },
  { label: 'Incarcerated', value: ClientStatus.INCARCERATED },
  { label: 'Diligent Search', value: ClientStatus.DILIGENT_SEARCH },
  { label: 'Rehabilitation', value: ClientStatus.REHABILITATION },
];

export const statusOptions = [
  { label: 'Active', value: ClientStatus.ACTIVE },
  ...requiredStatusOptions,
];

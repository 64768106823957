import { action } from 'easy-peasy';

import { IActionSetDemographicsAvailableColumns } from '../types';

const setDemographicsAvailableColumns: IActionSetDemographicsAvailableColumns = action(
  (state, payload) => {
    state.demographics.columns = payload;
  },
);

export default setDemographicsAvailableColumns;

import { ReactElement, useEffect, useMemo, useState } from 'react';
import Select, {
  ValueType,
  components,
  MenuListComponentProps,
  GroupTypeBase,
  OptionProps,
} from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';

import SelectAll from './components/SelectAll';
import Option from './components/Option';
import MultiValue from './components/MultiValue';
import MenuList from './components/MenuList';
import DropdownIndicator from './components/DropdownIndicator';
import ClearIndicator from './components/ClearIndicator';
import { MAX_SELECTED_TO_SHOW } from './components/MultiValue/MultiValue';
import customStylesAdditionally from './customStylesAdditionally';

import { IOption } from '../Select/types';
import customStyles from '../Select/customStyles';
import styles from '../Select/Select.module.scss';

type IValue = string | number;
type IsMulti = boolean;

interface IProps {
  control: Control<any>;
  selected?: IValue[];
  errors: FieldErrors;
  name: string;
  options: IOption[];
  label?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  maxMenuHeight?: number;
  minMenuHeight?: number;
  isMulti?: boolean;
  placeholder?: string;
  color?: string;
  disableMenu?: boolean;
  minHeight?: string;
  isRelativeWindow?: boolean;
  hideSelectedOptions?: boolean;
  openMenuOnFocus?: boolean;
  showCustomComponents?: boolean;
  closeMenuOnSelect?: boolean;
  showSelectAll?: boolean;
  oneLineMulti?: boolean;
  isNarrow?: boolean;
  tabIndex?: number;
  customComponents?: Partial<SelectComponents<IOption, boolean, GroupTypeBase<IOption>>>;
  CustomOption?: (
    optionProps: OptionProps<IOption, boolean, GroupTypeBase<IOption>>,
  ) => ReactElement;
  additionalStyleHandler?: () => any;
  setAll?: (all?: boolean) => void;
  onInputChange?: (value: string) => void;
  onSelect?: (value: ValueType<IOption | IOption[], IsMulti>) => void;
}

const SelectComponent = ({
  control,
  selected,
  errors,
  name,
  options: allOptions,
  label,
  isDisabled = false,
  disableMenu,
  isLoading = false,
  isMulti = false,
  isSearchable = true,
  isClearable = false,
  maxMenuHeight = 200,
  minMenuHeight,
  placeholder = '',
  color = 'black',
  minHeight,
  tabIndex,
  isRelativeWindow = true,
  hideSelectedOptions = true,
  openMenuOnFocus = false,
  showCustomComponents = false,
  closeMenuOnSelect = true,
  showSelectAll = false,
  oneLineMulti = false,
  isNarrow = false,
  customComponents,
  CustomOption,
  additionalStyleHandler = () => {},
  setAll,
  onInputChange,
  onSelect,
}: IProps): ReactElement => {
  // cut multi select options up to MAX_SELECTED, in order to show only MAX_SELECTED items
  const getFilteredOptions = () => {
    const selectedOptions = { ...selected };
    const hiddenOptions =
      selectedOptions.length > MAX_SELECTED_TO_SHOW
        ? selectedOptions.slice(0, MAX_SELECTED_TO_SHOW)
        : [];
    return allOptions.filter(x => !hiddenOptions.includes(Number(x.value)));
  };

  const options = isMulti && oneLineMulti ? getFilteredOptions() : allOptions;

  // eslint-disable-next-line no-underscore-dangle
  const selectedValues = control._formValues?.[name]?.length;

  const isAllSelected = useMemo(
    () => allOptions?.length && allOptions?.length === selectedValues,
    [allOptions?.length, selectedValues],
  );

  const [selectAll, setSelectAll] = useState(false);
  const [skip, setSkip] = useState(true);
  const [trigger, setTrigger] = useState(false);
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  const formatValue = (option: ValueType<IOption | IOption[], IsMulti>) => {
    const value = isMulti
      ? (option as IOption[]).map((item: IOption) => item.value)
      : (option as IOption)?.value ?? '';
    return value;
  };

  const getValue = (value: IValue | IValue[]): IOption | IOption[] => {
    if (options) {
      return isMulti && Array.isArray(value)
        ? options.filter(option => value.indexOf(option.value) >= 0)
        : options.find(option => option.value === value) || ('' as any);
    }

    return isMulti ? [] : ('' as any);
  };

  const handleChange = (option: ValueType<IOption | IOption[], IsMulti>) => {
    if (Array.isArray(option)) {
      if (option.length === 0) {
        setSelectAll(false);
      } else {
        setSkip(true);
        setTrigger(!trigger);
        setSelectAll(false);
      }
    }
    onSelect?.(option);
    return formatValue(option);
  };

  const mergedStyles = {
    ...customStyles,
    ...customStylesAdditionally(
      options,
      maxMenuHeight,
      error as string,
      minHeight,
      isRelativeWindow,
      color,
      showSelectAll,
      isNarrow,
      isAllSelected,
    ),
    ...additionalStyleHandler(),
  };
  const labelStyles = classNames(styles.label, { [styles.labelError]: error });

  // selecting custom dropdown header component
  const MenuHeader = useMemo(
    () =>
      showSelectAll
        ? (optionProps: MenuListComponentProps<IOption, boolean, GroupTypeBase<IOption>>) => (
            <SelectAll {...optionProps} selectAll={selectAll} setSelectAll={setSelectAll} />
          )
        : MenuList,
    [showSelectAll, selectAll],
  );

  useEffect(() => {
    if (skip) {
      setSkip(false);
      return;
    }
    if (selectAll) setAll?.(true);
    else setAll?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll, trigger]);

  // mark checkbox as selected if all items selected
  useEffect(() => {
    if (isAllSelected) {
      setSelectAll(true);
    }
  }, [isAllSelected]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormControl component="fieldset" className={styles.selectControl}>
          <FormLabel component="legend" className={labelStyles} error={isError}>
            {label}
          </FormLabel>
          <Select
            tabSelectsValue={false}
            instanceId={name}
            tabIndex={String(tabIndex)}
            styles={mergedStyles}
            isDisabled={isDisabled}
            closeMenuOnSelect={closeMenuOnSelect}
            isLoading={isLoading}
            openMenuOnFocus={openMenuOnFocus}
            isSearchable={isSearchable}
            hideSelectedOptions={hideSelectedOptions}
            maxMenuHeight={maxMenuHeight}
            minMenuHeight={minMenuHeight}
            name={name}
            components={{
              Option: CustomOption || (showCustomComponents ? Option : components.Option),
              DropdownIndicator,
              ClearIndicator,
              MenuList: showCustomComponents ? MenuHeader : components.MenuList,
              MultiValue: isMulti && oneLineMulti ? MultiValue : components.MultiValue,
              ...(disableMenu
                ? {
                    Menu: function hideMenu() {
                      return <></>;
                    },
                  }
                : {}),

              ...(customComponents || {}),
            }}
            menuShouldScrollIntoView
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            value={getValue(value)}
            onChange={val => onChange(handleChange(val))}
            onInputChange={onInputChange}
            onBlur={() => onBlur()}
            menuPortalTarget={document.body}
            isClearable={isClearable}
          />
          {error && (
            <FormHelperText error className={styles.error}>
              {error.toString()}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectComponent;

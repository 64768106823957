import { IToxicology } from '../components/AddVisit/types';
import { TestingStatus } from '../constants/toxicologyStatus';
import { isoToLocal, setNoon } from '~/utils/date/date';

const extractToxicologyString = (data?: IToxicology | null): string => {
  if (!data) {
    return '';
  }

  if (data?.status === TestingStatus.DONE) {
    return `Status: ${data.status}, Result: ${data.result}, Result Date: ${isoToLocal(
      setNoon(data.date),
    )}`;
  }
  return `Status: ${data.status}`;
};

export default extractToxicologyString;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { setToxicologyFilter } from '~/services/localStorage/filters';
import { IReportsActions, IReportsStore, IThunkGetToxicologyOverviewReport } from '../types';

const onGetToxicologyOverviewReport: IThunkGetToxicologyOverviewReport = thunk(
  async (
    { setToxicologyOverviewReport }: Actions<IReportsActions>,
    { requestInfo, params },
    { getState },
  ) => {
    const localState = getState() as IReportsStore;

    const { list: items } = localState.toxicology;

    const toxicology = await api.reports
      .getToxicologyOverviewReport(requestInfo, params)
      .then(r => r.data);

    setToxicologyFilter(params);

    setToxicologyOverviewReport({
      list: params.pageNumber === 1 ? toxicology.items : [...items, ...toxicology.items],
      pagination: {
        total: toxicology.total,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      },
    });
  },
);

export default onGetToxicologyOverviewReport;

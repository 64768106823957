import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnagClient = Bugsnag.createClient({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  collectUserIp: false,
  releaseStage: process.env.environment,
  plugins: [new BugsnagPluginReact()],
});

export default bugsnagClient;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { setClientsFilter } from '~/services/localStorage/filters';
import { IClientActions, IThunkGetTeamClients } from '../types';

const onGetTeamClients: IThunkGetTeamClients = thunk(
  async ({ setClients }: Actions<IClientActions>, payload) => {
    const { clinicId, teamId, params } = payload;

    const { items, total } = await api.client
      .getTeamClients(clinicId, teamId, params)
      .then(r => r.data);

    const pagination = { ...params, total };
    const filters = {
      name: params.name,
      status: params.status,
      sorting: Number(params.sorting || 0),
      teamIds: [] as number[],
    };

    // save filters to storage
    setClientsFilter(filters);

    setClients({ items, pagination, filters });
  },
);

export default onGetTeamClients;

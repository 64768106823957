import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IReleaseModel } from './types';

const release: IReleaseModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default release;

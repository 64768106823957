import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkChangeClientStatus } from '../types';

const onChangeClientStatus: IThunkChangeClientStatus = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { requestInfo, status } = payload;

    const { data } = await api.clientDetails.changeClientStatus({ ...requestInfo, status });

    setClientDetails({ status: data });
  },
);

export default onChangeClientStatus;

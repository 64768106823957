import { ReactNode } from 'react';

import AcceptableResourceMimeTypes from '../models/AcceptableResourceMimeTypes';

import { ReactComponent as DocumentIcon } from '~/ui/assets/images/fileType/document.svg';
import { ReactComponent as ExcelIcon } from '~/ui/assets/images/fileType/excel.svg';
import { ReactComponent as ImageIcon } from '~/ui/assets/images/fileType/image.svg';
import { ReactComponent as PdfIcon } from '~/ui/assets/images/fileType/pdf.svg';
import { ReactComponent as PresentationIcon } from '~/ui/assets/images/fileType/presentation.svg';
import { ReactComponent as TextIcon } from '~/ui/assets/images/fileType/text.svg';

const getFileTypeIconByMimeType = (type: AcceptableResourceMimeTypes): ReactNode => {
  switch (type) {
    case AcceptableResourceMimeTypes.Pdf: {
      return <PdfIcon />;
    }
    case AcceptableResourceMimeTypes.Xls:
    case AcceptableResourceMimeTypes.Xlsx: {
      return <ExcelIcon />;
    }
    case AcceptableResourceMimeTypes.Docx:
    case AcceptableResourceMimeTypes.Doc: {
      return <DocumentIcon />;
    }
    case AcceptableResourceMimeTypes.Txt: {
      return <TextIcon />;
    }
    case AcceptableResourceMimeTypes.Png:
    case AcceptableResourceMimeTypes.Jpeg: {
      return <ImageIcon />;
    }
    case AcceptableResourceMimeTypes.Ppt:
    case AcceptableResourceMimeTypes.Pptx: {
      return <PresentationIcon />;
    }
    default: {
      return <TextIcon />;
    }
  }
};

export default getFileTypeIconByMimeType;

import { ThemeProvider } from '@material-ui/styles';
import { useStoreRehydrated } from 'easy-peasy';
import { ReactElement } from 'react';

import SignalRContainer from '~/services/socket';
import { useStoreActions } from '~/store/hooks';
import BugsnagClient from '../utils/error/bugsnag';

import Routes from './Routes';
import Loader from './components/common/Loader';
import SignalRSOS from './pages/Notifications/components/SignalRSOS';
import muiTheme from './themes/muiTheme';
import ReleaseAlert from './pages/Notifications/components/ReleaseAlert';
import SystemAlert from './pages/Notifications/components/SystemAlerts/components/SystemAlert';
import useGlobalError from '~/utils/error/useGlobalError';

import './assets/styles/app.scss';

BugsnagClient.startSession();

const App = (): ReactElement => {
  const isRehydrated = useStoreRehydrated();
  useGlobalError();
  const onCheckAuthorization = useStoreActions(actions => actions.user.onCheckAuthorization);
  onCheckAuthorization();

  return (
    <SignalRContainer>
      <ThemeProvider theme={muiTheme}>
        <>
          {isRehydrated ? <Routes /> : <Loader />}
          <SignalRSOS.SignalRSOSAlert />
          <ReleaseAlert />
          <SystemAlert />
        </>
      </ThemeProvider>
    </SignalRContainer>
  );
};
export default App;

import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { IClinicParams, IClinic, ICurrentClinic } from './types';
import { IDictionary } from '../dictionaries/types';

export const getClinics = (
  params?: IClinicParams,
): IResponse<{ total: number; items: IClinic[] }> =>
  privateAxios({
    method: 'get',
    url: 'Clinic/list',
    params,
  });

export const getAllClinics = (): IResponse<IDictionary[]> =>
  privateAxios({
    method: 'get',
    url: `Clinic/available-list`,
  });

export const getClinic = (clinicId: string): IResponse<ICurrentClinic> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}`,
  });

export const getMyClinic = (): IResponse<ICurrentClinic> =>
  privateAxios({
    method: 'get',
    url: 'Clinic/my',
  });

export const addClinic = (data: ICurrentClinic): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: 'Clinic',
    data,
  });

export const archiveClinic = (clinicId: number): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}`,
  });

export const restoreClinic = (clinicId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/recover`,
  });

export const updateClinic = (clinicId: number, data: ICurrentClinic): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}`,
    data,
  });

export default {};

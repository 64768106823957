import { ReactElement } from 'react';

import styles from './Input.module.scss';

interface IProps {
  name: string;
  value: any;
  handleChange: (event: any) => void;
  handleKeyDown: (event: any) => void;
}

const Input = ({ handleKeyDown, handleChange, name, value }: IProps): ReactElement => (
  <input
    name={name}
    onChange={handleChange}
    onKeyDown={handleKeyDown}
    className={styles.input}
    maxLength={1}
    value={value[name] || ''}
  />
);

export default Input;

const extractDateFromString = (date: string, joinWith = '/', iso?: boolean): string => {
  let dateString = [date.substring(5, 7), date.substring(8, 10), date.substring(0, 4)];

  if (iso) {
    dateString = [date.substring(0, 4), date.substring(5, 7), date.substring(8, 10)];
  }
  return dateString.join(joinWith);
};

export default extractDateFromString;

import { IClientStatus } from '~/services/api/types';
import ClientStatus from '../../Clients/models/clientStatus';

const normalizeClientStatusDates = (
  prev: IClientStatus[],
  current: IClientStatus,
): IClientStatus[] =>
  prev
    .reduce((acc, obj, index) => {
      if (!index) {
        acc.push({
          ...obj,
          dateOfEntry: obj?.dateOfEntry,
          dateOfRelease:
            current?.dateOfRelease ||
            current?.dateOfEntry ||
            prev[0]?.dateOfEntry ||
            prev[0]?.dateOfRelease,
        });
      } else {
        acc.push({
          ...obj,
          dateOfEntry: obj.dateOfEntry,
          dateOfRelease:
            obj.dateOfRelease || prev[index - 1]?.dateOfEntry || prev[index - 1]?.dateOfRelease,
        });
      }

      return acc;
    }, [])
    .filter(item => item.value !== ClientStatus.ACTIVE)
    .sort(
      (a, b) =>
        Number(b.dateOfRelease.replaceAll('-', '')) - Number(a.dateOfRelease.replaceAll('-', '')),
    );

export default normalizeClientStatusDates;

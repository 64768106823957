import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, ref } from 'yup';

import { requiredField } from '~/ui/constants/errorMessages';

export const passwordValidation = string()
  .required(requiredField)
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    'Password must contain number, upper/lowercase, special characters and 8 characters length',
  );

const validationSchema = object().shape({
  oldPassword: passwordValidation,
  newPassword: passwordValidation,
  passwordConfirmation: string().oneOf(
    [ref('newPassword'), null],
    'The password entry fields do not match. Please enter the same password in both fields and try again',
  ),
});

export default yupResolver(validationSchema);

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ISuperAdminActions, IThunkGetSuperAdmins } from '../types';

const onGetSuperAdmins: IThunkGetSuperAdmins = thunk(
  async ({ setSuperAdmins }: Actions<ISuperAdminActions>, payload) => {
    const { items, total } = await api.superAdmin.getSuperAdmins(payload).then(r => r.data);
    const pagination = { ...payload, total };

    setSuperAdmins({ items, pagination });
  },
);

export default onGetSuperAdmins;

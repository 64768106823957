import { isoToFormat, setNoon } from '~/utils/date/date';
import { IBloodDrawn } from '../components/AddVisit/types';
import { TestingStatus } from '../constants/toxicologyStatus';

const extractBloodDrawString = (data?: IBloodDrawn | null): string => {
  if (!data) {
    return '';
  }

  if (data?.status === TestingStatus.DONE) {
    return `Status: ${data.status}, Draw Date: ${isoToFormat(setNoon(data.date))}`;
  }
  return `Status: ${data.status}`;
};

export default extractBloodDrawString;

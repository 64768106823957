import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetDeEscalationTechniques } from '../types';

const onGetDeEscalationTechniques: IThunkGetDeEscalationTechniques = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const deEscalationTechniques = await api.clientDetails
      .getClientDeEscalationTechniqueList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ deEscalationTechniques });
  },
);

export default onGetDeEscalationTechniques;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetRecertificationReports } from '../types';
import { setRecertificationReportFilter } from '~/services/localStorage/filters';

const onGetRecertificationReports: IThunkGetRecertificationReports = thunk(
  async ({ setRecertificationReport }: Actions<IReportsActions>, { requestInfo, params }) => {
    const { clinicId, date } = requestInfo;

    const { data: recertificationReports } = await api.reports.getRecertificationReports(
      { clinicId, date },
      params,
    );

    setRecertificationReportFilter({
      teamIds: params.teamIds,
      reportDate: date,
      orBy: params.daysTillExpiration,
      paperworkStatus: params.paperworkStatus,
    });

    setRecertificationReport({ recertification: recertificationReports });
  },
);

export default onGetRecertificationReports;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions } from '~/store/clientDetails/types';
import { IClientActions, IThunkGetClient } from '../types';

const onGetClient: IThunkGetClient = thunk(
  async (
    {
      setClient,
      setClientDetails,
      setDefaultClient,
    }: Actions<IClientActions & Pick<IClientDetailsActions, 'setClientDetails'>>,
    payload,
  ) => {
    const { clinicId, teamId, clientId } = payload;

    const { data } = await api.client.getClient(clinicId, teamId, clientId);

    setClient(data);
    setClientDetails({
      phones: data.phones,
      memo: data.memo,
      status: { current: data.status, previous: [] },
    });
    if (!data?.isArchived) {
      setDefaultClient(data);
    }
  },
);

export default onGetClient;

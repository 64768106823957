import { action } from 'easy-peasy';

import { IActionSetClientLastContactOverviewReport } from '../types';

const setClientLastContactOverviewReport: IActionSetClientLastContactOverviewReport = action(
  (state, payload) => {
    const { list, pagination } = payload;

    if (list) {
      state.clientLastContact.list = list;
    }
    if (pagination) {
      state.clientLastContact.pagination = pagination;
    }
  },
);

export default setClientLastContactOverviewReport;

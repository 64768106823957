import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetTeamMemberDashboardReport } from '../types';

const onGetTeamMemberDashboardReport: IThunkGetTeamMemberDashboardReport = thunk(
  async ({ setTeamMemberDashboardReport }: Actions<IReportsActions>, params, { getState }) => {
    const localState = getState() as IReportsStore;

    const data = await api.reports.getTeamMemberDashboardReport(params).then(r => r.data);

    setTeamMemberDashboardReport({ ...data, filters: localState.teamMemberDashboard.filters });
  },
);

export default onGetTeamMemberDashboardReport;

import { IClientTreatmentPlanResponse } from '~/services/api/clientDetails/types';
import { IGroupedTreatmentPlans } from './types';

export default (arr: IClientTreatmentPlanResponse[]): IGroupedTreatmentPlans =>
  arr.reduce(
    (acc, obj) => {
      if (obj.active && obj.isArchived) {
        return {
          ...acc,
          inactive: [...acc.inactive, obj],
        };
      }
      if (obj.active && !obj.isArchived) {
        return {
          ...acc,
          active: [...acc.active, obj],
        };
      }
      if (!obj.active && !obj.isArchived) {
        return {
          ...acc,
          draft: [...acc.draft, obj],
        };
      }
      return acc;
    },
    { inactive: [], active: [], draft: [] },
  );

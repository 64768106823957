import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { addMonths, format, subMonths } from 'date-fns';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { useStoreState } from '~/store/hooks';

import arrowBackIcon from '~/ui/assets/images/arrowBack.svg';
import styles from './MonthControls.module.scss';

interface IProps {
  selectedMonth: Date;
  prefix?: string;
  postfix?: string;
  noClient?: boolean;
  setSelectedMonth?: (date: Date) => void | Dispatch<SetStateAction<Date>>;
}

const MonthControls = ({
  selectedMonth,
  prefix,
  postfix,
  noClient = false,
  setSelectedMonth,
}: IProps): ReactElement => {
  const { current } = useStoreState(state => state.client);

  const nextMonth = () => setSelectedMonth?.(addMonths(selectedMonth, 1));
  const prevMonth = () => setSelectedMonth?.(subMonths(selectedMonth, 1));

  if (!current && !noClient) return null;

  return (
    <Box className={styles.monthControls}>
      <div className={classNames(styles.label, styles.bold)}>
        {prefix ? `${prefix} - ` : ''} {format(selectedMonth, 'MMM')}{' '}
        {postfix ? ` - ${postfix}` : ''}
      </div>
      <div className={styles.label}>{format(selectedMonth, 'YYY')}</div>
      <div className={styles.controls}>
        <IconButton onClick={prevMonth}>
          <img src={arrowBackIcon} alt="Previous month" />
        </IconButton>
        <IconButton onClick={nextMonth}>
          <img src={arrowBackIcon} alt="Next month" className={styles.reflected} />
        </IconButton>
      </div>
    </Box>
  );
};

export default MonthControls;

import { IClientAllergy, IClientAllergyResponse } from '~/services/api/clientDetails/types';

export default (allergy: IClientAllergyResponse): IClientAllergy => ({
  id: allergy.id,
  note: allergy.note,
  allergy: {
    id: allergy.allergy.id,
    name: allergy.allergy.name,
  },
  isArchived: false,
});

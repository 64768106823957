import { useState, ReactElement, useEffect, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';

import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';
import Input from '~/ui/components/inputs/Input';
import SimpleSelect from '~/ui/pages/MedicationLogistics/components/SimpleSelect';
import { extractErrorMessage } from '~/utils/error/error';
import formatActTeamsOptions from '~/utils/formatActTeamsOptions';

import styles from './AssignUserModal.module.scss';
import { IFormValues } from './types';
import TeamMembersInfinityList from '../TeamMembersInfinityList/TeamMembersInfinityList';
import { IUserRole } from '~/types';
import removeSpaces from '~/utils/text/removeSpaces';
import compareRoles from '~/utils/compareRoles';

interface IProps {
  modalTitle: string;
  role: string;
  onClose: () => void;
  refetchUsers?: () => void;
}

type IParams = {
  id?: string;
  actTeamId?: string;
};

const AssignUserModal = ({ modalTitle, role, onClose, refetchUsers }: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [teamId, setTeamId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  const { id: currentClinicId, actTeamId } = useParams<IParams>();

  const {
    register: registerModal,
    handleSubmit: handleSubmitModal,
    watch,
    formState: { errors: errorsModal },
  } = useForm();

  const { searchUser } = watch();

  const user = useStoreState(state => state.user.current);
  const { availableActTeams, current } = useStoreState(state => state.actTeam);

  const { onGetActTeamMembers, onAssignTeamMember } = useStoreActions(
    actions => actions.actTeamMember,
  );
  const { onAssignLocalAdmin } = useStoreActions(actions => actions.localAdmin);
  const { onAssignProgramAssistant } = useStoreActions(actions => actions.programAssistant);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeamsAvailable = useStoreActions(actions => actions.actTeam.onGetActTeamsAvailable);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const clinicId = currentClinicId || String(user?.clinic?.id);

  const actTeamOptions = formatActTeamsOptions(availableActTeams);

  const isLocalAdminRole = compareRoles(IUserRole.LocalAdmin, role);
  const isProgramAssistantRole = compareRoles(IUserRole.ProgramAssistant, role);

  const onSubmit = async (values: IFormValues) => {
    const payload = {
      clinicId,
      actTeamId: String(actTeamId),
      params: { ...values },
    };
    await onGetActTeamMembers(payload);
  };

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      if (!currentClinicId) {
        await onGetMyClinic();
      }
      await onGetActTeamsAvailable({ clinicId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [clinicId, currentClinicId, onGetActTeamsAvailable, onGetMyClinic, showError]);

  const onAssign = async () => {
    try {
      switch (role) {
        case removeSpaces(IUserRole.ActTeamMember): {
          await onAssignTeamMember({
            clinicId: parseInt(clinicId, 10),
            teamId: current?.id || null,
            userId,
            role,
          });
          showNotify({ message: `${IUserRole.ActTeamMember} successfully assigned` });
          break;
        }
        case removeSpaces(IUserRole.LocalAdmin): {
          await onAssignLocalAdmin({
            clinicId,
            localAdminId: userId,
          });
          showNotify({ message: `${IUserRole.LocalAdmin} successfully assigned` });
          break;
        }
        case removeSpaces(IUserRole.ProgramAssistant): {
          await onAssignProgramAssistant({
            clinicId,
            teamId,
            programAssistantId: userId,
          });
          showNotify({ message: `${IUserRole.ProgramAssistant} successfully assigned` });
          break;
        }
        default: {
          return;
        }
      }

      onClose();
      refetchUsers?.();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Modal className={styles.modal} open onClose={onClose}>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.modalWrapper}>
          <h3 className={styles.modalTitle}>{modalTitle}</h3>

          {!!actTeamOptions.length && !isLocalAdminRole && (
            <div className={styles.selectContainer}>
              <SimpleSelect
                label="Select Team"
                value={teamId}
                setValue={setTeamId}
                options={actTeamOptions}
              />
            </div>
          )}
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmitModal(onSubmit);
            }}
          >
            <Input
              startAdornment={<SearchIcon color="primary" />}
              placeholder="Search user"
              register={registerModal}
              name="searchUser"
              errors={errorsModal}
            />
          </form>
          <TeamMembersInfinityList
            teamId={teamId}
            assignTeamId={parseInt(actTeamId, 10)}
            clinicId={parseInt(clinicId, 10)}
            searchQuery={searchUser}
            selectedUserId={userId}
            setSelectedUserId={setUserId}
            role={role}
            noOptionsText={
              isProgramAssistantRole && !teamId ? 'Select Team for assignment' : undefined
            }
          />
          <div className={styles.actionButtons}>
            <Button color="primary" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onAssign} disabled={userId < 0}>
              Assign User
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AssignUserModal;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamActions, IThunkGetClinicActTeams } from '../types';

const onGetClinicActTeams: IThunkGetClinicActTeams = thunk(
  async ({ setActTeams }: Actions<IActTeamActions>, payload) => {
    const { clinicId, params } = payload;

    const { items, total } = await api.actTeam
      .getClinicActTeams(clinicId, params)
      .then(r => r.data);

    const pagination = { ...params, total };

    setActTeams({ items, pagination });
  },
);

export default onGetClinicActTeams;

import { TestingStatus, ToxicologyResult } from './toxicologyStatus';

export const testingStatusOptions = [
  { label: 'Done', value: TestingStatus.DONE },
  { label: 'Refused', value: TestingStatus.REFUSED },
  {
    label: 'Requested',
    value: TestingStatus.REQUESTED,
  },
];

export const testingStatusReportOptions = [
  { label: 'All', value: TestingStatus.ALL },
  ...testingStatusOptions,
];

export const toxicologyResultOptions = [
  { label: 'Positive', value: ToxicologyResult.POSITIVE },
  { label: 'Negative', value: ToxicologyResult.NEGATIVE },
];

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamActions, IThunkGetAllActTeams } from '../types';

const onGetAllActTeams: IThunkGetAllActTeams = thunk(
  async ({ setActTeams }: Actions<IActTeamActions>, params) => {
    const { items, total } = await api.actTeam.getAllActTeams(params).then(r => r.data);

    const pagination = { ...params, total };

    setActTeams({ items, pagination });
  },
);

export default onGetAllActTeams;

import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Loader from '~/ui/components/common/Loader';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import useRole from '~/store/user/hooks/useRole';
import { setNoon } from '~/utils/date/date';
import { extractErrorMessage } from '~/utils/error/error';
import formatActTeamMemberOptions from '~/utils/formatActTeamMemberOptions';
import { draftTreatmentPlanValidationSchema, treatmentPlanValidationSchema } from './validates';

import { IClientTreatmentPlanRequest } from '~/services/api/clientDetails/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/types';
import { ReactComponent as DeleteIcon } from '~/ui/assets/images/delete.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { IConfirmationDetails, IDetails } from '../types';
import styles from './Styles.module.scss';
import { IRequestInfo, IValidationError } from './types';

interface IProps {
  defaultValues: IClientTreatmentPlanRequest;
  details: IDetails | null;
  requestInfo: IRequestInfo;
  setModalTitle: (v: any) => void;
  setDetails: (v: any) => void;
}

interface IOptions {
  teamMembers: IOption[];
  planTypes: IOption[];
}

const emptyGoal = { id: 0, text: '', objectives: [{ id: 0, text: '' }] };

const AddTreatmentPlan = ({
  defaultValues,
  details,
  requestInfo,
  setModalTitle,
  setDetails,
}: IProps): ReactElement => {
  const [options, setOptions] = useState<IOptions>({} as IOptions);
  const [loading, setLoading] = useState(false);
  const [detailsInfo, setDetailsInfo] = useState<IConfirmationDetails | null>(null);

  const { isActTeamMember } = useRole();

  const {
    control,
    formState: { errors },
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
    clearErrors,
  } = useForm<IClientTreatmentPlanRequest>({
    defaultValues: {
      nextReviewDate: null,
      effectiveDate: null,
      ...defaultValues,
      goals: defaultValues?.goals.length ? defaultValues?.goals : [emptyGoal],
    },
    resolver: treatmentPlanValidationSchema,
  });

  const { treatmentPlans } = useStoreState(state => state.clientDetails);
  const { id: userId } = useStoreState(state => state.user.current);

  const currentTreatmentPlan = treatmentPlans.find(plan => plan.id === details?.id);

  const isActiveTreatmentPlan = currentTreatmentPlan?.active && !currentTreatmentPlan?.isArchived;

  const draftTreatmentPlan = treatmentPlans.find(plan => !plan.active && !plan.isArchived);
  const activeTreatmentPlan = treatmentPlans.find(plan => plan.active && !plan.isArchived);

  // case manager in active plan
  const isCaseManager = useMemo(
    () => activeTreatmentPlan?.caseManager?.id === userId || false,
    [userId, activeTreatmentPlan],
  );

  const isDraftCaseManager = useMemo(
    () => draftTreatmentPlan?.caseManager?.id === userId || false,
    [userId, draftTreatmentPlan],
  );

  const isEditingActivePlan = details?.id === activeTreatmentPlan?.id;

  // hook from react-hook-form for dynamic form update
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'goals',
  });

  const { goals, effectiveDate } = watch();

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const {
    onCreateTreatmentPlan,
    onUpdateTreatmentPlan,
    onUpdateOwnTreatmentPlan,
    onActivateOwnTreatmentPlan,
  } = useStoreActions(actions => actions.clientDetails);
  const setCurrent = useStoreActions(actions => actions.clientDetails.setCurrent);

  // fetch team members on mount for select component
  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const teamMembers = await api.actTeamMember
        .getActTeamMemberList(requestInfo.clinicId, requestInfo.teamId)
        .then(r => formatActTeamMemberOptions(r.data, true));
      const planTypes = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.TreatmentPlanType)
        .then(r => formatDictionaryOptions(r.data));

      setOptions({ teamMembers, planTypes });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [requestInfo.clinicId, requestInfo.teamId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  // submit form and perform save/update action
  const onSubmit = async (vals: IClientTreatmentPlanRequest, evt: any) => {
    const { name } = evt.nativeEvent.submitter;
    const dates = {
      nextReviewDate: vals.nextReviewDate || null,
      effectiveDate: vals.effectiveDate || null,
    };

    try {
      if (details && details.id && !details.duplicate) {
        const normalizedGoals = fields
          .map((field, index) => ({
            ...field,
            id: goals[index].id || 0,
            text: goals[index].text,
            objectives: goals[index].objectives,
          }))
          .filter(field => field.text.length);
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload: { ...vals, ...dates, goals: normalizedGoals, setActive: !name },
        };

        if (isActTeamMember) {
          await onUpdateOwnTreatmentPlan(payload);
          if (!name && !isEditingActivePlan) {
            await onActivateOwnTreatmentPlan({ ...requestInfo, id: String(details.id) });
          }
        } else {
          await onUpdateTreatmentPlan(payload);
        }
      } else {
        const payload = {
          requestInfo,
          requestPayload: { ...vals, ...dates, setActive: !name },
        };

        await onCreateTreatmentPlan(payload);
        reset({ effectiveDate: null, caseManagerId: null, nextReviewDate: null, goals: [] });
      }

      setModalTitle(null);
      setDetails(null);
      setCurrent(null);

      const type = details?.id && !details.duplicate ? 'updated' : 'added';

      showNotify({ message: `Treatment plan successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const handleSaveAsDraft = async () => {
    clearErrors();
    const data = getValues();

    try {
      await draftTreatmentPlanValidationSchema.validate(data, { abortEarly: false });

      const submitOptions = { nativeEvent: { submitter: { name: 'Save as Draft' } } };

      if (draftTreatmentPlan) {
        setDetailsInfo({
          description:
            'Your draft will be replaced with this treatment plan. Would you like to proceed?',
          onConfirm: () => onSubmit(data, submitOptions),
        });
      } else {
        onSubmit(data, submitOptions);
      }
    } catch (error) {
      // loop over all errors and setError to use them in react-hook-form
      (error as { inner: IValidationError[] }).inner?.map(
        (inner: IValidationError, index: number) => {
          const { type, path, errors: formErrors } = inner;
          return setError(path, { type, message: formErrors[index] });
        },
      );
    }
  };

  // we need to get user confirmation in case we replace active or draft plans
  const confirmAction = (...rest: [vals: IClientTreatmentPlanRequest, evt: any]) => {
    const { name } = rest[1].nativeEvent.submitter;
    let description = '';
    if (name && draftTreatmentPlan) {
      description =
        'Your draft will be replaced with this treatment plan. Would you like to proceed?';
      setDetailsInfo({ description, onConfirm: () => onSubmit(...rest) });
    } else if (!name && activeTreatmentPlan) {
      description =
        'Your active treatment plan will be replaced with this one. Would you like to proceed?';
      setDetailsInfo({ description, onConfirm: () => onSubmit(...rest) });
    } else {
      onSubmit(...rest);
    }
  };

  // add objective empty input to form
  const onAddObjectiveInput = (goalIndex: number) => {
    const updatedGoals = [...goals];
    const updatedObjectives = [...updatedGoals[goalIndex].objectives, { text: '', id: 0 }];
    updatedGoals[goalIndex] = { ...updatedGoals[goalIndex], objectives: updatedObjectives };

    setValue('goals', updatedGoals);
  };

  // add empty goal group to form
  const onAddGoalInput = () => {
    append(emptyGoal);
  };

  // remove goal group to form
  const onRemoveGoalGroup = (goalIndex: number) => {
    remove(goalIndex);
  };

  // remove objective input to form
  const onRemoveObjectiveInput = (goalIndex: number, objectiveIndex: number) => {
    const updatedGoals = [...goals];
    const updatedObjectives = [
      ...updatedGoals[goalIndex].objectives.filter((_objective, index) => index !== objectiveIndex),
    ];
    updatedGoals[goalIndex] = { ...updatedGoals[goalIndex], objectives: updatedObjectives };

    setValue('goals', updatedGoals);
  };

  // render delete icon
  const renderDeleteButton = (goalIndex: number, objectiveIndex?: number): ReactNode => (
    <InputAdornment position="end">
      <IconButton
        edge="end"
        onClick={() =>
          typeof objectiveIndex === 'number'
            ? onRemoveObjectiveInput(goalIndex, objectiveIndex)
            : onRemoveGoalGroup(goalIndex)
        }
        size="small"
      >
        <DeleteIcon width={20} color={variables.colorRed} />
      </IconButton>
    </InputAdornment>
  );

  if (loading) return <Loader />;

  return (
    <>
      <form onSubmit={handleSubmit(confirmAction)}>
        <Grid container spacing={2} className={styles.formContainer}>
          <Grid item sm={12}>
            <Select
              isRelativeWindow
              options={options.planTypes}
              name="typeId"
              control={control}
              errors={errors}
              label="Plan Type"
              hideSelectedOptions={false}
              isDisabled={isActTeamMember}
            />
          </Grid>
          <Grid item sm={12}>
            <Select
              isRelativeWindow
              options={options.teamMembers}
              name="caseManagerId"
              control={control}
              errors={errors}
              label="Case Manager"
              maxMenuHeight={200}
              hideSelectedOptions={false}
              isDisabled={isActTeamMember}
            />
          </Grid>
          <Grid item sm={12}>
            <DatePicker
              name="effectiveDate"
              control={control}
              openTo="date"
              errors={errors}
              label="Effective Date"
              minDate={
                isEditingActivePlan || !activeTreatmentPlan
                  ? undefined
                  : setNoon(activeTreatmentPlan.nextReviewDate)
              }
            />
          </Grid>
          <Grid item sm={12}>
            <DatePicker
              name="nextReviewDate"
              control={control}
              openTo="date"
              errors={errors}
              label="Next Review Date"
              minDate={
                effectiveDate ||
                (activeTreatmentPlan ? setNoon(activeTreatmentPlan.nextReviewDate) : undefined)
              }
            />
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          {fields.map((goal, index) => (
            <Fragment key={goal.id}>
              <Grid item sm={12}>
                <Input
                  name={`goals[${index}].text`}
                  register={register}
                  label="Goal"
                  errors={errors}
                  endAdornment={fields.length > 1 ? renderDeleteButton(index) : null}
                />
              </Grid>
              {goal.objectives.map((_objective, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`objective-${goal.id}-${i}`}>
                  <Grid item sm={12}>
                    <Input
                      name={`goals[${index}].objectives[${i}].text`}
                      register={register}
                      label="Objective"
                      errors={errors}
                      minRows={2}
                      endAdornment={
                        goal.objectives.length > 1 ? renderDeleteButton(index, i) : null
                      }
                      multiline
                    />
                  </Grid>
                  {goal.objectives.length === i + 1 && (
                    <Grid item sm={12} container justifyContent="flex-end" spacing={1}>
                      <Button
                        onClick={() => onAddObjectiveInput(index)}
                        size="small"
                        variant="text"
                        disableRipple
                        className={styles.red}
                      >
                        Add Objective
                      </Button>
                      {fields.length === index + 1 && (
                        <Button
                          onClick={onAddGoalInput}
                          size="small"
                          variant="text"
                          disableRipple
                          className={styles.red}
                        >
                          Add Goal
                        </Button>
                      )}
                    </Grid>
                  )}
                </Fragment>
              ))}
              {fields.length !== index + 1 && (
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              )}
            </Fragment>
          ))}
          <Grid item sm={12} container spacing={1}>
            <span className={styles.checkboxDescription}>
              Only one treatment plan can be active. Current treatment plan will be automatically
              deactivated. You can review and activate it anytime.{' '}
            </span>
          </Grid>
        </Grid>
        <div className={styles.buttonsWrapper}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setDetails(null);
              setModalTitle(null);
              setCurrent(null);
            }}
          >
            Cancel
          </Button>
          {!isActiveTreatmentPlan && (
            <Button
              color="primary"
              variant="outlined"
              type="button"
              onClick={handleSaveAsDraft}
              name="Save as Draft"
              className={styles.margin}
            >
              Save as Draft
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            type="submit"
            name={null}
            className={isActiveTreatmentPlan ? styles.margin : ''}
            disabled={
              ((details?.duplicate || !isActiveTreatmentPlan) &&
                setNoon(new Date()) < setNoon(effectiveDate)) ||
              (isActTeamMember && isDraftCaseManager && !isCaseManager && !!activeTreatmentPlan)
            }
          >
            {details?.duplicate || !isActiveTreatmentPlan ? 'Activate Treatment Plan' : 'Save'}
          </Button>
        </div>
      </form>
      {detailsInfo && (
        <ConfirmModal
          onConfirm={() => detailsInfo.onConfirm()}
          onClose={() => setDetailsInfo(null)}
          description={detailsInfo.description}
          confirmText="Proceed"
        />
      )}
    </>
  );
};

export default AddTreatmentPlan;

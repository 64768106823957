import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { deletePasswordExpiration, getPasswordExpiration } from '~/services/localStorage/auth';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { PROFILE } from '~/ui/constants/paths';

const usePasswordExpiration = (): void => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isProfilePage = pathname === PROFILE;
  const passwordExpirationData = getPasswordExpiration();
  const { daysTillExpiration, passwordExpiresSoon } = passwordExpirationData || {};
  const { current } = useStoreState(state => state.user);
  const { showNotify } = useStoreActions(actions => actions.snackbar);

  useEffect(() => {
    if (current && passwordExpiresSoon) {
      showNotify({
        message: `Your password will expire in ${daysTillExpiration || 5} days, please change.`,
        options: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          autoHide: false,
          buttonLabel: !isProfilePage ? 'Change Password' : undefined,
          onButtonClick: () => {
            navigate(PROFILE);
          },
          closeCb: () => deletePasswordExpiration(),
        },
      });
    }
  }, [current, daysTillExpiration, passwordExpiresSoon, showNotify, isProfilePage, navigate]);
};

export default usePasswordExpiration;

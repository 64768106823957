import { ReactElement, useEffect, useRef } from 'react';
import { GroupTypeBase, OptionProps, components } from 'react-select';

import { IOption } from '~/types';

import useIsVisible from '~/ui/pages/Reports/hooks/useIsVisible';
import styles from '../../Select/Select.module.scss';

const INDEX_TO_TRIGGER_FROM_END = 5;

type IOptionWithLastRef = OptionProps<IOption, boolean, GroupTypeBase<IOption>> & {
  loadMore?: () => void;
};

const OptionWithLastRef = ({ loadMore, ...props }: IOptionWithLastRef): ReactElement => {
  const lastOptionRef = useRef<HTMLDivElement>();
  const currentIndex = props.options?.findIndex(v => v.value === props.data.value);
  const listLength = props.options?.length;
  const isPreLastItem = listLength - INDEX_TO_TRIGGER_FROM_END === (currentIndex || 0) + 1; // we use pre last item in case if selected item would be excluded from dropdown

  const isVisible = useIsVisible(lastOptionRef);

  useEffect(() => {
    if (isVisible && isPreLastItem && loadMore) {
      loadMore();
      lastOptionRef.current = null;
    }
  }, [isVisible, isPreLastItem, loadMore]);

  return (
    <components.Option
      {...props}
      className={styles.menuOption}
      innerRef={isPreLastItem ? lastOptionRef : props.innerRef} // attach ref to the pre latest item for infinity scroll functionality
    />
  );
};

export default OptionWithLastRef;

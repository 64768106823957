import { ReactElement } from 'react';

import AddTriggerForm from './AddTrigger';
import AddMedicationForm from './AddMedication';
import AddMedicationDeliveryForm from './AddMedicationDelivery';
import AddDeEscalationForm from './AddDeEscalation';
import AddAllergyForm from './AddAllergy';
import AddAddressForm from './AddAddress';
import AddPhoneForm from './AddPhone';
import AddTreatmentPlanForm from './AddTreatmentPlan';
import AddSecondaryDiagnosisForm from './AddSecondaryDiagnosis';
import EditPrimaryDiagnosisForm from './EditPrimaryDiagnosis';
import AddInjectableMedicationDelivery from './AddInjectableMedicationDelivery';
import {
  formatTriggerDefaultValues,
  formatMedicationDefaultValues,
  formatMedicationDeliveryDefaultValues,
  formatDeEsclationDefaultValues,
  formatSecondaryDiagnosisDefaultValues,
  formatPrimaryDiagnosisDefaultValues,
  formatAllergyDefaultValues,
  formatAddressDefaultValues,
  formatPhoneDefaultValues,
  formatTreatmentPlanDefaultValues,
} from '~/utils/formatDefaultValues';

import { IRequestInfo } from './types';
import { IDetails } from '../types';

interface IProps {
  formType: string;
  setModalTitle: (str: string) => void;
  details: IDetails;
  setDetails: (arg: IDetails) => void;
  requestInfo: IRequestInfo;
  showSecondSubmit: boolean;
  current: any;
  primaryDiagnosis: any;
}

const Forms = ({ formType, current, primaryDiagnosis, ...rest }: IProps): ReactElement => {
  const { showSecondSubmit, details, ...sanitaizedProps } = rest;

  return (
    <>
      {formType === 'trigger' && (
        <AddTriggerForm
          {...rest}
          defaultValues={current ? formatTriggerDefaultValues(current) : undefined}
        />
      )}
      {formType === 'medication' && (
        <AddMedicationForm
          {...rest}
          defaultValues={current ? formatMedicationDefaultValues(current) : undefined}
        />
      )}
      {formType === 'medicationDelivery' && (
        <AddMedicationDeliveryForm
          {...rest}
          defaultValues={current ? formatMedicationDeliveryDefaultValues(current) : undefined}
        />
      )}
      {formType === 'injectableMedicationDelivery' && (
        <AddInjectableMedicationDelivery
          {...rest}
          defaultValues={current ? formatMedicationDeliveryDefaultValues(current) : undefined}
        />
      )}
      {formType === 'deEscalation' && (
        <AddDeEscalationForm
          {...rest}
          defaultValues={current ? formatDeEsclationDefaultValues(current) : undefined}
        />
      )}
      {formType === 'allergy' && (
        <AddAllergyForm
          {...rest}
          defaultValues={current ? formatAllergyDefaultValues(current) : undefined}
        />
      )}
      {formType === 'secondaryDiagnosis' && (
        <AddSecondaryDiagnosisForm
          {...rest}
          defaultValues={current ? formatSecondaryDiagnosisDefaultValues(current) : undefined}
        />
      )}
      {formType === 'primaryDiagnosis' && (
        <EditPrimaryDiagnosisForm
          {...sanitaizedProps}
          defaultValues={
            primaryDiagnosis ? formatPrimaryDiagnosisDefaultValues(primaryDiagnosis) : undefined
          }
        />
      )}
      {formType === 'address' && (
        <AddAddressForm
          {...rest}
          defaultValues={current ? formatAddressDefaultValues(current) : undefined}
        />
      )}
      {formType === 'phone' && (
        <AddPhoneForm
          {...rest}
          defaultValues={current ? formatPhoneDefaultValues(current) : undefined}
        />
      )}
      {formType === 'treatmentPlan' && (
        <AddTreatmentPlanForm
          {...rest}
          defaultValues={current ? formatTreatmentPlanDefaultValues(current) : undefined}
        />
      )}
    </>
  );
};

export default Forms;

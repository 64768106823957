import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { IOrganization, ICurrentOrganization, IOrganizationRequest } from './types';

export const getOrganizations = (): IResponse<IOrganization[]> =>
  privateAxios({
    method: 'get',
    url: 'organizations',
  });

export const getOrganization = (organizationId: number): IResponse<ICurrentOrganization> =>
  privateAxios({
    method: 'get',
    url: `organizations/${organizationId}`,
  });

export const addOrganization = (data: IOrganizationRequest): IResponse<IOrganization> =>
  privateAxios({
    method: 'post',
    url: 'organizations',
    data,
  });

export const archiveOrganization = (organizationId: number): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `organizations/${organizationId}`,
  });

export const restoreOrganization = (organizationId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `organizations/${organizationId}/recover`,
  });

export const updateOrganization = (
  organizationId: number,
  data: IOrganizationRequest,
): IResponse<IOrganization> =>
  privateAxios({
    method: 'put',
    url: `organizations/${organizationId}`,
    data,
  });

export default {};

/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useCallback, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import { useStoreActions } from '~/store/hooks';
import AvatarUpload from '~/ui/components/inputs/AvatarUpload';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import InputMask from '~/ui/components/inputs/InputMask';
import Loader from '~/ui/components/common/Loader';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import { extractErrorMessage } from '~/utils/error/error';
import formatSheltersOptions from '~/utils/formatSheltersOptions';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { IInitialValues } from './initialValues';
import validate, { editValidationSchema } from './validate';

import { IOption } from '~/types';
import statesOptions from '~/ui/constants/states';
import suicideRiskOptions from '~/ui/constants/suicideRisks';

import styles from './Form.module.scss';

interface IProps {
  isProcessing: boolean;
  leavePageDescription: string;
  clinicId: string;
  onSubmit: (val: IInitialValues) => void;
  submitText?: string;
  defaultValues?: IInitialValues;
}

interface IOptions {
  gender: IOption[];
  race: IOption[];
  referralSource: IOption[];
  actTeams: IOption[];
  shelters: IOption[];
}

const Form = ({
  isProcessing,
  leavePageDescription,
  clinicId,
  onSubmit,
  submitText = 'Add Client',
  defaultValues = { aot: false } as IInitialValues,
}: IProps): ReactElement => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOptions>(null);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const isEditing = !!defaultValues.firstName;

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isDirty },
    unregister,
  } = useForm<IInitialValues>({
    defaultValues,
    resolver: isEditing ? editValidationSchema : validate,
    mode: 'onBlur',
  });

  const { medicaIdRequired, isShelter } = watch();

  const onMount = useCallback(async () => {
    const { getAvailableTypeList } = api.dictionaries;
    try {
      setLoading(true);
      const [gender, race, referralSource, actTeams, shelters] = await Promise.all([
        getAvailableTypeList(IDictionaryTypes.Gender).then(r => formatDictionaryOptions(r.data)),
        getAvailableTypeList(IDictionaryTypes.Race).then(r => formatDictionaryOptions(r.data)),
        getAvailableTypeList(IDictionaryTypes.ReferralSource).then(r =>
          formatDictionaryOptions(r.data),
        ),
        api.actTeam.getActTeamsAvailable(clinicId).then(r => formatDictionaryOptions(r.data)),
        api.shelters
          .getClinicShelters(clinicId, { pageNumber: 1, pageSize: 5000, filtering: 1 })
          .then(r => formatSheltersOptions(r.data.items)),
      ]);

      setOptions({ gender, race, referralSource, actTeams, shelters });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [clinicId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (!medicaIdRequired) {
      unregister(['medicaIdRecertificationDate']);
    }
  }, [medicaIdRequired, unregister]);

  useEffect(() => {
    if (isShelter) {
      unregister(['address']);
    } else {
      unregister(['shelterId']);
    }
  }, [isShelter, unregister]);

  if (loading || !options) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box sx={{ p: 2, pr: 0 }}>
            <p className={styles.formTitle}>Personal Details</p>
            <Grid container>
              <Grid item sm={6}>
                <Grid container spacing={2} sm={12}>
                  <Grid item sm={12}>
                    <Input
                      tabIndex={1}
                      register={register}
                      errors={errors}
                      name="firstName"
                      label="First Name"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Input
                      tabIndex={2}
                      register={register}
                      errors={errors}
                      name="lastName"
                      label="Last Name"
                    />
                  </Grid>
                  {!isEditing && (
                    <Grid item sm={12}>
                      <InputMask
                        tabIndex={3}
                        control={control}
                        errors={errors}
                        name="phone"
                        label="Phone"
                      />
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <Input
                      tabIndex={4}
                      register={register}
                      errors={errors}
                      name="email"
                      label="Email"
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Input
                      tabIndex={5}
                      register={register}
                      errors={errors}
                      name="medicaId"
                      label="Medicaid #"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Checkbox
                      size="small"
                      name="medicaIdRequired"
                      control={control}
                      errors={errors}
                      label="Medicaid Recertification Required"
                    />
                  </Grid>
                  {medicaIdRequired && (
                    <Grid item sm={12}>
                      <DatePicker
                        tabIndex={6}
                        control={control}
                        errors={errors}
                        label="Recertification Expiration Date"
                        name="medicaIdRecertificationDate"
                        views={['year', 'month']}
                        dateFormat="MM/yyyy"
                      />
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <AvatarUpload control={control} name="photo" errors={errors} isPhoto />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2} sm={12}>
                  <Grid item sm={12}>
                    <DatePicker
                      tabIndex={7}
                      control={control}
                      errors={errors}
                      label="Date of Birth"
                      name="dateOfBirth"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Select
                      tabIndex={8}
                      options={options.gender}
                      name="genderId"
                      control={control}
                      errors={errors}
                      label="Gender"
                      hideSelectedOptions={false}
                      openMenuOnFocus
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Select
                      tabIndex={9}
                      options={options.race}
                      name="raceId"
                      control={control}
                      errors={errors}
                      label="Race"
                      hideSelectedOptions={false}
                      openMenuOnFocus
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Select
                      tabIndex={10}
                      options={suicideRiskOptions}
                      name="suicideRisk"
                      control={control}
                      errors={errors}
                      label="Suicide Risk"
                      hideSelectedOptions={false}
                      openMenuOnFocus
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Select
                      tabIndex={11}
                      options={options.actTeams}
                      name="teamId"
                      control={control}
                      errors={errors}
                      label="Assigned Team"
                      isDisabled={!!defaultValues?.firstName}
                      hideSelectedOptions={false}
                      openMenuOnFocus
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Input
                      tabIndex={12}
                      register={register}
                      errors={errors}
                      name="emrMrn"
                      label="EMR MRN"
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Checkbox
                      size="small"
                      name="aot"
                      control={control}
                      errors={errors}
                      label="AOT"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {!isEditing && (
          <div className={styles.marginTop}>
            <Paper>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item sm={6}>
                        <p className={styles.sectionTitle}>Location</p>
                      </Grid>
                      <Grid item sm={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Checkbox
                            size="small"
                            name="isShelter"
                            control={control}
                            errors={errors}
                            label="Shelter"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!isShelter ? (
                    <>
                      <Grid item sm={6}>
                        <Input
                          tabIndex={13}
                          register={register}
                          errors={errors}
                          name="address.city"
                          label="City"
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <Input
                          tabIndex={17}
                          register={register}
                          errors={errors}
                          name="address.line1"
                          label="Address 1"
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <Select
                          tabIndex={14}
                          options={statesOptions}
                          name="address.stateId"
                          control={control}
                          errors={errors}
                          label="State"
                          hideSelectedOptions={false}
                          openMenuOnFocus
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <InputMask
                          tabIndex={15}
                          alwaysShowMask={false}
                          mask="99999"
                          name="address.zipCode"
                          control={control}
                          errors={errors}
                          label="Zip Code"
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <Input
                          tabIndex={18}
                          register={register}
                          errors={errors}
                          name="address.line2"
                          label="Address 2"
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <Input
                          tabIndex={16}
                          register={register}
                          errors={errors}
                          name="address.poBox"
                          label="PO Box"
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <Input
                          tabIndex={19}
                          register={register}
                          errors={errors}
                          name="address.shelterName"
                          label="Name"
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item sm={6}>
                      <Select
                        tabIndex={13}
                        options={options.shelters}
                        name="shelterId"
                        control={control}
                        errors={errors}
                        label="Select Shelter"
                        hideSelectedOptions={false}
                        openMenuOnFocus
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </div>
        )}
        <div className={styles.marginTop}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <p className={styles.formTitle}>Admission Details</p>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Select
                    tabIndex={20}
                    options={options.referralSource}
                    name="admissionDetails.referralSourceId"
                    control={control}
                    errors={errors}
                    label="Referral Source"
                    hideSelectedOptions={false}
                    openMenuOnFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    tabIndex={21}
                    control={control}
                    openTo="date"
                    errors={errors}
                    label="Date of Admission"
                    name="admissionDetails.admissionDate"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </div>
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttonWrapper}>
            <Button variant="outlined" color="primary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isProcessing}
            tabIndex={21}
          >
            {submitText}
          </Button>
        </div>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </div>
  );
};

export default Form;

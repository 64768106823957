import onGetClinicShelters from './onGetClinicShelters';
import onGetClinicShelter from './onGetClinicShelter';
import onArchiveShelter from './onArchiveShelter';
import onCreateShelter from './onCreateShelter';
import onRestoreShelter from './onRestoreShelter';
import onUpdateShelter from './onUpdateShelter';

export default {
  onGetClinicShelters,
  onGetClinicShelter,
  onArchiveShelter,
  onRestoreShelter,
  onCreateShelter,
  onUpdateShelter,
};

import { IShelterAddress } from '~/services/api/shelters/types';

const formatShelterAddress = (item: IShelterAddress): string =>
  `${item.line1 ? `${item.line1.trim()}, ` : ''}${
    item.line2 ? `${item.line2.trim()}, ` : ''
  }${item.city?.trim()}, ${
    item.state?.abbreviation?.trim() || item.state?.name?.trim() || ''
  } ${item.zipCode?.trim()}`;

export default formatShelterAddress;

import axios from 'axios';
import { IRefreshTokenParams } from '~/services/api/auth/types';
import { IRefreshAccessToken } from '~/services/api/types';
import replaceApiVersion from '../text/replaceApiVersion';

const getNewTokens = (tokens: IRefreshTokenParams): Promise<IRefreshAccessToken> =>
  axios({
    method: 'post',
    url: `${replaceApiVersion(process.env.REACT_APP_API_URL, 'v3/')}auth/refresh`,
    data: tokens,
  }).then(res => res.data);

export default getNewTokens;

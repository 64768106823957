import { action } from 'easy-peasy';
import { IActionSetAllOptionsSelected } from '../types';

const setAllOptionsSelected: IActionSetAllOptionsSelected = action((state, payload) => {
  state.availableDictionaries[payload.type].isAllSelected = payload.value;

  if (payload.value) {
    state.availableDictionaries[payload.type].current =
      state.availableDictionaries[payload.type].list;
  }
});

export default setAllOptionsSelected;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IReportsActions, IThunkGetEffectiveDates } from '../types';

const onGetEffectiveDates: IThunkGetEffectiveDates = thunk(
  async ({ setEffectiveDates }: Actions<IReportsActions>, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const dates = await api.reports
      .getEffectiveDates({ clinicId, teamId, clientId })
      .then(r => r.data);

    setEffectiveDates({ dates });
  },
);

export default onGetEffectiveDates;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCompleteTreatmentPlan, IClientDetailsStore, IClientDetailsActions } from '../types';

const onCompleteOwnTreatmentPlan: IThunkCompleteTreatmentPlan = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.completeOwnTreatmentPlan(payload);

    const treatmentPlans = localState.treatmentPlans.map(item =>
      item.id !== Number(payload.id) ? item : { ...item, isArchived: true },
    );

    setClientDetails({ treatmentPlans });
  },
);

export default onCompleteOwnTreatmentPlan;

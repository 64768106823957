import { ReactElement } from 'react';

import ListItem from '~/ui/components/common/ListItem';

import { IActTeamMemberExtended } from '~/services/api/actTeamMember/types';
import { IProgramAssistantForm } from '~/services/api/programAssistant/types';
import styles from './PersonalDetails.module.scss';

interface IProps {
  teamMember: IActTeamMemberExtended | IProgramAssistantForm;
}

const PersonalDetails = ({ teamMember }: IProps): ReactElement => (
  <div className={styles.content}>
    <div className={styles.column}>
      <div className={styles.listItemBlock}>
        <div className={styles.item}>
          <h4>Personal Details</h4>
        </div>
        <ListItem label="First Name" content={teamMember.firstName} moreLabelWidth />
        <ListItem label="Last Name" content={teamMember.lastName} moreLabelWidth />
        <ListItem label="Phone" content={teamMember.phone} moreLabelWidth />
        <ListItem label="Email" content={teamMember.email} moreLabelWidth />
        <ListItem label="Sub-Role" content={teamMember?.subRoles?.[0]?.name} moreLabelWidth />
      </div>
    </div>
    <div className={styles.column}>
      <div className={styles.listItemBlock}>
        <div className={styles.item}>
          <h4>Address</h4>
        </div>
        <ListItem label="City" content={teamMember.address.city} moreLabelWidth />
        <ListItem label="Address 1" content={teamMember.address.line1} moreLabelWidth />
        <ListItem label="Address 2" content={teamMember.address.line2} moreLabelWidth />
        <ListItem label="State" content={teamMember.address.state.abbreviation} moreLabelWidth />
        <ListItem label="Zip Code" content={teamMember.address.zipCode} moreLabelWidth />
      </div>
    </div>
  </div>
);

export default PersonalDetails;

export const setItem = (key: string, item: unknown): void => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage.setItem(key, typeof item === 'string' ? item : JSON.stringify(item));
};

export const getItem = (key: string): any => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

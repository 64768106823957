export enum IDictionaryTypes {
  Trigger = 'Trigger',
  Credentials = 'Credentials',
  DeEscalationTechnique = 'DeEscalationTechnique',
  Allergy = 'Allergy',
  Diagnosis = 'Diagnosis',
  Race = 'Race',
  DischargeReason = 'discharge-reason',
  Education = 'Education',
  Ehr = 'Ehr',
  EmploymentStatus = 'EmploymentStatus',
  Medication = 'Medication',
  MedicationNonDeliveryReason = 'medication-non-delivery-reason',
  ReferralSource = 'referral-source',
  Relationship = 'Relationship',
  SubRole = 'SubRole',
  TeamType = 'TeamType',
  Gender = 'Gender',
  State = 'State',
  CLIENT_NO_SHOW_REASON = 'visit-no-show-reason',
  PhoneType = 'PhoneType',
  AddressType = 'AddressType',
  Intervention = 'Intervention',
  TreatmentPlanType = 'TreatmentPlanType',
  DocumentType = 'document-type',
}

export interface IDictionaryType {
  type: string;
  name: string;
}

interface IDictionaryTypeCustomField {
  title: string;
  name: string;
}

export interface ICustomDictionaryType extends IDictionaryType {
  customFields?: Array<IDictionaryTypeCustomField>;
  alternativeValues?: Array<string>;
}

export interface IDictionary {
  id: number;
  name: string;
}

export interface IAvailableDictionary {
  id: number;
  value: string;
  icd10: string;
}

export interface IDictionaryItem {
  id: number;
  value: string;
  isArchived: boolean;
}

export interface ICustomDictionaryItem extends IDictionaryItem {
  abbreviation?: string;
  alternativeValues?: string[];
  icd10?: number | string;
  [key: string]: string | number | boolean | string[];
}

export interface IDictionariesParams {
  name: string;
  pageSize: number;
  pageNumber: number;
}

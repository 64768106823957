import Box from '@material-ui/core/Box';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form';
import classNames from 'classnames';

import DatePicker from '~/ui/components/inputs/DatePicker';
import RadioGroup from '~/ui/components/inputs/RadioGroup';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';

import isStatusDone from '../../utils/isStatusDone';

import { IToxicology } from '../AddVisit/types';
import { testingStatusOptions, toxicologyResultOptions } from '../../constants/toxicologyOptions';

import styles from '../Form/Form.module.scss';

interface IToxicologyProps {
  toxicology: IToxicology | null;
  control: any;
  errors: FieldErrors;
}

const Toxicology: FC<IToxicologyProps> = ({ toxicology, control, errors }) => (
  <Box className={styles.column}>
    <RadioGroup
      name="toxicology.status"
      control={control}
      errors={errors}
      options={testingStatusOptions}
      label="Toxicology"
      size="small"
      className={styles.rowRadioGroup}
    />
    {isStatusDone(toxicology?.status) && (
      <Box className={classNames(styles.rowRadioGroup, styles.selectGroupWidth)}>
        <Select
          name="toxicology.result"
          control={control}
          errors={errors}
          label="Result"
          options={toxicologyResultOptions}
          hideSelectedOptions={false}
          openMenuOnFocus
        />
        <DatePicker
          name="toxicology.date"
          control={control}
          errors={errors}
          openTo="date"
          label="Result Date"
          maxDate={new Date().toISOString()}
        />
      </Box>
    )}
  </Box>
);

export default Toxicology;

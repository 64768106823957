import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IProgramAssistantActions, IThunkOnActivateProgramAssistant } from '../types';

const onActivateProgramAssistant: IThunkOnActivateProgramAssistant = thunk(
  async ({ activateProgramAssistant }: Actions<IProgramAssistantActions>, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.activateProgramAssistant(clinicId, String(programAssistantId));

    activateProgramAssistant(programAssistantId);
  },
);

export default onActivateProgramAssistant;

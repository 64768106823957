import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetClientLengthOfStayDetailsReport } from '../types';

const onGetClientLengthOfStayDetailsReport: IThunkGetClientLengthOfStayDetailsReport = thunk(
  async (
    { setClientLengthOfStayDetailsReport }: Actions<IReportsActions>,
    { requestInfo, params },
  ) => {
    const { clinicId } = requestInfo;

    const clientLengthOfStayReport = await api.reports
      .getClientLengthOfStayDetailsReport({ clinicId }, params)
      .then(r => r.data);

    setClientLengthOfStayDetailsReport({
      items: clientLengthOfStayReport.items,
      teamId: params.teamId,
      total: clientLengthOfStayReport.total,
      pagination: { ...params, total: clientLengthOfStayReport.total },
    });
  },
);

export default onGetClientLengthOfStayDetailsReport;

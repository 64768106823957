import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';

import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';

import { extractErrorMessage } from '~/utils/error/error';
import formatLocationGroupDefaultValues from '../helpers/formatLocationGroupDefaultValues';
import Form from './Form';

import styles from './LocationGroupModal.module.scss';

interface IProps {
  id?: number;
  onClose: () => void;
}

const LocationGroupModal = ({ id, onClose }: IProps): ReactElement => {
  const [loading, setLoading] = useState(true);

  const { id: currentClinicId } = useParams<{ id: string }>();

  const user = useStoreState(state => state.user.current);
  const locationGroup = useStoreState(state => (id ? state.locationGroups.current : null));

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onGetClinicLocationGroup } = useStoreActions(actions => actions.locationGroups);

  const clinicId = currentClinicId || String(user?.clinic?.id);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        await onGetClinicLocationGroup({ clinicId, id });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [id, onGetClinicLocationGroup, clinicId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Modal className={styles.modal} open onClose={onClose}>
      {loading ? (
        <Loader />
      ) : (
        <Form
          id={locationGroup?.id}
          defaultValues={formatLocationGroupDefaultValues(locationGroup)}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

export default LocationGroupModal;

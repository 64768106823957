import { useState, useEffect, ReactNode, ReactElement } from 'react';

type IProps = {
  children: ReactNode;
  waitBeforeShow?: number;
};

const Delayed = ({ children, waitBeforeShow = 1000 }: IProps): ReactElement => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? (children as ReactElement) : null;
};

export default Delayed;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateTreatmentPlan, IClientDetailsStore, IClientDetailsActions } from '../types';

const onCreateTreatmentPlan: IThunkCreateTreatmentPlan = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const { setActive } = requestPayload;

    const clientTreatmentPlan = await api.clientDetails
      .createTreatmentPlan(requestInfo, requestPayload)
      .then(r => r.data);

    const updatedTreatmentPlans = localState.treatmentPlans.map(item => ({
      ...item,
      ...(setActive && item.active && !item.isArchived ? { isArchived: true } : {}),
      ...(!setActive && !item.active && !item.isArchived ? { isArchived: true } : {}),
    }));

    const treatmentPlans = [clientTreatmentPlan, ...updatedTreatmentPlans];

    setClientDetails({ treatmentPlans });
  },
);

export default onCreateTreatmentPlan;

import { useState, useEffect, ReactElement, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import concat from 'lodash/concat';

import Loader from '~/ui/components/common/Loader';
import Form from '~/ui/pages/ActTeam/reusable/Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import { CLINIC_MANAGEMENT, VIEW_CLINIC } from '~/ui/constants/paths';

type IParams = {
  clinicId: string;
  actTeamId: string;
};

const breadcrumbItem = [
  {
    to: CLINIC_MANAGEMENT,
    title: 'Clinic Management',
  },
];

const CreateActTeam = (): ReactElement => {
  const { clinicId, actTeamId } = useParams<IParams>();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const clinic = useStoreState(state => state.clinic.current);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      await onGetClinic(clinicId);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [clinicId, onGetClinic, showError]);

  const onSubmit = async (values: any) => {
    try {
      setIsCreating(true);
      const payload = { id: actTeamId, ...values };
      setLoading(true);
      await api.actTeam.createClinicActTeam(clinicId, payload);
      showNotify({ message: 'Team Successfully added.' });
      navigate(VIEW_CLINIC.replace(':id', clinicId), { state: { selectedTab: 1 } });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsCreating(false);
    }
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !clinic) return <Loader />;

  const breadcrumbItems = concat(breadcrumbItem, [
    {
      title: clinic?.name,
      to: VIEW_CLINIC.replace(':id', clinicId),
    },
    {
      title: 'Add New Team',
      to: location.pathname,
    },
  ]);

  const defaultValues = {
    clinicId,
  };

  const leavePageDescription = 'Are you sure you want to discard changes?';
  const cancelLinkTo = {
    pathname: VIEW_CLINIC.replace(':id', clinicId),
    state: { selectedTab: 1 },
  };

  return (
    <div>
      <h2>Add New Team</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        isEdit
        defaultValues={defaultValues}
        clinicSelectOptions={[{ value: clinicId, label: clinic?.name }]}
        isProcessing={isCreating}
        cancelLinkTo={cancelLinkTo}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CreateActTeam;

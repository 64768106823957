import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkEditVisit, IVisitsActions } from '../types';

const onEditVisit: IThunkEditVisit = thunk(
  async ({ setVisit }: Actions<IVisitsActions>, payload) => {
    const {
      requestInfo: { clinicId, teamId, visitId },
      requestPayload,
    } = payload;
    const { data } = await api.visits.updateVisit({ clinicId, teamId, visitId }, requestPayload);

    setVisit(data);
  },
);

export default onEditVisit;

import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import capitalize from 'lodash/capitalize';
import curry from 'lodash/curry';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';

import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import TreatmentPlanDetails from './TreatmentPlanDetails';
import groupTreatmentPlansByStatus from './TreatmentPlanDetails/utils';
import Forms from '../HealthDetails/Forms';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useFeature from '~/store/clinic/hooks/useFeature';
import { extractErrorMessage } from '~/utils/error/error';
import useRole from '~/store/user/hooks/useRole';

import { IRequestParams } from '~/services/api/clientDetails/types';
import { IClientDetailed } from '~/services/api/client/types';
import { IDetailsInfo, IDetails, ITypeDetails } from './types';

import loaderStyles from '~/ui/components/common/Loader/Loader.module.scss';
import styles from './TreatmentPlan.module.scss';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
}

const TreatmentPlan = ({ client, clinicId, actTeamId }: IProps): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [formType, setFormType] = useState<ITypeDetails | null>(null);
  const [details, setDetails] = useState<IDetails | null>(null);
  const [detailsInfo, setDetailsInfo] = useState<IDetailsInfo | null>(null);

  const { treatmentPlans, current } = useStoreState(state => state.clientDetails);
  const { primaryDiagnosis } = useStoreState(state => state.client.current);
  const { id: userId } = useStoreState(state => state.user.current);

  const { isLocalAdmin, isActTeamLeader, isProgramAssistant, isActTeamMember } = useRole();
  const { isTreatmentPlansAvailable } = useFeature();

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const {
    setCurrent,
    onGetTreatmentPlan,
    onGetTreatmentPlans,
    onDeleteTreatmentPlan,
    onCompleteTreatmentPlan,
    onCompleteOwnTreatmentPlan,
  } = useStoreActions(actions => actions.clientDetails);

  const methods = {
    complete: isActTeamMember ? onCompleteOwnTreatmentPlan : onCompleteTreatmentPlan,
    delete: onDeleteTreatmentPlan,
  };

  const onMount = useCallback(async () => {
    try {
      if (!isTreatmentPlansAvailable) return;

      setLoading(true);
      const payload = { clinicId, teamId: actTeamId, clientId: String(client.id) };

      await onGetTreatmentPlans(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [actTeamId, client.id, clinicId, isTreatmentPlansAvailable, onGetTreatmentPlans, showError]);

  const loadDetails = useCallback(async () => {
    try {
      setLoadingDetails(true);
      const payload = {
        clinicId,
        clientId: String(client.id),
        teamId: actTeamId,
        id: String(details?.id),
      };

      if (details && details.type === 'treatmentPlan') {
        await onGetTreatmentPlan(payload);
      }

      setLoadingDetails(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [actTeamId, client.id, clinicId, details, onGetTreatmentPlan, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    loadDetails();
  }, [details, loadDetails]);

  const activeTreatmentPlan = treatmentPlans.find(plan => plan.active && !plan.isArchived);
  const draftTreatmentPlan = treatmentPlans.find(plan => !plan.active && !plan.isArchived);

  // case manager in active plan
  const isCaseManager = useMemo(
    () => activeTreatmentPlan?.caseManager?.id === userId || false,
    [userId, activeTreatmentPlan],
  );

  // case manager in  draft plan
  const isDraftCaseManager = useMemo(
    () => draftTreatmentPlan?.caseManager?.id === userId || false,
    [userId, draftTreatmentPlan],
  );

  const onAdd = (title: string, type: ITypeDetails) => {
    setModalTitle(title);
    setFormType(type);
  };

  const onEdit = curry(
    (
      type: ITypeDetails,
      healthDetailsType: string,
      removeHealthDetailsName: boolean,
      id: number,
      healthDetailsName: string,
    ) => {
      let title = `Edit “${healthDetailsName}” ${healthDetailsType}`;

      if (removeHealthDetailsName) {
        title = title.replace(/“[\w\s]*”/gi, '');
      }

      setModalTitle(title);
      setDetails({ type, id });
      setFormType(type);
    },
  );

  const onDuplicate = curry(
    (
      type: ITypeDetails,
      healthDetailsType: string,
      removeHealthDetailsName: boolean,
      id: number,
      healthDetailsName: string,
    ) => {
      let title = `Duplicate “${healthDetailsName}” ${healthDetailsType}`;

      if (removeHealthDetailsName) {
        title = title.replace(/“[\w\s]*”/gi, '');
      }

      setModalTitle(title);
      setDetails({ type, id, duplicate: true });
      setFormType(type);
    },
  );

  const onComplete = (id: number) => {
    setDetailsInfo({
      method: 'complete',
      id,
      description: 'Are you sure you want to complete current treatment plan?',
    });
  };

  const onDelete = (id: number) => {
    setDetailsInfo({
      method: 'delete',
      id,
      description: 'Are you sure you want to delete draft? You will not be able to restore it.',
    });
  };

  const requestInfo = { clientId: String(client.id), clinicId, teamId: actTeamId };

  const confirmText = capitalize(detailsInfo?.method);

  const method = methods[detailsInfo?.method];

  const onConfirmModal = async (payload: Required<IRequestParams>) => {
    try {
      await method(payload);

      showNotify({ message: `Treatment plan successfully ${detailsInfo?.method}d` });

      setDetailsInfo(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const showSecondSubmit = !Object.keys(details || {}).length;

  const canManageTreatmentPlan =
    isLocalAdmin || isProgramAssistant || isActTeamLeader || isCaseManager || isDraftCaseManager;

  const isClientActive = !client.isArchived;

  return (
    <div className={styles.wrapper}>
      {loading && <Loader className={loaderStyles.loaderContainer} />}
      {isClientActive && canManageTreatmentPlan && !isActTeamMember && (
        <Box className={styles.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => onAdd('Add Treatment Plan', 'treatmentPlan')}
          >
            Add Treatment Plan
          </Button>
        </Box>
      )}
      <TreatmentPlanDetails
        onEdit={onEdit('treatmentPlan', 'Treatment Plan', true)}
        onDuplicate={onDuplicate('treatmentPlan', 'Treatment Plan', true)}
        onComplete={onComplete}
        onDelete={onDelete}
        showEdit={isClientActive && canManageTreatmentPlan}
        showDuplicate={isClientActive && canManageTreatmentPlan}
        healthDetailGroup={groupTreatmentPlansByStatus(treatmentPlans)}
      />
      {(modalTitle || (details && details.id)) && (
        <Modal
          className={styles.modal}
          open
          onClose={() => {
            setModalTitle(null);
            setDetails(null);
            setCurrent(null);
          }}
        >
          {loadingDetails ? (
            <Loader />
          ) : (
            <div className={styles.modalWrapper}>
              <h3 className={styles.modalTitle}>{modalTitle}</h3>
              <Forms
                formType={formType}
                setModalTitle={setModalTitle}
                setDetails={setDetails}
                requestInfo={requestInfo}
                details={details}
                showSecondSubmit={showSecondSubmit}
                current={current}
                primaryDiagnosis={primaryDiagnosis}
              />
            </div>
          )}
        </Modal>
      )}
      {detailsInfo && (
        <ConfirmModal
          onConfirm={() => {
            onConfirmModal({
              clientId: String(client.id),
              clinicId,
              teamId: actTeamId,
              id: String(detailsInfo?.id),
            });
          }}
          onClose={() => setDetailsInfo(null)}
          description={detailsInfo.description}
          confirmText={confirmText}
        />
      )}
    </div>
  );
};

export default TreatmentPlan;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateTreatmentPlan, IClientDetailsStore, IClientDetailsActions } from '../types';

const onUpdateTreatmentPlan: IThunkUpdateTreatmentPlan = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;
    const { requestInfo, requestPayload } = payload;

    const { setActive } = requestPayload;

    const treatmentPlan = await api.clientDetails
      .updateTreatmentPlan(requestInfo, requestPayload)
      .then(r => r.data);

    const treatmentPlans = localState.treatmentPlans.map(item =>
      item.id !== treatmentPlan.id
        ? {
            ...item,
            ...(setActive && item.active && !item.isArchived
              ? { active: true, isArchived: true }
              : {}), // make current active - inactive
            ...(!setActive && !item.active && !item.isArchived ? { isArchived: true } : {}), // make current draft (if exists) - deleted
          }
        : treatmentPlan,
    );

    setClientDetails({ treatmentPlans });
  },
);

export default onUpdateTreatmentPlan;

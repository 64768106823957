import { action } from 'easy-peasy';
import { IActionSetDefaultClient, IClientOption } from '../types';
import extractFullName from '~/utils/text/extractFullName';

const setDefaultClient: IActionSetDefaultClient = action((state, payload) => {
  state.defaultClient = payload;

  const clientOption = state.clientOptions.list?.find(item => item.id === payload?.id);

  if (payload && !clientOption) {
    const defaultClientOption: IClientOption = {
      id: payload.id,
      name: extractFullName(payload),
      team: payload.actTeam,
      photo: '',
      photoThumbnail: '',
    };

    state.clientOptions.list = [...state.clientOptions.list, defaultClientOption];
  }
});

export default setDefaultClient;

import capitalize from 'lodash/capitalize';
import curry from 'lodash/curry';
import get from 'lodash/get';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { useStoreActions, useStoreState } from '~/store/hooks';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Loader from '~/ui/components/common/Loader';
import LocationGroup from './components/LocationGroup';
import Modal from '~/ui/components/common/Modal';
import useRole from '~/store/user/hooks/useRole';
import { extractErrorMessage } from '~/utils/error/error';
import Forms from '../HealthDetails/Forms';
import DetailsBlock from './DetailsBlock';

import { IClientDetailed } from '~/services/api/client/types';
import { IRequestParams } from '~/services/api/clientDetails/types';
import { IDetails, IDetailsInfo, ITypeDetails } from './types';
import styles from './LocationDetails.module.scss';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
}

const LocationDetails = ({ client, clinicId, actTeamId }: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [formType, setFormType] = useState<ITypeDetails | null>(null);
  const [details, setDetails] = useState<IDetails | null>(null);
  const [detailsInfo, setDetailsInfo] = useState<IDetailsInfo | null>(null);

  const { addresses, current } = useStoreState(state => state.clientDetails);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const { onGetAddress, onGetAddresses, onArchiveAddress, onRestoreAddress, setCurrent } =
    useStoreActions(actions => actions.clientDetails);

  const { isLocalAdmin, isProgramAssistant } = useRole();

  const methods = {
    address: {
      restore: onRestoreAddress,
      archive: onArchiveAddress,
    },
  };

  const onMount = useCallback(async () => {
    if (Number(clinicId) && actTeamId && client.id) {
      try {
        setLoading(true);
        const payload = { clinicId, teamId: actTeamId, clientId: String(client.id) };

        await onGetAddresses(payload);
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  }, [actTeamId, client.id, clinicId, onGetAddresses, showError]);

  const loadDetails = useCallback(async () => {
    try {
      setLoadingDetails(true);
      const payload = {
        clinicId,
        clientId: String(client.id),
        teamId: actTeamId,
        id: String(details?.id),
      };

      if (details && details.type === 'address') {
        await onGetAddress(payload);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingDetails(false);
    }
  }, [actTeamId, client.id, clinicId, details, onGetAddress, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    loadDetails();
  }, [details, loadDetails]);

  if (loading) return <Loader />;

  const onAdd = (title: string, type: ITypeDetails) => {
    setModalTitle(title);
    setFormType(type);
  };

  const onEdit = curry(
    (
      type: ITypeDetails,
      healthDetailsType: string,
      removeHealthDetailsName: boolean,
      id: number,
      healthDetailsName: string,
    ) => {
      let title = `Edit ${healthDetailsName} ${healthDetailsType}`;

      if (removeHealthDetailsName) {
        title = title.replace(/“[\w\s]*”/gi, '');
      }

      setModalTitle(title);
      setDetails({ type, id });
      setFormType(type);
    },
  );

  const requestInfo = { clientId: String(client.id), clinicId, teamId: actTeamId };

  const confirmText = detailsInfo?.method === 'archive' ? 'Archive' : 'Restore';
  const description = `Are you sure you want to ${confirmText.toLowerCase()} “${
    detailsInfo?.name
  }” ${detailsInfo?.title || detailsInfo?.typeDetails}?`;

  const method = get(methods, [detailsInfo?.typeDetails, detailsInfo?.method], () => {});

  const onConfirmModal = async (payload: Required<IRequestParams>) => {
    try {
      const type = detailsInfo?.method === 'archive' ? 'archived' : 'restored';

      await method(payload);

      showNotify({
        message: `${capitalize(
          detailsInfo?.title || detailsInfo?.typeDetails,
        )} successfully ${type}`,
      });

      setDetailsInfo(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const showSecondSubmit = !Object.keys(details || {}).length;

  const isClientActive = !client.isArchived;

  return (
    <div className={styles.wrapper}>
      <DetailsBlock
        onAdd={() => onAdd('Add Additional Address', 'address')}
        onEdit={onEdit('address', 'Address', false)}
        title="Location"
        buttonText={
          isClientActive && (isLocalAdmin || isProgramAssistant) && 'Add Additional Address'
        }
        showEdit={isClientActive}
        addresses={addresses}
        setDetailsInfo={rest => setDetailsInfo({ typeDetails: 'address', ...rest })}
        showArchivedButton
        noActions={!isLocalAdmin && !isProgramAssistant}
        chip={(isLocalAdmin || isProgramAssistant) && <LocationGroup />}
      />
      <div className={styles.width}>
        {(modalTitle || (details && details.id)) && (
          <Modal
            className={styles.modal}
            open
            onClose={() => {
              setModalTitle(null);
              setDetails(null);
              setCurrent(null);
            }}
          >
            {loadingDetails ? (
              <Loader />
            ) : (
              <div className={styles.modalWrapper}>
                <h3 className={styles.modalTitle}>{modalTitle}</h3>
                <Forms
                  formType={formType}
                  setModalTitle={setModalTitle}
                  setDetails={setDetails}
                  requestInfo={requestInfo}
                  details={details}
                  showSecondSubmit={showSecondSubmit}
                  current={current}
                  primaryDiagnosis={null}
                />
              </div>
            )}
          </Modal>
        )}
        {detailsInfo && (
          <ConfirmModal
            onConfirm={() => {
              onConfirmModal({
                clientId: String(client.id),
                clinicId,
                teamId: actTeamId,
                id: String(detailsInfo?.id),
              });
            }}
            onClose={() => setDetailsInfo(null)}
            description={description}
            confirmText={confirmText}
          />
        )}
      </div>
    </div>
  );
};

export default LocationDetails;

import { action } from 'easy-peasy';
import { setResourcesFilter } from '~/services/localStorage/filters';

import { IActionSetResourcesFilters } from '../types';

const setResourcesFilters: IActionSetResourcesFilters = action((state, payload) => {
  // save filters to storage
  setResourcesFilter(payload);

  state.filters = payload;
});

export default setResourcesFilters;

import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import useFeature from '~/store/clinic/hooks/useFeature';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import filterGoals from '../../utils/filterGoals';
import formatVisitToForm from '../../utils/formatVisitToForm';

import Loader from '~/ui/components/common/Loader';
import VisitBreadcrumbs from './components/VisitBreadcrumbs';
import Form from '../Form';

import { PaperWorkStatus } from '~/services/api/clientDetails/types';

type ILocationState = {
  state: { fromPreview?: boolean; paperwork?: PaperWorkStatus; clientNoShow: boolean };
};

const AddVisit = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const { visitId } = useParams<{ visitId: string }>();
  const location: ILocationState = useLocation();
  const { fromPreview, paperwork, clientNoShow } = location.state || {};

  const { current } = useStoreState(state => state.user);
  const { current: client } = useStoreState(state => state.client);
  const { treatmentPlans } = useStoreState(state => state.clientDetails);
  const { current: visit } = useStoreState(state => state.visits);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { onGetVisit } = useStoreActions(action => action.visits);
  const { onGetTreatmentPlans } = useStoreActions(action => action.clientDetails);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const { isTreatmentPlansAvailable } = useFeature();

  const activeTreatmentPlan = treatmentPlans.find(plan => plan.active && !plan.isArchived);

  const query = useQuery();
  const teamId = query.get('teamId');
  const clientId = query.get('clientId');

  const onMount = useCallback(async () => {
    setLoading(true);

    try {
      await onGetMyClinic();
      if (isTreatmentPlansAvailable) {
        await onGetTreatmentPlans({
          clinicId: String(current?.clinic?.id),
          teamId,
          clientId,
        });
      }

      if (visitId) {
        await onGetVisit({
          requestInfo: {
            clinicId: String(current?.clinic?.id),
            clientId,
            visitId: String(visitId),
            teamId,
          },
        });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    clientId,
    current?.clinic?.id,
    isTreatmentPlansAvailable,
    onGetMyClinic,
    onGetTreatmentPlans,
    onGetVisit,
    showError,
    teamId,
    visitId,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount, visitId]);

  const clientFullName = client ? `${client?.firstName} ${client?.lastName}` : '';
  if (loading) return <Loader />;
  return (
    <>
      <h2>{clientFullName}</h2>
      <VisitBreadcrumbs
        clientId={clientId}
        teamId={teamId}
        clientName={clientFullName}
        isEdit={!!visitId}
        visitId={visitId}
      />
      <Form
        visitId={visitId}
        defaultValues={visitId ? formatVisitToForm(visit) : null}
        fromPreview={fromPreview}
        paperworkStatus={paperwork}
        clientNoShow={clientNoShow}
        treatmentPlan={
          visitId
            ? visit.treatmentPlanDetails
            : { ...activeTreatmentPlan, goals: filterGoals(activeTreatmentPlan?.goals) }
        }
      />
    </>
  );
};

export default AddVisit;

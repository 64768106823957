import { IOption } from '~/types';

const formatEnumToOptions = <T>(sortBy: T): IOption[] =>
  Object.keys(sortBy)
    .map(item => ({
      value: (sortBy as unknown as { [key: string]: number | string })[item],
      label: item,
    }))
    .filter(item => typeof item.value === 'number');

export default formatEnumToOptions;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportVisit, IReportsActions, IThunkGetVisitsListByTeamReport } from '../types';

const onGetVisitsByTeamReport: IThunkGetVisitsListByTeamReport = thunk(
  async ({ setVisitsListByTeamReport }: Actions<IReportsActions>, { requestInfo, params }) => {
    const visitsListByTeamReport: {
      total: number;
      items: IReportVisit[];
    } = await api.reports.getVisitsListByTeamReport(requestInfo, params).then(r => r.data);

    setVisitsListByTeamReport({
      items: visitsListByTeamReport.items,
      teamMemberId: params.teamMemberId,
      pagination: { ...params, total: visitsListByTeamReport.total },
    });
  },
);

export default onGetVisitsByTeamReport;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IDictionariesActions, IThunkGetDictionariesByTypePaginated } from '../types';

const onGetDictionariesByTypePaginated: IThunkGetDictionariesByTypePaginated = thunk(
  async ({ setAvailableDictionaries }: Actions<IDictionariesActions>, payload) => {
    const { items, total } = await api.dictionaries
      .getAvailableTypeListPaginated(payload)
      .then(r => r.data);

    setAvailableDictionaries({
      type: payload.type,
      items,
      total: payload.name?.length ? undefined : total,
    });
  },
);

export default onGetDictionariesByTypePaginated;

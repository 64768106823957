import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IReleaseActions, IReleaseStore, IThunkReadReleaseUpdates } from '../types';

const onReadReleaseUpdates: IThunkReadReleaseUpdates = thunk(
  async ({ setReleaseUpdates }: Actions<IReleaseActions>, payload, { getState }) => {
    const localState = getState() as IReleaseStore;
    await api.release.readReleaseUpdates(payload);

    setReleaseUpdates({ ...localState.releaseUpdates, updatesAvailable: false });
  },
);

export default onReadReleaseUpdates;

import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IClientModel } from './types';

const client: IClientModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default client;

import { action } from 'easy-peasy';
import { setDashboardFilter } from '~/services/localStorage/filters';

import { IActionSetDashboardFilters } from '../types';

const setDashboardFilters: IActionSetDashboardFilters = action((state, payload) => {
  // save filters to storage
  setDashboardFilter({ ...state.dashboard.filters, ...payload });

  state.dashboard.filters = { ...state.dashboard.filters, ...payload };
});

export default setDashboardFilters;

import { action } from 'easy-peasy';

import { IActionSetClientLastContactReportFilters } from '../types';

const setClientLastContactReportFilters: IActionSetClientLastContactReportFilters = action(
  (state, filters) => {
    state.clientLastContact.filters = filters;
  },
);

export default setClientLastContactReportFilters;

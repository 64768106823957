import isJson from './isJson';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getChannelDataItem = (data?: string, item?: string): any => {
  if (!data?.length || !item?.length) {
    return undefined;
  }
  const canParse = isJson(data);
  if (canParse) {
    const obj = JSON.parse(data);
    return obj[item];
  }
  return undefined;
};

export default getChannelDataItem;

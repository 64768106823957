import { action } from 'easy-peasy';
import unionBy from 'lodash/unionBy';

import { sortByValue } from '~/ui/pages/Clients/reusable/HealthDetails/utils';
import { IActionSetAvailableDictionaries } from '../types';

const setAvailableDictionaries: IActionSetAvailableDictionaries = action((state, payload) => {
  const newItems = unionBy(
    [...payload.items, ...(state.availableDictionaries[payload.type]?.list || [])],
    'id',
  ).sort(sortByValue);

  state.availableDictionaries = {
    ...state.availableDictionaries,
    [payload.type]: {
      ...(state.availableDictionaries[payload.type] || {}),
      current: state.availableDictionaries?.[payload.type]?.current || null,
      list: newItems,
      total:
        typeof payload.total === 'number'
          ? payload.total
          : state.availableDictionaries?.[payload.type]?.total || 0,
    },
  };
});

export default setAvailableDictionaries;

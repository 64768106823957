import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EmailForm from './EmailForm';
import CodeForm from '../SignUp/components/CodeForm';
import ResetPasswordForm from '../SignUp/components/PasswordForm';
import { extractErrorMessage } from '~/utils/error/error';
import api from '~/services/api';
import { SIGN_IN } from '~/ui/constants/paths';

import companyLogo from '~/ui/assets/images/logo.svg';
import styles from './ForgotPassword.module.scss';

const ForgotPassword = (): ReactElement => {
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState<string>(null);
  const [currentCode, setCurrentCode] = useState<string>(null);
  const [error, setError] = useState<string>(null);
  const [step, setStep] = useState(0);

  const onSubmitEmail = async (vals: { email: string }) => {
    const email = vals.email?.trim();
    try {
      await api.auth.resetPasswordEmail({ email });
      setCurrentEmail(email);
      setStep(1);
      setError(null);
    } catch (e) {
      if (!email) {
        setError('This field is required');
        return;
      }
      setError(extractErrorMessage(e));
    }
  };
  const onSubmitCode = async (codeObj: any) => {
    const code = Object.values(codeObj).join('');
    try {
      await api.auth.validateRessetPasswordCode({ email: currentEmail, code });
      setCurrentCode(code);
      setStep(2);
      setError(null);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  const onSubmitResetPassword = async (vals: any) => {
    try {
      const payload = { email: currentEmail, resetPasswordCode: currentCode, ...vals };
      await api.auth.resetPassword(payload);
      navigate(SIGN_IN);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  return (
    <div className={styles.container}>
      <img src={companyLogo} alt="Logo" />
      {step === 0 && <EmailForm onSubmit={onSubmitEmail} error={error} resetError={resetError} />}
      {step === 1 && (
        <CodeForm
          showBack
          error={error}
          goBack={() => setStep(0)}
          onSubmit={onSubmitCode}
          buttonText="Reset Password"
          subTitle="Enter a code from email to reset your password"
        />
      )}
      {step === 2 && (
        <ResetPasswordForm
          error={error}
          onSubmit={onSubmitResetPassword}
          title="Reset Password"
          subTitle="Enter a new password"
          buttonText="Set New Password"
        />
      )}
    </div>
  );
};

export default ForgotPassword;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ILocationGroupActions, IThunkGetClinicLocationGroup } from '../types';

const onGetClinicLocationGroup: IThunkGetClinicLocationGroup = thunk(
  async ({ setLocationGroup }: Actions<ILocationGroupActions>, payload) => {
    const { clinicId, id } = payload;
    const { data } = await api.locationGroups.getClinicLocationGroup(clinicId, id);

    setLocationGroup(data);
  },
);

export default onGetClinicLocationGroup;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveLocationGroup, ILocationGroupsStore, ILocationGroupActions } from '../types';

const onArchiveLocationGroup: IThunkArchiveLocationGroup = thunk(
  async ({ setLocationGroups }: Actions<ILocationGroupActions>, payload, { getState }) => {
    const localState = getState() as ILocationGroupsStore;
    const { clinicId, id } = payload;

    await api.locationGroups.archiveLocationGroup(clinicId, id);

    const updatedList = localState.list.map(item =>
      item.id !== id ? item : { ...item, isArchived: true },
    );

    setLocationGroups({ items: updatedList });
  },
);

export default onArchiveLocationGroup;

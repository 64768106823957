import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IThunkChangePassword, IUserActions } from '../types';

const onChangePassword: IThunkChangePassword = thunk(async (_: Actions<IUserActions>, payload) => {
  await api.auth.changePassword(payload).then(response => response.data);
});

export default onChangePassword;

import { VisitStatus } from '~/store/reports/models/visitStatus';

export const visitStatusOptions = [
  {
    value: VisitStatus.INITIATED,
    label: 'Initiated',
  },
  {
    value: VisitStatus.SENT_TO_EHR,
    label: 'Sent to EHR',
  },
  {
    value: VisitStatus.FINALIZED,
    label: 'Finalized',
  },
];

export const visitStatusLabels = {
  [VisitStatus.INITIATED]: 'Initiated',
  [VisitStatus.SENT_TO_EHR]: 'Sent to EHR',
  [VisitStatus.FINALIZED]: 'Finalized',
};

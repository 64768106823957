import { action } from 'easy-peasy';
import { IActionSetMedicationDeliveries } from '../types';

const setMedicationDeliveries: IActionSetMedicationDeliveries = action((state, payload) => {
  const { deliveries, monitoredBy } = payload;
  state.list = deliveries;
  state.monitoredBy = monitoredBy;
});

export default setMedicationDeliveries;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOrganizationArchive, IOrganizationStore, IOrganizationActions } from '../types';

const onArchiveOrganization: IThunkOrganizationArchive = thunk(
  async ({ setOrganizations }: Actions<IOrganizationActions>, organizationId, { getState }) => {
    const localState = getState() as IOrganizationStore;

    await api.organization.archiveOrganization(organizationId);

    const updatedList = localState.list.map(organization =>
      organization.id !== organizationId ? organization : { ...organization, isArchived: true },
    );

    setOrganizations({ items: updatedList });
  },
);

export default onArchiveOrganization;

import { yupResolver } from '@hookform/resolvers/yup';
import { array, boolean, date, number, object, string } from 'yup';
import { validateDuration } from '~/ui/configs/form/validation';
import { requiredField } from '~/ui/constants/errorMessages';
import { TestingStatus } from '../../constants/toxicologyStatus';

const validationSchema = object().shape({
  date: date().required(requiredField),
  type: number().required(requiredField),
  visitedPeople: array().when('clientNoShow', {
    is: true,
    then: array().nullable(),
    otherwise: array().of(number()).required(requiredField).min(1, requiredField),
  }),
  note: string(),
  visitors: array().of(number()).required(requiredField).min(1, requiredField),
  startTime: date().required(requiredField).typeError(requiredField),
  toxicology: object().when('clientNoShow', {
    is: true,
    then: object().nullable(),
    otherwise: object().shape({
      result: string().when('status', {
        is: (value: TestingStatus) => value !== TestingStatus.DONE,

        then: string().nullable(),
        otherwise: string().required(requiredField).typeError(requiredField),
      }),
      date: string().when('status', {
        is: (value: TestingStatus) => value !== TestingStatus.DONE,
        then: string().nullable(),
        otherwise: string().required(requiredField).typeError(requiredField),
      }),
    }),
  }),
  bloodDrawn: object().when('clientNoShow', {
    is: true,
    then: object().nullable(),
    otherwise: object().shape({
      drawDate: string().when('status', {
        is: (value: TestingStatus) => value !== TestingStatus.DONE,
        then: string().nullable(),
        otherwise: string().required(requiredField).typeError(requiredField),
      }),
    }),
  }),
  duration: string().when('clientNoShow', {
    is: true,
    then: string().nullable(),
    otherwise: string()
      .required(requiredField)
      .typeError(requiredField)
      .test('maxDuration', 'Maximum 8 hours', val => validateDuration(val, 'max'))
      .test('minDuration', 'Minimum 1 minute', val => validateDuration(val, 'min')),
  }),
  clientNoShow: boolean().nullable(),
  noShowReason: string().when('clientNoShow', {
    is: true,
    then: string().required(requiredField).min(1, requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
  city: string().when(['type', 'address'], {
    is: (type: number, address: number) => !type && !address,
    then: string().required(requiredField),
    otherwise: string().nullable(),
  }),
  stateId: number().when(['type', 'address'], {
    is: (type: number, address: number) => !type && !address,
    then: number().required(requiredField),
    otherwise: number().nullable(),
  }),
  zipCode: string().when(['type', 'address'], {
    is: (type: number, address: number) => !type && !address,
    then: string().min(5).max(5).required(requiredField),
    otherwise: string().nullable(),
  }),
  line1: string().when(['type', 'address'], {
    is: (type: number, address: number) => !type && !address,
    then: string().required(requiredField),
    otherwise: string().nullable(),
  }),
});

export default yupResolver(validationSchema);

import { action } from 'easy-peasy';
import { IActionResetSystemAlertsStore } from '../types';
import initStore from '../initStore';

const resetSystemAlertsStore: IActionResetSystemAlertsStore = action(state => {
  state.pagination = initStore.pagination;
  state.list = initStore.list;
  state.systemAlertUpdates = initStore.systemAlertUpdates;
});

export default resetSystemAlertsStore;

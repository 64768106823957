import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreSupportContact, IClientDetailsStore, IClientDetailsActions } from '../types';

const onRestoreSupportContact: IThunkRestoreSupportContact = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;
    const { clientId, clinicId, teamId, id } = payload;

    await api.clientDetails.restoreSupportContact(clinicId, teamId, clientId, id);

    const supportContacts = localState.supportContacts.map(item =>
      item.id !== Number(id) ? item : { ...item, isArchived: false },
    );

    setClientDetails({ supportContacts });
  },
);

export default onRestoreSupportContact;

import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import useRole from '../../../store/user/hooks/useRole';
import { CLINIC_MANAGEMENT, MY_CLINIC, SIGN_IN, REPORTS } from '~/ui/constants/paths';
import { useStoreState } from '~/store/hooks';

const Home = (): ReactElement => {
  const { authorized } = useStoreState(state => state.user);

  const { isSuperAdmin, isProductOwner, isLocalAdmin, isProgramAssistant, isActTeam, role } =
    useRole();

  const getRedirectToLink = () => {
    if (isProductOwner || isSuperAdmin) {
      return CLINIC_MANAGEMENT;
    }

    if (isProgramAssistant || isLocalAdmin || isActTeam) {
      return REPORTS;
    }
    return MY_CLINIC;
  };

  const to = getRedirectToLink();

  return authorized && role ? <Navigate to={to} replace /> : <Navigate to={SIGN_IN} replace />;
};

export default Home;

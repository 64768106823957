import setTypeList from './setTypeList';
import setAvailableDictionaries from './setAvailableDictionaries';
import setAvailableDictionaryFilters from './setAvailableDictionaryFilters';
import setCurrentAvailableDictionaryOption from './setCurrentAvailableDictionaryOption';
import setAllOptionsSelected from './setAllOptionsSelected';
import setExcludedAvailableDictionaryOptions from './setExcludedAvailableDictionaryOptions';

export default {
  setTypeList,
  setAvailableDictionaries,
  setAvailableDictionaryFilters,
  setCurrentAvailableDictionaryOption,
  setAllOptionsSelected,
  setExcludedAvailableDictionaryOptions,
};

import { action } from 'easy-peasy';

import { IActionSetVisitsListByClientReport } from '../types';

const setVisitsListByClientReport: IActionSetVisitsListByClientReport = action((state, payload) => {
  const { items, clientId, pagination } = payload;

  state.visitsByClient.current = {
    items: pagination.pageNumber === 1 ? items : [...state.visitsByClient.current.items, ...items],
    clientId,
    pagination: pagination || state.visitsByTeam.current.pagination,
  };
});

export default setVisitsListByClientReport;

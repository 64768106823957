import { ReactElement } from 'react';

import formatClientAddress from '~/utils/dataFormat/formatClientAddress';

import ListItem from '~/ui/components/common/ListItem';

import { IClientSupportContanctResponse } from '~/services/api/clientDetails/types';
import { IAddressExtended } from '~/services/api/types';

import styles from './DetailsBlock.module.scss';

interface IProps {
  supportContact: IClientSupportContanctResponse;
  className?: string;
  showEdit?: boolean;
  showArchived?: boolean;
  showRestore?: boolean;
  noActions?: boolean;
  onEdit?: (num: number) => void;
  onRestore?: (contactInfo: any) => void;
  onArchive?: (contactInfo: any) => void;
}

const DetailsBlock = ({
  supportContact,
  className,
  showEdit = false,
  showArchived = false,
  showRestore = false,
  noActions,
  onRestore,
  onArchive,
  onEdit,
}: IProps): ReactElement => {
  const { firstName, lastName, address } = supportContact;

  const fullName = `${firstName} ${lastName}`;

  const addr = address ? formatClientAddress(address as IAddressExtended) : '';

  const label = (
    <div className={styles.labelWrapper}>
      <div className={styles.black}>{fullName}</div>
      <div className={styles.grey}>{supportContact.phone}</div>
      <div className={styles.grey}>{supportContact.email}</div>
      <div className={styles.grey}>{addr}</div>
    </div>
  );

  return (
    <ListItem
      className={className}
      label={label}
      noActions={noActions}
      additionalText={supportContact.relationship.name}
      showEdit={showEdit}
      showArchived={showArchived}
      showRestore={showRestore}
      onRestore={() => onRestore({ fullName, contactId: supportContact.id })}
      onEdit={() => onEdit(supportContact.id)}
      onArchive={() => onArchive({ fullName, contactId: supportContact.id })}
    />
  );
};

export default DetailsBlock;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveMedication, IClientDetailsStore, IClientDetailsActions } from '../types';

const onArchiveMedication: IThunkArchiveMedication = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.archiveClientMedication(payload, payload.endDate);

    const medications = localState.medications.map(item =>
      item.id !== Number(payload.id)
        ? item
        : { ...item, endDate: payload.endDate, isArchived: true },
    );

    setClientDetails({ medications });
  },
);

export default onArchiveMedication;

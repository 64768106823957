import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreRelease, IReleaseStore, IReleaseActions } from '../types';

const onRestoreRelease: IThunkRestoreRelease = thunk(
  async ({ setReleases }: Actions<IReleaseActions>, releaseId, { getState }) => {
    const localState = getState() as IReleaseStore;
    await api.release.restoreRelease(releaseId);

    const updatedList = localState.list.map(release =>
      release.id !== releaseId ? release : { ...release, isArchived: false },
    );

    setReleases({ items: updatedList });
  },
);

export default onRestoreRelease;

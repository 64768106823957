import { ReactElement, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import groupByStatus from '~/utils/grouping/groupByStatus';

import Button from '~/ui/components/common/Button';
import ListItem from '~/ui/components/common/ListItem';

import { IClientPhone } from '~/services/api/clientDetails/types';
import { ReactComponent as TeamLeaderIcon } from '~/ui/assets/images/teamLeader.svg';
import styles from './DetailsBlock.module.scss';

interface IProps {
  title: string;
  buttonText?: string;
  showEdit?: boolean;
  items: IClientPhone[];
  email: string;
  noActions?: boolean;
  showArchive?: boolean;
  showRestore?: boolean;
  showArchivedButton?: boolean;
  labelGrey?: boolean;
  onAdd?: () => void;
  onEdit?: (num: number, name: string) => void;
  setDetailsInfo?: (data: any) => void;
}

const DetailsBlock = ({
  title,
  buttonText,
  showEdit = true,
  items,
  email,
  noActions,
  showArchive = true,
  showRestore = true,
  showArchivedButton,
  labelGrey,
  onAdd,
  onEdit,
  setDetailsInfo,
}: IProps): ReactElement => {
  const [showArchivedList, setShowArchivedList] = useState(false);

  const toggle = () => setShowArchivedList(val => !val);

  const { active, archived } = groupByStatus(items);

  const icon = !showArchivedList ? (
    <ExpandMoreIcon color="primary" />
  ) : (
    <ExpandLessIcon color="primary" />
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <h4 className={styles.title}>{title}</h4>
        {buttonText && (
          <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={onAdd}>
            {buttonText}
          </Button>
        )}
      </div>
      {!!active.length &&
        active.map((item, idx) => {
          const content = (
            <div className={styles.labelWrapper}>
              <div className={styles.grey}>{item.number}</div>
            </div>
          );

          return (
            <ListItem
              noActions={noActions}
              key={item.id}
              label={item.type?.name}
              content={content}
              className={
                (active.length === 1 || active.length - 1 === idx) && !!archived.length
                  ? styles.noBorder
                  : ''
              }
              onEdit={() => onEdit(item.id, item.type?.name)}
              showEdit={showEdit}
              showArchived={showArchive}
              disableArchived={item.isPrimary}
              startIcon={item.isPrimary ? <span>Primary</span> : undefined}
              onArchive={() =>
                setDetailsInfo({ method: 'archive', id: item.id, name: item.type?.name })
              }
            />
          );
        })}
      {showArchivedButton && !!archived.length && (
        <div className={styles.archivedButtonWrapper}>
          <button type="button" onClick={toggle} className={styles.archivedButton}>
            Archived {icon}
          </button>
        </div>
      )}

      {!!archived.length &&
        showArchivedList &&
        archived.map(item => {
          const content = (
            <div className={styles.labelWrapper}>
              <div className={styles.grey}>{item.number}</div>
            </div>
          );

          const itemLabel = item.type?.name;
          return (
            <ListItem
              noActions={noActions}
              key={item.id}
              label={itemLabel}
              content={content}
              onEdit={() => onEdit(item.id, itemLabel)}
              showRestore={showRestore}
              labelGrey={labelGrey}
              startIcon={item.isPrimary ? <TeamLeaderIcon /> : undefined}
              onRestore={() => setDetailsInfo({ method: 'restore', id: item.id, name: itemLabel })}
            />
          );
        })}
      <ListItem noActions label="Email" content={email} showEdit={false} showArchived={false} />
    </div>
  );
};

export default DetailsBlock;

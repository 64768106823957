import { ICoordinateStore } from './types';

const initStore: ICoordinateStore = {
  activeChannelId: null,
  channels: [],
  current: null,
  messages: {},
};

export default initStore;

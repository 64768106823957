import { action } from 'easy-peasy';
import { IActionSetSystemAlertsPagination } from '../types';

const setSystemAlertsPagination: IActionSetSystemAlertsPagination = action((state, pagination) => {
  state.systemAlertUpdates.pagination.pageNumber = pagination.pageNumber;
  state.systemAlertUpdates.pagination.pageSize = pagination.pageSize;
  state.systemAlertUpdates.pagination.total =
    typeof pagination.total === 'number'
      ? pagination.total
      : state.systemAlertUpdates.pagination.total;
});

export default setSystemAlertsPagination;

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { requiredField, validEmail } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  email: string()
    .max(100, 'Max length - 100 symbols')
    .trim()
    .email(validEmail)
    .required(requiredField),
  password: string()
    .min(8, 'Min length - 8 symbols')
    .max(100, 'Max length - 100 symbols')
    .required(requiredField),
});

export default yupResolver(validationSchema);

import {
  IClientDeEscalationTechnique,
  IClientDeEscalationResponse,
} from '~/services/api/clientDetails/types';

const formatDeEscalation = (
  deEscalation: IClientDeEscalationResponse,
): IClientDeEscalationTechnique => ({
  id: deEscalation.id,
  note: deEscalation.note,
  deEscalationTechnique: {
    id: deEscalation.deEscalationTechnique.id,
    name: deEscalation.deEscalationTechnique.name,
  },
  isArchived: false,
});

export default formatDeEscalation;

import { IResponse } from '~/services/api/types';
import { privateAxios } from '../requestsCollection';
import { ILocationGroup, ILocationGroupRequest, ILocationGroupsParams } from './types';

export const getClinicLocationGroups = (
  clinicId: string,
  params: ILocationGroupsParams,
): IResponse<{ items: ILocationGroup[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/allocation-group`,
    params,
  });

export const getClinicLocationGroup = (clinicId: string, id: number): IResponse<ILocationGroup> =>
  privateAxios({ method: 'get', url: `clinic/${clinicId}/allocation-group/${id}` });

export const archiveLocationGroup = (clinicId: string, id: number): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `/clinic/${clinicId}/allocation-group/${id}`,
  });

export const restoreLocationGroup = (clinicId: string, id: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `/clinic/${clinicId}/allocation-group/${id}/recover`,
  });

export const updateClinicLocationGroup = (
  clinicId: string,
  id: number,
  data: ILocationGroupRequest,
): IResponse<ILocationGroup> =>
  privateAxios({
    method: 'put',
    url: `/clinic/${clinicId}/allocation-group/${id}`,
    data,
  });

export const createClinicLocationGroup = (
  clinicId: string,
  data: ILocationGroupRequest,
): IResponse<ILocationGroup> =>
  privateAxios({
    method: 'post',
    url: `/clinic/${clinicId}/allocation-group`,
    data,
  });

export default {};

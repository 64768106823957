import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IOrganizationActions, IThunkGetOrganization } from '../types';

const onGetOrganization: IThunkGetOrganization = thunk(
  async ({ setOrganization }: Actions<IOrganizationActions>, organizationId) => {
    const { data } = await api.organization.getOrganization(organizationId);

    setOrganization(data);
  },
);

export default onGetOrganization;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClinicActions, IThunkGetClinic } from '../types';

const onGetClinic: IThunkGetClinic = thunk(
  async ({ setClinic }: Actions<IClinicActions>, clinicId) => {
    const { data } = await api.clinic.getClinic(clinicId);

    setClinic(data);
  },
);

export default onGetClinic;

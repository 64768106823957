import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { format } from 'date-fns';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Modal from '~/ui/components/common/Modal';
import isJson from '~/ui/pages/Coordinate/helpers/isJson';
import useRole from '~/store/user/hooks/useRole';

import { generalDateDotted } from '~/ui/constants/dateFormat';
import getAlertsIds from '../../helpers/getAlertsIds';
import styles from './SystemAlert.module.scss';

const DEFAULT_SYSTEM_ALERTS_PAYLOAD = { pageNumber: 1, pageSize: 100, onlyActive: true };

const SystemAlert = (): ReactElement => {
  const { isSuperAdmin, isProductOwner } = useRole();

  const [readAll, setReadAll] = useState(false);

  const user = useStoreState(state => state.user.current);
  const showSystemAlerts = !(isSuperAdmin || isProductOwner) && !!user;
  const { items: newAlerts } = useStoreState(state => state.systemAlert.systemAlertUpdates);
  const { onGetSystemAlertUpdates, onReadSystemAlert, onReadAllSystemAlert } = useStoreActions(
    actions => actions.systemAlert,
  );

  const handleReceiveSystemAlerts = useCallback(() => {
    onGetSystemAlertUpdates(DEFAULT_SYSTEM_ALERTS_PAYLOAD);
  }, [onGetSystemAlertUpdates]);

  // get release updates on mount
  useEffect(() => {
    if (showSystemAlerts) {
      handleReceiveSystemAlerts();
    }
  }, [showSystemAlerts, handleReceiveSystemAlerts]);

  const unreadSystemAlertsIds: number[] = useMemo(() => getAlertsIds(newAlerts), [newAlerts]);

  const showReadAll = unreadSystemAlertsIds.length > 3;

  const markAsRead = async () => {
    if (readAll) {
      await onReadAllSystemAlert(unreadSystemAlertsIds);
    } else {
      await onReadSystemAlert(newAlerts[0].id);
    }
    await onGetSystemAlertUpdates(DEFAULT_SYSTEM_ALERTS_PAYLOAD);
  };

  const alertAvailable = newAlerts?.[0] && !newAlerts?.[0].acknowledged;

  if (!alertAvailable || !user) {
    return null;
  }

  const latestAlert = newAlerts[0];

  return (
    <Modal
      open={!!alertAvailable}
      className={styles.modal}
      onClose={markAsRead}
      preventOutsideClick
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalTitle}>Alert notification!</div>
        <br />
        <strong>
          <span className={styles.label}>Alert Date:</span>
          <span className={styles.message}>
            {format(new Date(latestAlert.date), generalDateDotted)}
          </span>
          <br />
          <span className={styles.label}>Message:</span>
          <pre>
            {latestAlert.message && isJson(latestAlert.message)
              ? JSON.parse(latestAlert.message)
              : latestAlert.message}
          </pre>
        </strong>
        <div className={styles.actionButtons}>
          <Button color="primary" variant="contained" onClick={markAsRead}>
            I acknowledge
          </Button>
          {showReadAll && (
            <div className={styles.readAll}>
              <span>You have {unreadSystemAlertsIds.length} Alerts</span>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={readAll}
                    color="primary"
                    onChange={(_e, checked) => setReadAll(checked)}
                  />
                }
                label="Acknowledge All"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SystemAlert;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ILocalAdminActions, IThunkGetLocalAdmin } from '../types';

const onGetLocalAdmin: IThunkGetLocalAdmin = thunk(
  async ({ setLocalAdmin }: Actions<ILocalAdminActions>, payload) => {
    const { clinicId, localAdminId } = payload;
    const { data } = await api.localAdmin.getLocalAdmin(clinicId, localAdminId);

    setLocalAdmin(data);
  },
);

export default onGetLocalAdmin;

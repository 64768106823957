import { Actions, thunk } from 'easy-peasy';
import { IMedicationLogisticsActions, IThunkGetMedicationDeliveries } from '../types';
import api from '~/services/api';

const onGetMedicationDeliveries: IThunkGetMedicationDeliveries = thunk(
  async ({ setMedicationDeliveries }: Actions<IMedicationLogisticsActions>, payload) => {
    const { clinicId, teamId, clientId, from, to } = payload;

    const { medications, monitoredBy } = await api.medicationLogistics
      .getClientMedicationDeliveriesList({ clinicId, teamId }, { clientId, from, to })
      .then(r => r.data);

    setMedicationDeliveries({ deliveries: medications, monitoredBy });
  },
);

export default onGetMedicationDeliveries;

import onGetClinicActTeams from './onGetClinicActTeams';
import onGetActTeamsAvailable from './onGetActTeamsAvailable';
import onGetClinicActTeam from './onGetClinicActTeam';
import onArchiveActTeam from './onArchiveActTeam';
import onRestoreActTeam from './onRestoreActTeam';
import onGetAllActTeams from './onGetAllActTeams';

export default {
  onGetActTeamsAvailable,
  onGetClinicActTeams,
  onGetClinicActTeam,
  onArchiveActTeam,
  onRestoreActTeam,
  onGetAllActTeams,
};

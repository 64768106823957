import { useState, ReactElement, useMemo } from 'react';
import Header from './components/Header';
import Table from './components/Table';

import { useStoreActions, useStoreState } from '~/store/hooks';
import ResourcesModal from './components/ResourcesModal';
import { IResource } from '~/services/api/resource/types';
import { extractErrorMessage } from '~/utils/error/error';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ResourcesFilters from './components/ResourcesFilters';
import FileViewer from './components/FileViewer';

const Resources = (): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [archivingId, setArchivingId] = useState<number | null>(null);
  const [file, setFile] = useState<IResource | null>(null);

  const { current, list } = useStoreState(state => state.resource);

  const {
    onGetResources,
    setResource,
    onArchiveResource,
    onUpdateResource,
    onDownloadResource,
    setBlobUrl,
  } = useStoreActions(actions => actions.resource);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setResource(null);
    setBlobUrl(null);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleUpdateResource = async (item: IResource) => {
    try {
      await onUpdateResource({
        requestInfo: { id: item.id },
        requestPayload: { name: item.name, type: item.type, isVisible: item.isVisible },
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const downloadFile = async (item: IResource, open = false) => {
    try {
      await onDownloadResource({ resource: item, open });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const openFile = async (item: IResource) => {
    setFile(item);
  };

  const handleArchiveResource = async (id: number) => {
    try {
      await onArchiveResource(id);
      setArchivingId(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const attemptArchive = async (id: number) => {
    setArchivingId(id);
  };

  const resourceName = useMemo(
    () => list.find(item => item.id === archivingId),
    [list, archivingId],
  );

  return (
    <div>
      <Header openModal={handleOpenModal} />
      <ResourcesFilters />
      <Table
        resources={list}
        onGetResources={onGetResources}
        setResource={(organization: IResource) => {
          setResource(organization);
          setIsModalVisible(true);
        }}
        onUpdateResource={handleUpdateResource}
        onArchiveResource={attemptArchive}
        downloadFile={downloadFile}
        openFile={openFile}
      />
      {isModalVisible && <ResourcesModal resourceId={current?.id} onClose={handleCloseModal} />}
      {archivingId && (
        <ConfirmModal
          onConfirm={() => handleArchiveResource(archivingId)}
          onClose={() => setArchivingId(null)}
          description={`Are you sure you want to delete “${resourceName?.name}“ resource?`}
          confirmText="Proceed"
        />
      )}
      {file && <FileViewer file={file} onClose={() => setFile(null)} />}
    </div>
  );
};

export default Resources;

import { AxiosPromise } from 'axios';
import ClientLastContactSortableColumns from '~/ui/pages/Reports/constants/clientLastContactSortableColumns';
import ToxicologySortableColumns from '~/ui/pages/Reports/constants/toxicologySortableColumns';
import { FilterOptions, SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import { TestingStatus } from '~/ui/pages/Visits/constants/toxicologyStatus';
import { IDictionary } from './dictionaries/types';

export type IResponse<Model> = AxiosPromise<Model>;

export interface IRequestInfo {
  clinicId: string;
  teamId: string;
}

export interface IPasswordExpirationData {
  passwordExpiresSoon?: boolean;
  daysTillExpiration?: number;
}

export interface IRefreshAccessToken extends IPasswordExpirationData {
  accessToken: string;
  refreshToken: string;
  userId: string;
}
export interface IPagination {
  total?: number;
  pageNumber?: number;
  pageSize?: number;
}

export interface IFilters {
  sorting: number;
  status: number;
  name: string;
}

enum Status {
  Any,
  Archived,
  Active,
}

export enum Sorting {
  ByName,
  ByCreateDate,
  ByEditDate,
}

export interface IParams {
  name?: string;
  total?: number;
  pageNumber?: number;
  pageSize?: number;
  status?: Status;
  sorting?: string | Sorting;
  sortDirection?: string;
}

export interface IClientsParams extends IParams {
  teams?: string[];
  teamIds?: string[];
}

export interface IShelterParams extends IParams {
  filtering?: number;
}

export interface IDateRange {
  from?: string;
  to?: string;
}

export interface IVisitsReportParams extends IParams {
  from: string;
  to: string;
  zipCode?: string;
  teamMemberId?: number;
  teamMemberIds?: number[];
  teamIds: (string | number)[];
  clientIds?: (string | number)[];
  clientId?: number;
  filtering?: string | FilterOptions;
}

interface IClientLengthOfStayCommonParams extends IParams {
  ['Range.From']: string;
  ['Range.To']: string;
  onlyActive: boolean;
}

export interface IClientLengthOfStayOverviewParams extends IClientLengthOfStayCommonParams {
  teamIds: string[];
}

export interface IVisitStatusReportOverviewParams extends IParams {
  teamIds: number[];
  statuses: string[];
  ['DateRange.From']: string;
  ['DateRange.To']: string;
}

export interface ILocationGroupOverviewParams extends IParams {
  teamIds: string[] | number[];
}

export interface IToxicologyOverviewParams extends IParams {
  teamIds: number[];
  clientIds: number[];
  visitDateFrom?: string;
  visitDateTo?: string;
  toxicologyStatus?: TestingStatus | null;
  bloodDrawnStatus?: TestingStatus | null;
  sorting?: ToxicologySortableColumns;
  sortDirection?: SortDirection;
}

export interface IClientLastContactOverviewParams extends IParams {
  sorting?: ClientLastContactSortableColumns;
  sortDirection?: SortDirection;
  teamIds: number[];
  clientIds: number[];
}

export interface IClientLengthOfStayDetailsParams extends IClientLengthOfStayCommonParams {
  teamId: string;
}

export interface ITreatmentPlansReportParams extends IParams {
  from: string;
  to: string;
  clientIds: number[];
  teamIds: number[];
}
export interface IClientStatusReportParams extends Omit<IParams, 'status'> {
  teamIds: number[];
  statuses: string[];
  ['EntryDateRange.From']: string;
  ['EntryDateRange.To']: string;
  ['ReleaseDateRange.From']: string;
  ['ReleaseDateRange.To']: string;
}

export interface IDemographicsReportParams {
  teamIds?: number[];
  columns: string[];
  ['ageRange.from']: string;
  ['ageRange.to']: string;
  zipCode?: string;
  diagnosisIds?: string[];
  triggerIds?: string[];
  medicationIds?: string[];
  onlyActive?: boolean;
  rowsCount?: number;
  sorting?: string | Sorting;
  sortDirection?: string;
  filterLogic?: string;
  pageSize?: number;
  pageNumber?: number;
}

export interface IMedicationsReportCommonParams {
  teamIds?: number[];
  clinicId: number;
  ['InjectableDateRange.From']: string;
  ['InjectableDateRange.To']: string;
  ['EndDateRange.From']: string;
  ['EndDateRange.To']: string;
  includeDeleted?: boolean;
  pageSize?: number;
  pageNumber?: number;
}

export interface IMedicationsReportTeamParams
  extends Omit<IMedicationsReportCommonParams, 'teamIds'> {
  teamId: number;
}

export type IMedicationsReportSorting = 'InjectableDueDate' | 'StartDate' | 'EndDate';

export interface IMedicationsReportParams extends IMedicationsReportCommonParams {
  clientId: number;
  sortDirection?: SortDirection;
  sorting?: IMedicationsReportSorting;
}

export interface IMedicationsReportExportParams extends IMedicationsReportCommonParams {
  sortDirection?: SortDirection;
  sorting?: IMedicationsReportSorting;
}

export interface IClientAllocationVisitsReportParams extends IParams {
  from: string;
  to: string;
  onlyActive: boolean;
  teamIds: number[];
  clientIds: number[];
}

export interface IAddress {
  stateId: number;
  city: string;
  zipCode: string;
  line1: string;
  line2: string;
  poBox?: string;
  shelterName?: string;
}

export interface IAddressExtended extends Omit<IAddress, 'stateId'> {
  id: number;
  state: IDictionary & { abbreviation: string };
  lat: number;
  lng: number;
  isPrimary: boolean;
  isArchived: boolean;
  type: IDictionary;
  poBox?: string;
  shelterName?: string;
  shelterId?: number;
  isShelterAddress?: boolean;
  shelter: IDictionary;
  locationGroup?: IDictionary | null;
}

export interface ICommonClientDetails {
  id: number;
  note: string;
  isArchived: boolean;
}

export interface IClientStatus {
  value: string;
  dateOfEntry: string | null;
  dateOfRelease: string | null;
}

export interface IClientStatusHistory {
  current: IClientStatus;
  previous: IClientStatus[];
}

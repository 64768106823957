import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetAllergies } from '../types';

const onGetAllergies: IThunkGetAllergies = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const allergies = await api.clientDetails
      .getClientAllergyList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ allergies });
  },
);

export default onGetAllergies;

import { ReactElement } from 'react';
import { components, GroupTypeBase, MenuListComponentProps } from 'react-select';
import { IOption } from '~/types';

import styles from '../../Select/Select.module.scss';

const MenuList = ({
  children,
  label,
  ...props
}: MenuListComponentProps<IOption, boolean, GroupTypeBase<IOption>> & {
  label?: string;
}): ReactElement => (
  <components.MenuList {...props}>
    <div className={styles.helperText}>
      {`${label || 'Clients'} not assigned to any team member are highlighted in red.`}
    </div>
    {children}
  </components.MenuList>
);

export default MenuList;

import AddIcon from '@material-ui/icons/Add';
import { ReactElement, useState } from 'react';
import classNames from 'classnames';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Modal from '~/ui/components/common/Modal';
import Button from '~/ui/components/common/Button';
import ListItem from '~/ui/components/common/ListItem';
import isJson from '~/ui/pages/Coordinate/helpers/isJson';
import { IClientDetailed } from '~/services/api/client/types';
import { extractErrorMessage } from '~/utils/error/error';

import Form from './Form';
import styles from './Memos.module.scss';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
}

const Memos = ({ client, clinicId, actTeamId }: IProps): ReactElement => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { memo } = useStoreState(state => state.clientDetails);

  const { isArchived } = client || {};

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onEditMemo } = useStoreActions(actions => actions.clientDetails);

  const onAdd = () => {
    setModalVisible(true);
  };

  const onEdit = () => {
    setModalVisible(true);
  };

  const onSubmit = async (values: { memo?: string | null }) => {
    setIsLoading(true);
    try {
      const payload = {
        requestInfo: { clinicId, teamId: actTeamId, clientId: String(client.id) },
        memo: JSON.stringify(values.memo),
      };
      await onEditMemo(payload);
      setModalVisible(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={classNames(styles.row, styles.topBlock)}>
          <h4 className={styles.title}>Memos</h4>
          {!memo && !isArchived && (
            <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={onAdd}>
              Add Memo
            </Button>
          )}
        </div>
        <ListItem
          label={memo && isJson(memo) ? JSON.parse(memo) : memo || 'No memos'}
          onEdit={onEdit}
          showEdit={!!memo && !isArchived}
          className={styles.item}
        />
      </div>

      {isModalVisible && (
        <Modal
          className={styles.modal}
          open
          onClose={() => {
            setModalVisible(false);
          }}
        >
          <div>
            <h3 className={styles.modalTitle}>{`${memo ? 'Edit' : 'Add'} Memo`}</h3>
            <Form
              defaultValues={{ memo: memo && isJson(memo) ? JSON.parse(memo) : memo }}
              isProcessing={isLoading}
              onSubmit={onSubmit}
              onCancel={() => setModalVisible(false)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Memos;

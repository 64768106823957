import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import Tabs from '@material-ui/core/Tabs';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import concat from 'lodash/concat';

import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import PrivateImage from '~/ui/components/common/PrivateImage';
import Profile from '~/ui/pages/ActTeam/reusable/Profile';

import { useStoreActions, useStoreState } from '~/store/hooks';
import {
  CLINIC_EDIT_ACT_TEAM,
  CLINIC_MANAGEMENT,
  CLINIC_VIEW_ACT_TEAM,
  VIEW_CLINIC,
} from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error/error';

import editWhite from '~/ui/assets/images/editWhite.svg';
import styles from './ViewActTeam.module.scss';

type IParams = {
  clinicId: string;
  actTeamId: string;
};

const breadcrumbItem = [
  {
    title: 'Clinic management',
    to: CLINIC_MANAGEMENT,
  },
];

const ViewActTeam = (): ReactElement => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { clinicId, actTeamId } = useParams<IParams>();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const currentClinic = useStoreState(state => state.clinic.current);
  const currentActTeam = useStoreState(state => state.actTeam.current);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);

  const handleEditClick = () => {
    const to = CLINIC_EDIT_ACT_TEAM.replace(':clinicId', clinicId).replace(':actTeamId', actTeamId);
    const state = {
      from: CLINIC_VIEW_ACT_TEAM.replace(':clinicId', clinicId).replace(
        ':actTeamId',
        String(currentActTeam.id),
      ),
    };
    navigate(to, { state });
  };

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all([onGetClinicActTeam({ clinicId, actTeamId }), onGetClinic(clinicId)]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [actTeamId, clinicId, onGetClinic, onGetClinicActTeam, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !currentClinic || !currentActTeam) return <Loader />;

  const breadcrumbItems = concat(
    breadcrumbItem,
    {
      title: currentClinic?.name,
      to: VIEW_CLINIC.replace(':id', clinicId),
    },
    {
      title: currentActTeam?.name,
      to: pathname,
    },
  );

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2>{currentActTeam?.name}</h2>
          <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
        </div>
        <div className={styles.headerRightSide}>
          <div className={styles.imageContainer}>
            {currentClinic?.logo && (
              <PrivateImage
                src={currentClinic?.logo}
                alt="logo"
                className={styles.logo}
                withLoader
              />
            )}
          </div>
          {tab === 0 && !currentActTeam.isArchived && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<img src={editWhite} alt="edit" />}
              onClick={handleEditClick}
              disabled={currentActTeam.isArchived}
            >
              Edit Team Profile
            </Button>
          )}
        </div>
      </div>
      <div className={styles.tabsWrapper}>
        <Tabs
          value={tab}
          textColor="primary"
          indicatorColor="primary"
          onChange={(_e, val) => setTab(val)}
        >
          <Tab label="TEAM PROFILE" />
          <Tab label="TEAM MEMBERS" />
        </Tabs>
      </div>
      {tab === 0 && <Profile actTeam={currentActTeam} />}
      {tab === 1 && <Table />}
    </div>
  );
};

export default ViewActTeam;

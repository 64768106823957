import { ISystemAlertStore } from './types';

const pagination = { pageSize: 25, pageNumber: 1, total: 0 };

const initStore: ISystemAlertStore = {
  systemAlertUpdates: { items: [], pagination },
  list: [],
  pagination,
};

export default initStore;

import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { ILocalAdminModel } from './types';

const localAdmin: ILocalAdminModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default localAdmin;

import { action } from 'easy-peasy';
import { IActionSetReleasesPagination } from '../types';

const setReleasesPagination: IActionSetReleasesPagination = action((state, pagination) => {
  state.pagination.total = pagination.total;
  state.pagination.pageNumber = pagination.pageNumber;
  state.pagination.pageSize = pagination.pageSize;
});

export default setReleasesPagination;

import { action } from 'easy-peasy';

import { IActionSetRecertificationReportFilters } from '../types';

const setRecertificationReportFilters: IActionSetRecertificationReportFilters = action(
  (state, filters) => {
    state.recertification.filters = filters;
  },
);

export default setRecertificationReportFilters;

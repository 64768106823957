import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreResource, IResourceStore, IResourceActions } from '../types';

const onRestoreResource: IThunkRestoreResource = thunk(
  async ({ setResources }: Actions<IResourceActions>, resourceId, { getState }) => {
    const localState = getState() as IResourceStore;
    await api.resource.restoreResource(resourceId);

    const updatedList = localState.list.map(resource =>
      resource.id !== resourceId ? resource : { ...resource, isArchived: false },
    );

    setResources({
      items: updatedList,
      pagination: localState.pagination,
    });
  },
);

export default onRestoreResource;

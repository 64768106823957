import { IAvailableDictionaryFilters } from '~/store/dictionaries/types';
import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import {
  IAvailableDictionary,
  IDictionariesParams,
  IDictionaryItem,
  IDictionaryType,
} from './types';

export const getTypes = (): IResponse<IDictionaryType[]> =>
  privateAxios({ method: 'get', url: 'dictionaries/types' });

// Get list of items of specified dictionary for menu
export const getTypeList = (
  type: string,
  params?: IDictionariesParams,
): IResponse<{ total: number; items: IDictionaryItem[] }> =>
  privateAxios({
    method: 'get',
    url: `dictionaries/${type}/list`,
    params,
  });

// Get list of items of specified dictionary for dropdown
export const getAvailableTypeList = (type: string): IResponse<IAvailableDictionary[]> =>
  privateAxios({
    method: 'get',
    url: `dictionaries/${type}/available-list`,
  });

export const getAvailableTypeListPaginated = ({
  type,
  ...params
}: { type: string } & IAvailableDictionaryFilters): IResponse<{
  items: IAvailableDictionary[];
  total: number;
}> =>
  privateAxios(
    {
      method: 'get',
      url: `dictionaries/${type}/available-list`,
      params,
    },
    'v2/',
  );

export const archiveTypeItem = (type: string, id: number): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `dictionaries/${type}/${id}`,
  });

export const restoreTypeItem = (type: string, id: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `dictionaries/${type}/${id}/recover`,
  });

export const updateTypeItem = ({
  type,
  id,
  isArchived,
  ...rest
}: {
  type: string;
  id: number;
  value: string;
  isArchived: boolean;
  [key: string]: string | number | boolean;
}): IResponse<IDictionaryItem> =>
  privateAxios({
    method: 'put',
    url: `dictionaries/${type}/${id}`,
    data: rest,
  });

export const addTypeItem = ({
  type,
  isArchived,
  ...rest
}: {
  type: string;
  value: string;
  isArchived: boolean;
  [key: string]: string | number | boolean;
}): IResponse<IDictionaryItem> =>
  privateAxios({
    method: 'post',
    url: `dictionaries/${type}`,
    data: rest,
  });

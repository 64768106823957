import setClients from './setClients';
import setClient from './setClient';
import setAvailableClients from './setAvailableClients';
import resetCurrentClient from './resetCurrentClient';
import resetClientStore from './resetClientStore';
import setDefaultClient from './setDefaultClient';
import setClientDetails from '../../clientDetails/actions/setClientDetails';
import setClientOptions from './setClientOptions';
import setClientOptionsFilter from './setClientOptionsFilter';
import setCurrentClientOption from './setCurrentClientOption';
import setExcludedClientOption from './setExcludedClientOption';
import setAllClientOption from './setAllClientOption';

export default {
  setClients,
  setClient,
  setAvailableClients,
  resetCurrentClient,
  setClientDetails,
  setDefaultClient,
  resetClientStore,
  setClientOptions,
  setClientOptionsFilter,
  setCurrentClientOption,
  setExcludedClientOption,
  setAllClientOption,
};

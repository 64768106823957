import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveAvatar, IUserActions } from '../types';

const onRemoveAvatar: IThunkRemoveAvatar = thunk(
  async ({ removeAvatar }: Actions<IUserActions>) => {
    await api.users.uploadProfilePhoto('');
    removeAvatar();
  },
);

export default onRemoveAvatar;

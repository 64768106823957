import { IActTeamStore } from './types';

const initStore: IActTeamStore = {
  current: null,
  list: [],
  availableActTeams: [],
  pagination: {
    pageSize: 25,
    pageNumber: 1,
    total: 0,
  },
};

export default initStore;

import { action } from 'easy-peasy';
import { IActionSetSystemAlerts } from '../types';

const setSystemAlerts: IActionSetSystemAlerts = action((state, payload) => {
  const { items, pagination } = payload;

  state.list = items;

  if (pagination) {
    state.pagination.total = pagination.total;
    state.pagination.pageNumber = pagination.pageNumber;
    state.pagination.pageSize = pagination.pageSize;
  }
});

export default setSystemAlerts;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetVisitsListByClientReport } from '../types';

const onGetVisitsListByClientReport: IThunkGetVisitsListByClientReport = thunk(
  async ({ setVisitsListByClientReport }: Actions<IReportsActions>, { requestInfo, params }) => {
    const { clinicId } = requestInfo;

    const visitsListByClientReport = await api.reports
      .getVisitsListByClientReport({ clinicId }, params)
      .then(r => r.data);

    setVisitsListByClientReport({
      items: visitsListByClientReport.items,
      clientId: params.clientId,
      pagination: { ...params, total: visitsListByClientReport.total },
    });
  },
);

export default onGetVisitsListByClientReport;

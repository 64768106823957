import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import formatDeEscalation from '~/utils/formatDeEscalation';
import { IThunkUpdateDeEscalation, IClientDetailsStore, IClientDetailsActions } from '../types';

const onUpdateDeEscalation: IThunkUpdateDeEscalation = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const deEscalation = await api.clientDetails
      .updateDeEscalationTechnique(requestInfo, requestPayload)
      .then(r => formatDeEscalation(r.data));

    const deEscalationTechniques = localState.deEscalationTechniques.map(item =>
      item.id !== deEscalation.id ? item : deEscalation,
    );

    setClientDetails({ deEscalationTechniques });
  },
);

export default onUpdateDeEscalation;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetVisitsByClientReport } from '../types';

const onGetVisitsByClientReport: IThunkGetVisitsByClientReport = thunk(
  async ({ setVisitsByClientReport }: Actions<IReportsActions>, { requestInfo, params }) => {
    const visitsByClientReport = await api.reports
      .getVisitsByClientReport(requestInfo, params)
      .then(r => r.data);

    setVisitsByClientReport({
      visitsByClient: {
        items: visitsByClientReport.items.items,
        total: visitsByClientReport.items.total,
        totals: visitsByClientReport.totals,
        pagination: params,
      },
    });
  },
);

export default onGetVisitsByClientReport;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkApproveSosRequest, ISosRequestsStore, ISosRequestActions } from '../types';

const onApproveSosRequest: IThunkApproveSosRequest = thunk(
  async ({ setSosRequests }: Actions<ISosRequestActions>, payload, { getState }) => {
    const localState = getState() as ISosRequestsStore;
    const { clinicId, teamId, userId } = payload;

    await api.sosRequests.approveSosRequest({
      clinicId,
      teamId,
      userId,
    });

    const updatedList = localState.list.filter(item => String(item.id) !== userId);

    setSosRequests({ items: updatedList });
  },
);

export default onApproveSosRequest;

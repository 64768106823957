import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkApproveDeleteRequest, IDeleteRequestsStore, IDeleteRequestActions } from '../types';

const onApproveDeleteRequest: IThunkApproveDeleteRequest = thunk(
  async ({ setDeleteRequests }: Actions<IDeleteRequestActions>, payload, { getState }) => {
    const localState = getState() as IDeleteRequestsStore;
    const { clinicId, teamId, userId } = payload;

    await api.deleteRequests.approveDeleteRequest({
      clinicId,
      teamId,
      userId,
    });

    const updatedList = localState.list.filter(item => String(item.id) !== userId);

    setDeleteRequests({ items: updatedList });
  },
);

export default onApproveDeleteRequest;

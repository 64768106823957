import { format } from 'date-fns';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';
import isJson from '~/ui/pages/Coordinate/helpers/isJson';
import useRole from '~/store/user/hooks/useRole';

import { generalDateDotted } from '~/ui/constants/dateFormat';
import styles from './ReleaseAlert.module.scss';
import getReleasesIds from './helpers/getReleasesIds';

const ReleaseAlert = (): ReactElement => {
  const { isSuperAdmin, isProductOwner } = useRole();

  const [readAll, setReadAll] = useState(false);

  const user = useStoreState(state => state.user.current);
  const showReleaseUpdates = !(isSuperAdmin || isProductOwner) && !!user;
  const { updatesAvailable, newReleases } = useStoreState(state => state.release.releaseUpdates);
  const { onGetReleaseUpdates, onReadReleaseUpdates, onReadAllReleaseUpdates } = useStoreActions(
    actions => actions.release,
  );

  const handleReceiveReleaseUpdates = useCallback(() => {
    onGetReleaseUpdates();
  }, [onGetReleaseUpdates]);

  // get release updates on mount
  useEffect(() => {
    if (showReleaseUpdates) {
      handleReceiveReleaseUpdates();
    }
  }, [showReleaseUpdates, handleReceiveReleaseUpdates]);

  const unreadReleasesIds: number[] = useMemo(() => getReleasesIds(newReleases), [newReleases]);

  const showReadAll = unreadReleasesIds.length > 3;

  const markAsRead = async () => {
    if (readAll) {
      await onReadAllReleaseUpdates(unreadReleasesIds);
    } else {
      await onReadReleaseUpdates(newReleases[0].id);
    }
    await onGetReleaseUpdates();
  };

  if (!updatesAvailable || !user) {
    return null;
  }

  const latestRelease = newReleases[0];

  return (
    <Modal open={!!updatesAvailable} className={styles.modal} onClose={markAsRead}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalTitle}>Welcome {user.firstName}!</div>
        <p>The VCP has a new release available.</p>
        <strong>
          <span>Version: {latestRelease.version}</span>
          <br />
          <span>Release Date: {format(new Date(latestRelease.date), generalDateDotted)}</span>
          <br />
          <span>What’s New:</span>
          <br />
          <pre>
            {latestRelease.notes && isJson(latestRelease.notes)
              ? JSON.parse(latestRelease.notes)
              : latestRelease.notes}
          </pre>
        </strong>
        <p>
          Please clear your browser history and reload the application to access the latest version.
        </p>
        <div className={styles.actionButtons}>
          <Button color="primary" variant="contained" onClick={markAsRead}>
            I acknowledge
          </Button>
          {showReadAll && (
            <div className={styles.readAll}>
              <span>You have {unreadReleasesIds.length} Alerts</span>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={readAll}
                    color="primary"
                    onChange={(_e, checked) => setReadAll(checked)}
                  />
                }
                label="Acknowledge All"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReleaseAlert;

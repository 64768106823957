import { action } from 'easy-peasy';

import { IActionSetClientLengthOfStayDetailsReport } from '../types';

const setClientLengthOfStayDetailsReport: IActionSetClientLengthOfStayDetailsReport = action(
  (state, payload) => {
    const { items, teamId, total, pagination } = payload;

    state.clientLengthOfStay.current = {
      items:
        pagination.pageNumber === 1 ? items : [...state.clientLengthOfStay.current.items, ...items],
      teamId,
      total: total || state.clientLengthOfStay.current.total,
      pagination: pagination || state.clientLengthOfStay.current.pagination,
    };
  },
);

export default setClientLengthOfStayDetailsReport;

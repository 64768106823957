import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IProgramAssistantActions, IThunkOnUnlockProgramAssistant } from '../types';

const onUnlockProgramAssistant: IThunkOnUnlockProgramAssistant = thunk(
  async ({ activateProgramAssistant }: Actions<IProgramAssistantActions>, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.unlockProgramAssistant(clinicId, String(programAssistantId));

    activateProgramAssistant(programAssistantId);
  },
);

export default onUnlockProgramAssistant;

import { IDictionariesStore } from './types';

const initStore: IDictionariesStore = {
  list: [],
  pagination: {
    pageSize: 25,
    pageNumber: 1,
    total: 0,
  },
  availableDictionaries: {},
};

export default initStore;

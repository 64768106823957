import { ReactNode } from 'react';

import ResourceType from '../models/ResourceType';

import { ReactComponent as DocumentIcon } from '~/ui/assets/images/fileType/document.svg';
import { ReactComponent as ExcelIcon } from '~/ui/assets/images/fileType/excel.svg';
import { ReactComponent as ImageIcon } from '~/ui/assets/images/fileType/image.svg';
import { ReactComponent as PdfIcon } from '~/ui/assets/images/fileType/pdf.svg';
import { ReactComponent as PresentationIcon } from '~/ui/assets/images/fileType/presentation.svg';
import { ReactComponent as TextIcon } from '~/ui/assets/images/fileType/text.svg';

const getFileTypeIcon = (type: ResourceType): ReactNode => {
  switch (type) {
    case ResourceType.Pdf: {
      return <PdfIcon />;
    }
    case ResourceType.Excel: {
      return <ExcelIcon />;
    }
    case ResourceType.Document: {
      return <DocumentIcon />;
    }
    case ResourceType.Text: {
      return <TextIcon />;
    }
    case ResourceType.Image: {
      return <ImageIcon />;
    }
    case ResourceType.Presentation: {
      return <PresentationIcon />;
    }
    default: {
      return <TextIcon />;
    }
  }
};

export default getFileTypeIcon;

import onArchiveClinic from './onArchiveClinic';
import onGetClinic from './onGetClinic';
import onGetClinics from './onGetClinics';
import onRestoreClinic from './onRestoreClinic';
import onGetMyClinic from './onGetMyClinic';

export default {
  onArchiveClinic,
  onGetClinic,
  onGetClinics,
  onRestoreClinic,
  onGetMyClinic,
};

import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmModal from '~/ui/components/common/ConfirmModal';
import LocationGroupModal from './LocationGroupModal';
import LocationGroupsTable from './Table';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

type IParams = {
  id: string;
};

const LocationGroups = (): ReactElement => {
  const { id } = useParams<IParams>();
  const [isArchiveLocationGroup, setIsArchiveLocationGroup] = useState(null);
  const [locationGroup, setLocationGroup] = useState(null);
  const [locationGroupModalVisible, setLocationGroupModalVisible] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const { onArchiveLocationGroup, onRestoreLocationGroup } = useStoreActions(
    actions => actions.locationGroups,
  );

  const confirmText = isArchiveLocationGroup ? 'Archive' : 'Restore';
  const description = `Are you sure you want to ${confirmText.toLowerCase()} “${
    locationGroup?.name
  }” Location Group?`;

  const onConfirmMethod = isArchiveLocationGroup ? onArchiveLocationGroup : onRestoreLocationGroup;

  const onConfirm = async (locationGroupId: number) => {
    try {
      await onConfirmMethod({ clinicId: id, id: locationGroupId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLocationGroup(null);
    }
  };

  return (
    <>
      <LocationGroupsTable
        setLocationGroup={setLocationGroup}
        setIsArchiveLocationGroup={setIsArchiveLocationGroup}
        setLocationGroupModalVisible={setLocationGroupModalVisible}
        clinicId={id}
      />
      {locationGroup && !locationGroupModalVisible && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onConfirm={() => onConfirm(locationGroup.id)}
          onClose={() => setLocationGroup(null)}
        />
      )}
      {locationGroupModalVisible && (
        <LocationGroupModal
          id={locationGroup?.id}
          onClose={() => {
            setLocationGroup(null);
            setLocationGroupModalVisible(null);
          }}
        />
      )}
    </>
  );
};

export default LocationGroups;

import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkAssignTeamMember } from '../types';

const onAssignTeamMember: IThunkAssignTeamMember = thunk(async (_, payload) => {
  const { clinicId, teamId, userId, role } = payload;

  await api.actTeamMember.assignTeamMember(clinicId, userId, teamId, role);
});

export default onAssignTeamMember;

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as DangerIcon } from '~/ui/assets/images/danger.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './InfoMessage.module.scss';

interface IProps {
  message: string;
  className?: string;
  messageClassName?: string;
  icon?: React.ReactNode;
}

const InfoMessage = ({
  className,
  message,
  messageClassName,
  icon = <DangerIcon color={variables.colorRed} />,
}: IProps): ReactElement => (
  <Box className={classNames(styles.container, className)}>
    {icon}
    <Typography variant="body2" className={classNames(styles.message, messageClassName)}>
      {message}
    </Typography>
  </Box>
);

export default InfoMessage;

import { differenceInCalendarDays, startOfMonth } from 'date-fns';

import { paperworkStatuses } from '~/ui/pages/Reports/constants/recertificationPaperworkStatusOptions';
import { PaperWorkStatus } from '~/services/api/clientDetails/types';
import variables from '~/ui/assets/styles/colors.module.scss';

export const getRecertificationColorByDate = (
  recertificationDate: string,
  status?: PaperWorkStatus | null,
): string => {
  let color = variables.colorGrey;

  if (!recertificationDate) {
    return color;
  }

  if (status === PaperWorkStatus.Submitted) {
    return variables.colorGreen;
  }

  if (status === PaperWorkStatus.PromptAcknowledged) {
    return variables.colorOrange;
  }

  if (status === PaperWorkStatus.Expired) {
    return variables.colorRed;
  }

  const date = startOfMonth(new Date(recertificationDate));

  const differenceInDays = differenceInCalendarDays(new Date(date), new Date());

  if (differenceInDays < 75) {
    color = variables.colorOrange;
  }
  if (differenceInDays < 60) {
    color = variables.colorRed;
  }

  return color;
};

export const getExpiresIn = (date: string): string => {
  const differenceInDays = differenceInCalendarDays(new Date(date), new Date());

  return differenceInDays > 0 && differenceInDays < 4
    ? ` | Expiration in ${differenceInDays} Day${differenceInDays > 1 ? 's' : ''}`
    : '';
};

export const getPaperworkStatus = (status: PaperWorkStatus | null): string =>
  paperworkStatuses[status] || '';

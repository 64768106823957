import { ReactElement } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import styles from './Loader.module.scss';

interface IProps {
  size?: number;
  className?: string;
}

const Loader = ({ className, size = 30 }: IProps): ReactElement => (
  <div className={classNames(styles.loader, className)}>
    <CircularProgress color="primary" size={size} />
  </div>
);

export default Loader;

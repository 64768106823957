import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetDeEscalation } from '../types';

const onGetDeEscalation: IThunkGetDeEscalation = thunk(
  async ({ setCurrent }: Actions<IClientDetailsActions>, payload) => {
    const current = await api.clientDetails
      .getClientDeEscalationTechnique(payload)
      .then(r => r.data);

    setCurrent(current);
  },
);

export default onGetDeEscalation;

import classNames from 'classnames';
import { ReactElement } from 'react';

import styles from './Chip.module.scss';

interface IProps {
  text: string;
  archived?: boolean;
  className?: string;
}

const Chip = ({ text, archived, className }: IProps): ReactElement => (
  <span className={classNames(styles.chip, { [styles.archived]: archived }, className)}>
    {text}
  </span>
);

export default Chip;

import React, { useEffect, ReactElement, useState, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import Loader from '~/ui/components/common/Loader';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import Input from '~/ui/components/inputs/Input';
import Button from '~/ui/components/common/Button';
import InputMask from '~/ui/components/inputs/InputMask';
import Checkbox from '~/ui/components/inputs/Checkbox';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { locationValidationSchema } from './validates';
import formatSheltersOptions from '~/utils/formatSheltersOptions';

import { IRequestInfo } from './types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/types';
import { IDetails } from '../types';
import { IClinic } from '~/services/api/clinic/types';
import { IClientAddressRequest } from '~/services/api/clientDetails/types';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './Styles.module.scss';

interface IProps {
  showSecondSubmit: boolean;
  defaultValues: IClientAddressRequest;
  details: IDetails | null;
  requestInfo: IRequestInfo;
  setModalTitle: (str: string) => void;
  setDetails: (arg: IDetails) => void;
}

const AddAddress = ({
  showSecondSubmit,
  defaultValues,
  details,
  requestInfo,
  setModalTitle,
  setDetails,
}: IProps): ReactElement => {
  const [states, setStates] = useState<IOption[]>([] as IOption[]);
  const [shelters, setShelters] = useState<IOption[]>([] as IOption[]);
  const [addressTypes, setAddressTypes] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      ...(defaultValues || { setAsPrimary: false, isShelter: false, archivePrimary: true }),
    },
    resolver: locationValidationSchema,
  });
  const isEdit = !!defaultValues;

  const clinic = useStoreState(state => state.clinic.current || ({} as IClinic));

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onUpdateAddress, onCreateAddress, setCurrent } = useStoreActions(
    actions => actions.clientDetails,
  );

  const { setAsPrimary, isShelter } = watch();

  const onMount = useCallback(async () => {
    try {
      const [stateDictionary, addressDictionary, shelterOptions] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.State)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.AddressType)
          .then(r => formatDictionaryOptions(r.data)),
        api.shelters
          .getClinicShelters(String(clinic.id), { pageNumber: 1, pageSize: 5000, filtering: 1 })
          .then(r => formatSheltersOptions(r.data.items)),
      ]);

      setShelters(shelterOptions);
      setStates(stateDictionary);
      setAddressTypes(addressDictionary);

      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [clinic?.id, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = async (vals: IClientAddressRequest, evt: React.BaseSyntheticEvent) => {
    const shelter = vals.isShelter ? vals.shelterId : null;
    const { name } = (evt as React.BaseSyntheticEvent<HTMLFormElement>).nativeEvent.submitter;
    try {
      setProcessing(true);
      if (details && details.id) {
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload: {
            ...vals,
            shelterId: shelter,
            archivePrimary: vals.setAsPrimary ? !vals.archivePrimary : false,
          },
        };
        await onUpdateAddress(payload);

        setDetails(null);
        setCurrent(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload: {
            ...vals,
            shelterId: shelter,
            archivePrimary: vals.setAsPrimary ? !vals.archivePrimary : false,
          },
        };

        await onCreateAddress(payload);

        reset({
          address: null,
        });
      }
      setModalTitle(name);
      const type = details?.id ? 'update' : 'added';
      showNotify({ message: `Address successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    setValue('archivePrimary', setAsPrimary);
  }, [setAsPrimary, setValue]);

  useEffect(() => {
    if (isShelter) {
      setValue('address', null);
    } else {
      setValue('shelterId', null);
    }
  }, [isShelter, setValue]);

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Checkbox
            size="small"
            name="isShelter"
            control={control}
            errors={errors}
            label="Shelter"
            disabled={isEdit}
          />
        </Grid>
        <Grid item sm={12}>
          <Select
            name="typeId"
            hideSelectedOptions={false}
            options={addressTypes}
            control={control}
            errors={errors}
            maxMenuHeight={200}
            label="Select Type"
          />
        </Grid>
        {!isShelter ? (
          <>
            <Grid item sm={12}>
              <Input register={register} name="address.shelterName" errors={errors} label="Name" />
            </Grid>
            <Grid item sm={12}>
              <Input register={register} name="address.city" errors={errors} label="City" />
            </Grid>
            <Grid item sm={12}>
              <Input register={register} name="address.line1" errors={errors} label="Address 1" />
            </Grid>
            <Grid item sm={12}>
              <Input register={register} name="address.line2" errors={errors} label="Address 2" />
            </Grid>
            <Grid item sm={6}>
              <Select
                name="address.stateId"
                hideSelectedOptions={false}
                options={states}
                control={control}
                errors={errors}
                maxMenuHeight={150}
                label="Select State"
              />
            </Grid>
            <Grid item sm={6}>
              <InputMask
                alwaysShowMask={false}
                mask="99999"
                name="address.zipCode"
                control={control}
                errors={errors}
                label="Zip Code"
              />
            </Grid>
            <Grid item sm={6}>
              <Input register={register} name="address.poBox" errors={errors} label="PO Box" />
            </Grid>
          </>
        ) : (
          <Grid item sm={12}>
            <Select
              options={shelters}
              name="shelterId"
              control={control}
              errors={errors}
              label="Select Shelter"
              hideSelectedOptions={false}
              openMenuOnFocus
            />
          </Grid>
        )}

        {!defaultValues?.setAsPrimary && (
          <Grid item sm={12}>
            <Checkbox
              size="small"
              name="setAsPrimary"
              control={control}
              errors={errors}
              label="Primary Address"
            />
          </Grid>
        )}
        {setAsPrimary && !defaultValues?.setAsPrimary && (
          <Grid item sm={12}>
            <Checkbox
              size="small"
              name="archivePrimary"
              control={control}
              errors={errors}
              label="Retain current Primary Address as Alternate"
            />
          </Grid>
        )}
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          className={styles.margin}
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          isLoading={processing}
          loaderColor={variables.colorGrey}
          disabled={processing}
          className={!showSecondSubmit ? styles.margin : ''}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddAddress;

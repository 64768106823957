import { getAllocationsFilter } from '~/services/localStorage/filters';
import { IClientAllocationStore } from './types';

const initStore: IClientAllocationStore = {
  current: null,
  list: [],
  locationGroups: [],
  pagination: {
    total: 0,
    pageNumber: 1,
    pageSize: 25,
  },
  filters: getAllocationsFilter() || {
    sorting: 1,
    dateRange: [],
  },
};

export default initStore;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ISosRequestActions, ISosRequestsSorting, IThunkGetSosRequests } from '../types';
import { setSosRequestsFilter } from '~/services/localStorage/filters';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';

const onGetSosRequests: IThunkGetSosRequests = thunk(
  async ({ setSosRequests }: Actions<ISosRequestActions>, payload) => {
    const { clinicId, params } = payload;

    const {
      data: { items, total },
    } = await api.sosRequests.getSosRequests(String(clinicId), params);

    const pagination = { ...params, total };

    const filters = {
      sorting: params.sorting as ISosRequestsSorting,
      sortDirection: params.sortDirection as SortDirection,
    };

    setSosRequestsFilter(filters);

    setSosRequests({ items, pagination });
  },
);

export default onGetSosRequests;

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { ReactElement, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Status from '~/ui/components/common/Status';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import formatShelterAddress from '../helpers/formatShelterAddress';

import { IParams, Sorting } from '~/services/api/types';

import { IShelter } from '~/services/api/shelters/types';
import archiveIcon from '~/ui/assets/images/archiveIcon.svg';
import editGrey from '~/ui/assets/images/editGrey.svg';
import { red } from '~/ui/constants/colors';
import { filterOptions, sortingOptions } from '~/ui/constants/sortingOptions';
import styles from './Table.module.scss';
import classNames from 'classnames';

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  filtering: 0,
};

interface IProps {
  clinicId: string;
  shelters: IShelter[];
  setShelter: (arg: IShelter) => void;
  setIsArchiveShelter: (archived: boolean) => void;
  setShelterModalVisible: (visible: boolean) => void;
}

const SheltersTable = ({
  clinicId,
  setShelter,
  setIsArchiveShelter,
  setShelterModalVisible,
}: IProps): ReactElement => {
  const formMethods = useForm({ defaultValues });
  const {
    control,
    formState: { errors },
    watch,
    register,
    handleSubmit,
  } = formMethods;
  const { sorting, filtering, name } = watch();

  const { list, pagination } = useStoreState(state => state.shelters);
  const onGetClinicShelters = useStoreActions(actions => actions.shelters.onGetClinicShelters);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const handlePagination = useCallback(
    async (pageNumber: number, pageSize: number) => {
      try {
        await onGetClinicShelters({
          clinicId,
          params: { pageNumber, pageSize, sorting, name, filtering },
        });
      } catch (e) {
        showError(extractErrorMessage(e));
      }
    },
    [clinicId, onGetClinicShelters, showError, sorting, filtering, name],
  );

  const onSubmit = (params: IParams) => {
    onGetClinicShelters({ clinicId, params: { ...pagination, ...params } });
  };

  useEffect(() => {
    handlePagination(pagination.pageNumber, pagination.pageSize);
  }, [handlePagination, pagination.pageNumber, pagination.pageSize]);

  return (
    <TableContainer className={styles.tableContainer}>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search Shelter"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Filter By Status"
                  control={control}
                  name="filtering"
                  errors={errors}
                  options={filterOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>Shelter Name</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item: IShelter) => (
            <TableRow key={item.id}>
              <TableCell
                className={classNames(styles.link, { [styles.disabled]: item.isArchived })}
                onClick={() => {
                  setShelter(item);
                  setShelterModalVisible(true);
                }}
              >
                {item.name}
              </TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>{formatShelterAddress(item.address)}</TableCell>
              <TableCell>
                <Status status={item.isArchived ? 'Archived' : 'Active'} />
              </TableCell>
              {item.isArchived ? (
                <TableCell align="right">
                  <button
                    type="button"
                    className={styles.restoreButton}
                    onClick={() => {
                      setShelter(item);
                      setIsArchiveShelter(false);
                    }}
                  >
                    Restore
                  </button>
                </TableCell>
              ) : (
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      setShelter(item);
                      setShelterModalVisible(true);
                    }}
                  >
                    <img src={editGrey} alt="edit" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setShelter(item);
                      setIsArchiveShelter(true);
                    }}
                  >
                    <img src={archiveIcon} alt="archive" />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => {
          handlePagination(pageNumber + 1, pagination.pageSize);
        }}
        onRowsPerPageChange={e => {
          handlePagination(1, Number(e.target.value));
        }}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Box className={styles.absoluteButton}>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          onClick={() => {
            setShelterModalVisible(true);
          }}
        >
          Add Shelter
        </Button>
      </Box>
    </TableContainer>
  );
};

export default SheltersTable;

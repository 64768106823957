import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { MouseEventHandler, ReactElement } from 'react';
import { components, GroupTypeBase, MultiValueProps } from 'react-select';

import { IOption } from '~/types';
import { IAllocationClientsByGroup } from '~/services/api/clientAllocation/types';

import styles from './MultiValue.module.scss';

const MultiValue = ({
  locationGroups,
  clientIds = [],
  getValue,
  onDelete,
  onDeleteGroup,
  ...props
}: MultiValueProps<IOption, GroupTypeBase<IOption>> & {
  locationGroups: IAllocationClientsByGroup[];
  clientIds: number[];
  onDelete: (id: number) => void;
  onDeleteGroup: (id: number) => void;
}): ReactElement => {
  const selected = getValue()?.find(v => v.value === props.data.value);
  const group = locationGroups.find(item => item.locationGroup.id === selected.value);

  const clients = group.clients.filter(client => clientIds.includes(client.id));

  const onClick: MouseEventHandler<HTMLDivElement> = e => {
    props.removeProps.onClick(e);
    onDeleteGroup(Number(props.data.value));
  };

  return (
    <Box
      flexDirection="row"
      display="flex"
      alignItems="center"
      onMouseDown={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <components.MultiValue
        {...props}
        getValue={getValue}
        removeProps={{
          ...props.removeProps,
          onClick,
        }}
      />
      {clients.map(client => (
        <Chip
          key={`client-chip-${client.id}`}
          label={client.name}
          variant="default"
          onDelete={() => {
            if (clients.length === 1) {
              onDeleteGroup(Number(props.data.value));
            } else {
              onDelete(client.id);
            }
          }}
          className={styles.chip}
        />
      ))}
    </Box>
  );
};

export default MultiValue;

import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import { useStoreState } from '~/store/hooks';
import Sidebar from '~/ui/components/layouts/Sidebar';
import Header from '~/ui/components/layouts/Header';

import Snackbar from '~/ui/components/layouts/Snackbar';

import styles from './GeneralLayout.module.scss';

interface IProps {
  children: ReactNode;
}

const GeneralLayout = ({ children }: IProps): ReactElement => {
  const isMenuOpened = useStoreState(state => state.app.sidebarMenuOpened);
  return (
    <>
      <div className={styles.layout}>
        <Header />
        <Sidebar />
        <div className={classNames(styles.main, { [styles.mainExtended]: !isMenuOpened })}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default GeneralLayout;

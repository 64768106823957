import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { ReactElement, useState } from 'react';

import { IClientAllergy } from '~/services/api/clientDetails/types';
import styles from './Allergies.module.scss';

interface IProps {
  item: IClientAllergy;
  last: boolean;
}

const AllergiesItem = ({ item, last }: IProps): ReactElement => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => {
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  return (
    <Box
      className={styles.item}
      key={`allergy-${item.allergy.id}`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {item.allergy.name}
      {last ? null : ','}
      <div
        className={classNames(styles.tooltip, {
          [styles.visible]: isVisible,
          [styles.w100]: item.note?.length > 15,
          [styles.w200]: item.note?.length > 50,
          [styles.w300]: item.note?.length > 100,
        })}
      >
        <span className={styles.title}>{item.allergy.name}</span>
        <span className={styles.note}>{item.note}</span>
      </div>
    </Box>
  );
};

export default AllergiesItem;

import { action } from 'easy-peasy';

import { IActionSetClientTreatmentPlansReport } from '../types';

const setClientTreatmentPlansReport: IActionSetClientTreatmentPlansReport = action(
  (state, payload) => {
    const { items } = payload;

    state.clientTreatmentPlans.report = items;
  },
);

export default setClientTreatmentPlansReport;

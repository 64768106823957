import { useEffect, ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Paper from '@material-ui/core/Paper';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import { requiredField } from '~/ui/constants/errorMessages';

import styles from './EmailForm.module.scss';

const resolver = yupResolver(object().shape({ email: string().trim().required(requiredField) }));

interface IProps {
  error: string;
  onSubmit: (val: { email: string }) => void;
  resetError: () => void;
}

const EmailForm = ({ error, onSubmit, resetError }: IProps): ReactElement => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
    watch,
  } = useForm({ resolver });

  const emailValue = watch('email');

  useEffect(() => {
    resetError();
    clearErrors('email');
  }, [emailValue, resetError, clearErrors]);

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <Paper className={styles.signUp}>
        <h2 className={styles.title}>Welcome to VelloHealth</h2>
        <p className={styles.subTitle}>Please enter your email address below</p>
        <Input register={register} errors={errors} name="email" label="Email" />
        <span className={styles.error}>{error}</span>
        <div className={styles.buttonWrapper}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Sign Up
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default EmailForm;

import Box from '@material-ui/core/Box';
import { ReactElement } from 'react';
import classNames from 'classnames';

import Initials from '~/ui/components/common/Initials';

import { IDictionary } from '~/services/api/dictionaries/types';
import styles from './PrintableMonitoredBy.module.scss';

interface IProps {
  monitoredBy: IDictionary[];
}

const PrintableMonitoredBy = ({ monitoredBy }: IProps): ReactElement => (
  <Box className={classNames(styles.column, styles.printableContainer)}>
    <div className={styles.black}>Monitored by:</div>
    <div className={styles.row}>
      {monitoredBy.map(item => (
        <div className={styles.initialsItem} key={`printable-team-member-${item.id}`}>
          <Initials name={item.name} className={styles.marginRight} />
        </div>
      ))}
    </div>
  </Box>
);

export default PrintableMonitoredBy;

import { action } from 'easy-peasy';
import { IActionSetResources } from '../types';

const setResources: IActionSetResources = action((state, payload) => {
  const { items, pagination } = payload;

  state.list = items;
  state.pagination = pagination;
});

export default setResources;

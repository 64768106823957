enum ToxicologySortableColumns {
  ClientName = 'ClientName',
  ResultDate = 'ResultDate',
  DrawnDate = 'DrawDate',
  VisitDate = 'VisitDate',
}

export const toxicologySortableColumns = [
  ToxicologySortableColumns.ClientName,
  ToxicologySortableColumns.ResultDate,
  ToxicologySortableColumns.DrawnDate,
  ToxicologySortableColumns.VisitDate,
];

export default ToxicologySortableColumns;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateAddress, IClientDetailsStore, IClientDetailsActions } from '../types';

const onCreateAddress: IThunkCreateAddress = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const { setAsPrimary, archivePrimary } = requestPayload;

    const clientAddress = await api.clientDetails
      .createAddress(requestInfo, requestPayload)
      .then(r => r.data);

    const updatedAddresses = localState.addresses.map(item => ({
      ...item,
      ...(archivePrimary ? { isArchived: item.isPrimary ? true : item.isArchived } : {}),
      ...(setAsPrimary ? { isPrimary: item.id === Number(clientAddress.id) } : {}),
    }));

    const addresses = [...updatedAddresses, clientAddress];

    setClientDetails({ addresses });
  },
);

export default onCreateAddress;

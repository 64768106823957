import setVisit from './setVisit';
import setVisits from './setVisits';
import setPastVisits from './setPastVisits';
import setVisitsFilters from './setVisitsFilters';
import setClientDocuments from './setClientDocuments';
import setClientDocumentsFilter from './setClientDocumentsFilter';

export default {
  setVisit,
  setVisits,
  setPastVisits,
  setVisitsFilters,
  setClientDocuments,
  setClientDocumentsFilter,
};

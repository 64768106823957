import { useState, useEffect, ReactElement, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import Form from './Form';
import Loader from '~/ui/components/common/Loader';
import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import { isFile } from '~/utils/file';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import { VIEW_ACT_TEAM, ACT_TEAM_MANAGEMENT } from '~/ui/constants/paths';

import styles from './AddTeamMember.module.scss';

const AddTeamMember = (): ReactElement => {
  const { pathname } = useLocation();
  const { actTeamId } = useParams<{ actTeamId: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);

  const userClinic = useStoreState(state => state.user.current.clinic);
  const clinic = useStoreState(state => state.clinic.current);
  const actTeam = useStoreState(state => state.actTeam.current);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onMount = useCallback(async () => {
    try {
      await Promise.all([
        onGetMyClinic(),
        onGetActTeam({ clinicId: String(userClinic.id), actTeamId }),
      ]);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [actTeamId, onGetActTeam, onGetMyClinic, showError, userClinic.id]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = async (vals: any) => {
    try {
      const { primaryRole, email, emailDomain, ...restVals } = vals;
      const payload = { ...restVals, email: `${email}${emailDomain}` };
      const isTeamMember = primaryRole === 'teamMember';
      const memberType = isTeamMember ? 'Team Member' : 'Team Leader';
      setIsAdding(true);

      if (isFile(payload.photo)) {
        const formData = new FormData();
        formData.append('file', payload.photo);
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        payload.photo = fileName;
      }

      if (isTeamMember) {
        await api.actTeamMember.createTeamMember(userClinic.id, Number(actTeamId), payload);
      } else {
        await api.actTeamMember.createTeamLeader(userClinic.id, Number(actTeamId), payload);
      }
      showNotify({ message: `${memberType} successfully created` });
      navigate(VIEW_ACT_TEAM.replace(':actTeamId', actTeamId), { state: { selectedTab: 1 } });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  if (loading || !clinic) return <Loader />;

  const emailDomainOptions = clinic.emailDomains.map(emailDomain => ({
    value: emailDomain,
    label: emailDomain,
  }));

  const breadcrumbsItems = [
    {
      title: `${userClinic.name} Teams`,
      to: ACT_TEAM_MANAGEMENT,
    },
    {
      title: actTeam.name,
      to: { pathname: VIEW_ACT_TEAM.replace(':actTeamId', actTeamId), state: { selectedTab: 1 } },
    },
    {
      title: 'Add Team Member Profile',
      to: pathname,
    },
  ];

  const leavePageDescription =
    'Are you sure you want to cancel “Add New Team Member”? All the changes will be discarded.';
  const cancelLinkTo = {
    pathname: VIEW_ACT_TEAM.replace(':actTeamId', actTeamId),
    state: { selectedTab: 1 },
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Add New Team Member</h2>
      </div>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
      <Form
        isProcessing={isAdding}
        cancelLinkTo={cancelLinkTo}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
        emailDomainOptions={emailDomainOptions}
      />
    </div>
  );
};

export default AddTeamMember;

import {
  IAllocationClient,
  IAllocationClientsByGroup,
} from '~/services/api/clientAllocation/types';

const groupAllocationClients = (
  groups: IAllocationClientsByGroup[],
  clients: IAllocationClient[],
): IAllocationClientsByGroup[] => {
  const clientsIds = clients.map(client => client.id);
  return groups.map(group => ({
    ...group,
    clients: group.clients.filter(client => clientsIds.includes(client.id)),
  }));
};

export default groupAllocationClients;

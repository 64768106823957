import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeactivateSuperAdmin, ISuperAdminStore, ISuperAdminActions } from '../types';

const onDeactivateSuperAdmin: IThunkDeactivateSuperAdmin = thunk(
  async ({ setSuperAdmins }: Actions<ISuperAdminActions>, superAdminId, { getState }) => {
    const localState = getState() as ISuperAdminStore;
    await api.superAdmin.deactivateSuperAdmin(superAdminId);

    const updatedList = localState.list.map(superAdmin =>
      superAdmin.id !== superAdminId ? superAdmin : { ...superAdmin, status: 'Deactivated' },
    );

    setSuperAdmins({ items: updatedList });
  },
);

export default onDeactivateSuperAdmin;

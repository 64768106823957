import onGetSystemAlerts from './onGetSystemAlerts';
import onAddSystemAlert from './onAddSystemAlert';
import onGetSystemAlertUpdates from './onGetSystemAlertUpdates';
import onReadSystemAlert from './onReadSystemAlert';
import onReadAllSystemAlert from './onReadAllSystemAlert';

export default {
  onGetSystemAlerts,
  onAddSystemAlert,
  onGetSystemAlertUpdates,
  onReadSystemAlert,
  onReadAllSystemAlert,
};

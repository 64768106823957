import { Dispatch, ReactElement, SetStateAction } from 'react';
import { components, GroupTypeBase, MenuListComponentProps } from 'react-select';
import classNames from 'classnames';

import { IOption } from '~/types';
import CustomCheckbox from '../../CustomCheckbox';

import styles from '../../Select/Select.module.scss';

interface IProps extends MenuListComponentProps<IOption, boolean, GroupTypeBase<IOption>> {
  selectAll: boolean;
  setSelectAll: (value: boolean) => void | Dispatch<SetStateAction<boolean>>;
}

const SelectAll = ({ children, ...rest }: IProps): ReactElement => {
  const isChecked = rest.selectAll || rest.getValue()?.length === rest.options?.length;

  return rest.options.length > 1 ? (
    <components.MenuList {...rest}>
      <div className={classNames(styles.selectAll, { [styles.checked]: isChecked })}>
        <CustomCheckbox
          size="small"
          checked={isChecked}
          className={styles.customCheckbox}
          onChange={() => rest.setSelectAll(!rest.selectAll)}
        />
        <span>Select All</span>
      </div>
      {children}
    </components.MenuList>
  ) : (
    <components.MenuList {...rest}>{children}</components.MenuList>
  );
};

export default SelectAll;

import { IResponse } from '~/services/api/types';
import { privateAxios } from '../requestsCollection';

export const uploadProfilePhoto = (photo: string): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `Account/profile-photo`,
    data: { photo },
  });

export default {};

import Grid from '@material-ui/core/Grid';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import Button from '~/ui/components/common/Button';
import Checkbox from '~/ui/components/inputs/Checkbox';
import Input from '~/ui/components/inputs/Input';
import DatePicker from '~/ui/components/inputs/DatePicker';
import MedicationSelect from '../../MedicationSelect';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import { medicationValidationSchema } from './validates';
import useMedicationOptions from '../../../hooks/useMedicationOptions';

import { IMedicationInitialValues, IRequestInfo } from './types';
import { IDetails } from '../types';
import styles from './Styles.module.scss';

interface IProps {
  showSecondSubmit: boolean;
  setModalTitle: (v: any) => void;
  setDetails: (v: any) => void;
  defaultValues: IMedicationInitialValues;
  requestInfo: IRequestInfo;
  details: IDetails | null;
}

const AddMedication = ({
  showSecondSubmit,
  setDetails,
  setModalTitle,
  defaultValues,
  details,
  requestInfo,
}: IProps): ReactElement => {
  const {
    control,
    formState: { errors },
    reset,
    register,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      ...((defaultValues || {}) as IMedicationInitialValues),
      startDate: defaultValues?.startDate || new Date().toISOString(),
    },
    resolver: medicationValidationSchema,
  });

  const setCurrent = useStoreActions(actions => actions.clientDetails.setCurrent);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onUpdateMedication, onCreateMedication } = useStoreActions(
    actions => actions.clientDetails,
  );
  const { startDate } = watch();

  const { loading, loadMore } = useMedicationOptions();

  const onSubmit = async (values: IMedicationInitialValues, evt: any) => {
    const { name } = evt.nativeEvent.submitter;
    try {
      if (details && details.id) {
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload: values,
        };
        onUpdateMedication(payload);
        setDetails(null);
        setCurrent(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload: values,
        };
        await onCreateMedication(payload);
        reset({ id: null, dose: '', note: '', injectable: false });
      }
      setModalTitle(name);

      const type = details?.id ? 'updated' : 'added';

      showNotify({ message: `Medication successfully ${type}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MedicationSelect
            loading={loading}
            control={control}
            errors={errors}
            isDisabled={!!defaultValues?.id}
            loadMore={loadMore}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            name="dose"
            register={register}
            label="Medication Dose"
            multiline
            errors={errors}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            name="note"
            register={register}
            label="Medication Note"
            multiline
            errors={errors}
            minRows={3}
          />
        </Grid>
        <Grid item sm={6}>
          <Checkbox
            size="small"
            name="injectable"
            control={control}
            errors={errors}
            label="Injectable"
          />
        </Grid>
        <Grid item sm={6}>
          <Checkbox
            size="small"
            name="outsideFacility"
            control={control}
            errors={errors}
            label="Outside Facility"
          />
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <DatePicker
                name="startDate"
                openTo="date"
                control={control}
                errors={errors}
                label="Start Date"
              />
            </Grid>
            <Grid item sm={6}>
              <DatePicker
                name="endDate"
                openTo="date"
                control={control}
                errors={errors}
                label="End Date"
                minDate={startDate || new Date().toISOString()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        {showSecondSubmit && (
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            name="Add Medication"
            className={styles.margin}
          >
            Save and Add another
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={!showSecondSubmit ? styles.margin : ''}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddMedication;

import { action } from 'easy-peasy';
import { setVisitsByFilter } from '~/services/localStorage/filters';

import { IActionSetVisitsByReportFilters } from '../types';

const setVisitsByReportFilters: IActionSetVisitsByReportFilters = action((state, payload) => {
  // save filters to storage
  setVisitsByFilter(payload);

  state.visitsByClient.filters = payload;
});

export default setVisitsByReportFilters;

import { action } from 'easy-peasy';
import { IActionActivateProgramAssistant } from '../types';

const activateProgramAssistant: IActionActivateProgramAssistant = action(
  (state, programAssistantId) => {
    const updatedList = state.list.map(item =>
      item.id !== programAssistantId ? item : { ...item, status: 'Active' },
    );

    state.list = updatedList;
  },
);

export default activateProgramAssistant;

import { IActTeam } from '~/services/api/actTeam/types';
import { VIEW_ACT_TEAM, CLINIC_VIEW_ACT_TEAM } from '~/ui/constants/paths';

const getViewActTeamLink = (actTeam: IActTeam, isLocalAdmin: boolean): string =>
  isLocalAdmin
    ? VIEW_ACT_TEAM.replace(':actTeamId', String(actTeam.id))
    : CLINIC_VIEW_ACT_TEAM.replace(':clinicId', String(actTeam.clinic.id)).replace(
        ':actTeamId',
        String(actTeam.id),
      );

export default getViewActTeamLink;

import { StoreProvider } from 'easy-peasy';
import { createRoot } from 'react-dom/client';

import store from './store';
import App from './ui/App';

const root = createRoot(document.getElementById('root'));

root.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
);

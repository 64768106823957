import { action } from 'easy-peasy';
import { getMedicationsFilter, setMedicationsFilter } from '~/services/localStorage/filters';

import { IActionSetMedicationsFilters } from '../types';

const setMedicationsFilters: IActionSetMedicationsFilters = action((state, payload) => {
  const storageMedicationsFilters = getMedicationsFilter();
  // save filters to storage
  setMedicationsFilter({ ...(storageMedicationsFilters || {}), ...payload });

  state.medications.filters = {
    ...state.medications.filters,
    ...payload,
  };
});

export default setMedicationsFilters;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ILocationGroupActions, IThunkGetClinicLocationGroups } from '../types';

const onGetClinicLocationGroups: IThunkGetClinicLocationGroups = thunk(
  async ({ setLocationGroups }: Actions<ILocationGroupActions>, payload) => {
    const { clinicId, params } = payload;

    const { items, total } = await api.locationGroups
      .getClinicLocationGroups(clinicId, params)
      .then(r => r.data);

    const pagination = { ...params, total };

    setLocationGroups({ items, pagination });
  },
);

export default onGetClinicLocationGroups;

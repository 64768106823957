import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { VisitedPerson, visitTypes } from '~/ui/pages/Visits/constants/filterOptions';
import { FilterType, IVisitParams, IVisitRequestParams } from '~/services/api/visits/types';
import { IThunkGetVisits, IVisitsActions } from '../types';
import { normalizeDateString, setNoon } from '~/utils/date/date';
import { visitStatusLabels } from '~/ui/pages/Reports/constants/visitStatusOptions';

const onGetVisits: IThunkGetVisits = thunk(
  async ({ setVisits, setVisitsFilters }: Actions<IVisitsActions>, { requestInfo, params }) => {
    const { clinicId, clientId, teamId, includeDeleted = false } = requestInfo;
    const { filterBy, sortBy, dateFrom, dateTo, ...rest } = params as IVisitParams;

    const filterOptions = (): IVisitRequestParams => {
      switch (sortBy) {
        case 1:
          return {
            filtering: FilterType.PERSON,
            personFiltering: VisitedPerson[filterBy]?.replace(' ', '') || 'Client',
          };
        case 2:
          return { filtering: FilterType.TYPE, type: Object.keys(visitTypes)[filterBy] };
        case 3:
          if (dateFrom && dateTo) {
            return {
              filtering: FilterType.DATE,
              startDate: normalizeDateString(dateFrom),
              endDate: normalizeDateString(dateTo, true),
            };
          }
          return {
            sorting: FilterType.DATE,
          };
        case 4:
          return { filtering: FilterType.STATUS, status: Object.keys(visitStatusLabels)[filterBy] };
        default:
          return { sorting: 'all' };
      }
    };

    const filters = filterOptions();

    const visits = await api.visits
      .getVisits({ clinicId, clientId, teamId, includeDeleted }, { ...rest, ...filters })
      .then(r => r.data);

    setVisitsFilters({
      sortBy: sortBy || 0,
      filterBy: filterBy || 0,
      startDate: setNoon(dateFrom),
      endDate: setNoon(dateTo),
      includeDeleted,
    });
    setVisits(visits);
  },
);

export default onGetVisits;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IShelterActions, IThunkGetClinicShelters } from '../types';

const onGetClinicShelters: IThunkGetClinicShelters = thunk(
  async ({ setShelters }: Actions<IShelterActions>, payload) => {
    const { clinicId, params } = payload;

    const { items, total } = await api.shelters
      .getClinicShelters(clinicId, params)
      .then(r => r.data);

    const pagination = { ...params, total };

    setShelters({ items, pagination });
  },
);

export default onGetClinicShelters;

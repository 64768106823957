import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveClient, IClientStore, IClientActions } from '../types';

const onArchiveClient: IThunkArchiveClient = thunk(
  async (
    { setClients, setDefaultClient, setClient, setClientOptions }: Actions<IClientActions>,
    payload,
    { getState },
  ) => {
    const localState = getState() as IClientStore;
    const { clientId, clinicId, teamId, dischargeReasonId, dischargeDate } = payload;

    await api.client.archiveClient(
      { clinicId, teamId, clientId },
      { dischargeReasonId, dischargeDate },
    );

    const updatedList = localState.list.map(item =>
      String(item.id) !== clientId ? item : { ...item, isArchived: true },
    );

    setClients({ items: updatedList });
    setDefaultClient(null);
    setClient(null);
    setClientOptions({ items: localState.clientOptions.list, archived: [Number(clientId)] });
  },
);

export default onArchiveClient;

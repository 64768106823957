import { ReactElement, ReactNode } from 'react';
import { Controller, Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  register?: UseFormRegister<any>;
  size?: 'medium' | 'small';
  name: string;
  label?: string;
  centerIcon?: ReactNode;
  disabled?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  cb?: () => void;
}

const ToggleComponent = ({
  control,
  errors,
  register,
  size = 'medium',
  name,
  label = '',
  disabled = false,
  centerIcon,
  labelPlacement,
  cb,
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');

  const props = register?.(name) || {};

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant="outlined">
          <FormControlLabel
            control={
              <>
                <Switch
                  checked={value}
                  name={name}
                  color="primary"
                  size={size}
                  onChange={e => {
                    onChange(e);
                    cb?.();
                  }}
                  {...(props || {})}
                />
                {centerIcon}
              </>
            }
            label={label}
            disabled={disabled}
            labelPlacement={labelPlacement}
          />
          {error && <FormHelperText error>{error.toString()}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default ToggleComponent;

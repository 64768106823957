import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import ListItem from '~/ui/components/common/ListItem';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import FileViewer from '~/ui/pages/Resources/components/FileViewer';
import EmptyState from '~/ui/pages/Notifications/components/EmptyState';
import Loader from '~/ui/components/common/Loader';

import { useStoreActions, useStoreState } from '~/store/hooks';
import formatResource from './helpers/formatResource';
import useDocuments from './hooks/useDocuments';
import { getLocalDateNoFormat } from '~/utils/date/date';

import { generalDate } from '~/ui/constants/dateFormat';
import { sortDirectionOptions } from '~/ui/constants/sortingOptions';
import orderByOptions from '../../../constants/orderByOptions';
import DocumentsOrderBy from '../../../models/documentsOrderBy';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import { IOption } from '~/types';

import { ReactComponent as LoadMoreIcon } from '~/ui/assets/images/loadMore.svg';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';

import { IResource } from '~/services/api/resource/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import loaderStyles from '~/ui/components/common/Loader/Loader.module.scss';
import styles from './DocumentsBlock.module.scss';

const DocumentsBlock = (): ReactElement => {
  const [file, setFile] = useState<IResource | null>(null);

  const { current } = useStoreState(state => state.user);
  const client = useStoreState(state => state.client.current);
  const documentDictionary =
    useStoreState(
      state => state.dictionaries.availableDictionaries[IDictionaryTypes.DocumentType]?.list,
    ) || [];

  const onGetDictionariesByType = useStoreActions(
    actions => actions.dictionaries.onGetDictionariesByType,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const documentDictionaryOptions = formatDictionaryOptions(documentDictionary || []);

  const {
    watch,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      documentTypeId: null,
      sorting: DocumentsOrderBy.DATE,
      sortDirection: SortDirection.Desc,
    },
  });

  const { name, documentTypeId } = watch();

  const {
    items,
    loading,
    loadMore,
    setSearchQuery,
    setDocumentTypeFilter,
    setDocumentOrderBy,
    setDocumentSortDirection,
  } = useDocuments();

  useEffect(() => {
    setSearchQuery(name);
  }, [name, setSearchQuery]);

  useEffect(() => {
    setDocumentTypeFilter(documentTypeId);
  }, [documentTypeId, setDocumentTypeFilter]);

  useEffect(() => {
    try {
      onGetDictionariesByType(IDictionaryTypes.DocumentType);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [onGetDictionariesByType, showError]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <h4 className={styles.title}>Documents</h4>
        <div>
          <Select
            options={orderByOptions}
            name="sorting"
            control={control}
            label="Order By"
            errors={errors}
            hideSelectedOptions={false}
            placeholder="Date"
            onSelect={option => setDocumentOrderBy(option as IOption)}
          />
          <Select
            options={sortDirectionOptions}
            name="sortDirection"
            control={control}
            label="Sort Direction"
            errors={errors}
            hideSelectedOptions={false}
            placeholder="Descending"
            onSelect={option => setDocumentSortDirection(option as IOption)}
          />
          <Select
            options={documentDictionaryOptions}
            name="documentTypeId"
            control={control}
            label="Filter By Type"
            errors={errors}
            hideSelectedOptions={false}
            isClearable
            placeholder="All"
          />
          <Input
            register={register}
            placeholder="Search By Name"
            name="name"
            errors={errors}
            startAdornment={<SearchIcon color="primary" />}
          />
        </div>
      </div>
      {!!loading && <Loader className={loaderStyles.loaderContainer} />}
      {items.length ? (
        items.map((item, idx) => (
          <ListItem
            className={classNames(
              items.length === 1 || items.length - 1 === idx ? styles.noBorder : '',
              styles.itemLabel,
            )}
            key={item.id + item.type}
            label={item.type}
            content={`${item.staff?.length ? `${item.staff} - ` : ''}${format(
              getLocalDateNoFormat(item.date),
              generalDate,
            )}`}
            onPreview={() =>
              setFile(formatResource(item, current.clinic.id, client.actTeam.id, client.id))
            }
            showPreview
          />
        ))
      ) : (
        <>
          {!items.length && (
            <EmptyState
              className={styles.emptyState}
              noCellWrap
              text="No Documents have been added"
            />
          )}
        </>
      )}
      {loadMore && (
        <div className={styles.paginationButton}>
          <Button
            onClick={loadMore}
            color="primary"
            variant="outlined"
            startIcon={<LoadMoreIcon />}
          >
            Load more
          </Button>
        </div>
      )}
      {file && <FileViewer file={file} onClose={() => setFile(null)} />}
    </div>
  );
};

export default DocumentsBlock;

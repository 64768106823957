import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IClientDetailsModel } from './types';

const clientDetails: IClientDetailsModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default clientDetails;

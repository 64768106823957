import { ReactElement } from 'react';
import classNames from 'classnames';

import PrivateImage from '../PrivateImage';
import defaultAvatar from '~/ui/assets/images/defaultAvatar.svg';
import styles from './Avatar.module.scss';

interface IProps {
  url: string;
  className?: string;
}

const Avatar = ({ url, className }: IProps): ReactElement => (
  <PrivateImage
    src={url || defaultAvatar}
    className={classNames(styles.avatar, className)}
    alt="Avatar"
  />
);

export default Avatar;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ILocalAdminActions, IThunkDeactivateLocalAdmin } from '../types';

const onDeactivateLocalAdmin: IThunkDeactivateLocalAdmin = thunk(
  async ({ deactivateLocalAdmin }: Actions<ILocalAdminActions>, payload) => {
    const { clinicId, localAdminId } = payload;

    await api.localAdmin.deactivateLocalAdmin(clinicId, localAdminId);

    deactivateLocalAdmin(localAdminId);
  },
);

export default onDeactivateLocalAdmin;

import { useCallback, useEffect } from 'react';
import { ILocationGroup } from '~/services/api/locationGroups/types';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

const useClinicLocationGroups = (): ILocationGroup[] => {
  const { showError } = useStoreActions(actions => actions.snackbar);
  const { list: locationGroups } = useStoreState(state => state.locationGroups);

  const onGetClinicLocationGroups = useStoreActions(
    actions => actions.locationGroups.onGetClinicLocationGroups,
  );

  const user = useStoreState(state => state.user.current);

  // get location group options
  const onMount = useCallback(async () => {
    if (user?.clinic?.id) {
      try {
        await onGetClinicLocationGroups({
          clinicId: String(user.clinic?.id),
          params: { pageNumber: 1, pageSize: 1000, filtering: 'Active' },
        });
      } catch (e) {
        showError(extractErrorMessage(e));
      }
    }
  }, [user?.clinic?.id, onGetClinicLocationGroups, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return locationGroups;
};

export default useClinicLocationGroups;

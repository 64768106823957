import { ReactElement, useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import validate from './validate';

import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Toggle from '~/ui/components/inputs/Toggle';
import FileUploadComponent from '~/ui/components/inputs/FileUpload';

import { IFormValues } from './types';

import styles from './ResourcesModal.module.scss';

interface IProps {
  defaultValues?: IFormValues;
  loading: boolean;
  onSubmit: (values: IFormValues, evt: any) => Promise<void>;
  onClose: () => void;
}

const Form = ({ defaultValues, loading, onSubmit, onClose }: IProps): ReactElement => {
  const {
    formState: { errors },
    control,
    register,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: validate,
    mode: 'onBlur',
  });

  const { name, externalUrl, isVisible } = watch();

  const isEdit = !!defaultValues?.name;

  const noChanges =
    name === defaultValues?.name &&
    defaultValues?.externalUrl === externalUrl &&
    defaultValues?.isVisible === isVisible;

  useEffect(() => {
    const file = externalUrl as unknown as File;
    if (file?.name && !isEdit) {
      setValue('name', file.name.replace(/\.[^/.]+$/, '')); // remove extension from filename
    }
  }, [externalUrl, isEdit, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <Grid container spacing={2}>
          {isEdit && (
            <Grid item sm={12}>
              <Input label="Resource File Name" register={register} name="name" errors={errors} />
            </Grid>
          )}
          {!isEdit && (
            <Grid item sm={12}>
              <FileUploadComponent control={control} name="externalUrl" errors={errors} />
              <span className={styles.availableFileTypes}>
                * available file types: pdf, excel, doc, image, presentation
              </span>
            </Grid>
          )}
          <Grid item sm={12} className={styles.toggle}>
            <Toggle
              name="isVisible"
              size="small"
              control={control}
              label="Show file"
              errors={errors}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <div className={styles.actionButtons}>
          <Button color="primary" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            isLoading={loading}
            name={isEdit ? 'edit' : null}
            disabled={noChanges || !name || !externalUrl}
          >
            {isEdit ? 'Save' : 'Submit'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Form;

import { IAllocationClient } from '~/services/api/clientAllocation/types';

interface IClientIds {
  clientIds: number[];
}

const excludeArchivedClients = <T extends IClientIds>(
  allocations: (T & IClientIds)[],
  archivedClients: IAllocationClient[],
): T[] => {
  const clientIdsToExclude = archivedClients.map(client => client.id);
  return (
    allocations?.map(item => ({
      ...item,
      clientIds: item.clientIds.filter(clientId => !clientIdsToExclude.includes(clientId)),
    })) || []
  );
};

export default excludeArchivedClients;

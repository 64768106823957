import { ReactElement } from 'react';
import { components, GroupTypeBase, SingleValueProps } from 'react-select';

import Chip from '~/ui/pages/MedicationLogistics/components/Chip';

import extractChipText from '~/utils/text/extractChipText';

import { IOption } from '~/types';

import chipStyles from '~/ui/pages/MedicationLogistics/components/Chip/Chip.module.scss';
import styles from './CustomSingleValue.module.scss';

const CustomSingleValue = (
  props: SingleValueProps<IOption, GroupTypeBase<IOption>>,
): ReactElement => {
  const { text, chipText } = extractChipText(props.data.label);

  return (
    <components.SingleValue {...props} className={styles.customSingleValue}>
      <span className={styles.label}>{text}</span>
      {!!chipText && <Chip text={chipText} className={chipStyles.outlineLabel} />}
      {props.data.description && (
        <span className={styles.description}>{props.data.description}</span>
      )}
    </components.SingleValue>
  );
};

export default CustomSingleValue;

import { ReactElement, Suspense, lazy, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import Loader from '~/ui/components/common/Loader';

import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import useFeature from '~/store/clinic/hooks/useFeature';
import { useStoreActions } from '~/store/hooks';

import { ReportType } from './constants/reportTypes';
import { extractErrorMessage } from '~/utils/error/error';

import styles from './Reports.module.scss';

const ReportsHeader = lazy(() => import('./components/ReportsHeader'));
const RecertificationReport = lazy(() => import('./components/RecertificationReport'));
const VisitsByTeamReport = lazy(() => import('./components/VisitsByTeamReport'));
const MedicationsReport = lazy(() => import('./components/MedicationsReport'));
const ClientAllocationVisitsReport = lazy(
  () => import('./components/ClientAllocationVisitsReport'),
);
const VisitsByClientReport = lazy(() => import('./components/VisitsByClientReport'));
const DashboardReport = lazy(() => import('./components/DashboardReport'));
const DemographicsReport = lazy(() => import('./components/DemographicsReport'));
const TreatmentPlansReport = lazy(() => import('./components/TreatmentPlansReport'));
const ClientTreatmentPlansReport = lazy(() => import('./components/ClientTreatmentPlansReport'));
const ClientStatusReport = lazy(() => import('./components/ClientStatusReport'));
const ClientLengthOfStayReport = lazy(() => import('./components/ClientLengthOfStayReport'));
const LocationGroupReport = lazy(() => import('./components/LocationGroupReport'));
const ToxicologyReport = lazy(() => import('./components/ToxicologyReport'));
const LastClientContactReport = lazy(() => import('./components/LastClientContactReport'));
const VisitStatusReport = lazy(() => import('./components/VisitStatusReport'));

const Reports = (): ReactElement => {
  const query = useQuery();
  const reportType = query.get('reportType');

  const { isTreatmentPlansAvailable } = useFeature();
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const renderReport = () => {
    switch (true) {
      case reportType === ReportType.MedicaIdRecertification: {
        return <RecertificationReport />;
      }
      case reportType === ReportType.ClientAllocationVisits: {
        return <ClientAllocationVisitsReport />;
      }
      case reportType === ReportType.VisitsByTeam: {
        return <VisitsByTeamReport />;
      }
      case reportType === ReportType.VisitsByClient: {
        return <VisitsByClientReport />;
      }
      case reportType === ReportType.Demographics: {
        return <DemographicsReport />;
      }
      case reportType === ReportType.Dashboard: {
        return <DashboardReport />;
      }
      case reportType === ReportType.ClientStatus: {
        return <ClientStatusReport />;
      }
      case reportType === ReportType.TreatmentPlans && isTreatmentPlansAvailable: {
        return <TreatmentPlansReport />;
      }
      case reportType === ReportType.ClientTreatmentPlans && isTreatmentPlansAvailable: {
        return <ClientTreatmentPlansReport />;
      }
      case reportType === ReportType.Medications: {
        return <MedicationsReport />;
      }
      case reportType === ReportType.ClientLengthOfStay: {
        return <ClientLengthOfStayReport />;
      }
      case reportType === ReportType.LocationGroup: {
        return <LocationGroupReport />;
      }
      case reportType === ReportType.Toxicology: {
        return <ToxicologyReport />;
      }
      case reportType === ReportType.LastClientContact: {
        return <LastClientContactReport />;
      }
      case reportType === ReportType.VisitStatus: {
        return <VisitStatusReport />;
      }
      default: {
        // return <NoDataFound title="Coming soon..." />;
        return <DashboardReport />;
      }
    }
  };

  const onMount = useCallback(async () => {
    try {
      await onGetMyClinic();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [onGetMyClinic, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={classNames(styles.container, {
          [styles.scrollableContainer]:
            !reportType ||
            reportType === ReportType.Dashboard ||
            reportType === ReportType.ClientTreatmentPlans,
        })}
      >
        <ReportsHeader />
        {renderReport()}
      </div>
    </Suspense>
  );
};

export default Reports;

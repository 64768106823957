import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetClientStatus } from '../types';

const onGetClientStatus: IThunkGetClientStatus = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { data } = await api.clientDetails.getClientStatus(payload);

    setClientDetails({ status: data });
  },
);

export default onGetClientStatus;

import { action } from 'easy-peasy';

import { IActionSetVisitsListByTeamReport } from '../types';

const setVisitsListByTeamReport: IActionSetVisitsListByTeamReport = action((state, payload) => {
  const { items, teamMemberId, pagination } = payload;

  state.visitsByTeam.current = {
    items: pagination.pageNumber === 1 ? items : [...state.visitsByTeam.current.items, ...items],
    teamMemberId,
    pagination: pagination || state.visitsByTeam.current.pagination,
  };
});

export default setVisitsListByTeamReport;

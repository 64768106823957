import { GroupTypeBase, Styles } from 'react-select';

import { red } from '~/ui/constants/colors';
import calculateOptionsHeight from '~/utils/style/calculateOptionsHeight';
import { IOption } from '~/types';

import variables from '~/ui/assets/styles/colors.module.scss';

export default (
  options: IOption[],
  maxMenuHeight: number,
  error: string,
  minHeight: string,
  isRelativeWindow: boolean,
  color: string,
  showSelectAll?: boolean,
  isNarrow?: boolean,
  isAllSelected?: boolean,
): Partial<Styles<IOption, boolean, GroupTypeBase<IOption>>> => ({
  option: (provided: any, { data, isFocused }: any) => {
    const dataColor = data.color || red;
    const backgroundColor = data.backgroundColor || '#ebebeb';

    return {
      ...provided,
      wordBreak: 'break-word',
      fontWeight: 300,
      backgroundColor: isFocused && !isAllSelected ? backgroundColor : 'transparent',
      color: isFocused ? dataColor : 'black',
      ':active': {
        backgroundColor: variables.colorTransparentBlack,
      },
      ':focused': {
        backgroundColor: variables.colorTransparentBlack,
      },
    };
  },
  control: (provided: any, { isFocused }: any) => ({
    ...provided,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#c4c4c4',
    borderRadius: '10px',
    outline: 'none',
    minHeight: minHeight || '40px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    ':hover': {
      borderColor: '#1e1f21',
    },
    'div[class*="indicatorContainer"]': {
      padding: '0 8px 0 0',
    },

    '::before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      outline: 'none',
      borderRadius: 10,
      borderWidth: isFocused ? '2px' : '1px',
      borderStyle: 'solid',
      borderColor: isFocused || !!error ? '#d83713!important' : '#c4c4c4',
    },
  }),
  singleValue: (provided: any, { data }: any) => {
    const dataColor = data.color;
    return {
      ...provided,
      color: dataColor || color,
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    fontWeight: 300,
  }),
  menu: (provided: any) => {
    const longLabels = options.filter(option => option.label.length > 25);
    return {
      ...provided,
      position: isRelativeWindow ? 'relative' : 'absolute',
      height: calculateOptionsHeight(
        (isNarrow && !!longLabels.length
          ? options?.length + longLabels.length * 0.6
          : options?.length) + (showSelectAll ? 1.4 : 0),
        maxMenuHeight,
      ),
      marginTop: '8px',
      marginBottom: '8px',
      overflow: 'hidden',
      transition: 'all 0.3s ease-in-out',
      zIndex: 2,
    };
  },
  menuList: (provided: any) => {
    const longLabels = options.filter(option => option.label.length > 30);
    return {
      ...provided,
      maxHeight: calculateOptionsHeight(
        (isNarrow && !!longLabels.length
          ? options?.length + longLabels.length * 0.6
          : options?.length) + (showSelectAll ? 1.4 : 0),
        maxMenuHeight,
      ),
    };
  },
});

import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { ICoordinateModel } from './types';

const coordinate: ICoordinateModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default coordinate;

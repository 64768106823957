import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ISystemAlertActions, IThunkGetSystemAlertUpdates } from '../types';

const onGetSystemAlertUpdates: IThunkGetSystemAlertUpdates = thunk(
  async ({ setSystemAlertUpdates }: Actions<ISystemAlertActions>, payload) => {
    const { items, total } = await api.systemAlert.getSystemAlertUpdates(payload).then(r => r.data);

    const pagination = { ...payload, total };
    setSystemAlertUpdates({ items, pagination });
  },
);

export default onGetSystemAlertUpdates;

import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ShelterModal from './ShelterModal';
import SheltersTable from './Table';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

type IParams = {
  id: string;
};

const Shelters = (): ReactElement => {
  const { id } = useParams<IParams>();
  const [isArchiveShelter, setIsArchiveShelter] = useState(null);
  const [shelter, setShelter] = useState(null);
  const [shelterModalVisible, setShelterModalVisible] = useState(false);

  const { list } = useStoreState(state => state.shelters);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const { onArchiveShelter, onRestoreShelter } = useStoreActions(actions => actions.shelters);

  const confirmText = isArchiveShelter ? 'Archive' : 'Restore';
  const description = `Are you sure you want to ${confirmText.toLowerCase()} “${
    shelter?.name
  }” Shelter?`;

  const onConfirmMethod = isArchiveShelter ? onArchiveShelter : onRestoreShelter;

  const onConfirm = async (shelterId: string) => {
    try {
      await onConfirmMethod({ clinicId: id, id: shelterId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setShelter(null);
    }
  };

  return (
    <>
      <SheltersTable
        setShelter={setShelter}
        setIsArchiveShelter={setIsArchiveShelter}
        setShelterModalVisible={setShelterModalVisible}
        shelters={list}
        clinicId={id}
      />
      {shelter && !shelterModalVisible && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onConfirm={() => onConfirm(shelter.id)}
          onClose={() => setShelter(null)}
        />
      )}
      {shelterModalVisible && (
        <ShelterModal
          id={shelter?.id}
          onClose={() => {
            setShelter(null);
            setShelterModalVisible(null);
          }}
        />
      )}
    </>
  );
};

export default Shelters;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateShelter, ISheltersStore, IShelterActions } from '../types';

const onUpdateShelter: IThunkUpdateShelter = thunk(
  async ({ setShelters }: Actions<IShelterActions>, payload, { getState }) => {
    const localState = getState() as ISheltersStore;

    const { clinicId, id, data } = payload;

    const shelter = await api.shelters.updateClinicShelter(clinicId, id, data).then(r => r.data);

    const updatedList = localState.list.map(item => (item.id !== id ? item : shelter));

    setShelters({ items: updatedList });
  },
);

export default onUpdateShelter;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUploadAvatar, IUserActions } from '../types';

const onUploadAvatar: IThunkUploadAvatar = thunk(
  async ({ setAvatar }: Actions<IUserActions>, file) => {
    const formData = new FormData();
    formData.append('file', file);
    const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
    await api.users.uploadProfilePhoto(fileName);
    setAvatar(fileName);
  },
);

export default onUploadAvatar;

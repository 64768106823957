import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { ILocationGroupsModel } from './types';

const locationGroups: ILocationGroupsModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default locationGroups;

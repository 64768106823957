import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { ReactElement, useState } from 'react';

import useRole from '~/store/user/hooks/useRole';

import EmptyState from '~/ui/pages/Notifications/components/EmptyState';
import ListItem from './ListItem';

import { ReactComponent as LoadMoreIcon } from '~/ui/assets/images/loadMore.svg';
import { IGroupedTreatmentPlans } from './types';
import styles from './TreatmentPlanDetails.module.scss';

interface IProps {
  showEdit?: boolean;
  showDuplicate?: boolean;
  healthDetailGroup: IGroupedTreatmentPlans;
  noActions?: boolean;
  onEdit?: (num: number, name: string) => void;
  onDelete?: (id: number) => void;
  onDuplicate?: (num: number, name: string) => void;
  onComplete?: (id: number) => void;
}

const TreatmentPlanDetails = ({
  showEdit = true,
  showDuplicate = true,
  healthDetailGroup,
  noActions,
  onEdit,
  onDelete,
  onComplete,
  onDuplicate,
}: IProps): ReactElement => {
  const [showDetails, setShowDetails] = useState([1, 2, 3]);

  const { isActTeamMember } = useRole();

  // toggle show more/less buttons
  const toggle = (itemIndex: number) =>
    setShowDetails(prev => {
      const detailsIndex = prev.findIndex(index => index === itemIndex);
      if (detailsIndex >= 0) {
        const prevData = [...prev];
        prevData.splice(detailsIndex, 1);
        return prevData;
      }
      return [...prev, itemIndex];
    });

  const renderPlanType = (name?: string) =>
    name ? (
      <Typography variant="body2" className={styles.planType}>
        Plan Type: {name}
      </Typography>
    ) : null;

  // get icon according to show more/less state
  const getIcon = (expanded: boolean) =>
    !expanded ? <ExpandMoreIcon color="secondary" /> : <ExpandLessIcon color="secondary" />;

  const { active, inactive, draft } = healthDetailGroup;

  return (
    <div className={styles.col}>
      <div className={styles.wrapper}>
        {!!active.length && (
          <div
            className={classNames(styles.titleBlock, {
              [styles.roundedBottom]: !draft.length && !showDetails.includes(1),
            })}
          >
            <h4 className={styles.title}>Active Treatment Plan</h4>
            {renderPlanType(active?.[0].type?.name)}
            <Button
              variant="text"
              endIcon={getIcon(showDetails.includes(1))}
              onClick={() => toggle(1)}
              color="secondary"
            >
              {`${showDetails.includes(1) ? 'Hide' : 'Show'} Details`}
            </Button>
          </div>
        )}
        {!!active.length &&
          showDetails.includes(1) &&
          active.map(item => (
            <ListItem
              noActions={noActions}
              className={styles.noBorder}
              key={item.id}
              treatmentPlan={item}
              onEdit={() => onEdit(item.id, 'treatment plan')}
              showEdit={showEdit && item.canEdit && !item.isReadOnly}
              onComplete={
                showEdit && item.canEdit && !item.isReadOnly ? () => onComplete(item.id) : undefined
              }
            />
          ))}
        {!!draft.length && (
          <div
            className={classNames(styles.titleBlock, styles.draftTitle, {
              [styles.roundedBottom]: !showDetails.includes(2),
              [styles.roundedTop]: !active.length,
            })}
          >
            <h4 className={styles.title}>Treatment Plan Draft</h4>
            {renderPlanType(draft[0]?.type?.name)}
            <Button
              variant="text"
              endIcon={getIcon(showDetails.includes(2))}
              onClick={() => toggle(2)}
              color="secondary"
            >
              {`${showDetails.includes(2) ? 'Hide' : 'Show'} Details`}
            </Button>
          </div>
        )}
        {!!draft.length &&
          showDetails.includes(2) &&
          draft.map(item => (
            <ListItem
              noActions={noActions}
              className={styles.noBorder}
              key={item.id}
              treatmentPlan={item}
              onEdit={() => onEdit(item.id, 'treatment plan')}
              showEdit={showEdit && item.canEdit && !item.isReadOnly}
              onDelete={
                showEdit && item.canEdit && !isActTeamMember && !item.isReadOnly
                  ? () => onDelete(item.id)
                  : undefined
              }
            />
          ))}
      </div>
      {!!inactive.length && (
        <div className={styles.wrapper}>
          <div className={classNames(styles.titleBlock, styles.inactiveTitle)}>
            <h4 className={styles.title}>Treatment Plan History</h4>
          </div>
          {!!inactive.length &&
            inactive
              .slice(0, showDetails.includes(3) ? 4 : inactive.length)
              .map((item, index) => (
                <ListItem
                  key={item.id}
                  treatmentPlan={item}
                  labelGrey
                  className={
                    (showDetails.includes(3) && index === 3) ||
                    (!showDetails.includes(3) && index === inactive.length - 1)
                      ? styles.noBorder
                      : ''
                  }
                  showDuplicate={
                    showDuplicate &&
                    (item.isArchived || !item.active) &&
                    item.canEdit &&
                    !isActTeamMember &&
                    !item.isReadOnly
                  }
                  noActions={noActions}
                  onDuplicate={showEdit ? () => onDuplicate(item.id, 'treatment plan') : undefined}
                  showEdit={false}
                  onEdit={() => onEdit(item.id, 'treatment plan')}
                />
              ))}
          {inactive.length > 4 && (
            <div className={styles.buttonContainer}>
              <Button
                onClick={() => toggle(3)}
                color="primary"
                variant="outlined"
                startIcon={<LoadMoreIcon />}
              >
                {`Show ${showDetails.includes(3) ? 'more' : 'less'}`}
              </Button>
            </div>
          )}
        </div>
      )}
      {!draft.length && !inactive.length && !active.length && (
        <EmptyState
          className={styles.emptyState}
          noCellWrap
          text="No Treatment Plans have been added"
        />
      )}
    </div>
  );
};

export default TreatmentPlanDetails;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamActions, IThunkGetActTeamsAvailable } from '../types';

const onGetActTeamsAvailable: IThunkGetActTeamsAvailable = thunk(
  async ({ setAvailableActTeams }: Actions<IActTeamActions>, payload) => {
    const { clinicId } = payload;

    const { data } = await api.actTeam.getActTeamsAvailable(clinicId);

    setAvailableActTeams(data);
  },
);

export default onGetActTeamsAvailable;

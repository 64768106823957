import { format, addMinutes } from 'date-fns';

import { generalDate } from '~/ui/constants/dateFormat';

export const isoToFormat = (date: string, dateFormat = generalDate): string =>
  format(new Date(date), dateFormat);

export const getLocalDateNoFormat = (date: string | Date): Date => {
  const tempDate = new Date(date);
  return addMinutes(tempDate, tempDate.getTimezoneOffset());
};

export const isoToLocal = (date: string, dateFormat = generalDate): string =>
  format(getLocalDateNoFormat(date), dateFormat);

export const normalizeDateString = (date: string | Date, endDay?: boolean): string => {
  let newDate = date;

  if (!date) {
    return '';
  }
  if (typeof date !== 'string') {
    newDate = new Date(date.setHours(12))?.toISOString();
  }

  return `${(newDate as string).split('T')[0]}T${endDay ? '23:59:00' : '00:00:00'}Z`;
};

export const setNoon = (date: string | Date): string => {
  let newDate = date;

  if (!date) {
    return '';
  }
  if (typeof date !== 'string') {
    newDate = new Date(date.setHours(12))?.toISOString();
  }

  return `${(newDate as string).split('T')[0]}T12:00:00Z`;
};

export const destructHours = (hours: string): Date => {
  const date = new Date();
  const time = hours.split(':');
  date.setHours(Number(time[0]), Number(time[1]), Number(time[2]));

  return date;
};

export const getCombinedDateTime = (date: string, time?: string): Date =>
  time
    ? new Date(`${date?.split('T')[0]}T${new Date().toISOString()?.split('T')[1]}`)
    : new Date(`${date?.split('T')[0]}T${time?.split('T')[1]}`);

export const removeTime = (date?: string): string | undefined =>
  date ? date.split('T')?.[0] : undefined;

import { action } from 'easy-peasy';
import { FileMessage } from 'sendbird';

import { IActionRemoveTempFile } from '../types';

const removeTempFile: IActionRemoveTempFile = action((state, payload) => {
  const { channelUrl, url } = payload;

  const newMessages = state.messages[channelUrl].items.filter(
    item => (item as FileMessage).url !== url,
  );

  state.messages = {
    ...state.messages,
    [channelUrl]: {
      ...state.messages[channelUrl],
      items: [...newMessages],
    },
  };
});

export default removeTempFile;

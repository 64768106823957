import { ReactElement, Suspense, lazy, useCallback, useEffect } from 'react';

import Loader from '~/ui/components/common/Loader';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import styles from './Visits.module.scss';

const VisitsHeader = lazy(() => import('./components/VisitsHeader'));
const VisitsTable = lazy(() => import('./components/VisitsTable'));
const VisitsTiles = lazy(() => import('./components/VisitsTiles'));
const PastVisitsTable = lazy(() => import('./components/PastVisits/PastVisitsTable'));

const Visits = (): ReactElement => {
  const clinic = useStoreState(state => state.clinic.current);
  const showError = useStoreActions(state => state.snackbar.showError);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);

  const onMount = useCallback(async () => {
    try {
      await onGetMyClinic();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [onGetMyClinic, showError]);

  useEffect(() => {
    if (!clinic) {
      onMount();
    }
  }, [clinic, onMount]);

  return (
    <Suspense fallback={<Loader />}>
      <div className={styles.container}>
        <VisitsHeader />
        <VisitsTiles />
        <VisitsTable />
        <PastVisitsTable />
      </div>
    </Suspense>
  );
};

export default Visits;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetVisitStatusOverviewReport } from '../types';

const onGetVisitStatusOverviewReport: IThunkGetVisitStatusOverviewReport = thunk(
  async ({ setVisitStatusOverviewReport }: Actions<IReportsActions>, { requestInfo, params }) => {
    const visitStatusReport = await api.reports
      .getVisitStatusOverviewReport(requestInfo, params)
      .then(r => r.data);

    setVisitStatusOverviewReport({
      items: visitStatusReport.items,
      pagination: {
        total: visitStatusReport.total,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      },
    });
  },
);

export default onGetVisitStatusOverviewReport;

import { IVisitTreatmentPlan } from '~/services/api/clientDetails/types';
import { IOption } from '~/types';

const getSelectedObjectives = (treatmentPlan: IVisitTreatmentPlan): IOption[] =>
  ((treatmentPlan?.goals || []).map(item => item.objectives) || [])
    .flat()
    .reduce((acc: IOption[], item) => {
      if (item.reviewed) {
        acc.push({ label: item.text, value: item.id });
      }
      return acc;
    }, []);

export default getSelectedObjectives;

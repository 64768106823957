import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import { phone } from '~/ui/configs/form/validation';
import { requiredField } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  firstName: string().required(requiredField),
  lastName: string().required(requiredField),
  phone,
});

export default yupResolver(validationSchema);

import { privateAxios } from '../requestsCollection';
import { IDateRange, IParams, IResponse } from '../types';
import {
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
  IClientAllocationGroup,
  IOwnClientAllocation,
  IRelatedClientsForAllocation,
} from './types';

interface RequestInfo {
  clinicId: string;
  teamId: string;
  clientAllocationId?: string;
}

interface IFiltersRequestPayload {
  from: string;
  to: string;
  includeWeekends: boolean;
}

export interface IAddClientAllocation {
  userId: string;
  clientIds: number[];
  locationGroupIds: number[];
}

export interface IDatesValidation {
  isValid: boolean;
  users: { userId: number; unavailableDates: string[] }[];
}

interface IClientAllocationRequestPayload {
  allocations: IAddClientAllocation[];
}

interface IAddOwnClientAllocationRequestPayload extends IFiltersRequestPayload {
  clientIds: number[];
  locationGroupIds: number[];
}

interface IClientAllocationByDateRequest {
  clinicId: string;
  teamId: string;
  clientAllocationDate: string;
}
interface IAddClientAllocationRequestPayload
  extends IFiltersRequestPayload,
    IClientAllocationRequestPayload {}

interface IValidateDatesRequestPayload extends IFiltersRequestPayload {
  userIds: number[];
}

export const getClientAllocationList = (
  { clinicId, teamId }: RequestInfo,
  params: IParams & IDateRange,
): IResponse<{ total: number; items: IClientAllocationGroup[] }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/list`,
    params,
  });

export const getRelatedClients = (
  clinicId: string,
  teamId: string,
): IResponse<IRelatedClientsForAllocation[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/available-list`,
  });

export const addClientAllocation = (
  { clinicId, teamId }: RequestInfo,
  requestPayload: IAddClientAllocationRequestPayload,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation`,
    data: requestPayload,
  });

export const addOwnClientAllocation = (
  { clinicId, teamId }: RequestInfo,
  requestPayload: IAddOwnClientAllocationRequestPayload,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/own`,
    data: requestPayload,
  });

export interface IUpdateClientAllocation {
  id: string;
  clientIds: number[];
  locationGroupIds: number[];
}

interface IUpdateClientAllocationRequestPayload {
  allocations: IUpdateClientAllocation[];
}

interface IUpdateOwnClientAllocationRequestPayload {
  clientIds: number[];
  locationGroupIds: number[];
}

export const updateClientAllocation = (
  { clinicId, teamId }: RequestInfo,
  requestPayload: IUpdateClientAllocationRequestPayload,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation`,
    data: requestPayload,
  });

export const getAllocationClientsByGroup = ({
  clinicId,
  teamId,
}: RequestInfo): IResponse<IAllocationClientsByGroup[]> =>
  privateAxios(
    {
      method: 'get',
      url: `clinic/${clinicId}/act-team/${teamId}/client/by-location-group`,
    },
    'v2/',
  );

export const updateOwnClientAllocation = (
  { clinicId, teamId, clientAllocationId }: RequestInfo,
  requestPayload: IUpdateOwnClientAllocationRequestPayload,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/own/${clientAllocationId}`,
    data: requestPayload,
  });

export const deleteClientAllocation = ({
  clinicId,
  teamId,
  clientAllocationId,
}: RequestInfo): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/${clientAllocationId}`,
  });

export const deleteOwnClientAllocation = ({
  clinicId,
  teamId,
  clientAllocationId,
}: RequestInfo): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/own/${clientAllocationId}`,
  });

export const getClientAllocation = ({
  clinicId,
  teamId,
  clientAllocationDate,
}: IClientAllocationByDateRequest): IResponse<IClientAllocationDetailed[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/${clientAllocationDate}`,
  });

export const getOwnClientAllocation = ({
  clientAllocationDate,
}: IClientAllocationByDateRequest): IResponse<IOwnClientAllocation> =>
  privateAxios({
    method: 'get',
    url: `client-allocation/my?date=${clientAllocationDate}`,
  });

export const validateDateRange = (
  { clinicId, teamId }: RequestInfo,
  requestPayload: IValidateDatesRequestPayload,
): IResponse<IDatesValidation> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/validate-dates`,
    data: requestPayload,
  });

export const validateOwnDateRange = (
  { clinicId, teamId }: RequestInfo,
  requestPayload: IFiltersRequestPayload,
): IResponse<IDatesValidation> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/own/validate-dates`,
    data: requestPayload,
  });

export default {};

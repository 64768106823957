const splitString = (text: { name: string }) => text.name.split(' ')[1] || text.name;
const prepareString = (text: { name: string }, split = false) =>
  (split ? splitString(text) : text.name).toLocaleLowerCase();

export default (
  a: { name: string },
  b: { name: string },
  split = false,
  reverse = false,
): number => {
  if (reverse) {
    return prepareString(a, split) < prepareString(b, split) ? 1 : -1;
  }
  return prepareString(a, split) < prepareString(b, split) ? -1 : 1;
};

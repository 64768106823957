import { action } from 'easy-peasy';

import { IActionResetClientMedications } from '../types';

const resetClientMedications: IActionResetClientMedications = action((state, payload) => {
  state.medications.report = state.medications.report.map(item => {
    if (item.team.id === payload.teamId) {
      return {
        ...item,
        clients: {
          items: item.clients.items.filter(client => client.id !== payload.clientId),
          total: item.clients.total,
        },
      };
    }
    return item;
  });
});

export default resetClientMedications;

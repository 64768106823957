import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IFavoriteReportRequest } from '~/services/api/reports/types';

import { IReportsActions, IReportsStore, IThunkRemoveFavoriteReport } from '../types';

const onRemoveFavoriteReport: IThunkRemoveFavoriteReport = thunk(
  async (
    { setFavoriteReports }: Actions<IReportsActions>,
    { type }: IFavoriteReportRequest,
    { getState },
  ) => {
    const { favoriteReports } = getState() as IReportsStore;

    await api.reports.removeFavoriteReport({ type }).then(r => r.data);

    const filteredReports = favoriteReports.filter(item => item !== type);

    setFavoriteReports(filteredReports);
  },
);

export default onRemoveFavoriteReport;

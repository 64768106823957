import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IReleaseActions, IThunkGetReleases } from '../types';

const onGetReleases: IThunkGetReleases = thunk(
  async ({ setReleases }: Actions<IReleaseActions>, payload) => {
    const { items, total } = await api.release.getReleases(payload).then(r => r.data);
    const pagination = { ...payload, total };

    setReleases({ items, pagination });
  },
);

export default onGetReleases;

import SearchIcon from '@material-ui/icons/Search';
import { ReactElement, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';

import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation/SelectWithoutAnimation';
import { useStoreActions, useStoreState } from '~/store/hooks';

import resourceTypeOptions from '../../constants/resourceTypeOptions';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './ResourcesFilters.module.scss';

const ResourcesFilters = (): ReactElement => {
  const { filters } = useStoreState(action => action.resource);

  const {
    control,
    formState: { errors },
    watch,
    register,
  } = useForm({
    defaultValues: { name: filters.name, type: filters.type },
  });

  const { name, type } = watch();

  const { setResourcesFilters } = useStoreActions(action => action.resource);

  // save filters to storage
  useEffect(() => {
    setResourcesFilters({ name, type });
  }, [name, type, setResourcesFilters]);

  return (
    <div className={styles.resourcesFilters}>
      <form>
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <Input
              register={register}
              placeholder="Search resource"
              name="name"
              errors={errors}
              startAdornment={<SearchIcon color="primary" />}
            />
          </Grid>
          <Grid item sm={2} className={styles.selectContainer}>
            <SelectComponent
              label="Filter By File Type"
              control={control}
              name="type"
              errors={errors}
              options={resourceTypeOptions}
              hideSelectedOptions={false}
              placeholder="All"
              color={variables.colorRed}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default ResourcesFilters;

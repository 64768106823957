import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamMemberActions, IThunkDeactivateTeamMember } from '../types';

const onDeactivateTeamMember: IThunkDeactivateTeamMember = thunk(
  async ({ deactivateTeamMember }: Actions<IActTeamMemberActions>, payload) => {
    const { clinicId, actTeamId, teamMemberId } = payload;

    await api.actTeamMember.deactivateTeamMember(clinicId, actTeamId, teamMemberId);

    deactivateTeamMember(teamMemberId);
  },
);

export default onDeactivateTeamMember;

import { ReactElement, ReactNode, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import groupByStatus from '~/utils/grouping/groupByStatus';
import formatClientAddress from '~/utils/dataFormat/formatClientAddress';

import Button from '~/ui/components/common/Button';
import ListItem from '~/ui/components/common/ListItem';

import { IAddressExtended } from '~/services/api/types';
import { ReactComponent as TeamLeaderIcon } from '~/ui/assets/images/teamLeader.svg';
import styles from './DetailsBlock.module.scss';

interface IProps {
  title: string;
  buttonText?: string;
  showEdit?: boolean;
  addresses: IAddressExtended[];
  noActions?: boolean;
  showArchive?: boolean;
  showRestore?: boolean;
  showArchivedButton?: boolean;
  labelGrey?: boolean;
  chip?: ReactNode;
  onAdd?: () => void;
  onEdit?: (num: number, name: string) => void;
  setDetailsInfo?: (data: any) => void;
}

const DetailsBlock = ({
  title,
  buttonText,
  showEdit = true,
  addresses,
  noActions,
  showArchive = true,
  showRestore = true,
  showArchivedButton,
  labelGrey,
  chip,
  onAdd,
  onEdit,
  setDetailsInfo,
}: IProps): ReactElement => {
  const [showArchivedList, setShowArchivedList] = useState(false);

  const toggle = () => setShowArchivedList(val => !val);

  const { active, archived } = groupByStatus(addresses);

  const icon = !showArchivedList ? (
    <ExpandMoreIcon color="primary" />
  ) : (
    <ExpandLessIcon color="primary" />
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <h4 className={styles.title}>{title}</h4>
        {buttonText && (
          <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={onAdd}>
            {buttonText}
          </Button>
        )}
      </div>
      {!!active.length &&
        active.map((item, idx) => {
          const addr = formatClientAddress(item);

          const content = (
            <div className={styles.labelWrapper}>
              <div className={styles.grey}>{addr}</div>
            </div>
          );

          const itemLabel = item.type?.name;
          return (
            <ListItem
              noActions={noActions}
              className={active.length === 1 || active.length - 1 === idx ? styles.noBorder : ''}
              key={item.id}
              label={itemLabel}
              content={content}
              onEdit={() => onEdit(item.id, itemLabel)}
              showEdit={showEdit}
              showArchived={showArchive}
              disableArchived={item.isPrimary}
              startIcon={item.isPrimary ? <span>Primary</span> : undefined}
              onArchive={() => setDetailsInfo({ method: 'archive', id: item.id, name: itemLabel })}
              chip={item.isPrimary ? chip : undefined}
            />
          );
        })}
      {showArchivedButton && !!archived.length && (
        <div className={styles.archivedButtonWrapper}>
          <button type="button" onClick={toggle} className={styles.archivedButton}>
            Archived {icon}
          </button>
        </div>
      )}

      {!!archived.length &&
        showArchivedList &&
        archived.map((item, idx) => {
          const addr = formatClientAddress(item);

          const content = (
            <div className={styles.labelWrapper}>
              <div className={styles.grey}>{addr}</div>
            </div>
          );

          const itemLabel = item.type?.name;
          return (
            <ListItem
              noActions={noActions}
              className={active.length === 1 || active.length - 1 === idx ? styles.noBorder : ''}
              key={item.id}
              label={itemLabel}
              content={content}
              onEdit={() => onEdit(item.id, itemLabel)}
              showRestore={showRestore}
              labelGrey={labelGrey}
              startIcon={item.isPrimary ? <TeamLeaderIcon /> : undefined}
              onRestore={() => setDetailsInfo({ method: 'restore', id: item.id, name: itemLabel })}
            />
          );
        })}
    </div>
  );
};

export default DetailsBlock;

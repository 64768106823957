import { action } from 'easy-peasy';
import { IActionDeactivateLocalAdmin } from '../types';

const deactivateLocalAdmin: IActionDeactivateLocalAdmin = action((state, localAdminId) => {
  const updatedList = state.list.map(item =>
    item.id !== localAdminId ? item : { ...item, status: 'Deactivated' },
  );

  state.list = updatedList;
});

export default deactivateLocalAdmin;

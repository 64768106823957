import AddIcon from '@material-ui/icons/Add';
import { ReactElement } from 'react';

import Button from '~/ui/components/common/Button';
import useRole from '~/store/user/hooks/useRole';

import styles from './Header.module.scss';

const Header = ({ openModal }: { openModal: () => void }): ReactElement => {
  const { isSuperAdmin } = useRole();
  return (
    <div className={styles.header}>
      <h2>Resources</h2>
      {isSuperAdmin && (
        <div className={styles.buttonWrapper}>
          <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={openModal}>
            Add Resource
          </Button>
        </div>
      )}
    </div>
  );
};
export default Header;

import { action } from 'easy-peasy';
import { setTreatmentPlansFilter } from '~/services/localStorage/filters';

import { IActionSetTreatmentPlansFilters } from '../types';

const setTreatmentPlansFilters: IActionSetTreatmentPlansFilters = action((state, payload) => {
  // save filters to storage
  setTreatmentPlansFilter(payload);

  state.treatmentPlans.filters = payload;
});

export default setTreatmentPlansFilters;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClinicActions, IThunkGetMyClinic } from '../types';

const onGetMyClinic: IThunkGetMyClinic = thunk(async ({ setClinic }: Actions<IClinicActions>) => {
  const { data } = await api.clinic.getMyClinic();

  setClinic(data);
});

export default onGetMyClinic;

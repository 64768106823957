import { useState, useEffect, ReactElement, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import api from '~/services/api';
import Form from '../Form';
import Loader from '~/ui/components/common/Loader';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import { formatClientDefaultValues } from '~/utils/formatDefaultValues';
import { IInitialValues } from '../Form/initialValues';
import { isFile } from '~/utils/file';
import replaceAt from '~/utils/text/replaceAt';
import { normalizeDateString } from '~/utils/date/date';
import { extractErrorMessage } from '~/utils/error/error';
import { IClinic } from '~/services/api/clinic/types';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { CLIENT, VIEW_CLIENT } from '~/ui/constants/paths';

interface IParams {
  [key: string]: string;
}

type ILocationState = { state: { actTeamId: string } };

const EditClient = (): ReactElement => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { clientId } = useParams<IParams>();
  const location: ILocationState = useLocation();

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const clinic = useStoreState(state => state.clinic.current || ({} as IClinic));
  const client = useStoreState(state => state.client.current);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClient = useStoreActions(actions => actions.client.onGetClient);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      if (!clinic?.id) {
        await onGetMyClinic();
      }

      if (clinic.id) {
        await onGetClient({
          clinicId: String(clinic.id),
          teamId: location.state?.actTeamId,
          clientId,
        });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [clientId, clinic?.id, location.state?.actTeamId, onGetClient, onGetMyClinic, showError]);

  const { name: clinicName, id: clinicId } = clinic;

  const onSubmit = async (vals: IInitialValues) => {
    try {
      const { teamId, medicaId, emrMrn, dateOfBirth, medicaIdRecertificationDate, ...rest } = vals;

      setIsEditing(true);

      if (isFile(rest.photo)) {
        const formData = new FormData();
        formData.append('file', rest.photo);
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        rest.photo = fileName;
      }

      await api.client.editClient(
        { clinicId: String(clinicId), teamId: String(teamId), clientId },
        {
          dateOfBirth: normalizeDateString(dateOfBirth),
          medicaId: medicaId === '' ? null : medicaId,
          emrMrn: emrMrn === '' ? null : emrMrn,
          medicaIdRecertificationDate: medicaIdRecertificationDate
            ? replaceAt(medicaIdRecertificationDate, 8, '01')
            : null,
          ...rest,
        },
      );
      showNotify({ message: 'Client successfully updated' });

      navigate(
        VIEW_CLIENT.replace(':clientId', String(client.id)).replace(
          ':actTeamId',
          String(client.actTeam.id),
        ),
        {
          state: { actTeamId: String(client.actTeam.id), selectedTab: 1 },
        },
      );
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsEditing(false);
    }
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  const description =
    'Are you sure you want to cancel “Edit Client Profile”? All the changes will be discarded.';

  if (loading || !clinicId || !client) return <Loader />;

  const itemsForBreadcrumbs = [
    { to: CLIENT, title: `${clinicName} Clients` },
    {
      to: {
        pathname: VIEW_CLIENT.replace(':clientId', String(client.id)).replace(
          ':actTeamId',
          String(client.actTeam.id),
        ),
        state: { actTeamId: String(client.actTeam.id) },
      },
      title: 'Client Profile',
    },
    { to: pathname, title: 'Edit Client Profile' },
  ];

  return (
    <div>
      <h2>Edit Client Profile</h2>
      <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
      <Form
        defaultValues={formatClientDefaultValues(client)}
        clinicId={String(clinicId)}
        leavePageDescription={description}
        isProcessing={isEditing}
        onSubmit={onSubmit}
        submitText="Save Changes"
      />
    </div>
  );
};

export default EditClient;

import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';

import { requiredField } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  dischargeReasonId: number().required(requiredField),
  dischargeDate: string().required(requiredField),
});

export default yupResolver(validationSchema);

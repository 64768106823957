import { SIGN_IN } from '~/ui/constants/paths';

// perform logout, clear storage and redirect to login page
const forceLogout = (): Promise<never> => {
  localStorage.clear();
  window.location.replace(SIGN_IN);

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    data: { message: 'Unable to restore session. Please login again' },
  });
};

export default forceLogout;

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { ReactElement } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, DatePickerView, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isValid, parseISO } from 'date-fns';
import get from 'lodash/get';

import { generalDate } from '~/ui/constants/dateFormat';

import { ReactComponent as CalendarIcon } from '~/ui/assets/images/calendarIcon.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './DatePicker.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  minDate?: string;
  maxDate?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  clearable?: boolean;
  dateFormat?: string;
  openTo?: 'year' | 'month' | 'date';
  size?: 'medium' | 'small';
  tabIndex?: number;
  className?: string;
  views?: DatePickerView[];
  cb?: (value: Date) => void;
}

const DatePickerComponent = ({
  control,
  errors,
  name,
  minDate,
  maxDate,
  openTo = 'year',
  label = '',
  placeholder = '',
  disabled = false,
  dateFormat = generalDate,
  size = 'small',
  views = ['year', 'month', 'date'],
  tabIndex,
  clearable,
  className,
  cb,
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  const handleChange = (date: [Date, Date] | Date) => {
    if (Array.isArray(date) || !isValid(date)) return '';

    return new Date(date.setHours(12)).toISOString();
  };

  const formatValue = (val: any) => {
    const date = parseISO(val);

    if (!isValid(date)) return null;
    return date;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <>
          <FormControl
            variant="outlined"
            size={size}
            fullWidth
            className={styles.datePickerControl}
          >
            {label && (
              <InputLabel shrink error={isError}>
                {label}
              </InputLabel>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                InputProps={{
                  tabIndex,
                  endAdornment: <CalendarIcon color={variables.colorRed} />,
                }}
                error={!!error}
                openTo={openTo}
                views={views}
                fullWidth
                minDate={minDate || undefined}
                maxDate={maxDate || undefined}
                inputVariant="outlined"
                size={size}
                format={dateFormat}
                value={formatValue(value)}
                onChange={val => {
                  cb?.(val);
                  onChange(handleChange(val));
                }}
                disabled={disabled}
                placeholder={placeholder}
                clearable={clearable}
                className={className}
              />
            </MuiPickersUtilsProvider>

            {error && (
              <FormHelperText error className={styles.errorText}>
                {error.toString()}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
    />
  );
};

export default DatePickerComponent;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { ISosRequestActions, ISosRequestsStore, IThunkRejectSosRequest } from '../types';

const onRejectSosRequest: IThunkRejectSosRequest = thunk(
  async ({ setSosRequestsPagination }: Actions<ISosRequestActions>, payload, { getState }) => {
    const localState = getState() as ISosRequestsStore;
    const { clinicId, id } = payload;

    await api.sosRequests.rejectSosRequest({
      clinicId,
      id,
    });
    const pagination = { ...localState.pagination, total: localState.pagination.total - 1 };
    setSosRequestsPagination(pagination);
  },
);

export default onRejectSosRequest;

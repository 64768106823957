import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkEditMemo, IClientDetailsActions } from '../types';

const onEditMemo: IThunkEditMemo = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { requestInfo, memo } = payload;

    await api.clientDetails.editMemo({ ...requestInfo, memo });

    setClientDetails({ memo });
  },
);

export default onEditMemo;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetSupportContacts } from '../types';

const onGetSupportContacts: IThunkGetSupportContacts = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const supportContacts = await api.clientDetails
      .getClientSupportContactList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ supportContacts });
  },
);

export default onGetSupportContacts;

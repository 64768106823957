import ResourceType from '../models/ResourceType';

const resourceTypeOptions = [
  { value: undefined, label: 'All' },
  { value: ResourceType.Pdf, label: 'Pdf' },
  { value: ResourceType.Excel, label: 'Excel' },
  { value: ResourceType.Text, label: 'Text' },
  { value: ResourceType.Document, label: 'Document' },
  { value: ResourceType.Image, label: 'Image' },
  { value: ResourceType.Presentation, label: 'Presentation' },
];

export default resourceTypeOptions;

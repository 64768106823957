import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { ReactElement } from 'react';

import noDataImage from '~/ui/assets/images/noData.svg';

import styles from './EmptyState.module.scss';

interface IProps {
  noCellWrap?: boolean;
  image?: string;
  text?: string;
  className?: string;
}

const EmptyState = ({ className, image, text, noCellWrap = false }: IProps): ReactElement => {
  const content = (
    <div className={classNames(className, styles.noDataImage)}>
      <img src={image || noDataImage} alt="404" />
      <span>{text || 'No data to display'}</span>
    </div>
  );

  if (noCellWrap) {
    return content;
  }

  return (
    <TableRow>
      <TableCell colSpan={10} align="center">
        {content}
      </TableCell>
    </TableRow>
  );
};

export default EmptyState;

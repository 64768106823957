import { useState, ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import AvatarUpload from '~/ui/components/common/AvatarUpload';

import getFileTypeIconByMimeType from '~/ui/pages/Resources/helpers/getFileTypeIconByMimeType';

import AcceptableResourceMimeTypes from '~/ui/pages/Resources/models/AcceptableResourceMimeTypes';
import styles from './FileUpload.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  label?: string;
}

const FileUploadComponent = ({ control, errors, name, label = '' }: IProps): ReactElement => {
  const [uploadError, setUploadError] = useState('');
  const error = get(errors, `${name}.message`, '');

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControl component="fieldset" className={styles.uploadControl} fullWidth>
            <FormLabel component="legend" className={styles.label}>
              {label}
            </FormLabel>
            <AvatarUpload
              contentTitle="File"
              acceptTypes={Object.values(AcceptableResourceMimeTypes).join(',')}
              value={value}
              maxFileSize={20}
              onUpload={file => {
                onChange(file);
                setUploadError('');
              }}
              className={styles.filePreviewContainer}
              filePreview={
                value?.type ? (
                  <div className={styles.previewIconContainer}>
                    {getFileTypeIconByMimeType(value.type as AcceptableResourceMimeTypes)}
                  </div>
                ) : null
              }
              onRemove={() => onChange('')}
              onError={err => setUploadError(err)}
            />
            {uploadError && <FormHelperText error>{uploadError}</FormHelperText>}
            {error && <FormHelperText error>{error.toString()}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};

export default FileUploadComponent;

import { IReleaseStore } from './types';

const initStore: IReleaseStore = {
  current: null,
  releaseUpdates: { updatesAvailable: false, newReleases: [] },
  list: [],
  pagination: {
    pageSize: 25,
    pageNumber: 1,
    total: 0,
  },
};

export default initStore;

import { ISystemAlertUpdatesItem } from '~/services/api/systemAlert/types';

const getAlertsIds = (systemAlerts: ISystemAlertUpdatesItem[]): number[] =>
  (systemAlerts || [])?.reduce((acc: number[], item: ISystemAlertUpdatesItem) => {
    if (!item.acknowledged) {
      acc.push(item.id);
    }
    return acc;
  }, []);

export default getAlertsIds;

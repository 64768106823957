import { ISheltersStore } from './types';

const initStore: ISheltersStore = {
  current: null,
  list: [],
  pagination: {
    total: 0,
    pageNumber: 1,
    pageSize: 25,
  },
};

export default initStore;

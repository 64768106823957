import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IResourceActions, IThunkGetResource } from '../types';

const onGetResource: IThunkGetResource = thunk(
  async ({ setResource }: Actions<IResourceActions>, resourceId) => {
    const { data } = await api.resource.getResource(resourceId);

    setResource(data);
  },
);

export default onGetResource;

import Envs from '~/ui/constants/envs';

const getEnv = (): Envs => {
  switch (true) {
    case process.env.REACT_APP_API_URL.includes(Envs.DEMO):
      return Envs.DEMO;
    case process.env.REACT_APP_API_URL.includes(Envs.DEV):
      return Envs.DEV;
    case process.env.REACT_APP_API_URL.includes(Envs.STAGE):
      return Envs.STAGE;
    default:
      return Envs.PROD;
  }
};

export default getEnv;

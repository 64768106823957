import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { ISosRequestModel } from './types';

const sosRequest: ISosRequestModel = {
  ...initStore,
  ...thunks,
  ...actions,
};

export default sosRequest;

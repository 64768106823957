import setActTeamMembers from './setActTeamMembers';
import setAvailableActTeamMembers from './setAvailableActTeamMembers';
import setActTeamMember from './setActTeamMember';
import activateTeamMember from './activateTeamMember';
import deactivateTeamMember from './deactivateTeamMember';
import resetPagination from './resetPagination';

export default {
  setActTeamMembers,
  setAvailableActTeamMembers,
  setActTeamMember,
  activateTeamMember,
  deactivateTeamMember,
  resetPagination,
};

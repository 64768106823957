import { ReactElement } from 'react';

import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import { PREVIEW_VISIT, VISITS } from '~/ui/constants/paths';
import { isoToLocal } from '~/utils/date/date';

interface IProps {
  teamId: string;
  clientId: string;
  visitId: string;
  visitDate: string;
}

const PreviewBreadcrumbs = ({ teamId, clientId, visitId, visitDate }: IProps): ReactElement => {
  const breadcrumbItems = [
    {
      title: 'Visits',
      to: {
        pathname: VISITS,
        search: `?teamId=${teamId}&clientId=${clientId}`,
      },
    },
    {
      title: isoToLocal(visitDate),
      to: {
        pathname: PREVIEW_VISIT.replace(':visitId', visitId),
      },
    },
  ];
  return <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />;
};

export default PreviewBreadcrumbs;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IFavoriteReportRequest } from '~/services/api/reports/types';

import { IReportsActions, IReportsStore, IThunkAddFavoriteReport } from '../types';

const onAddFavoriteReport: IThunkAddFavoriteReport = thunk(
  async (
    { setFavoriteReports }: Actions<IReportsActions>,
    { type }: IFavoriteReportRequest,
    { getState },
  ) => {
    const { favoriteReports } = getState() as IReportsStore;

    await api.reports.addFavoriteReport({ type }).then(r => r.data);

    favoriteReports.push(type);

    setFavoriteReports(favoriteReports);
  },
);

export default onAddFavoriteReport;

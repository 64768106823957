import { ReactElement, ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button, { ButtonProps } from '@material-ui/core/Button';

import styles from './Button.module.scss';

interface ICommon {
  children?: ReactNode;
  isLoading?: boolean;
  loaderColor?: string;
}

type IProps = ICommon & ButtonProps;

// This component extends basic MUI Button component
// It supports: isLoading (loader spinner)
const ButtonComponent = ({
  children,
  isLoading = false,
  loaderColor,
  ...props
}: IProps): ReactElement => (
  <Button {...props}>
    <>
      {children}
      {isLoading && (
        <>
          <div className={styles.spacer} />
          <CircularProgress size={20} style={loaderColor ? { color: loaderColor } : {}} />
        </>
      )}
    </>
  </Button>
);

export default ButtonComponent;

interface IItemsGrouped<T> {
  active: T[];
  archived: T[];
}

interface IDeletedProp {
  isArchived: boolean;
}

const groupByStatus = <T extends IDeletedProp>(arr: T[]): IItemsGrouped<T> =>
  arr.reduce(
    (acc, item) => {
      if (item.isArchived) {
        acc.archived.push(item);
      } else {
        acc.active.push(item);
      }

      return acc;
    },
    { active: [], archived: [] },
  );

export default groupByStatus;

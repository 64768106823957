import ResourceType from '../models/ResourceType';

const getFileExtension = (type: ResourceType): string => {
  switch (type) {
    case ResourceType.Pdf: {
      return '.pdf';
    }
    case ResourceType.Excel: {
      return '.xlsx';
    }
    case ResourceType.Document: {
      return '.docx';
    }
    case ResourceType.Text: {
      return '.txt';
    }
    case ResourceType.Image: {
      return '.jpeg';
    }
    case ResourceType.Presentation: {
      return '.pptx';
    }
    default: {
      return '.txt';
    }
  }
};

export default getFileExtension;

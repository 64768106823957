import Box from '@material-ui/core/Box';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useStoreActions, useStoreState } from '~/store/hooks';

import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import useClinicLocationGroups from '~/ui/pages/Clients/hooks/useClinicLocationGroups';

import { extractErrorMessage } from '~/utils/error/error';
import formatLocationGroupOptions from '~/utils/formatLocationGroupOptions';

const LocationGroup = (): ReactElement => {
  const [loading, setLoading] = useState(false);

  const clinic = useStoreState(state => state.clinic.current);
  const { addresses: clientAddresses } = useStoreState(state => state.clientDetails);
  const client = useStoreState(state => state.client.current);

  const onSetClientLocationGroup = useStoreActions(
    actions => actions.clientDetails.onSetClientLocationGroup,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const locationGroup = useMemo(
    () => clientAddresses?.find(address => address.isPrimary).locationGroup,
    [clientAddresses],
  );

  const locationGroups = useClinicLocationGroups();

  const {
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      id: locationGroup?.id || null,
    },
  });

  const { id } = watch();

  // set new client location group
  const handleChangeLocationGroup = useCallback(async () => {
    if (!id || !client.id || !clinic.id || !client.actTeam.id) return;

    const requestInfo = {
      clinicId: String(clinic.id),
      clientId: String(client.id),
      teamId: String(client.actTeam.id),
    };

    const selectedLocationGroup = locationGroups.find(group => Number(group.id) === id);
    try {
      setLoading(true);
      await onSetClientLocationGroup({ requestInfo, locationGroup: selectedLocationGroup });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    id,
    client.id,
    client.actTeam.id,
    clinic.id,
    locationGroups,
    onSetClientLocationGroup,
    showError,
  ]);

  // track changes location group changes
  useEffect(() => {
    if (!loading && id && locationGroup?.id !== id) {
      handleChangeLocationGroup();
    }
  }, [id, locationGroup?.id, loading, handleChangeLocationGroup]);

  return (
    <Box width={150} marginLeft={1} marginRight={1}>
      <Select
        name="id"
        options={formatLocationGroupOptions(locationGroups)}
        control={control}
        errors={errors}
        label="Location Group"
        hideSelectedOptions={false}
        isDisabled={loading}
        isLoading={loading}
      />
    </Box>
  );
};

export default LocationGroup;

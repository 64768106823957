import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IClientDetailsStore, IThunkUpdateAddress } from '../types';

const onUpdateAddress: IThunkUpdateAddress = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const { setAsPrimary, archivePrimary } = requestPayload;
    const { id } = requestInfo;

    const address = await api.clientDetails
      .updateAddress(requestInfo, requestPayload)
      .then(r => r.data);

    const addresses = localState.addresses.map(item =>
      item.id !== Number(id)
        ? {
            ...item,
            ...(archivePrimary
              ? {
                  isArchived: item.isPrimary ? true : item.isArchived,
                  locationGroup: item.isPrimary ? null : item.locationGroup, // reset location group for prev primary address
                }
              : {}),
            ...(setAsPrimary
              ? {
                  isPrimary: false,
                  locationGroup: null, // reset location group for prev primary address
                }
              : {}),
          }
        : { ...address, isPrimary: setAsPrimary },
    );

    setClientDetails({ addresses });
  },
);

export default onUpdateAddress;

import { action } from 'easy-peasy';
import { IActionSetExcludedAvailableDictionaryOptions } from '../types';

const setExcludedAvailableDictionaryOptions: IActionSetExcludedAvailableDictionaryOptions = action(
  (state, payload) => {
    state.availableDictionaries[payload.type].excluded = payload.value;
  },
);

export default setExcludedAvailableDictionaryOptions;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { setTeamMembersFilter } from '~/services/localStorage/filters';
import { IActTeamMemberActions, IThunkGetActTeamMembers } from '../types';

const onGetActTeamMembers: IThunkGetActTeamMembers = thunk(
  async ({ setActTeamMembers }: Actions<IActTeamMemberActions>, payload) => {
    const { clinicId, actTeamId, params } = payload;

    const { items, total } = await api.actTeamMember
      .getActTeamMembers(clinicId, actTeamId, params)
      .then(r => r.data);
    const pagination = { ...params, total };

    const filters = {
      name: params.name,
      status: params.status,
      sorting: Number(params.sorting),
    };

    // save filters to storage
    setTeamMembersFilter(filters);

    setActTeamMembers({ items, pagination, filters });
  },
);

export default onGetActTeamMembers;

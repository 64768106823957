import makeStyles from '@material-ui/styles/makeStyles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import { ReactElement } from 'react';
import { red } from '~/ui/constants/colors';

const useStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: '8px',
    color: 'black',
  },
  checked: {
    color: `${red} !important`,
  },
});

const CustomCheckbox = (props: CheckboxProps): ReactElement => {
  const classes = useStyles();
  return <Checkbox classes={{ root: classes.root, checked: classes.checked }} {...props} />;
};

export default CustomCheckbox;

import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkAssignLocalAdmin } from '../types';

const onAssignLocalAdmin: IThunkAssignLocalAdmin = thunk(async (_, payload) => {
  const { clinicId, localAdminId } = payload;

  await api.localAdmin.assignLocalAdmin(Number(clinicId), localAdminId);
});

export default onAssignLocalAdmin;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkChangeClientStatusDate } from '../types';

const onChangeClientStatusDate: IThunkChangeClientStatusDate = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { requestInfo, dateOfEntry } = payload;

    const { data } = await api.clientDetails.changeClientStatusDate({
      ...requestInfo,
      dateOfEntry,
    });

    setClientDetails({ status: data });
  },
);

export default onChangeClientStatusDate;

import { RiskAssessment } from '~/ui/constants/riskAssessment';

const getRiskAssessmentLabel = (riskAssessment?: string | null): string => {
  switch (riskAssessment) {
    case RiskAssessment.HighRisk: {
      return 'HR';
    }
    case RiskAssessment.AtRisk: {
      return 'AR';
    }
    case RiskAssessment.NoRisk: {
      return 'NR';
    }
    default: {
      return 'NR';
    }
  }
};

export default getRiskAssessmentLabel;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { setClientsFilter } from '~/services/localStorage/filters';
import { IClientActions, IThunkGetClinicClients } from '../types';

const onGetClinicClients: IThunkGetClinicClients = thunk(
  async ({ setClients }: Actions<IClientActions>, payload) => {
    const { clinicId, teamIds, params } = payload;

    const { items, total } = await api.client
      .getClients(clinicId, teamIds, params)
      .then(r => r.data);

    const pagination = { ...params, total };
    const filters = {
      name: params.name,
      status: params.status,
      sorting: Number(params.sorting),
      teamIds: teamIds?.map(item => Number(item)) || [],
    };

    // save filters to storage
    setClientsFilter(filters);

    setClients({ items, pagination, filters });
  },
);

export default onGetClinicClients;

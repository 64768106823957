import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionResetClientStore } from '../types';

const resetClientStore: IActionResetClientStore = action(state => {
  state.current = initStore.current;
  state.list = initStore.list;
  state.filters = initStore.filters;
  state.clientOptions = initStore.clientOptions;
  state.defaultClient = initStore.defaultClient;
  state.availableClients = initStore.availableClients;
});

export default resetClientStore;

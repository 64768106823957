import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IReleaseActions, IThunkGetReleaseUpdates } from '../types';

const onGetReleaseUpdates: IThunkGetReleaseUpdates = thunk(
  async ({ setReleaseUpdates }: Actions<IReleaseActions>) => {
    const { data } = await api.release.getReleaseUpdates();

    setReleaseUpdates(data);
  },
);

export default onGetReleaseUpdates;

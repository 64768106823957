import { action } from 'easy-peasy';
import { setDemographicsFilter } from '~/services/localStorage/filters';

import { IActionSetDemographicsFilters } from '../types';

const setDemographicsFilters: IActionSetDemographicsFilters = action((state, payload) => {
  // save filters to storage
  setDemographicsFilter(payload);

  state.demographics.filters = payload;
});

export default setDemographicsFilters;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateResource, IResourceStore, IResourceActions } from '../types';

const onCreateResource: IThunkCreateResource = thunk(
  async ({ setResources }: Actions<IResourceActions>, payload, { getState }) => {
    const localState = getState() as IResourceStore;

    const resource = await api.resource.addResource(payload).then(r => r.data);

    setResources({
      items: [resource, ...localState.list],
      pagination: { ...localState.pagination, total: localState.pagination.total + 1 },
    });
  },
);

export default onCreateResource;

import { action } from 'easy-peasy';
import { setClientTreatmentPlansFilter } from '~/services/localStorage/filters';

import { IActionSetClientTreatmentPlansFilters } from '../types';

const setClientTreatmentPlansFilters: IActionSetClientTreatmentPlansFilters = action(
  (state, payload) => {
    // save filters to storage
    setClientTreatmentPlansFilter(payload);

    state.clientTreatmentPlans.filters = payload;
  },
);

export default setClientTreatmentPlansFilters;

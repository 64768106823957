import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkUnAssignLocalAdmin } from '../types';

const onUnAssignLocalAdmin: IThunkUnAssignLocalAdmin = thunk(async (_, payload) => {
  const { clinicId, localAdminId } = payload;

  await api.localAdmin.unAssignLocalAdmin(Number(clinicId), localAdminId);
});

export default onUnAssignLocalAdmin;

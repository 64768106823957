import { ReactElement, useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import Form from './Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import { extractErrorMessage } from '~/utils/error/error';
import {
  teamMemberMapper,
  clientMapper,
  sortByName,
  clientAllocationMapper,
} from '~/utils/clientAllocation';

import {
  ACT_TEAM_MANAGEMENT,
  MY_ACT_TEAM,
  VIEW_ACT_TEAM,
  VIEW_CLIENT_ALLOCATION,
} from '~/ui/constants/paths';
import extractDateFromString from '~/utils/date/extractDateFromString';

type IParams = {
  actTeamId: string;
  clientAllocationDate: string;
};

const EditClientAllocation = (): ReactElement => {
  const { actTeamId, clientAllocationDate } = useParams<IParams>();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [clients, setClients] = useState([]);
  const [assignedClients, setAssignedClients] = useState<number[]>([]);

  const { clinic: userClinic, id: userId } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const clientAllocation = useStoreState(state => state.clientAllocation.current);
  const locationGroups = useStoreState(state => state.clientAllocation.locationGroups);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const onGetAllocationClientsByGroup = useStoreActions(
    actions => actions.clientAllocation.onGetAllocationClientsByGroup,
  );
  const { onGetClientAllocation, onGetOwnClientAllocation } = useStoreActions(
    actions => actions.clientAllocation,
  );

  const { isActTeam, isActTeamMember } = useRole();

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };

      await Promise.all([
        onGetMyClinic(),
        onGetClinicActTeam(payload),
        api.clientAllocation
          .getRelatedClients(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(data => data.map(clientMapper))
          .then(r => setClients(r)),
        api.actTeamMember
          .getActTeamMemberList(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(data => data.sort(sortByName).map(teamMemberMapper))
          .then(r => {
            if (isActTeamMember) {
              setTeamMembers(r.filter(member => member.id === userId));
            } else {
              setTeamMembers(r);
            }
          }),
        isActTeamMember
          ? onGetOwnClientAllocation({
              clinicId: String(userClinic.id),
              teamId: actTeamId,
              clientAllocationDate: `${clientAllocationDate}T00:00:00Z`,
            })
          : onGetClientAllocation({
              clinicId: String(userClinic.id),
              teamId: actTeamId,
              clientAllocationDate: `${clientAllocationDate}T00:00:00Z`,
            }),
        isActTeamMember
          ? api.clientAllocation
              .getClientAllocation({
                clinicId: String(userClinic.id),
                teamId: actTeamId,
                clientAllocationDate: `${clientAllocationDate}T00:00:00Z`,
              })
              .then(r => r.data)
              .then(data => {
                setAssignedClients(clientAllocationMapper(data));
              })
          : () => {},
        onGetAllocationClientsByGroup({ clinicId: String(userClinic.id), teamId: actTeamId }),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    actTeamId,
    clientAllocationDate,
    isActTeamMember,
    onGetAllocationClientsByGroup,
    onGetClientAllocation,
    onGetClinicActTeam,
    onGetMyClinic,
    onGetOwnClientAllocation,
    showError,
    userClinic.id,
    userId,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !actTeam || !clientAllocation) return <Loader />;

  const breadcrumbItems = [
    ...(isActTeam
      ? [
          {
            title: actTeam.name,
            to: {
              pathname: MY_ACT_TEAM,
              state: { selectedTab: 0 },
            },
          },
          {
            to: {
              pathname: VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                ':clientAllocationDate',
                String(clientAllocation[0].date.split('T')[0]),
              ),
            },
            title: 'Client Allocation',
          },
        ]
      : [
          { to: ACT_TEAM_MANAGEMENT, title: `${userClinic.name} Teams` },
          {
            to: {
              pathname: VIEW_ACT_TEAM.replace(':actTeamId', String(actTeam.id)),
              state: { selectedTab: 2 },
            },
            title: actTeam.name,
          },
        ]),
    { to: pathname, title: 'Edit Client Allocation' },
  ];

  return (
    <div>
      <h2>{`Edit Client Allocation | ${extractDateFromString(clientAllocation[0].date)}`}</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        clientAllocation={clientAllocation}
        assignedClients={assignedClients}
        teamMembers={teamMembers}
        clients={clients}
        actTeamId={actTeamId}
        clinicId={String(userClinic.id)}
        locationGroups={locationGroups}
      />
    </div>
  );
};

export default EditClientAllocation;

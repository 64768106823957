import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveShelter, ISheltersStore, IShelterActions } from '../types';

const onArchiveActTeam: IThunkArchiveShelter = thunk(
  async ({ setShelters }: Actions<IShelterActions>, payload, { getState }) => {
    const localState = getState() as ISheltersStore;
    const { clinicId, id } = payload;

    await api.shelters.archiveShelter(clinicId, id);

    const updatedList = localState.list.map(item =>
      item.id !== id ? item : { ...item, isArchived: true },
    );

    setShelters({ items: updatedList });
  },
);

export default onArchiveActTeam;

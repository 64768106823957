import { IClientTriggerResponse, IClientTrigger } from '~/services/api/clientDetails/types';

const formatTrigger = (trigger: IClientTriggerResponse): IClientTrigger => ({
  id: trigger.id,
  note: trigger.note,
  type: trigger.type,
  trigger: { id: trigger.trigger.id, name: trigger.trigger.name },
  isArchived: false,
});

export default formatTrigger;

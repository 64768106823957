import { ReactElement, useState } from 'react';

import LocationDetails from '../LocationDetails';
import ListItem from '~/ui/components/common/ListItem';
import ProfilePhotoUpload from '~/ui/components/common/ProfilePhotoUpload';
import getRiskAssessmentLabel from '~/ui/pages/MedicationLogistics/helpers/getRiskAssessmentLabel';
import ContactDetails from '../ContactDetails';
import Memos from '../Memos';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

import api from '~/services/api';
import { isFile } from '~/utils/file';
import { isoToLocal } from '~/utils/date/date';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import { IClientDetailed } from '~/services/api/client/types';
import { getExpiresIn, getPaperworkStatus, getRecertificationColorByDate } from './helpers';

import { suicideRiskColor } from '~/ui/constants/suicideRisks';

import styles from './PersonalDetails.module.scss';
import Status from '../Status';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
}

const PersonalDetails = ({ client, clinicId, actTeamId }: IProps): ReactElement => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [value, setValue] = useState(client.photo || null);
  const [error, setError] = useState(null);
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { suicideRisk, riskAssessment } = client;

  const formattedBirth = isoToLocal(client.dateOfBirth);
  const formattedAdmission = isoToLocal(client.admissionDetails.admissionDate);
  const formattedDischarge = isoToLocal(client.admissionDetails.dischargeDate);
  const formattedRecertification = client.medicaIdRecertificationDate
    ? isoToLocal(client.medicaIdRecertificationDate, 'MM/yyyy')
    : null;

  const color = suicideRiskColor[suicideRisk];

  const onUpload = async (file: any) => {
    try {
      setLoadingPhoto(true);

      if (!isFile(file)) return;

      const formData = new FormData();
      formData.append('file', file);
      const { fileName } = await api.file.uploadFile(formData).then(r => r.data);

      await api.client.uploadClientPhoto(
        { clinicId, clientId: String(client.id), teamId: actTeamId },
        fileName,
      );

      setValue(file);
      showNotify({ message: 'Photo successfully updated' });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const onRemove = async () => {
    try {
      setLoadingPhoto(true);

      await api.client.uploadClientPhoto(
        { clinicId, clientId: String(client.id), teamId: actTeamId },
        '',
      );

      setValue('');
      setShowConfirm(false);
      showNotify({ message: 'Photo successfully removed!' });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const description = 'Are you sure you want to remove client photo?';

  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.listItemBlock}>
            <div className={styles.item}>
              <h4>Client Profile Details</h4>
              <h4 style={{ color }}>
                {!!riskAssessment && (
                  <span className={styles.black}>{getRiskAssessmentLabel(riskAssessment)}/</span>
                )}
                SR{client.aot ? <span className={styles.black}>/AOT</span> : ''}
              </h4>
            </div>
            <ListItem label="First Name" content={client.firstName} moreLabelWidth />
            <ListItem label="Last Name" content={client.lastName} moreLabelWidth />
            <ListItem label="Gender" content={client.gender.name} moreLabelWidth />
            <ListItem label="Date of Birth" content={formattedBirth} moreLabelWidth />
            {client.race && <ListItem label="Race" content={client.race.name} moreLabelWidth />}
            <ListItem
              label="Medicaid #"
              content={
                <div
                  style={{
                    color: getRecertificationColorByDate(
                      client.medicaIdRecertificationDate,
                      client.paperworkStatus,
                    ),
                  }}
                >
                  {client.medicaId}
                </div>
              }
              moreLabelWidth
            />
            <ListItem label="EMR MRN" content={client.emrMrn} moreLabelWidth />
            {!!formattedRecertification && (
              <ListItem
                label="Recertification Expiration Date"
                content={
                  <div
                    style={{
                      color: getRecertificationColorByDate(
                        client.medicaIdRecertificationDate,
                        client.paperworkStatus,
                      ),
                    }}
                  >
                    {formattedRecertification}
                    {getExpiresIn(client.medicaIdRecertificationDate)}
                    {!!client.paperworkStatus && (
                      <>
                        <br />
                        <span className={styles.paperwork}>
                          {getPaperworkStatus(client.paperworkStatus)}
                        </span>
                      </>
                    )}
                  </div>
                }
                moreLabelWidth
              />
            )}
            <ListItem label="Assigned Team" content={client.actTeam.name} moreLabelWidth />
            <ListItem
              label="Referral Source"
              content={client.admissionDetails.referralSource.name}
              moreLabelWidth
            />
            <ListItem label="Date of Admission" content={formattedAdmission} moreLabelWidth />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.uploaderWrapper}>
            <ProfilePhotoUpload
              disabled={loadingPhoto || client.isArchived}
              value={value}
              onUpload={onUpload}
              onError={setError}
              onRemove={() => setShowConfirm(true)}
              showButtons={!client.isArchived}
            />
            {error && <div className={styles.red}>{error}</div>}
          </div>
        </div>
        {showConfirm && (
          <ConfirmModal
            description={description}
            confirmText="Remove"
            onClose={() => setShowConfirm(false)}
            onConfirm={() => onRemove()}
          />
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <LocationDetails
            client={client}
            clinicId={clinicId}
            actTeamId={String(client.actTeam.id)}
          />
          <ContactDetails
            client={client}
            clinicId={clinicId}
            actTeamId={String(client.actTeam.id)}
          />
        </div>
        <div className={styles.rightColumn}>
          <Status client={client} clinicId={clinicId} actTeamId={String(client.actTeam.id)} />
          <Memos client={client} clinicId={clinicId} actTeamId={String(client.actTeam.id)} />
          {client.isArchived && (
            <div className={styles.dischargeDetails}>
              <h4 className={styles.red}>ARCHIVED CLIENT</h4>
              <div className={styles.listItemBlock}>
                <div className={styles.item}>
                  <h4>Details of Discharge</h4>
                </div>
                <ListItem label="Date of Discharge" content={formattedDischarge} moreLabelWidth />
                <ListItem
                  label="Reason for Discharge"
                  content={client.admissionDetails.dischargeReason.name}
                  moreLabelWidth
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import formatSecondaryDiagnosis from '~/utils/formatSecondaryDiagnosis';
import {
  IThunkUpdateSecondaryDiagnosis,
  IClientDetailsStore,
  IClientDetailsActions,
} from '../types';

const onUpdateSecondaryDiagnosis: IThunkUpdateSecondaryDiagnosis = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const diagnosis = await api.clientDetails
      .updateClientSecondaryDiagnosis(requestInfo, requestPayload)
      .then(r => formatSecondaryDiagnosis(r.data));

    const diagnoses = localState.diagnoses.map(item =>
      item.id !== diagnosis.id ? item : diagnosis,
    );

    setClientDetails({ diagnoses });
  },
);

export default onUpdateSecondaryDiagnosis;

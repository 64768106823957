import { Sorting } from '~/services/api/types';
import { getTeamMembersFilter } from '~/services/localStorage/filters';
import { IActTeamMemberStore } from './types';

const initStore: IActTeamMemberStore = {
  current: null,
  list: [],
  availableList: [],
  pagination: {
    pageSize: 25,
    pageNumber: 1,
    total: 0,
  },
  filters: getTeamMembersFilter() || {
    name: '',
    sorting: Sorting.ByEditDate,
    status: 1,
  },
};

export default initStore;

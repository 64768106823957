import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IReleaseActions, IThunkGetRelease } from '../types';

const onGetRelease: IThunkGetRelease = thunk(
  async ({ setRelease }: Actions<IReleaseActions>, releaseId) => {
    const { data } = await api.release.getRelease(releaseId);

    setRelease(data);
  },
);

export default onGetRelease;

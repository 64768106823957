export enum SortOptions {
  ByName = 'ByName',
  ByDuration = 'ByDuration',
  ByCompletedVisits = 'ByCompletedVisits',
  ByNoShow = 'ByNoShow',
}

export enum FilterOptions {
  CompletedVisits = 'CompletedVisits',
  NoShow = 'NoShow',
  NoVisits = 'NoVisits',
  ActiveOnly = 'ActiveOnly',
}

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum FilterLogic {
  Or = 'Or',
  And = 'And',
}

export const visitsSortOptions = [
  {
    value: SortOptions.ByName,
    label: 'Name',
  },
  {
    value: SortOptions.ByDuration,
    label: 'Duration',
  },
  {
    value: SortOptions.ByCompletedVisits,
    label: 'Completed Visits',
  },
  {
    value: SortOptions.ByNoShow,
    label: 'No Show',
  },
];

export const visitsFilterOptions = [
  {
    value: FilterOptions.NoVisits,
    label: 'No Visits',
  },
  {
    value: FilterOptions.CompletedVisits,
    label: 'Completed Visits',
  },
  {
    value: FilterOptions.NoShow,
    label: 'No Show',
  },
];

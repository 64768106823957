import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetMedicationsReport } from '../types';

const onGetMedicationsReport: IThunkGetMedicationsReport = thunk(
  async ({ setMedicationsReport }: Actions<IReportsActions>, params) => {
    const report = await api.reports.getMedicationsReport(params).then(r => r.data);

    setMedicationsReport({
      report,
      filters: {
        teamIds: params.teamIds,
        injectable: {
          from: params['InjectableDateRange.From'],
          to: params['InjectableDateRange.To'],
        },
        endDate: { from: params['EndDateRange.From'], to: params['EndDateRange.To'] },
      },
    });
  },
);

export default onGetMedicationsReport;

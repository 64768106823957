import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import get from 'lodash/get';
import { ReactElement } from 'react';
import DateRangePicker from 'react-datepicker';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { isSameDay, isValid } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

import { generalDate } from '~/ui/constants/dateFormat';
import { ReactComponent as CalendarIcon } from '~/ui/assets/images/calendarIcon.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './DateRangePicker.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  minDate?: string;
  maxDate?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  dateFormat?: string;
  showYearDropdown?: boolean;
  size?: 'medium' | 'small';
}

const CustomDateRangePicker = ({
  control,
  errors,
  name,
  minDate,
  maxDate,
  label = '',
  placeholder = '',
  disabled = false,
  dateFormat = generalDate,
  size = 'small',
  showYearDropdown,
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant="outlined" size={size} fullWidth className={styles.datePickerControl}>
          {label && (
            <InputLabel shrink error={isError}>
              {label}
            </InputLabel>
          )}
          <DateRangePicker
            onChange={dates => {
              // fix for selection same date for range
              const update = dates as [Date, Date];
              const firstSelected = !!value && value[0] && !value[1];
              const sameDay = !!value && isSameDay(value[0], update[0]);

              if (firstSelected && sameDay && !update[1]) {
                onChange([update[0], update[0]]);
              } else {
                onChange(update);
              }
            }}
            onChangeRaw={event => {
              const dates = event.target.value?.split(' - ');
              const isValidDates = dates
                ? isValid(new Date(dates[0])) && isValid(new Date(dates[1]))
                : false;
              const from = isValidDates ? new Date(dates[0]) : null;
              const to = isValidDates ? new Date(dates[1]) : null;
              if (isValidDates) {
                onChange([from, to], event);
              }
            }}
            startDate={value?.[0]}
            endDate={value?.[1]}
            placeholderText={placeholder}
            disabled={disabled}
            selectsRange
            shouldCloseOnSelect={false}
            calendarClassName={styles.calendar}
            customInput={
              <OutlinedInput endAdornment={<CalendarIcon color={variables.colorRed} />} fullWidth />
            }
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            dateFormat={dateFormat}
            showYearDropdown={showYearDropdown}
          />

          {error && (
            <FormHelperText error className={styles.errorText}>
              {error.toString()}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default CustomDateRangePicker;

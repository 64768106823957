export enum ReportType {
  MedicaIdRecertification = 'MedicaidStats',
  VisitMetrics = 'VisitMetrics',
  ClientStatus = 'ClientStatusReport',
  VisitsByTeam = 'VisitsByTeam',
  VisitsByClient = 'VisitsByClient',
  Dashboard = 'Dashboard',
  ClientAllocationVisits = 'AllocationVisits',
  Demographics = 'ClientDemographics',
  TreatmentPlans = 'TreatmentPlans',
  ClientTreatmentPlans = 'ClientTreatmentPlans',
  Medications = 'ClientMedications',
  ClientLengthOfStay = 'ClientLengthOfStay',
  LocationGroup = 'LocationGroups',
  Toxicology = 'Toxicology',
  LastClientContact = 'LastContact',
  VisitStatus = 'VisitStatus',
}

const reportTypes = {
  [ReportType.MedicaIdRecertification]: 'Medicaid Recertification Statistics',
  [ReportType.VisitMetrics]: 'Visit Metrics Report',
  [ReportType.ClientStatus]: 'Client Status Report',
  [ReportType.VisitsByTeam]: 'Visits Report by Team',
  [ReportType.VisitsByClient]: 'Visits Report by Client',
  [ReportType.Dashboard]: 'Dashboard',
  [ReportType.Demographics]: 'Client Demographics and Health Details',
  [ReportType.ClientAllocationVisits]: 'Client Allocation Visits',
  [ReportType.TreatmentPlans]: 'Treatment Plan Report',
  [ReportType.ClientTreatmentPlans]: 'Treatment Plans by Client',
  [ReportType.Medications]: 'Client Medication Details',
  [ReportType.ClientLengthOfStay]: 'Client Length of Stay Details',
  [ReportType.LocationGroup]: 'Location Group',
  [ReportType.Toxicology]: 'Client Toxicology Report',
  [ReportType.LastClientContact]: 'Last Client Contact',
  [ReportType.VisitStatus]: 'Visit Status Report',
};

export const reportTypeOptions = [
  {
    value: ReportType.MedicaIdRecertification,
    label: reportTypes[ReportType.MedicaIdRecertification],
  },
  // {
  //   value: ReportType.VisitMetrics,
  //   label: reportTypes[ReportType.VisitMetrics],
  // },
  {
    value: ReportType.VisitsByTeam,
    label: reportTypes[ReportType.VisitsByTeam],
  },
  {
    value: ReportType.VisitsByClient,
    label: reportTypes[ReportType.VisitsByClient],
  },
  {
    value: ReportType.ClientStatus,
    label: reportTypes[ReportType.ClientStatus],
  },
  {
    value: ReportType.Dashboard,
    label: reportTypes[ReportType.Dashboard],
  },
  {
    value: ReportType.ClientAllocationVisits,
    label: reportTypes[ReportType.ClientAllocationVisits],
  },
  {
    value: ReportType.Demographics,
    label: reportTypes[ReportType.Demographics],
  },
  {
    value: ReportType.TreatmentPlans,
    label: reportTypes[ReportType.TreatmentPlans],
  },
  {
    value: ReportType.ClientTreatmentPlans,
    label: reportTypes[ReportType.ClientTreatmentPlans],
  },
  {
    value: ReportType.Medications,
    label: reportTypes[ReportType.Medications],
  },
  {
    value: ReportType.ClientLengthOfStay,
    label: reportTypes[ReportType.ClientLengthOfStay],
  },
  {
    value: ReportType.LocationGroup,
    label: reportTypes[ReportType.LocationGroup],
  },
  {
    value: ReportType.Toxicology,
    label: reportTypes[ReportType.Toxicology],
  },
  {
    value: ReportType.LastClientContact,
    label: reportTypes[ReportType.LastClientContact],
  },
  {
    value: ReportType.VisitStatus,
    label: reportTypes[ReportType.VisitStatus],
  },
];

export const onlyAdminReports = [
  ReportType.MedicaIdRecertification,
  ReportType.VisitMetrics,
  ReportType.ClientStatus,
  ReportType.VisitsByTeam,
  ReportType.VisitsByClient,
  ReportType.ClientAllocationVisits,
  ReportType.Demographics,
  ReportType.ClientLengthOfStay,
  ReportType.LocationGroup,
  ReportType.LastClientContact,
  ReportType.VisitStatus,
];

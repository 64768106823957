import { ICurrentClinic } from '~/services/api/clinic/types';
import { useStoreState } from '~/store/hooks';
import Features from '~/ui/constants/features';

interface IUseFeature {
  isTreatmentPlansAvailable: boolean;
  isToxicologyAvailable: boolean;
  isBidirectionalAvailable: boolean;
  isDocumentsAvailable: boolean;
}

const useFeature = (): IUseFeature => {
  const clinic = useStoreState(state => state.clinic.current || ({} as ICurrentClinic));

  const isTreatmentPlansAvailable = clinic.features?.includes(Features.TREATMENT_PLAN);
  const isToxicologyAvailable = clinic.features?.includes(Features.TOXICOLOGY);
  const isBidirectionalAvailable = clinic.features?.includes(Features.BIDIRECTIONAL);
  const isDocumentsAvailable = clinic.features?.includes(Features.DOCUMENTS);

  return {
    isTreatmentPlansAvailable,
    isToxicologyAvailable,
    isBidirectionalAvailable,
    isDocumentsAvailable,
  };
};

export default useFeature;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreClient, IClientStore, IClientActions } from '../types';
import extractFullName from '~/utils/text/extractFullName';

const onRestoreClient: IThunkRestoreClient = thunk(
  async ({ setClients, setClientOptions }: Actions<IClientActions>, payload, { getState }) => {
    const localState = getState() as IClientStore;

    await api.client.restoreClient(payload);

    const updatedList = localState.list.map(item =>
      String(item.id) !== payload.clientId ? item : { ...item, isArchived: false },
    );

    const restoredClient = localState.list.find(item => String(item.id) === payload.clientId);

    setClients({ items: updatedList });

    if (restoredClient) {
      setClientOptions({
        items: [
          ...localState.clientOptions.list,
          {
            id: restoredClient.id,
            name: extractFullName(restoredClient),
            photo: restoredClient.photo,
            team: restoredClient.actTeam,
          },
        ],
        archived: localState.clientOptions.excluded.filter(
          item => item !== Number(payload.clientId),
        ),
      });
    }
  },
);

export default onRestoreClient;

import { ReactElement, useCallback, useEffect, useState } from 'react';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';
import ClinicProfile from '../ViewClinic/ClinicProfile';
import { extractErrorMessage } from '~/utils/error/error';

import styles from './MyClinic.module.scss';

const MyClinic = (): ReactElement => {
  const [loading, setLoading] = useState(false);

  const clinic = useStoreState(state => state.clinic.current);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      await onGetMyClinic();
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [onGetMyClinic, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !clinic) return <Loader />;

  return (
    <div>
      <div className={styles.header}>
        <h2>{clinic.name} Clinic</h2>
      </div>
      <ClinicProfile clinic={clinic} />
    </div>
  );
};

export default MyClinic;

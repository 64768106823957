import { ReactElement, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProgramAssistantTable from './Table';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import formatActTeamsOptions from '~/utils/formatActTeamsOptions';
import SimpleSelect from '~/ui/pages/MedicationLogistics/components/SimpleSelect';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import styles from './ProgramAssistant.module.scss';

type IParams = {
  id: string;
};

const ProgramAssistant = (): ReactElement => {
  const { id: clinicId } = useParams<IParams>();
  const [programAssistant, setProgramAssistant] = useState(null);
  const [teamId, setTeamId] = useState(null);

  const programAssistantPagination = useStoreState(state => state.programAssistant.pagination);

  const {
    onDeactivateProgramAssistant,
    onActivateProgramAssistant,
    onUnlockProgramAssistant,
    onReInviteProgramAssistant,
    onUnAssignProgramAssistant,
    onGetProgramAssistants,
  } = useStoreActions(actions => actions.programAssistant);

  const actTeamOptions = formatActTeamsOptions(programAssistant?.teams || []);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const getConfirmProps = useCallback(
    (status: string) => {
      if (status === 'Active')
        return {
          confirmText: 'Deactivate',
          description: 'Are you sure you want to deactivate this user?',
          method: () =>
            onDeactivateProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
        };

      if (status === 'Deactivated')
        return {
          confirmText: 'Activate',
          description: 'Are you sure you want to activate this user?',
          method: () =>
            onActivateProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
        };

      if (status === 'Locked')
        return {
          confirmText: 'Unlock',
          description: 'Are you sure you want to unlock this user?',
          method: () =>
            onUnlockProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
        };

      if (status === 'Assigned')
        return {
          confirmText: 'Unassign',
          description: `Are you sure you want to unassign ${programAssistant.firstName} ${programAssistant.lastName}?`,
          confirmDisabled: !teamId,
          customContent: (
            <div className={styles.selectContainer}>
              <span>Please select Team of this member to be unassigned.</span>
              <SimpleSelect
                label="Select Team"
                value={teamId}
                setValue={setTeamId}
                options={actTeamOptions}
              />
            </div>
          ),
          method: async () => {
            try {
              await onUnAssignProgramAssistant({
                clinicId,
                programAssistantId: programAssistant?.id,
                teamId,
              });
              onGetProgramAssistants({
                clinicId,
                params: {
                  pageNumber: programAssistantPagination.pageNumber,
                  pageSize: programAssistantPagination.pageSize,
                },
              });
              showNotify({ message: `User successfully unassigned` });
            } catch (e) {
              showError(extractErrorMessage(e));
            }
          },
        };

      return {
        confirmText: 'Resend',
        description: 'Are you sure you want to resend invitation?',
        method: () =>
          onReInviteProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
      };
    },
    [
      programAssistant?.firstName,
      programAssistant?.lastName,
      programAssistant?.id,
      teamId,
      actTeamOptions,
      onDeactivateProgramAssistant,
      clinicId,
      onActivateProgramAssistant,
      onUnlockProgramAssistant,
      onUnAssignProgramAssistant,
      onGetProgramAssistants,
      programAssistantPagination.pageNumber,
      programAssistantPagination.pageSize,
      showNotify,
      showError,
      onReInviteProgramAssistant,
    ],
  );

  const { confirmText, description, customContent, confirmDisabled, method } = getConfirmProps(
    programAssistant?.status,
  );

  const onConfirm = async () => {
    try {
      await method();
      setProgramAssistant(false);
    } catch (e) {
      setProgramAssistant(extractErrorMessage(e));
    } finally {
      setProgramAssistant(false);
    }
  };

  return (
    <>
      <ProgramAssistantTable clinicId={clinicId} setProgramAssistant={setProgramAssistant} />
      {programAssistant && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          disabled={confirmDisabled}
          customContent={customContent}
          onClose={() => {
            setProgramAssistant(null);
            setTeamId(null);
          }}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default ProgramAssistant;

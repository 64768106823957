import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkTypeItemUpdate, IDictionariesStore, IDictionariesActions } from '../types';

const onUpdateTypeItem: IThunkTypeItemUpdate = thunk(
  async ({ setTypeList }: Actions<IDictionariesActions>, payload, { getState }) => {
    const localState = getState() as IDictionariesStore;

    const { data } = await api.dictionaries.updateTypeItem(payload);

    const updatedList = localState.list.map(item => (item.id !== payload.id ? item : data));

    setTypeList({ items: updatedList });
  },
);

export default onUpdateTypeItem;

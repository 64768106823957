import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateShelter, ISheltersStore, IShelterActions } from '../types';

const onCreateShelter: IThunkCreateShelter = thunk(
  async ({ setShelters }: Actions<IShelterActions>, payload, { getState }) => {
    const localState = getState() as ISheltersStore;

    const { clinicId, data } = payload;

    const shelter = await api.shelters.createClinicShelter(clinicId, data).then(r => r.data);

    setShelters({ items: [shelter, ...localState.list] });
  },
);

export default onCreateShelter;

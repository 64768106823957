import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

interface Item {
  to: any;
  title: string;
}

interface IProps {
  itemsForBreadcrumbs: Item[];
}

const Breadcrumbs = ({ itemsForBreadcrumbs }: IProps): ReactElement => (
  <div className={styles.breadcrumbsContainer}>
    <MUIBreadcrumbs separator="›">
      {itemsForBreadcrumbs.map((item, index) => {
        const last = itemsForBreadcrumbs.length - 1 === index;
        return (
          <NavLink
            key={item.title}
            to={item.to}
            className={({ isActive }) => {
              const linkClasses = [styles.navLink];
              if (isActive && last) linkClasses.push(styles.activeNavLink);

              return linkClasses.join(' ');
            }}
          >
            {item.title}
          </NavLink>
        );
      })}
    </MUIBreadcrumbs>
  </div>
);

export default Breadcrumbs;

import { action } from 'easy-peasy';

import { IActionSetChannels } from '../types';

const setChannels: IActionSetChannels = action((state, payload) => {
  state.channels = payload.sort((a, b) => {
    const at = a.lastMessage ? a.lastMessage.createdAt : a.createdAt;
    const bt = b.lastMessage ? b.lastMessage.createdAt : b.createdAt;
    return bt - at;
  });
});

export default setChannels;

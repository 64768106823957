import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IMedicationLogisticsActions, IThunkGetMedicationDelivery } from '../types';

const onGetMedicationDelivery: IThunkGetMedicationDelivery = thunk(
  async (
    { setMedicationDelivery }: Actions<IMedicationLogisticsActions>,
    { clinicId, teamId, medicationDeliveryId },
  ) => {
    const { data } = await api.medicationLogistics.getMedicationDelivery(
      { clinicId, teamId },
      medicationDeliveryId,
    );
    setMedicationDelivery(data);
  },
);

export default onGetMedicationDelivery;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IClientDetailsStore, IThunkSetClientLocationGroup } from '../types';

const onSetClientLocationGroup: IThunkSetClientLocationGroup = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, locationGroup } = payload;

    await api.clientDetails.setClientLocationGroup(requestInfo, Number(locationGroup.id));

    const addresses = localState.addresses.map(item =>
      item.isPrimary
        ? {
            ...item,
            locationGroup,
          }
        : item,
    );

    setClientDetails({ addresses });
  },
);

export default onSetClientLocationGroup;

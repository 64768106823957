import { action } from 'easy-peasy';
import { setLocationGroupFilter } from '~/services/localStorage/filters';

import { IActionSetLocationGroupFilters } from '../types';

const setLocationGroupFilters: IActionSetLocationGroupFilters = action((state, payload) => {
  // save filters to storage
  setLocationGroupFilter(payload);

  state.locationGroup.filters = payload;
});

export default setLocationGroupFilters;

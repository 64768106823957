import { red, orange, green } from './colors';

export const suicideRiskColor = {
  Low: green,
  Medium: orange,
  High: red,
};

export const suicideRiskLabel: { [key: string]: string } = {
  High: 'High',
  Medium: 'Moderate',
  Low: 'Low',
};

export default [
  {
    value: 'High',
    label: 'Higher risk of suicide',
    color: red,
    backgroundColor: 'rgba(216, 55, 19, 0.2)',
  },
  {
    value: 'Medium',
    label: 'Moderate risk of suicide',
    color: orange,
    backgroundColor: 'rgba(255, 180, 0, 0.2)',
  },
  {
    value: 'Low',
    label: 'Lower risk of suicide',
    color: green,
    backgroundColor: 'rgba(76, 175, 81, 0.2)',
  },
];

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IVisitsRequestInfo } from '~/services/api/visits/types';
import { IThunkRestoreVisit, IVisitsActions } from '../types';

const onRestoreVisit: IThunkRestoreVisit = thunk(
  async (_: Actions<IVisitsActions>, payload: IVisitsRequestInfo) => {
    const { clinicId, visitId, teamId } = payload;

    await api.visits.restoreVisit({ clinicId, visitId, teamId });
  },
);

export default onRestoreVisit;

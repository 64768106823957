/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Input from '~/ui/components/inputs/Input';
import SelectEmailDomain from '~/ui/components/inputs/SelectEmailDomain';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import InputMask from '~/ui/components/inputs/InputMask';
import AvatarUpload from '~/ui/components/inputs/AvatarUpload';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import Loader from '~/ui/components/common/Loader';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';

import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IProgramAssistantForm } from '~/services/api/programAssistant/types';
import { IOption } from '~/types';

import validate from './validate';
import styles from './Form.module.scss';

interface IProps {
  defaultValues?: IProgramAssistantForm;
  isEdit?: boolean;
  submitText?: string;
  isProcessing: boolean;
  leavePageDescription: string;
  cancelLinkTo: any;
  emailDomainOptions?: IOption[];
  isPending?: boolean;
  onSubmit: (values: IProgramAssistantForm) => void;
}

interface IOptions {
  states: IOption[];
  credentials: IOption[];
  educations: IOption[];
  employmentStatuses: IOption[];
  subRolesIds: IOption[];
  teams: IOption[];
}

type IParams = {
  id: string;
};

const Form = ({
  defaultValues,
  isEdit = false,
  submitText = 'Send Invite',
  isProcessing,
  leavePageDescription,
  cancelLinkTo,
  emailDomainOptions,
  isPending,
  onSubmit,
}: IProps): ReactElement => {
  const { id: clinicId } = useParams<IParams>();
  const [options, setOptions] = useState<IOptions>(null);
  const [loading, setLoading] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ mode: 'onBlur', defaultValues, resolver: validate, shouldUnregister: true });

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const [states, credentials, educations, employmentStatuses, subRolesIds, teams] =
        await Promise.all([
          api.dictionaries
            .getAvailableTypeList(IDictionaryTypes.State)
            .then(r => formatDictionaryOptions(r.data)),
          api.dictionaries
            .getAvailableTypeList(IDictionaryTypes.Credentials)
            .then(r => formatDictionaryOptions(r.data)),
          api.dictionaries
            .getAvailableTypeList(IDictionaryTypes.Education)
            .then(r => formatDictionaryOptions(r.data)),
          api.dictionaries
            .getAvailableTypeList(IDictionaryTypes.EmploymentStatus)
            .then(r => formatDictionaryOptions(r.data)),
          api.dictionaries
            .getAvailableTypeList(IDictionaryTypes.SubRole)
            .then(r => formatDictionaryOptions(r.data)),
          api.actTeam.getActTeamsAvailable(clinicId).then(r => formatDictionaryOptions(r.data)),
        ]);

      setOptions({ states, credentials, educations, employmentStatuses, subRolesIds, teams });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [clinicId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (emailDomainOptions?.length) {
      setValue('emailDomain', emailDomainOptions[0].value as string);
    }
  }, [emailDomainOptions, setValue]);

  if (loading || !options) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <Paper>
          <Box sx={{ p: 2 }}>
            <p className={styles.formTitle}>Personal Details</p>

            <Grid container spacing={2}>
              <Grid item container sm={6} spacing={2}>
                <Grid item sm={12}>
                  <Input
                    tabIndex={1}
                    register={register}
                    errors={errors}
                    name="firstName"
                    label="First Name"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Input
                    tabIndex={2}
                    register={register}
                    errors={errors}
                    name="lastName"
                    label="Last Name"
                  />
                </Grid>
                <Grid item sm={12}>
                  <InputMask
                    tabIndex={3}
                    control={control}
                    errors={errors}
                    name="phone"
                    label="Phone"
                  />
                </Grid>

                <Grid item sm={12}>
                  <div className={styles.combinedInputWrapper}>
                    <Input
                      combinedInput={!isEdit || isPending}
                      disabled={isEdit && !isPending}
                      register={register}
                      errors={errors}
                      name="email"
                      label="Email"
                    />
                    {isPending && (
                      <SelectEmailDomain
                        isDisabled={isEdit}
                        name="emailDomain"
                        control={control}
                        errors={errors}
                        options={emailDomainOptions}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item sm={12}>
                  <AvatarUpload control={control} name="photo" errors={errors} isPhoto />
                </Grid>
              </Grid>

              <Grid item container sm={6} spacing={2}>
                <Grid item sm={12}>
                  <Select
                    tabIndex={4}
                    isMulti
                    control={control}
                    errors={errors}
                    name="teams"
                    label="Assigned Teams"
                    options={options.teams}
                    openMenuOnFocus
                    hideSelectedOptions
                  />
                </Grid>
                <Grid item sm={12}>
                  <Select
                    tabIndex={4}
                    isMulti
                    control={control}
                    errors={errors}
                    name="subRoleIds"
                    label="Sub-Roles"
                    options={options.subRolesIds}
                    openMenuOnFocus
                    hideSelectedOptions={false}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Input
                    tabIndex={5}
                    register={register}
                    errors={errors}
                    name="address.city"
                    label="City"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Input
                    tabIndex={6}
                    register={register}
                    errors={errors}
                    name="address.line1"
                    label="Address 1"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Input
                    tabIndex={7}
                    register={register}
                    errors={errors}
                    name="address.line2"
                    label="Address 2"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={8}
                    options={options.states}
                    name="address.stateId"
                    control={control}
                    errors={errors}
                    label="State"
                    openMenuOnFocus
                    hideSelectedOptions={false}
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputMask
                    tabIndex={9}
                    alwaysShowMask={false}
                    mask="99999"
                    name="address.zipCode"
                    control={control}
                    errors={errors}
                    label="Zip Code"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <div className={styles.paperContainer}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <p className={styles.formTitle}>Work Details</p>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Select
                    tabIndex={10}
                    options={options.credentials}
                    control={control}
                    errors={errors}
                    name="workDetails.credentialsId"
                    label="Credentials"
                    openMenuOnFocus
                    hideSelectedOptions={false}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={13}
                    options={options.employmentStatuses}
                    control={control}
                    errors={errors}
                    name="workDetails.employmentStatusId"
                    label="Employment Status"
                    openMenuOnFocus
                    hideSelectedOptions={false}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={11}
                    options={options.educations}
                    control={control}
                    errors={errors}
                    name="workDetails.educationId"
                    label="Education"
                    openMenuOnFocus
                    hideSelectedOptions={false}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={14}
                    type="number"
                    register={register}
                    errors={errors}
                    name="workDetails.experience"
                    label="Years of Experience"
                  />
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    tabIndex={12}
                    control={control}
                    openTo="date"
                    errors={errors}
                    label="Hire Date"
                    name="workDetails.hireDate"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttonWrapper}>
              <Link to={cancelLinkTo} className={styles.link}>
                <Button variant="outlined" color="primary" disabled={isProcessing}>
                  Cancel
                </Button>
              </Link>
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isProcessing}
              tabIndex={15}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </div>
  );
};

export default Form;

import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { IAssignableActTeamMember } from '~/services/api/actTeamMember/types';
import Loader from '~/ui/components/common/Loader';
import useMembers from '../../hooks/useMembers';

import { ReactComponent as CheckmarkIcon } from '~/ui/assets/images/checkmark.svg';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './TeamMembersInfinityList.module.scss';

interface IProps {
  searchQuery: string;
  teamId: number;
  assignTeamId: number;
  clinicId: number;
  selectedUserId: number | null;
  role: string;
  noOptionsText?: string;
  setSelectedUserId: Dispatch<SetStateAction<number | null>>;
}

const DEFAULT_SCROLLABLE_HEIGHT = 200;

const TeamMembersInfinityList = ({
  clinicId,
  teamId,
  assignTeamId,
  searchQuery,
  selectedUserId,
  role,
  noOptionsText = 'No available options',
  setSelectedUserId,
}: IProps): ReactElement => {
  const scrollableRef = useRef(null);

  const { data, loading, hasMore, loadMore } = useMembers({
    searchQuery,
    clinicId,
    teamId,
    assignTeamId,
    role,
  });

  // load more items on scroll to bottom
  const next = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop } = event.currentTarget;

    if (scrollTop >= scrollHeight - DEFAULT_SCROLLABLE_HEIGHT - 50 && hasMore && !loading) {
      loadMore();
    }
  };

  const selectUser = useCallback(
    (userId: number) => {
      setSelectedUserId(userId);
    },
    [setSelectedUserId],
  );

  const renderItem = (item: IAssignableActTeamMember) => (
    <Box
      className={classNames(styles.itemContainer, {
        [styles.selected]: item.id === selectedUserId,
      })}
      key={`scrollable-list-item-${item.id}`}
      onClick={() => selectUser(item.id)}
    >
      <div className={styles.itemLabel}>{item.name}</div>
      <div className={styles.itemDescription}>
        {item.roles.map(option => option.name).join(', ')}
      </div>
      {item.id === selectedUserId && <CheckmarkIcon color={variables.colorRed} />}
    </Box>
  );

  // set default user to prevent rerender list on select user
  useEffect(() => {
    setTimeout(() => {
      selectUser(-1);
    }, 0);
  }, [selectUser, teamId]);

  return (
    <div
      className={classNames(styles.scrollableContainer, { [styles.emptyList]: !data.length })}
      onScroll={next}
      ref={scrollableRef}
    >
      {loading && !data.length ? <Loader /> : null}
      {!!data.length && data.map(renderItem)}
      {!data.length && !loading && <>{noOptionsText}</>}
    </div>
  );
};

export default TeamMembersInfinityList;

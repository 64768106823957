import Box from '@material-ui/core/Box';
import { ReactElement } from 'react';
import { components, GroupTypeBase, MultiValueProps } from 'react-select';

import extractChipText from '~/utils/text/extractChipText';

import { IOption } from '../../../Select/types';

import styles from './MultiValueNoChipText.module.scss';

const MAX_SELECTED_TO_SHOW = 2;

const MultiValueWithChip = ({
  getValue,
  ...props
}: MultiValueProps<IOption, GroupTypeBase<IOption>>): ReactElement => {
  const isAllSelected = props.options?.length === getValue()?.length;
  const isSingleOption = props.options?.length === 1;

  const selectedIndex = getValue()?.findIndex(v => v.value === props.data.value) || 0;

  const overflow = getValue()
    .slice(MAX_SELECTED_TO_SHOW)
    .map((x: IOption) => x.label);

  // show All chip instead of first item, if all selected
  if (isAllSelected && selectedIndex === 1 && !isSingleOption) {
    return (
      <components.MultiValue
        {...props}
        getValue={getValue}
        data={{ ...props.data, label: 'All' }}
        removeProps={{ ...props.removeProps, onClick: () => props.clearValue() }}
      >
        <components.MultiValueLabel {...props}>All</components.MultiValueLabel>
      </components.MultiValue>
    );
  }
  // hide all other chips but only first item, if all selected
  if (isAllSelected && !isSingleOption) {
    return null;
  }

  if (selectedIndex < MAX_SELECTED_TO_SHOW) {
    const { text } = extractChipText(props.data.label);
    return (
      <components.MultiValue {...props} getValue={getValue}>
        <components.MultiValueLabel {...props}>{text}</components.MultiValueLabel>
      </components.MultiValue>
    );
  }

  return selectedIndex === MAX_SELECTED_TO_SHOW ? (
    <Box className={styles.container}>
      <span>...</span>
      <Box className={styles.hiddenItemsContainer}>{overflow.length}</Box>
    </Box>
  ) : null;
};

export default MultiValueWithChip;

import { getSosRequestsFilter } from '~/services/localStorage/filters';
import { ISosRequestsStore } from './types';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';

const initStore: ISosRequestsStore = {
  current: null,
  list: [],
  pagination: {
    total: 0,
    pageNumber: 1,
    pageSize: 25,
  },
  filters: getSosRequestsFilter() || {
    sorting: 'Date',
    sortDirection: SortDirection.Desc,
  },
};

export default initStore;

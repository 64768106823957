import { action } from 'easy-peasy';
import { setClientLengthOfStayFilter } from '~/services/localStorage/filters';

import { IActionSetClientLengthOfStayFilters } from '../types';

const setClientLengthOfStayFilters: IActionSetClientLengthOfStayFilters = action(
  (state, payload) => {
    // save filters to storage
    setClientLengthOfStayFilter(payload);

    state.clientLengthOfStay.filters = payload;
  },
);

export default setClientLengthOfStayFilters;

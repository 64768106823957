import { MouseEventHandler, ReactElement } from 'react';
import { components, GroupTypeBase, OptionProps } from 'react-select';

import { IOption } from '~/types';
import CustomCheckbox from '~/ui/components/inputs/CustomCheckbox';

import styles from '~/ui/components/inputs/Select/Select.module.scss';

const Option = ({
  label,
  isSelected,
  selectGroup,
  ...rest
}: OptionProps<IOption, boolean, GroupTypeBase<IOption>> & {
  selectGroup: (id: number) => void;
}): ReactElement => {
  const onClick: MouseEventHandler<HTMLDivElement> = e => {
    rest.innerProps.onClick(e);
    selectGroup(rest.data.value);
  };

  return (
    <components.Option
      {...rest}
      isSelected={isSelected}
      label={label}
      className={styles.menuOption}
      innerProps={{ ...rest.innerProps, onClick }}
    >
      <CustomCheckbox size="small" checked={isSelected} />
      <span>{label}</span>
    </components.Option>
  );
};

export default Option;

import { PaperWorkStatus } from '~/services/api/clientDetails/types';

export const paperworkStatuses = {
  [PaperWorkStatus.NotReceived]: 'Paperwork Received: No',
  [PaperWorkStatus.NotSubmitted]: 'Paperwork Submitted: No',
  [PaperWorkStatus.PromptAcknowledged]: 'Paperwork Notice: Acknowledged',
  [PaperWorkStatus.Submitted]: 'Paperwork Submitted: Yes',
  [PaperWorkStatus.Expired]: 'Medicaid Сertification: Expired',
};

export const paperworkStatusOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: PaperWorkStatus.PromptAcknowledged,
    label: 'Paperwork Notice Acknowledged',
  },
  {
    value: PaperWorkStatus.NotReceived,
    label: 'Paperwork Not Received',
  },
  {
    value: PaperWorkStatus.NotSubmitted,
    label: 'Paperwork Not Submitted',
  },
  {
    value: PaperWorkStatus.Submitted,
    label: 'Paperwork Submitted',
  },
  {
    value: PaperWorkStatus.Expired,
    label: 'Certification Expired',
  },
];

export const chartPaperworkStatuses = {
  [PaperWorkStatus.NotReceived]: 'Paperwork Not Received',
  [PaperWorkStatus.PromptAcknowledged]: 'Paperwork Notice Acknowledged',
  [PaperWorkStatus.NotSubmitted]: 'Paperwork Not Submitted',
  [PaperWorkStatus.Submitted]: 'Paperwork Submitted',
  [PaperWorkStatus.Expired]: 'Certification Expired',
};

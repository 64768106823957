import { IOption } from '~/types';
import { IVisitTreatmentPlanObjective } from '~/services/api/clientDetails/types';

const formatObjectivesOptions = (objectives: IVisitTreatmentPlanObjective[]): IOption[] =>
  objectives.map(item => ({
    label: item.text,
    value: String(item.id),
  }));

export default formatObjectivesOptions;

import onArchiveClient from './onArchiveClient';
import onGetClient from './onGetClient';
import onGetClinicClients from './onGetClinicClients';
import onGetTeamClients from './onGetTeamClients';
import onRestoreClient from './onRestoreClient';
import onGetAvailableClients from './onGetAvailableClients';
import onGetAvailableClientsByTeams from './onGetAvailableClientsByTeams';
import onGetClientOptions from './onGetClientOptions';

export default {
  onGetClinicClients,
  onGetAvailableClients,
  onGetTeamClients,
  onArchiveClient,
  onRestoreClient,
  onGetClient,
  onGetAvailableClientsByTeams,
  onGetClientOptions,
};

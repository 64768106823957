import Box from '@material-ui/core/Box';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import Delayed from '~/ui/components/common/Delayed';
import Loader from '~/ui/components/common/Loader';
import EmptyState from '~/ui/pages/Notifications/components/EmptyState';
import ResourceItem from './ResourceItem';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import { extractErrorMessage } from '~/utils/error/error';

import { IResource, IResourceParams } from '~/services/api/resource/types';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import ResourceSorting from '../../models/ResourceSorting';

import { ReactComponent as ArrowUpIcon } from '~/ui/assets/images/arrowUpWhite.svg';

import styles from './Table.module.scss';

interface IProps {
  resources: IResource[];
  onGetResources: ({
    fullList,
    params,
  }: {
    fullList: boolean;
    params: IResourceParams;
  }) => Promise<void>;
  onUpdateResource: (item: IResource) => Promise<void>;
  onArchiveResource: (id: number) => Promise<void>;
  setResource: (resource: IResource) => void;
  downloadFile: (resource: IResource) => void;
  openFile: (resource: IResource) => void;
}

const Table = ({
  resources,
  onGetResources,
  setResource,
  onUpdateResource,
  onArchiveResource,
  downloadFile,
  openFile,
}: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);

  const { filters, pagination } = useStoreState(action => action.resource);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const [pageNumber, setPageNumber] = useState(pagination.pageNumber);
  const [pageSize, setPageSize] = useState(pagination.pageSize);
  const [sorting, setSorting] = useState(filters.sorting || ResourceSorting.ByName);
  const [sortDirection, setSortDirection] = useState(filters.direction || SortDirection.Asc);

  const { isSuperAdmin } = useRole();

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      await onGetResources({
        fullList: !!isSuperAdmin,
        params: {
          pageNumber,
          pageSize,
          name: filters.name,
          type: filters.type,
          sorting,
          direction: sortDirection,
        },
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    sorting,
    sortDirection,
    isSuperAdmin,
    pageNumber,
    pageSize,
    filters.name,
    filters.type,
    showError,
    onGetResources,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handlePagination = async (pSize: number, pNumber: number) => {
    setPageSize(pSize);
    setPageNumber(pNumber);
  };

  const toggleSortDirection = () => {
    setSortDirection(prev => {
      if (prev === SortDirection.Asc) {
        return SortDirection.Desc;
      }
      return SortDirection.Asc;
    });
  };

  // change sort direction and sort column
  const handleSort = (value: ResourceSorting) => {
    if (value === sorting) {
      toggleSortDirection();
    } else {
      setSorting(value as ResourceSorting);
    }
  };

  const renderSortableCell = ({
    label,
    sortOption,
    colSpan,
  }: {
    label: string;
    sortOption: ResourceSorting;
    colSpan?: number;
  }) => (
    <TableCell
      colSpan={colSpan}
      className={styles.buttonCell}
      onClick={() => {
        setPageNumber(1);
        handleSort(sortOption);
      }}
    >
      <Box className={styles.cellContent}>
        {label}
        <ArrowUpIcon
          width={15}
          height={15}
          className={classNames(styles.arrowUpIcon, {
            [styles.rotate]: sortDirection === SortDirection.Asc,
            [styles.arrowHidden]: sorting !== sortOption,
          })}
        />
      </Box>
    </TableCell>
  );

  return (
    <TableContainer>
      {loading ? <Loader className={styles.loader} /> : null}
      <MUITable>
        <TableHead>
          <TableRow>
            {renderSortableCell({
              label: 'File Name',
              sortOption: ResourceSorting.ByName,
              colSpan: 4,
            })}
            {renderSortableCell({
              label: 'Last Modified',
              sortOption: ResourceSorting.ByEditDate,
            })}
            {isSuperAdmin && <TableCell>Display</TableCell>}
            <TableCell
              colSpan={3}
              className={classNames(styles.actionsCell, {
                [styles.lastHeaderCellAdmin]: isSuperAdmin,
                [styles.lastHeaderCell]: !isSuperAdmin,
              })}
              align="right"
            >
              <span>Actions</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.length ? (
            resources.map(resource => (
              <ResourceItem
                key={`resource-${resource.id}`}
                item={resource}
                onUpdateResource={onUpdateResource}
                onArchiveResource={onArchiveResource}
                setResource={setResource}
                openFile={openFile}
                downloadFile={downloadFile}
              />
            ))
          ) : (
            <Delayed waitBeforeShow={2000}>
              <EmptyState />
            </Delayed>
          )}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pgNumber) => handlePagination(pagination.pageSize, pgNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;

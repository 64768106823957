import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';
import { IUserModel } from './types';

const user: IUserModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default user;

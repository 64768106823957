type IError = any;

export const extractErrorMessage = (err: IError, defaultError?: string): string => {
  const error = err?.data?.error;
  const message = err?.data?.message;

  if (error) return error;
  if (message) return message;

  return defaultError || 'Unknown error occurred';
};

export const extractErrorCode = (err: IError): string | undefined => err?.data?.code;

export interface IDefaultError {
  data: {
    code?: string;
    message?: string;
    type?: string;
  };
}

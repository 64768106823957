import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetDashboardReport } from '../types';

const onGetDashboardReport: IThunkGetDashboardReport = thunk(
  async ({ setDashboardReport }: Actions<IReportsActions>, params, { getState }) => {
    const localState = getState() as IReportsStore;

    const data = await api.reports.getDashboardReport(params).then(r => r.data);

    setDashboardReport({ ...data, filters: localState.dashboard.filters });
  },
);

export default onGetDashboardReport;

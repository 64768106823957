import { action } from 'easy-peasy';
import { FileMessage, UserMessage } from 'sendbird';

import { IActionSetMessage } from '../types';

const setMessage: IActionSetMessage = action((state, message) => {
  const { channelUrl } = message;

  const extendedMessage = message;
  if (state.messages[channelUrl].items.length > 0) {
    Object.assign(extendedMessage, {
      hasSameSenderAbove:
        (message as UserMessage | FileMessage).sender &&
        (state.messages[channelUrl].items[0] as UserMessage | FileMessage).sender &&
        (message as UserMessage | FileMessage).sender.userId ===
          (state.messages[channelUrl].items[0] as UserMessage | FileMessage).sender.userId,
    });
  }

  state.messages = {
    ...state.messages,
    [channelUrl]: {
      ...state.messages[channelUrl],
      messagesMap: {
        ...state.messages[channelUrl].messagesMap,
        [message.messageId]: true,
      },
      items: [...state.messages[channelUrl].items, extendedMessage],
    },
  };
});

export default setMessage;

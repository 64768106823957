import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetVisitsByTeamReport } from '../types';

const onGetVisitsByTeamReport: IThunkGetVisitsByTeamReport = thunk(
  async ({ setVisitsByTeamReport }: Actions<IReportsActions>, { requestInfo, params }) => {
    const visitsByTeamReport = await api.reports
      .getVisitsByTeamReport(requestInfo, params)
      .then(r => r.data);

    setVisitsByTeamReport({
      visitsByTeam: {
        items: visitsByTeamReport.items.items,
        total: visitsByTeamReport.items.total,
        totals: visitsByTeamReport.totals,
        pagination: params,
      },
    });
  },
);

export default onGetVisitsByTeamReport;

import { ReactElement } from 'react';

import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import { ADD_VISIT, EDIT_VISIT, VIEW_CLIENT, VISITS } from '~/ui/constants/paths';

interface IProps {
  clientId: string;
  teamId: string;
  clientName: string;
  visitId: string;
  isEdit: boolean;
}
const VisitBreadcrumbs = ({
  clientId,
  teamId,
  clientName,
  isEdit,
  visitId,
}: IProps): ReactElement => {
  const breadcrumbItems = [
    {
      title: 'Visits',
      to: {
        pathname: VISITS,
        search: `?teamId=${teamId}&clientId=${clientId}`,
      },
    },
    {
      title: clientName,
      to: {
        pathname: VIEW_CLIENT.replace(':clientId', clientId).replace(':actTeamId', teamId),
      },
    },
    {
      title: `${isEdit ? 'Edit' : 'Add'} Visit`,
      to: {
        pathname: isEdit ? EDIT_VISIT.replace(':visitId', visitId) : ADD_VISIT,
      },
    },
  ];
  return <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />;
};
export default VisitBreadcrumbs;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateSupportContact, IClientDetailsStore, IClientDetailsActions } from '../types';

const onCreateSupportContact: IThunkCreateSupportContact = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;
    const { requestInfo, requestPayload } = payload;

    const clientSupportContact = await api.clientDetails
      .createSupportContact(requestInfo, requestPayload)
      .then(r => r.data);

    setClientDetails({
      supportContacts: [clientSupportContact, ...localState.supportContacts],
    });
  },
);

export default onCreateSupportContact;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetMedicationsReportClient } from '../types';

const onGetMedicationsReportClient: IThunkGetMedicationsReportClient = thunk(
  async ({ setMedicationsReport }: Actions<IReportsActions>, params, { getState }) => {
    const clientMedications = await api.reports
      .getMedicationsReportClient(params)
      .then(r => r.data);

    const localState = getState() as IReportsStore;

    const teams = localState.medications.report.map(reportItem => {
      if (reportItem.team.id === params.teamIds?.[0]) {
        return {
          ...reportItem,
          clients: {
            items: reportItem.clients.items.map(item =>
              item.id !== params.clientId
                ? item
                : {
                    ...item,
                    medications: {
                      items: [
                        ...(params.pageNumber === 1
                          ? clientMedications.items
                          : [...(item.medications?.items || []), ...clientMedications.items]),
                      ],
                      total: clientMedications.total,
                    },
                  },
            ),
            total: reportItem.clients.total,
          },
        };
      }
      return reportItem;
    });

    setMedicationsReport({
      ...localState.medications,
      report: teams,
    });
  },
);

export default onGetMedicationsReportClient;

import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { ReactElement } from 'react';

import styles from './UnreadCounter.module.scss';

interface IProps {
  count: number;
  noBorder?: boolean;
  inactive?: boolean;
  className?: string;
}

const UnreadCounter = ({ count, noBorder, inactive, className }: IProps): ReactElement => (
  <Box
    className={classNames(
      styles.unreadMessages,
      {
        [styles.inactive]: inactive,
        [styles.noBorder]: noBorder,
      },
      className,
    )}
  >
    <Box className={classNames(styles.count, { [styles.inactiveCount]: inactive })}>
      {count > 99 ? '99+' : count}
    </Box>
  </Box>
);

export default UnreadCounter;

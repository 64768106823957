import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IClinicModel } from './types';

const clinic: IClinicModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default clinic;

import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, object } from 'yup';

import { AllocationMode } from './constants/allocationMode';

export interface IFormValues {
  includeWeekends: boolean;
  dateRange: [Date, Date];
  allocationMode: AllocationMode.BY_CLIENT;
}

const validationSchema = object().shape({
  includeWeekends: boolean(),
});

export default yupResolver(validationSchema);

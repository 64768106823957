import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { ISystemAlertModel } from './types';

const systemAlert: ISystemAlertModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default systemAlert;

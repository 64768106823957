import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetDemographicsReport } from '../types';

const onGetDemographicsReport: IThunkGetDemographicsReport = thunk(
  async (
    { setDemographicsReport }: Actions<IReportsActions>,
    { requestInfo, params },
    { getState },
  ) => {
    const localState = getState() as IReportsStore;

    const report = await api.reports.getDemographicsReport(requestInfo, params).then(r => r.data);

    setDemographicsReport({ ...report, filters: localState.demographics.filters });
  },
);

export default onGetDemographicsReport;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientActions, IThunkGetAvailableClientsByTeams } from '../types';

const onGetAvailableClientsByTeams: IThunkGetAvailableClientsByTeams = thunk(
  async ({ setAvailableClients }: Actions<IClientActions>, { clinicId, teamIds }) => {
    if (teamIds?.length) {
      const { data } = await api.client.getAvailableClientsByTeams(String(clinicId), teamIds);
      setAvailableClients(data);
    } else {
      setAvailableClients([]);
    }
  },
);

export default onGetAvailableClientsByTeams;

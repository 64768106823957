import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ILocalAdminActions, IThunkUnlockLocalAdmin } from '../types';

const onUnlockLocalAdmin: IThunkUnlockLocalAdmin = thunk(
  async ({ activateLocalAdmin }: Actions<ILocalAdminActions>, payload) => {
    const { clinicId, localAdminId } = payload;

    await api.localAdmin.unlockLocalAdmin(clinicId, localAdminId);

    activateLocalAdmin(localAdminId);
  },
);

export default onUnlockLocalAdmin;

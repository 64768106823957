import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IVisitsRequestInfo } from '~/services/api/visits/types';
import { IThunkGetVisit, IVisitsActions } from '../types';

const onGetVisit: IThunkGetVisit = thunk(
  async ({ setVisit }: Actions<IVisitsActions>, payload: { requestInfo: IVisitsRequestInfo }) => {
    const {
      requestInfo: { clinicId, visitId, teamId },
    } = payload;

    const { data } = await api.visits.getVisit({ clinicId, visitId, teamId });

    setVisit(data);
  },
);

export default onGetVisit;

import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { ReactElement } from 'react';

import Chip from '../Chip';

import { IClientMedicationDelivery } from '~/services/api/medicationLogistics/types';
import injectionIcon from '~/ui/assets/images/injection.svg';
import clientInfoIcon from '~/ui/assets/images/clientInfo.svg';
import archivedInjectionIcon from '~/ui/assets/images/archivedInjection.svg';
import archivedClientInfoIcon from '~/ui/assets/images/archivedClientInfo.svg';
import styles from './MedicationDeliveryInfo.module.scss';
import chipStyles from '~/ui/pages/MedicationLogistics/components/Chip/Chip.module.scss';

interface IProps {
  name: string;
  medication: IClientMedicationDelivery;
  fullInfo: boolean;
  archived?: boolean;
  outsideFacility?: boolean;
}

const MedicationDeliveryInfo = ({
  name,
  medication,
  fullInfo,
  archived,
  outsideFacility,
}: IProps): ReactElement => (
  <Box
    className={classNames(styles.medicationDeliveryInfo, {
      [styles.fullInfo]: fullInfo,
      [styles.archived]: archived,
    })}
  >
    {name && (
      <div>
        <img
          className={styles.injectable}
          src={archived ? archivedClientInfoIcon : clientInfoIcon}
          alt="client"
        />
        <span className={styles.name}>{name}</span>
      </div>
    )}
    {fullInfo && (
      <div>
        {medication && <span>{medication.medication.name}</span>}
        {!!outsideFacility && <Chip text="O.F." className={chipStyles.outlineLabel} />}
        {medication?.medication.injectable && (
          <img
            className={styles.injectable}
            src={archived ? archivedInjectionIcon : injectionIcon}
            alt="injectable"
          />
        )}
      </div>
    )}
  </Box>
);

export default MedicationDeliveryInfo;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetTreatmentPlan } from '../types';

const onGetTreatmentPlan: IThunkGetTreatmentPlan = thunk(
  async ({ setCurrent }: Actions<IClientDetailsActions>, payload) => {
    const current = await api.clientDetails.getClientTreatmentPlan(payload).then(r => r.data);

    setCurrent(current);
  },
);

export default onGetTreatmentPlan;

import { action } from 'easy-peasy';
import { setClientStatusFilter } from '~/services/localStorage/filters';

import { IActionSetClientStatusFilters } from '../types';

const setClientStatusFilters: IActionSetClientStatusFilters = action((state, payload) => {
  // save filters to storage
  setClientStatusFilter(payload);

  state.clientStatus.filters = payload;
});

export default setClientStatusFilters;

import { Sorting } from '~/services/api/types';
import { getClientsFilter } from '~/services/localStorage/filters';
import { IClientStore } from './types';

const initStore: IClientStore = {
  current: null,
  availableClients: [],
  list: [],
  pagination: {
    total: 0,
    pageNumber: 1,
    pageSize: 25,
  },
  filters: getClientsFilter() || {
    name: '',
    sorting: Sorting.ByName,
    status: 0,
    teamIds: [],
  },
  defaultClient: null,
  clientOptions: {
    list: [],
    filters: { pageNumber: 1, name: '' },
    total: 0,
    current: [],
    excluded: [],
    isAllSelected: false,
  },
};

export default initStore;

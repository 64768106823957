import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreAllergy, IClientDetailsStore, IClientDetailsActions } from '../types';

const onRestoreAllergy: IThunkRestoreAllergy = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.restoreAllergy(payload);

    const allergies = localState.allergies.map(item =>
      item.id !== Number(payload.id) ? item : { ...item, isArchived: false },
    );

    setClientDetails({ allergies });
  },
);

export default onRestoreAllergy;

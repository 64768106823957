import { action } from 'easy-peasy';

import { IActionSetRecertificationReport } from '../types';

const setRecertificationReport: IActionSetRecertificationReport = action((state, payload) => {
  const {
    recertification: { items, total },
  } = payload;
  state.recertification.list = items;
  state.recertification.pagination = { ...state.recertification.pagination, total };
});

export default setRecertificationReport;

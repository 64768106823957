import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetClientAllocationVisitsReport } from '../types';

const onGetClientAllocationVisitsReport: IThunkGetClientAllocationVisitsReport = thunk(
  async (
    { setClientAllocationVisitsReport }: Actions<IReportsActions>,
    { requestInfo, params },
    { getState },
  ) => {
    const localState = getState() as IReportsStore;
    const clientAllocationVisits = await api.reports
      .getClientAllocationVisitsReport(requestInfo, params)
      .then(r => r.data);

    setClientAllocationVisitsReport({
      ...clientAllocationVisits,
      filters: localState.clientAllocationVisits.filters,
    });
  },
);

export default onGetClientAllocationVisitsReport;

import { action } from 'easy-peasy';

import { IActionSetLocationGroupOverviewReport } from '../types';

const setLocationGroupOverviewReport: IActionSetLocationGroupOverviewReport = action(
  (state, payload) => {
    const {
      locationGroup: { items, total, pageNumber, pageSize, sorting, sortDirection },
    } = payload;

    state.locationGroup.list = items;
    state.locationGroup.pagination.total = total;
    state.locationGroup.pagination.pageNumber = pageNumber;
    state.locationGroup.pagination.pageSize = pageSize;
    state.locationGroup.filters.sorting = sorting;
    state.locationGroup.filters.sortDirection = sortDirection;
  },
);

export default setLocationGroupOverviewReport;

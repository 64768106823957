import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IReportsActions, IThunkGetClientTreatmentPlansReport } from '../types';

const onGetClientTreatmentPlansReport: IThunkGetClientTreatmentPlansReport = thunk(
  async ({ setClientTreatmentPlansReport }: Actions<IReportsActions>, payload) => {
    const { clinicId, teamId, clientId, treatmentPlanIds } = payload;

    const { treatmentPlans } = await api.reports
      .getClientTreatmentPlansReport({ clinicId, teamId, clientId, treatmentPlanIds })
      .then(r => r.data);

    setClientTreatmentPlansReport({ items: treatmentPlans });
  },
);

export default onGetClientTreatmentPlansReport;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetFavoriteReports } from '../types';

const onGetFavoriteReports: IThunkGetFavoriteReports = thunk(
  async ({ setFavoriteReports }: Actions<IReportsActions>) => {
    const { reportTypes } = await api.reports.getFavoriteReports().then(r => r.data);

    setFavoriteReports(reportTypes);
  },
);

export default onGetFavoriteReports;

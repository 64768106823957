import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IResourceActions, IThunkGetResources } from '../types';

const onGetResources: IThunkGetResources = thunk(
  async ({ setResources, setResourcesFilters }: Actions<IResourceActions>, payload) => {
    const response = await api.resource.getResources(payload).then(r => r.data);

    setResources({
      items: response.items,
      pagination: {
        total: response.total,
        pageNumber: payload.params.pageNumber,
        pageSize: payload.params.pageSize,
      },
    });

    setResourcesFilters({
      name: payload.params.name,
      type: payload.params.type,
      sorting: payload.params.sorting,
      direction: payload.params.direction,
    });
  },
);

export default onGetResources;

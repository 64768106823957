import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { ReactElement } from 'react';

import extractDateFromString from '~/utils/date/extractDateFromString';

import { IClientTreatmentPlanResponse } from '~/services/api/clientDetails/types';

import { ReactComponent as EditIcon } from '~/ui/assets/images/editGrey.svg';
import { ReactComponent as DeleteIcon } from '~/ui/assets/images/delete.svg';
import { ReactComponent as CompleteIcon } from '~/ui/assets/images/complete.svg';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './ListItem.module.scss';

interface IProps {
  treatmentPlan: IClientTreatmentPlanResponse;
  showArchived?: boolean;
  showEdit?: boolean;
  showDuplicate?: boolean;
  noActions?: boolean;
  onArchive?: () => void;
  onEdit?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
  onComplete?: () => void;
  className?: any;
  additionalText?: string;
  moreLabelWidth?: boolean;
  labelGrey?: boolean;
  disableArchived?: boolean;
}

const ListItem = ({
  treatmentPlan,
  showArchived,
  disableArchived,
  showEdit,
  showDuplicate,
  onArchive,
  onEdit,
  onDelete,
  onComplete,
  onDuplicate,
  noActions,
  className,
  additionalText = '',
  moreLabelWidth = false,
  labelGrey,
}: IProps): ReactElement => (
  <div className={classNames(styles.item, className)}>
    <div className={classNames(styles.contentWrapper, { [styles.grey]: labelGrey })}>
      <div
        className={classNames(styles.label, {
          [styles.moreLabelWidth]: moreLabelWidth,
        })}
      >
        {labelGrey && treatmentPlan.type?.name && (
          <>
            <span className={styles.labelPrefix}>Plan Type: {treatmentPlan.type?.name}</span>
            <br />
          </>
        )}
        <span className={styles.labelPrefix}>Case Manager:</span>{' '}
        <span>{treatmentPlan.caseManager?.name}</span>
        {treatmentPlan.effectiveDate && (
          <>
            <br />
            <span className={styles.labelPrefix}>Effective Date:</span>{' '}
            <span>{extractDateFromString(treatmentPlan.effectiveDate)}</span>
          </>
        )}
        {treatmentPlan.nextReviewDate && (
          <>
            <br />
            <span className={styles.labelPrefix}>Next Review Date:</span>{' '}
            <span>{extractDateFromString(treatmentPlan.nextReviewDate)}</span>
          </>
        )}
      </div>
      <div className={styles.column}>
        {treatmentPlan.goals
          .filter(item => !item.isArchived)
          ?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.goalColumn} key={`goal-${item.id}-${index}`}>
              <div className={styles.row}>
                <span className={styles.itemCounter}>Goal {index + 1}.</span>
                <span>{item.text}</span>
              </div>
              {item.objectives.map((objective, i) => {
                if (objective.isArchived) {
                  return null;
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styles.content} key={`objective-${objective.id}-${i}`}>
                    <span className={styles.itemCounter}>Objective {i + 1}.</span>
                    {objective.text}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    </div>
    {!noActions && (
      <div>
        {additionalText && <h5 className={styles.additionalText}>{additionalText}</h5>}
        {showEdit && (
          <IconButton onClick={onEdit}>
            <EditIcon width={26} height={26} fill={variables.grey} />
          </IconButton>
        )}
        {showArchived && (
          <IconButton onClick={onArchive} edge="end" disabled={disableArchived}>
            <div
              className={classNames(styles.archiveIcon, { [styles.disabled]: disableArchived })}
            />
          </IconButton>
        )}
        {onDelete && (
          <IconButton onClick={onDelete} edge="end">
            <DeleteIcon width={22} height={22} color={variables.grey} />
          </IconButton>
        )}
        {onComplete && (
          <IconButton onClick={onComplete} edge="end">
            <CompleteIcon width={22} height={22} fill={variables.grey} />
          </IconButton>
        )}
        {showDuplicate && (
          <div role="presentation" onClick={onDuplicate} className={styles.restoreButton}>
            Duplicate
          </div>
        )}
      </div>
    )}
  </div>
);

export default ListItem;

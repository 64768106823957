import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { ICoordinateActions, IThunkRemoveMember } from '../types';

const onRemoveMember: IThunkRemoveMember = thunk(
  async (
    { removeMember }: Actions<ICoordinateActions>,
    { channelUrl, userIds }: { channelUrl: string; userIds: string[] },
  ) => {
    await api.coordinate.removeMember(channelUrl, userIds);

    removeMember({ channelUrl, userIds });
  },
);

export default onRemoveMember;

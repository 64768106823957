import { action } from 'easy-peasy';

import { IActionSetTreatmentPlansReport } from '../types';

const setTreatmentPlansReport: IActionSetTreatmentPlansReport = action((state, payload) => {
  const { items, total, pageNumber } = payload;

  state.treatmentPlans.report = {
    items: pageNumber === 1 ? items : [...state.treatmentPlans.report.items, ...items],
    total,
    pageNumber,
  };
});

export default setTreatmentPlansReport;

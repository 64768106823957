import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkAssignProgramAssistant } from '../types';

const onAssignProgramAssistant: IThunkAssignProgramAssistant = thunk(async (_, payload) => {
  const { clinicId, programAssistantId, teamId } = payload;

  await api.programAssistant.assignProgramAssistant(Number(clinicId), programAssistantId, teamId);
});

export default onAssignProgramAssistant;

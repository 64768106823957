import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';

import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';
import { extractErrorMessage } from '~/utils/error/error';

import api from '~/services/api';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { formatShelterDefaultValues } from '~/utils/formatDefaultValues';
import Form from './Form';

import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/types';
import styles from './ShelterModal.module.scss';

interface IProps {
  id?: string;
  onClose: () => void;
}

const ShelterModal = ({ id, onClose }: IProps): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState<IOption[]>([] as IOption[]);

  const { id: currentClinicId } = useParams<{ id: string }>();

  const user = useStoreState(state => state.user.current);
  const shelter = useStoreState(state => (id ? state.shelters.current : null));

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onGetClinicShelter } = useStoreActions(actions => actions.shelters);

  const clinicId = currentClinicId || String(user?.clinic?.id);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        await onGetClinicShelter({ clinicId, id });
      }

      const stateDictionary = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.State)
        .then(r => formatDictionaryOptions(r.data));

      setStates(stateDictionary);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [id, onGetClinicShelter, clinicId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Modal className={styles.modal} open onClose={onClose}>
      {loading ? (
        <Loader />
      ) : (
        <Form
          id={shelter?.id}
          defaultValues={formatShelterDefaultValues(shelter)}
          onClose={onClose}
          states={states}
        />
      )}
    </Modal>
  );
};

export default ShelterModal;

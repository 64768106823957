import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IThunkGetTreatmentPlansReport } from '../types';

const onGetTreatmentPlansReport: IThunkGetTreatmentPlansReport = thunk(
  async ({ setTreatmentPlansReport }: Actions<IReportsActions>, { requestInfo, params }) => {
    const treatmentPlansReport = await api.reports
      .getTreatmentPlansReport(requestInfo, params)
      .then(r => r.data);

    setTreatmentPlansReport({
      items: treatmentPlansReport.items,
      total: treatmentPlansReport.total,
      pageNumber: params.pageNumber,
    });
  },
);

export default onGetTreatmentPlansReport;

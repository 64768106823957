import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IClientActions, IThunkGetClientOptions } from '../types';

const onGetClientOptions: IThunkGetClientOptions = thunk(
  async ({ setClientOptions, setClient }: Actions<IClientActions>, payload) => {
    const data = await api.client.getClientsOptions(payload).then(r => r.data);

    const teamId = payload.teamIds?.[0];

    let archived: number[] = [];

    if (teamId && !payload.includeDeleted) {
      const { items } = await api.client
        .getTeamClients(payload.clinicId, String(teamId), {
          // get latest archived clients
          status: 1,
          sorting: 2,
          pageSize: 10,
          pageNumber: 1,
        })
        .then(r => r.data);

      archived = (items || []).map(client => client.id);
    }

    setClientOptions({
      items: data.items,
      total: payload.name.length ? undefined : data.total,
      archived,
    });

    if (!data.items.length) {
      setClient(null);
    }
  },
);

export default onGetClientOptions;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IThunkGetDocuments, IVisitsActions } from '../types';

const onGetDocuments: IThunkGetDocuments = thunk(
  async ({ setClientDocuments }: Actions<IVisitsActions>, { requestInfo, params }) => {
    const documents = await api.visits.getClientDocuments(requestInfo, params).then(r => r.data);

    setClientDocuments({ list: documents.items, total: documents.total });
  },
);

export default onGetDocuments;

import { action } from 'easy-peasy';
import { IActionSetUser } from '../types';

const setUser: IActionSetUser = action((state, payload) => {
  state.current = {
    ...payload,
    teamId: state.current?.teamId,
    roleId: payload.roleId || state.current?.roleId,
  };
});

export default setUser;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import formatAllergy from '~/utils/formatAllergy';
import { IThunkCreateAllergy, IClientDetailsStore, IClientDetailsActions } from '../types';

const onCreateAllergy: IThunkCreateAllergy = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const clientAllergy = await api.clientDetails
      .createAllergy(requestInfo, requestPayload)
      .then(r => formatAllergy(r.data));

    const allergies = [clientAllergy, ...localState.allergies];

    setClientDetails({ allergies });
  },
);

export default onCreateAllergy;

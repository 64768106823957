enum AcceptableResourceMimeTypes {
  Png = 'image/png',
  Jpeg = 'image/jpeg',
  Pdf = 'application/pdf',
  Txt = 'text/plain',
  Xls = 'application/vnd.ms-excel',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Ppt = 'application/vnd.ms-powerpoint',
  Pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Doc = 'application/msword',
}

export default AcceptableResourceMimeTypes;

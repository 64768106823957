import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchivePhone, IClientDetailsStore, IClientDetailsActions } from '../types';

const onArchivePhone: IThunkArchivePhone = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.archivePhone(payload);

    const phones = localState.phones.map(item =>
      item.id !== Number(payload.id) ? item : { ...item, isArchived: true },
    );

    setClientDetails({ phones });
  },
);

export default onArchivePhone;

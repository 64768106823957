import { ReactElement } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';

import { getAccessToken } from '~/services/localStorage/auth';
import { IResource } from '~/services/api/resource/types';

import styles from './FileViewer.module.scss';

interface IFileViewerProps {
  file: IResource;
  onClose: () => void;
}

const FileViewer = ({ file, onClose }: IFileViewerProps): ReactElement => {
  const accessToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  if (!file) {
    return null;
  }

  return (
    <Modal open className={styles.modal} onClose={onClose}>
      <>
        <DocViewer
          documents={[{ uri: file.externalUrl, fileName: file.name }]}
          pluginRenderers={DocViewerRenderers}
          requestHeaders={headers}
          prefetchMethod="GET"
          config={{
            pdfVerticalScrollByDefault: true,
            loadingRenderer: { overrideComponent: () => <Loader className={styles.loader} /> },
          }}
          style={{ minHeight: 350 }}
        />
      </>
    </Modal>
  );
};

export default FileViewer;

import { action } from 'easy-peasy';
import { ISetLocationGroupsAction } from '../types';

const setLocationGroups: ISetLocationGroupsAction = action((state, payload) => {
  const { items, pagination } = payload;

  if (pagination) {
    state.pagination.total = pagination.total;
    state.pagination.pageNumber = pagination.pageNumber;
    state.pagination.pageSize = pagination.pageSize;
  }

  state.list = items;
});

export default setLocationGroups;

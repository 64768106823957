interface IStateAbbreviation {
  [key: string]: string;
}

export const statesAbbreviation: IStateAbbreviation = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HA',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export default [
  {
    value: 1,
    label: 'Alabama',
  },
  {
    value: 2,
    label: 'Alaska',
  },
  {
    value: 3,
    label: 'Arizona',
  },
  {
    value: 4,
    label: 'Arkansas',
  },
  {
    value: 5,
    label: 'California',
  },
  {
    value: 6,
    label: 'Colorado',
  },
  {
    value: 7,
    label: 'Connecticut',
  },
  {
    value: 8,
    label: 'Delaware',
  },
  {
    value: 9,
    label: 'District of Columbia',
  },
  {
    value: 10,
    label: 'Florida',
  },
  {
    value: 11,
    label: 'Georgia',
  },
  {
    value: 12,
    label: 'Hawaii',
  },
  {
    value: 13,
    label: 'Idaho',
  },
  {
    value: 14,
    label: 'Illinois',
  },
  {
    value: 15,
    label: 'Indiana',
  },
  {
    value: 16,
    label: 'Iowa',
  },
  {
    value: 17,
    label: 'Kansas',
  },
  {
    value: 18,
    label: 'Kentucky',
  },
  {
    value: 19,
    label: 'Louisiana',
  },
  {
    value: 20,
    label: 'Maine',
  },
  {
    value: 21,
    label: 'Maryland',
  },
  {
    value: 22,
    label: 'Massachusetts',
  },
  {
    value: 23,
    label: 'Michigan',
  },
  {
    value: 24,
    label: 'Minnesota',
  },
  {
    value: 25,
    label: 'Mississippi',
  },
  {
    value: 26,
    label: 'Missouri',
  },
  {
    value: 27,
    label: 'Montana',
  },
  {
    value: 28,
    label: 'Nebraska',
  },
  {
    value: 29,
    label: 'Nevada',
  },
  {
    value: 30,
    label: 'New Hampshire',
  },
  {
    value: 31,
    label: 'New Jersey',
  },
  {
    value: 32,
    label: 'New Mexico',
  },
  {
    value: 33,
    label: 'New York',
  },
  {
    value: 34,
    label: 'North Carolina',
  },
  {
    value: 35,
    label: 'North Dakota',
  },
  {
    value: 36,
    label: 'Ohio',
  },
  {
    value: 37,
    label: 'Oklahoma',
  },
  {
    value: 38,
    label: 'Oregon',
  },
  {
    value: 39,
    label: 'Pennsylvania',
  },
  {
    value: 40,
    label: 'Rhode Island',
  },
  {
    value: 41,
    label: 'South Carolina',
  },
  {
    value: 42,
    label: 'South Dakota',
  },
  {
    value: 43,
    label: 'Tennessee',
  },
  {
    value: 44,
    label: 'Texas',
  },
  {
    value: 45,
    label: 'Utah',
  },
  {
    value: 46,
    label: 'Vermont',
  },
  {
    value: 47,
    label: 'Virginia',
  },
  {
    value: 48,
    label: 'Washington',
  },
  {
    value: 49,
    label: 'West Virginia',
  },
  {
    value: 50,
    label: 'Wisconsin',
  },
  {
    value: 51,
    label: 'Wyoming',
  },
];

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetMedicationsReportTeam } from '../types';

const onGetMedicationsReportTeam: IThunkGetMedicationsReportTeam = thunk(
  async ({ setMedicationsReport }: Actions<IReportsActions>, params, { getState }) => {
    const localState = getState() as IReportsStore;

    const { teamId } = params || {};

    const report = await api.reports.getMedicationsReportTeam(params).then(r => r.data);

    const teams = localState.medications.report.map(item => {
      if (item.team.id === teamId) {
        return {
          ...item,
          clients: {
            items: [
              ...(params.pageNumber
                ? [...(item.clients.items || []), ...report.items]
                : report.items),
            ],
            total: report.total,
            pageNumber: params.pageNumber,
          },
        };
      }
      return item;
    });

    setMedicationsReport({
      report: teams,
      filters: {
        teamIds: localState.medications.filters.teamIds,
        injectable: {
          from: params['InjectableDateRange.From'],
          to: params['InjectableDateRange.To'],
        },
        endDate: { from: params['EndDateRange.From'], to: params['EndDateRange.To'] },
      },
    });
  },
);

export default onGetMedicationsReportTeam;

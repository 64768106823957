import { format, parseISO } from 'date-fns';

export default (time: Date, date: string): string => {
  const combinedDateTime = `${format(new Date(date), 'yyyy-MM-dd')} ${format(
    new Date(time),
    'HH:mm:ss',
  )}`;

  const parsedDate = parseISO(combinedDateTime);
  return parsedDate.toISOString();
};

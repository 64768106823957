import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientDetailsActions, IThunkGetTreatmentPlans } from '../types';

const onGetTreatmentPlans: IThunkGetTreatmentPlans = thunk(
  async ({ setClientDetails }: Actions<IClientDetailsActions>, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const treatmentPlans = await api.clientDetails
      .getClientTreatmentPlanList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ treatmentPlans });
  },
);

export default onGetTreatmentPlans;

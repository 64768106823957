import { action } from 'easy-peasy';
import { setTeamMemberDashboardFilter } from '~/services/localStorage/filters';

import { IActionSetTeamMemberDashboardFilters } from '../types';

const setTeamMemberDashboardFilters: IActionSetTeamMemberDashboardFilters = action(
  (state, payload) => {
    // save filters to storage
    setTeamMemberDashboardFilter({ ...state.teamMemberDashboard.filters, ...payload });

    state.teamMemberDashboard.filters = { ...state.teamMemberDashboard.filters, ...payload };
  },
);

export default setTeamMemberDashboardFilters;

import { IShelter } from '~/services/api/shelters/types';
import { IOption } from '~/types';
import formatShelterAddress from '~/ui/pages/Clinics/ViewClinic/Shelters/helpers/formatShelterAddress';

const formatSheltersOptions = (shelters: IShelter[]): IOption[] =>
  shelters.map(item => ({
    label: item.name,
    value: item.id,
    description: formatShelterAddress(item.address),
  }));

export default formatSheltersOptions;

import { IAddressExtended } from '~/services/api/types';

const formatClientAddress = (item: IAddressExtended): string =>
  item
    ? `${item.shelterName ? `${item.shelterName.trim()}, ` : ''}${
        item.line1 ? `${item.line1.trim()}, ` : ''
      }${item.line2 ? `${item.line2.trim()}, ` : ''}${
        item.poBox ? `PO Box ${item.poBox.trim()}, ` : ''
      }${item.city?.trim()}, ${
        item.state?.abbreviation?.trim() || item.state?.name?.trim() || ''
      } ${item.zipCode?.trim()}`
    : '';

export default formatClientAddress;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IOrganizationActions, IOrganizationStore, IThunkUpdateOrganization } from '../types';

const onUpdateOrganization: IThunkUpdateOrganization = thunk(
  async ({ setOrganizations }: Actions<IOrganizationActions>, payload, { getState }) => {
    const localState = getState() as IOrganizationStore;

    const { requestInfo, requestPayload } = payload;

    const organization = await api.organization
      .updateOrganization(requestInfo.id, requestPayload)
      .then(r => r.data);

    const organizations = localState.list.map(item =>
      item.id !== organization.id ? item : organization,
    );

    setOrganizations({ items: organizations });
  },
);

export default onUpdateOrganization;

import { action } from 'easy-peasy';

import { IActionSetVisitStatusOverviewReport } from '../types';

const setVisitStatusOverviewReport: IActionSetVisitStatusOverviewReport = action(
  (state, payload) => {
    const { items, pagination } = payload;

    state.visitStatus.list =
      pagination.pageNumber === 1 ? items : [...state.visitStatus.list, ...items];
    state.visitStatus.pagination = pagination;
  },
);

export default setVisitStatusOverviewReport;

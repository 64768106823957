import onArchiveAllergy from './onArchiveAllergy';
import onArchiveDeEscalation from './onArchiveDeEscalation';
import onArchiveMedication from './onArchiveMedication';
import onArchiveSecondaryDiagnosis from './onArchiveSecondaryDiagnosis';
import onArchiveSupportContact from './onArchiveSupportContact';
import onArchiveTrigger from './onArchiveTrigger';
import onCreateAllergy from './onCreateAllergy';
import onCreateDeEscalation from './onCreateDeEscalation';
import onCreateMedication from './onCreateMedication';
import onCreateSecondaryDiagnosis from './onCreateSecondaryDiagnosis';
import onCreateSupportContact from './onCreateSupportContact';
import onCreateTrigger from './onCreateTrigger';
import onGetAllergies from './onGetAllergies';
import onGetAllergy from './onGetAllergy';
import onGetDeEscalation from './onGetDeEscalation';
import onGetDeEscalationTechniques from './onGetDeEscalationTechniques';
import onGetDiagnoses from './onGetDiagnoses';
import onGetMedication from './onGetMedication';
import onGetMedications from './onGetMedications';
import onGetSecondaryDiagnosis from './onGetSecondaryDiagnosis';
import onGetSupportContact from './onGetSupportContact';
import onGetSupportContacts from './onGetSupportContacts';
import onGetTrigger from './onGetTrigger';
import onGetTriggers from './onGetTriggers';
import onRestoreAllergy from './onRestoreAllergy';
import onRestoreDeEscalation from './onRestoreDeEscalation';
import onRestoreMedication from './onRestoreMedication';
import onRestoreSecondaryDiagnosis from './onRestoreSecondaryDiagnosis';
import onRestoreSupportContact from './onRestoreSupportContact';
import onRestoreTrigger from './onRestoreTrigger';
import onUpdateAllergy from './onUpdateAllergy';
import onUpdateDeEscalation from './onUpdateDeEscalation';
import onUpdateMedication from './onUpdateMedication';
import onUpdateSecondaryDiagnosis from './onUpdateSecondaryDiagnosis';
import onUpdateSupportContact from './onUpdateSupportContact';
import onUpdateTrigger from './onUpdateTrigger';
/** CLIENT ADDITIONAL ADDRESSES */
import onArchiveAddress from './onArchiveAddress';
import onCreateAddress from './onCreateAddress';
import onGetAddress from './onGetAddress';
import onGetAddresses from './onGetAddresses';
import onRestoreAddress from './onRestoreAddress';
import onUpdateAddress from './onUpdateAddress';
/** CLIENT ADDITIONAL PHONE NUMBERS */
import onArchivePhone from './onArchivePhone';
import onCreatePhone from './onCreatePhone';
import onGetPhone from './onGetPhone';
import onRestorePhone from './onRestorePhone';
import onUpdatePhone from './onUpdatePhone';
/** TREATMENT PLANS */
import onDeleteTreatmentPlan from './onDeleteTreatmentPlan';
import onCreateTreatmentPlan from './onCreateTreatmentPlan';
import onGetTreatmentPlan from './onGetTreatmentPlan';
import onGetTreatmentPlans from './onGetTreatmentPlans';
import onCompleteTreatmentPlan from './onCompleteTreatmentPlan';
import onUpdateTreatmentPlan from './onUpdateTreatmentPlan';
import onUpdateOwnTreatmentPlan from './onUpdateOwnTreatmentPlan';
import onCompleteOwnTreatmentPlan from './onCompleteOwnTreatmentPlan';
import onActivateOwnTreatmentPlan from './onActivateOwnTreatmentPlan';
/** MEMOS */
import onEditMemo from './onEditMemo';
/** STATUS */
import onChangeClientStatus from './onChangeClientStatus';
import onChangeClientStatusDate from './onChangeClientStatusDate';
import onGetClientStatus from './onGetClientStatus';
/** LOCATION GROUP */
import onSetClientLocationGroup from './onSetClientLocationGroup';

export default {
  onGetDeEscalationTechniques,
  onGetDiagnoses,
  onGetMedications,
  onGetTriggers,
  onGetTrigger,
  onCreateTrigger,
  onUpdateTrigger,
  onArchiveTrigger,
  onRestoreTrigger,
  onCreateMedication,
  onUpdateMedication,
  onArchiveMedication,
  onRestoreMedication,
  onGetMedication,
  onCreateDeEscalation,
  onUpdateDeEscalation,
  onGetDeEscalation,
  onArchiveDeEscalation,
  onRestoreDeEscalation,
  onCreateSecondaryDiagnosis,
  onArchiveSecondaryDiagnosis,
  onRestoreSecondaryDiagnosis,
  onGetSecondaryDiagnosis,
  onUpdateSecondaryDiagnosis,
  onGetSupportContacts,
  onArchiveSupportContact,
  onRestoreSupportContact,
  onGetSupportContact,
  onCreateSupportContact,
  onUpdateSupportContact,
  onCreateAllergy,
  onUpdateAllergy,
  onGetAllergy,
  onGetAllergies,
  onArchiveAllergy,
  onRestoreAllergy,
  /** CLIENT ADDITIONAL ADDRESSES */
  onGetAddresses,
  onArchiveAddress,
  onRestoreAddress,
  onGetAddress,
  onCreateAddress,
  onUpdateAddress,
  /** CLIENT ADDITIONAL PHONE NUMBERS */
  onArchivePhone,
  onRestorePhone,
  onGetPhone,
  onCreatePhone,
  onUpdatePhone,
  /** TREATMENT PLANS */
  onDeleteTreatmentPlan,
  onCompleteTreatmentPlan,
  onGetTreatmentPlan,
  onGetTreatmentPlans,
  onCreateTreatmentPlan,
  onUpdateTreatmentPlan,
  onUpdateOwnTreatmentPlan,
  onCompleteOwnTreatmentPlan,
  onActivateOwnTreatmentPlan,
  /** MEMOS */
  onEditMemo,
  /** STATUS */
  onChangeClientStatus,
  onChangeClientStatusDate,
  onGetClientStatus,
  /** LOCATION GROUP */
  onSetClientLocationGroup,
};

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClinicActions, IThunkGetClinics } from '../types';

const onGetClinics: IThunkGetClinics = thunk(
  async ({ setClinics }: Actions<IClinicActions>, payload) => {
    const { items, total } = await api.clinic.getClinics(payload).then(r => r.data);
    const pagination = { ...payload, total };

    setClinics({ items, pagination });
  },
);

export default onGetClinics;

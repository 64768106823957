import { ReactElement, ReactNode, createContext, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import useQuery from '~/store/medicationLogistics/hooks/useQuery';

interface IUseQueryParam {
  setQuery: (n: string | string[], v: string | string[]) => void;
}

const UrlQueryContext = createContext({} as IUseQueryParam);
export const UrlQueryContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const query = useQuery();

  const setQuery = (n: string | string[], v: string | string[]) => {
    if (
      (typeof n === 'object' && typeof v === 'string') ||
      (typeof v === 'object' && typeof n === 'string' && v !== null)
    ) {
      throw new Error('Name and value must be same type');
    }

    if (typeof n === 'object' && typeof v === 'object') {
      n.forEach((item: string, index: number) => {
        if (v[index]) {
          const isSame = query.get(item) === v[index];
          if (!isSame) {
            query.set(item, v[index]);
          }
        } else {
          query.delete(item);
        }
      });
    }

    if (typeof n === 'string' && typeof v === 'string') {
      if (v) {
        const isSame = query.get(n) === v;
        if (!isSame) {
          query.set(n, v);
        }
      } else {
        query.delete(n);
      }
    }

    navigate({
      pathname,
      search: query.toString(),
    });
  };

  return <UrlQueryContext.Provider value={{ setQuery }}>{children}</UrlQueryContext.Provider>;
};

const useQueryParam = (
  name: string | string[],
  value: string | string[],
  delay = 0,
): IUseQueryParam => {
  const { setQuery } = useContext(UrlQueryContext);

  useEffect(() => {
    setTimeout(() => {
      setQuery(name, value);
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { setQuery };
};

export default useQueryParam;

import onLogin from './onLogin';
import onLogout from './onLogout';
import onCheckAuthorization from './onCheckAuthorization';
import onChangeRole from './onChangeRole';
import onUploadAvatar from './onUploadAvatar';
import onRemoveAvatar from './onRemoveAvatar';
import onGetSendbirdData from './onGetSendbirdData';
import onGetCurrentProfile from './onGetCurrentProfile';
import onChangePassword from './onChangePassword';

export default {
  onCheckAuthorization,
  onChangeRole,
  onLogin,
  onLogout,
  onUploadAvatar,
  onRemoveAvatar,
  onGetSendbirdData,
  onGetCurrentProfile,
  onChangePassword,
};

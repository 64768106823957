import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';
import { IProgramAssistantModel } from './types';

const programAssistant: IProgramAssistantModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default programAssistant;

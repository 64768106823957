import { red } from '~/ui/constants/colors';
import { IStyleProperty } from '../SelectEmailDomain/customStyles';

const customStyles = {
  indicatorSeparator: (): IStyleProperty => ({
    display: 'none',
  }),
  multiValue: (provided: IStyleProperty): IStyleProperty => ({
    ...provided,
    color: red,
    backgroundColor: '#FFFBFA',
    border: `1px solid ${red}`,
    borderRadius: 25,
    maxWidth: 150,
    ':last-child': {
      minWidth: 22,
    },
  }),
  multiValueLabel: (provided: IStyleProperty): IStyleProperty => ({
    ...provided,
    color: '#323232',
  }),
  multiValueRemove: (provided: IStyleProperty): IStyleProperty => ({
    ...provided,
    ':hover': {
      cursor: 'pointer',
    },
  }),
  menuPortal: (provided: IStyleProperty): IStyleProperty => ({
    ...provided,
    zIndex: 10000,
  }),
};

export default customStyles;

import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { ISosRequest, ISosRequestsParams } from './types';

export const getSosRequests = (
  clinicId: string,
  params: ISosRequestsParams,
): IResponse<{ items: ISosRequest[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/account-management/panic-requests`,
    params,
  });

export const rejectSosRequest = ({
  clinicId,
  id,
}: {
  clinicId: string;
  id: number;
}): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/account-management/panic-requests/${id}/mark-read`,
  });

export const approveSosRequest = ({
  clinicId,
  teamId,
  userId,
}: {
  clinicId: string;
  teamId: string;
  userId: string;
}): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/team/${teamId}/account-management/panic-requests/${userId}/approve`,
  });

export default {};

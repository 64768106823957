import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import {
  ISystemAlertParams,
  ISystemAlert,
  ISystemAlertRequest,
  ISystemAlertUpdatesItem,
} from './types';

export const getSystemAlerts = (
  params?: ISystemAlertParams,
): IResponse<{ total: number; items: ISystemAlert[] }> =>
  privateAxios({
    method: 'get',
    url: 'organization-alerts/list',
    params,
  });

export const getSystemAlertUpdates = (
  params?: ISystemAlertParams,
): IResponse<{ total: number; items: ISystemAlertUpdatesItem[] }> =>
  privateAxios({
    method: 'get',
    url: `organization-alerts/updates`,
    params,
  });

export const readSystemAlert = (id: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `organization-alerts/${id}/acknowledge`,
  });

export const readAllSystemAlert = (ids: number[]): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `organization-alerts/acknowledge-list`,
    data: { ids },
  });

export const addSystemAlert = (data: ISystemAlertRequest): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: 'organization-alerts',
    data,
  });

export default {};

import { ILocationGroup, ILocationGroupRequest } from '~/services/api/locationGroups/types';

const formatLocationGroupDefaultValues = (
  locationGroup: ILocationGroup,
): ILocationGroupRequest | undefined =>
  locationGroup
    ? {
        name: locationGroup.name,
      }
    : undefined;

export default formatLocationGroupDefaultValues;

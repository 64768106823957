import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IReportsActions, IReportsStore, IThunkGetDemographicsReport } from '../types';

const onGetDemographicsReportByTeam: IThunkGetDemographicsReport = thunk(
  async (
    { setDemographicsReport }: Actions<IReportsActions>,
    { requestInfo, params },
    { getState },
  ) => {
    const clients = await api.reports
      .getDemographicsReportByTeam(requestInfo, params)
      .then(r => r.data);

    const localState = getState() as IReportsStore;

    const { teamId } = requestInfo;

    const teams = localState.demographics.report.teams.map(item =>
      item.id !== teamId ? item : { ...item, clients: [...item.clients, ...clients] },
    );

    setDemographicsReport({
      ...localState.demographics.report,
      teams,
    });
  },
);

export default onGetDemographicsReportByTeam;

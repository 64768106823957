import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { NOTIFICATIONS, PROFILE } from '~/ui/constants/paths';
import Avatar from '~/ui/components/common/Avatar';
import UnreadNotificationsIndicator from './UnreadNotificationsIndicator';
import useRole from '~/store/user/hooks/useRole';
import PrivateImage from '~/ui/components/common/PrivateImage';

import AlertsOrderBy from '~/ui/pages/Notifications/models/alertsOrderBy';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import logo from '~/ui/assets/images/logo.svg';
import { ReactComponent as NotificationsIcon } from '~/ui/assets/images/notifications.svg';
import styles from './Header.module.scss';

const DEFAULT_PAGINATION = { pageNumber: 1, pageSize: 25 };

const Header = (): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isNotificationsPage = pathname === '/notifications';

  const { firstName, lastName, photo, clinic } = useStoreState(state => state.user.current);
  const { current } = useStoreState(state => state.clinic);
  const { logo: clinicLogo } = current || {};
  const { list: sosAlerts } = useStoreState(state => state.sosRequests);
  const { list: deleteRequests } = useStoreState(state => state.deleteRequests);
  const { items: systemAlerts } = useStoreState(state => state.systemAlert.systemAlertUpdates);

  const unreadSosAlerts = useMemo(() => sosAlerts.filter(item => !item.isRead), [sosAlerts]);
  const unreadSystemAlerts = useMemo(
    () => systemAlerts.filter(item => !item.acknowledged),
    [systemAlerts],
  );

  const { isLocalAdmin, isProgramAssistant, isActTeam } = useRole();

  const hasUnreadNotifications =
    isLocalAdmin || isProgramAssistant
      ? !!unreadSosAlerts.length || !!deleteRequests.length || !!unreadSystemAlerts.length
      : !!unreadSystemAlerts.length;

  const fullName = `${firstName} ${lastName}`;

  const showNotifications = isLocalAdmin || isProgramAssistant || isActTeam;

  const onGetDeleteRequests = useStoreActions(
    actions => actions.deleteRequests.onGetDeleteRequests,
  );
  const onGetSosRequests = useStoreActions(actions => actions.sosRequests.onGetSosRequests);
  const onGetSystemAlertUpdates = useStoreActions(
    actions => actions.systemAlert.onGetSystemAlertUpdates,
  );

  const handleReceiveRequests = useCallback(() => {
    onGetDeleteRequests({
      clinicId: String(clinic?.id),
      params: DEFAULT_PAGINATION,
    });
  }, [clinic?.id, onGetDeleteRequests]);

  const handleReceiveSosRequests = useCallback(() => {
    onGetSosRequests({
      clinicId: String(clinic?.id),
      params: {
        ...DEFAULT_PAGINATION,
        onlyActive: true,
        sorting: AlertsOrderBy.DATE,
        sortDirection: SortDirection.Desc,
      },
    });
  }, [clinic?.id, onGetSosRequests]);

  const handleReceiveSystemAlerts = useCallback(() => {
    onGetSystemAlertUpdates({ ...DEFAULT_PAGINATION, onlyActive: true });
  }, [onGetSystemAlertUpdates]);

  // get notifications on route change
  useEffect(() => {
    if (pathname && showNotifications && !isActTeam && clinic?.id && !isNotificationsPage) {
      handleReceiveRequests();
      handleReceiveSosRequests();
    }
  }, [
    pathname,
    showNotifications,
    isActTeam,
    clinic?.id,
    isNotificationsPage,
    handleReceiveSosRequests,
    handleReceiveRequests,
  ]);

  // get system alerts on route change
  useEffect(() => {
    if (pathname && showNotifications && !isNotificationsPage) {
      handleReceiveSystemAlerts();
    }
  }, [pathname, showNotifications, isNotificationsPage, handleReceiveSystemAlerts]);

  return (
    <header className={styles.header}>
      <div className={styles.leftContainer}>
        {clinicLogo ? (
          <PrivateImage className={styles.image} src={clinicLogo} height={30} alt="clinic-logo" />
        ) : (
          <img src={logo} alt="logo" />
        )}
      </div>
      <div className={styles.rightContainer}>
        {!!clinicLogo && (
          <Box className={styles.poweredBy}>
            <Typography variant="body2">Powered by VelloHealth</Typography>
            <img src={logo} alt="logo" />
          </Box>
        )}
        {showNotifications && (
          <Box className={styles.notificationsButton}>
            <IconButton onClick={() => navigate(NOTIFICATIONS)}>
              <NotificationsIcon />
              {hasUnreadNotifications && <UnreadNotificationsIndicator />}
            </IconButton>
          </Box>
        )}
        <div className={styles.block}>
          <div role="presentation" className={styles.inner} onClick={() => navigate(PROFILE)}>
            <Avatar url={photo} />
            <span className={styles.title}>{fullName || 'Nathaniel Weiss'}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ISystemAlertActions, ISystemAlertStore, IThunkOnAddSystemAlert } from '../types';

const onAddSystemAlert: IThunkOnAddSystemAlert = thunk(
  async ({ setSystemAlertsPagination }: Actions<ISystemAlertActions>, payload, { getState }) => {
    await api.systemAlert.addSystemAlert(payload);

    const localState = getState() as ISystemAlertStore;

    setSystemAlertsPagination({ ...localState.pagination, total: localState.pagination.total + 1 });
  },
);

export default onAddSystemAlert;

import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, ref } from 'yup';

import { passwordValidation } from '~/ui/pages/ChangePassword/components/ChangePasswordForm/validate';

const validationSchema = object().shape({
  password: passwordValidation,
  passwordConfirmation: string().oneOf(
    [ref('password'), null],
    'The password entry fields do not match. Please enter the same password in both fields and try again',
  ),
});

export default yupResolver(validationSchema);

import debounce from 'lodash/debounce';
import { ReactElement, useMemo } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { ValueType } from 'react-select';

import { useStoreActions, useStoreState } from '~/store/hooks';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';

import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import OptionWithLastRef from '~/ui/components/inputs/SelectWithoutAnimation/components/OptionWithLastRef';

import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/types';

interface IMedicationSelectProps {
  loading: boolean;
  control: Control<any>;
  errors: FieldErrors;
  label?: string;
  isDisabled?: boolean;
  loadMore?: () => void;
  onSelect?: (value: IOption) => void;
}

const MedicationSelect = ({
  loading,
  control,
  errors,
  label = 'Select Medication',
  isDisabled,
  loadMore,
  onSelect,
}: IMedicationSelectProps): ReactElement => {
  const { list: availableMedications } =
    useStoreState(store => store.dictionaries.availableDictionaries[IDictionaryTypes.Medication]) ||
    {};

  const { setAvailableDictionaryFilters } = useStoreActions(action => action.dictionaries);

  const medicationOptions = useMemo(
    () => formatDictionaryOptions(availableMedications || []),
    [availableMedications],
  );

  const handleSelectOption = (option: ValueType<IOption | IOption[], boolean>) => {
    onSelect?.(option as IOption);
  };

  const setValueDebounced = debounce(
    name =>
      setAvailableDictionaryFilters({ type: IDictionaryTypes.Medication, name, pageNumber: 1 }),
    500,
  );

  return (
    <Select
      options={medicationOptions}
      name="id"
      control={control}
      label={label}
      errors={errors}
      hideSelectedOptions
      isDisabled={isDisabled}
      onInputChange={setValueDebounced}
      customComponents={{
        Option: props => (
          <OptionWithLastRef
            {...props}
            loadMore={loadMore && !loading ? debounce(loadMore, 1000) : undefined}
          />
        ),
      }}
      onSelect={handleSelectOption}
      isLoading={loading}
    />
  );
};

export default MedicationSelect;
